import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Dropdown } from 'primereact/dropdown';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import Alert from '../../../components/atoms2/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import "react-datepicker/dist/react-datepicker.css";
//ACTIONS AND CONTEXT
import { RestActions } from "../../../actions";
import { useMainContext } from "../../../contexts/MainContext";
import { useCatalogsContext } from "../../../contexts/CatalogsContext";
//COMPONENTS
import InputFormCol from "../../../components/atoms/InputFormCol";

const ControlView = ({ phaseID, gatheringInformationID, showModal, handleGatheringInfoID, handleRefreshList }) => {
    const rest = new RestActions();
    let mainContext = useMainContext();
    let catalogs = useCatalogsContext();

    const [formData, setFormData] = useState({ amount: null, listNumber: null, typicalSeparationValue: null, levels: [], isPreferencialRate: false, isSeparationPercentage: false });
    const [closedAtDate, setClosedAtDate] = useState(false)
    useEffect(() => {
        if (showModal)
            getInfo();
    }, [showModal])

    const getInfo = async () => {
        mainContext.setIsLoading(true);
        let params = { phaseID: parseInt(phaseID), isNew: true }
        if (gatheringInformationID) {
            params.gatheringInformationID = gatheringInformationID;
            params.isNew = false;
        }
        try {
            let response = await rest.post("getGatheringInformation", params);
            if (gatheringInformationID) {
                response[0].levels = groupBy(response[0].units);
                var dateParts = response[0].gatheringAt.split("/");
                setClosedAtDate(new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]))


                setFormData(response[0]);

            }
            else
                setFormData({ ...formData, levels: groupBy(response) });

            mainContext.setIsLoading(false);
        }
        catch (error) {/*TODO SOMETHING*/ }
    }

    const groupBy = (arr) => {
        _.forEach(arr, (row, index) => {
            arr[index].unitsGatheringInformation = row.unitsGatheringInformation;
        })

        let group = _.uniqBy(arr, (row) => { return row.levels.levelNumber; });
        _.forEach(group, (row, index) => {
            group[index].units = _.filter(arr, (row2) => { return row2.levels.levelNumber == row.levels.levelNumber });
        })
        return group;
    }

    const resetModal = async () => {
        setFormData({ amount: null, listNumber: null, typicalSeparationValue: null, levels: [], isPreferencialRate: false, isSeparationPercentage: false });
        handleGatheringInfoID(null);
        mainContext.setIsLoading(false);
        mainContext.setIsDirty(false);
    }

    function format(num, _decimal = 2) {
        if (!num)
            return num;
        if (!isNaN(parseFloat(num)))
            num = parseFloat(num).toFixed(_decimal);
        return ('' + num).replace(
            /(\d)(?=(?:\d{3})+(?:\.|$))|(\.\d\d?)\d*$/g,
            function (m, s1, s2) {
                return s2 || (s1 + ',');
            }
        );
    }
    const formatDate = (date) => {
        let year = date.getFullYear()
        let month = date.getMonth() + 1
        month = `${month}`
        month = month.length === 1 ? `0${month}` : month
        let formatedDate = `01/${month}/${year}`
        return formatedDate;
    }
    return (
        <Modal className="px-4" size={"models"} show={showModal} onHide={resetModal} backdrop="static">
            <Modal.Header className="no-border" closeButton>
                <Modal.Title className="pl-3"></Modal.Title>
            </Modal.Header>
            <Modal.Body className="mx-3">
                <div className="custom-card">
                    <div className="custom-card-header">
                        <h4 className="py-3 px-3">
                            VER LEVANTAMIENTO
                        </h4>
                    </div>
                    <div className="custom-card-body px-3">
                        {(gatheringInformationID && formData.closedAt != "-" && formData.saleStatus) &&
                            <>
                                <div className="row mt-4 input-height-30">
                                    <InputFormCol label="Unidades totales" col="col-4" withMessage={false}>
                                        <input value={format(formData.saleStatus[0].totalUnits)} disabled={true} className="form-control black-border large-input w-75" type="text" />
                                    </InputFormCol>
                                    <InputFormCol label="Área vendible" col="col-4" withMessage={false}>
                                        <input value={format(formData.saleStatus[0].totalM2)} disabled={true} className="form-control black-border large-input w-75" type="text" />
                                    </InputFormCol>
                                    <InputFormCol label="Precio promedio inv." col="col-4" withMessage={false}>
                                        <input value={format(formData.saleStatus[0].avgAmountForSale)} disabled={true} className="form-control black-border large-input w-75" type="text" />
                                    </InputFormCol>
                                </div>
                                <div className="row input-height-30">
                                    <InputFormCol label="Unidades vendidas" col="col-4" withMessage={false}>
                                        <input value={format(formData.saleStatus[0].soldUnits)} disabled={true} className="form-control black-border large-input w-75" type="text" />
                                    </InputFormCol>
                                    <InputFormCol label="Área vendida" col="col-4" withMessage={false}>
                                        <input value={format(formData.saleStatus[0].soldArea)} disabled={true} className="form-control black-border large-input w-75" type="text" />
                                    </InputFormCol>
                                    <InputFormCol label={<span>$/M<sup>2</sup></span>} col="col-4" withMessage={false}>
                                        <input value={format(formData.saleStatus[0].avgPriceM2)} disabled={true} className="form-control black-border large-input w-75 d-block" type="text" />
                                    </InputFormCol>
                                </div>
                                <div className="row input-height-30">
                                    <InputFormCol label="% Avance ventas" col="col-4" withMessage={false}>
                                        <input value={format(formData.saleStatus[0].saleAdvance)} disabled={true} className="form-control black-border large-input w-75" type="text" />
                                    </InputFormCol>
                                    <InputFormCol label="% Avance área vendida" col="col-4" withMessage={false}>
                                        <input value={format(formData.saleStatus[0].advanceSoldArea)} disabled={true} className="form-control black-border large-input w-75" type="text" />
                                    </InputFormCol>
                                </div>
                                <hr />
                            </>
                        }
                        <div className="row input-height-30">
                            <InputFormCol label="Tasa preferencial" col="col-4" withMessage={false}>
                                <input name="isPreferencialRate" checked={formData.isPreferencialRate} className="d-block" type="checkbox" />
                            </InputFormCol>

                            <InputFormCol label="Valor de cambio" col="col-4" withMessage={false}>
                                <input value={format(formData.amount)} disabled={true} className="form-control w-75" />
                            </InputFormCol>

                            <InputFormCol label="Fecha de levantamiento" col="col-4" withMessage={false}>
                                {/*<input value={formData.closedAt} name="closedAt" maxLength="50" disabled={true} className="form-control black-border large-input w-75" type="text" />*/}

                                <DatePicker
                                    selected={closedAtDate}
                                    disabled={true}

                                    onChange={date => {
                                        setClosedAtDate(date)
                                        setFormData({ ...formData, gatheringAt: formatDate(new Date(date)) })
                                        mainContext.setIsDirty(true);
                                    }}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                    className={"small-input black-border form-control"}
                                    placeholderText="Seleccionar"
                                    required={true}
                                    id={"startDate"}
                                />
                                {
                                    (!formData.closedAt) &&
                                    <Alert className="input__alert-error mt-2" theme={"error"} type="error" size={"large"}>
                                        *Campo obligatorio.
                                    </Alert>
                                }

                            </InputFormCol>
                        </div>
                        <div className="row input-height-30">
                            <InputFormCol label="¿Separación en %?" col="col-4" withMessage={false}>
                                <Form.Check checked={formData.isSeparationPercentage} type="switch" label="" id="isSeparationPercentage" disabled={true} className="mt-2" />
                            </InputFormCol>

                            <InputFormCol label="Separación típica" col="col-4" withMessage={false}>
                                <div className="input-group w-75">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            {(formData.isSeparationPercentage) ? "%" : "$"}
                                        </span>
                                    </div>
                                    <input value={format(formData.typicalSeparationValue)} disabled={true} className="form-control w-75" />
                                </div>
                            </InputFormCol>

                            <InputFormCol label="Lista No." col="col-4" withMessage={false}>
                                <input value={formData.listNumber} disabled={true} className="form-control w-75 d-block" />
                            </InputFormCol>
                        </div>
                    </div>
                </div>
                <div className="custom-card mt-4">
                    <div className="custom-card-header">
                        <h4 className="py-3 px-3">
                            ASIGNACIÓN DE LEVANTAMIENTOS
                        </h4>
                    </div>
                    <div className="custom-card-body px-3">
                        <div className="row">
                            <table className="table-no-border table-striped table-responsive table-levantamientos">
                                <thead>
                                    <tr>
                                        <th className="fixedth1">Nombre</th>
                                        <th className="text-center fixedth2">Modelo</th>
                                        <th className="text-center fixedth3">M<sup>2</sup> totales</th>
                                        <th className="text-center">Tipo</th>
                                        <th className="text-center">Último precio registrado</th>
                                        <th className="text-center">Fecha del último registro</th>
                                        <th className="text-center column-gray" title="Es obligatorio capturar por lo menos dos precios para un mismo modelo, excepto si es la ultima unidad disponible">
                                            Precio actual <span title="Es obligatorio capturar por lo menos dos precios para un mismo modelo, excepto si es la ultima unidad disponible">
                                                <FontAwesomeIcon icon={faQuestionCircle} />
                                            </span><br />
                                            <small className="font-weight-bold"><FontAwesomeIcon icon={faUserCircle} /> = Monto capturado</small>
                                        </th>
                                        <th className="text-center">Precio por M<sup>2</sup></th>
                                        <th className="text-center ">Estado venta <span title="Es necesario actualizar el estado de venta de todos los inmuebles de cada levantamiento"><FontAwesomeIcon icon={faQuestionCircle} /></span></th>
                                        <th className="text-center">Precio de renta</th>
                                        <th className="text-center column-gray">Estado de renta <span title="Es necesario actualizar el estado de venta de todos los inmuebles de cada levantamiento"><FontAwesomeIcon icon={faQuestionCircle} /></span></th>
                                        <th className="text-center">Categoria <br /> (Comercial/oficina) </th>
                                        <th className="text-center">Marca / Empresa </th>
                                        <th className="text-center">Separación</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {formData.levels.map((level, indexLevel) =>
                                    (
                                        <>
                                            <tr key={`row-${indexLevel}`} className="table-row-border-top">
                                                <td className="fixed1" colSpan="6"><b>{level.levels.levelName}</b></td>
                                                <td className="column-border"></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td className=""></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            {level.units.map((unit, indexUnit) =>
                                            (
                                                <tr key={`row-${indexUnit}`}>
                                                    <td className="text-center fixed1">{unit.code}</td>
                                                    <td className="text-center fixed2">{unit.models.name}</td>
                                                    <td className="text-center fixed3">{unit.models.totalM2}</td>
                                                    <td className="text-center">{unit.models.modelTypes.description}</td>
                                                    <td className="text-center">{format(unit.lastPrice, 0)}</td>
                                                    <td className="text-center">{unit.lastDate}</td>
                                                    <td className="text-center column-border">
                                                        {format(unit.unitsGatheringInformation.actualPrice, 0)}
                                                        {unit.unitsGatheringInformation?.actualPrice != "" && unit.unitsGatheringInformation?.actualPrice != null && unit.unitsGatheringInformation.isInterpolated == false ?
                                                            <span className="ml-1"><FontAwesomeIcon icon={faUserCircle} /></span> : <span className="ml-3"></span>

                                                        }

                                                    </td>
                                                    <td className="text-center">{(unit.models.priceM2) ? format(unit.models.priceM2, 0) : "-"}</td>
                                                    <td className="text-center ">
                                                        <Dropdown value={unit.unitsGatheringInformation.saleStatusID} disabled={true} options={catalogs.saleStatus} optionValue="saleStatusID" optionLabel="description" className={`dropdown-color-${unit.unitsGatheringInformation.saleStatusID}`} />
                                                    </td>
                                                    <td className="text-center">
                                                        {unit.unitsGatheringInformation.rentalPrice}
                                                    </td>
                                                    <td className="text-center">
                                                        <Dropdown value={unit.unitsGatheringInformation.rentalStatusID} disabled={true} options={catalogs.rentalStatus} optionValue="rentalStatusID" optionLabel="description" className={`dropdown-color-rent-${unit.unitsGatheringInformation.rentalStatusID}`} />
                                                    </td>
                                                    <td className="text-center">
                                                        <Dropdown value={unit.unitsGatheringInformation.subCategoryID} disabled={true} options={catalogs.subCategories} optionValue="subCategoryID" optionLabel="description" />
                                                    </td>
                                                    <td className="text-center">
                                                        {unit.unitsGatheringInformation.brandName}
                                                    </td>
                                                    <td className="text-center">
                                                        {(formData.isSeparationPercentage && unit.unitsGatheringInformation.separationValue)
                                                            ? format(unit.unitsGatheringInformation.separationValue, 0) + "%"
                                                            : (unit.unitsGatheringInformation.separationValue) && "$" + format(unit.unitsGatheringInformation.separationValue, 0)}
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    )
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-5 offset-7 d-flex justify-content-end">
                        <button onClick={resetModal} className="btn btn-primary w-100 mx-3">Regresar</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
export default ControlView