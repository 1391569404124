import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import deleteImg from '../../../assets/icons/delete-files.svg';
import exclamationIcon from '../../../assets/icons/icon-exclamation-mark.svg';
import './styles.css'
import ImportIcon from '../../../assets/icons/icon-import.svg'
import Dropdown from "../Dropdown";
import { useMainContext } from "../../../contexts/MainContext";
import { useCatalogsContext } from "../../../contexts/CatalogsContext";
import InputCheckboxList from '../InputCheckboxList';
import { RestActions } from "../../../actions";
import { ItemLoading } from "../Loading/itemLoading";

const ModalCreateCity = (props) => {
    const rest = new RestActions();
    let mainContext = useMainContext();
    let catalogs = useCatalogsContext();
    const { showModal, setShow, setCancelAction, handlerAccept, cancel, success, edit, dataUser, nameCatalog, hasStatus } = props
    const [formData, setFormData] = useState({ status: { "id": 1, "status": "Activo" } })
    const [stateData, setStateData] = useState([])
    const [municipalitiesData, setMunicipalitiesData] = useState([])
    const [zoneData, setZoneData] = useState([{}])
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [zoneLoading, setZoneLoading] = useState(false);
    const [municipalitiLoading, setMunicipalitiLoading] = useState(false);



    /**
        * Cierra el modal.
    */
    const handleClose = () => {
        setShow(false);
    }

    const sendData = async () => {
        setIsSubmitted(true)
        let data = { ...formData }
        let states = []
        if (valitation()) {
            data.state.map((el) => {
                states.push(el.stateID)
            })
            data = { ...data, "state": states };

            await handlerAccept(data)
            await setFormData({})
        }

    }
    const valitation = () => {
        if (!formData
            || formData.name === undefined
            || formData.state === undefined
            || formData.country === undefined
            || formData.status === undefined
            || formData.municipalities === undefined
            || formData.municipalities === ""
            || formData.municipalities === null
            || formData.name === ""
            || formData.state === ""
            || formData.country === ""
            || formData.status === ""
            || formData.name === null
            || formData.state === null
            || formData.country === null
            || formData.status === null)
            return false
        else
            return true
    }
    /**
        * Comfirmacion de borrado.
    */
    useEffect(() => {
        setIsSubmitted(false)
        setStateData([]);
        setMunicipalitiesData([]);
        setZoneData([{}]);

        if (edit) {

            let countriesArray = [...new Set(dataUser.countries)];
            let statesArray = [...new Set(dataUser.states)];
            let municipalitiesArray = [...new Set(dataUser.municipalities)];
            let status = dataUser.status ? { "id": 1, "status": "Activo" } : { "id": 0, "status": "Inactivo" };

            setZoneLoading(true);
            setMunicipalitiLoading(true);
            setStateByCountry(countriesArray);
            setMunicipalitiesByStates(statesArray);
            setZonesByMunicipalities(municipalitiesArray);

            setFormData({
                ...formData,
                id: dataUser.id,
                country: initMultiCombo(countriesArray, "countryID", "countryID"),
                status: status,
                state: initMultiCombo(statesArray, "stateID", "stateID"),
                municipalities: initMultiCombo(municipalitiesArray, "municipalitiesID", "municipalitiesID"),
                name: dataUser.name
            })

        } else {
            setFormData({
                ...formData,
                status: { "id": 1, "status": "Activo" },
                name: '',
                country: [],
                state: [],
                municipalities: [],
                id: null
            })
            setZoneLoading(false);
            setMunicipalitiLoading(false);
        }


    }, [showModal, dataUser])


    const onFormDataChange = async (name, e) => {
        setIsSubmitted(false)
        if (name == "status")
            setFormData({ ...formData, [name]: e });
        else
            setFormData({ ...formData, [name]: e.value });
    }

    const setStateByCountry = (data, updateCheck) => {

        if (updateCheck == '' || updateCheck == null || updateCheck == undefined) {
            updateCheck = {
                paramName: 'country',
                data: data.map(element => ({ 'countryID': element }))
            }
        }

        let stateBycountry = []
        data.map((elID) => {

            let result = catalogs.countries.find(({ countryID }) => countryID === elID);

            if (result.states) {
                result.states.map(state => {
                    stateBycountry.push(state)
                })
            }
            else {
                var newArray = formData.country && formData.country.filter((item) => item.countryID !== elID); // esto da error cuando no tiene datos

                console.log("newArray ", newArray);
                // setFormData({ ...formData, country: newArray });
                mainContext.setMessageAlert(`El Pais ${result.countryName} no tiene estados`);
            }


        })

        console.log("stateBycountry ", stateBycountry);
        setFormData({
            ...formData,
            countryAllId: data,
            [updateCheck.paramName]: updateCheck.data
        });
        setStateData([...stateBycountry])
    }

    const setMunicipalitiesByStates = async (data, updateCheck) => {

        if (updateCheck == '' || updateCheck == null || updateCheck == undefined) {
            updateCheck = {
                paramName: 'state',
                data: data.map(element => ({ 'stateID': element }))
            }
        }

        let municipalities = [];

        if (data.length >= 1) municipalities = await rest.post("getMunicipalities", { stateID: data });

        setFormData({ ...formData, stateAllId: data, [updateCheck.paramName]: updateCheck.data });
        setMunicipalitiesData(municipalities[0]);
        setMunicipalitiLoading(false);

    }

    // Buscamos la zonas de la municipalidad 
    const setZonesByMunicipalities = async (data, updateCheck) => {
        // let _catalogs = await rest.post("getCatalogs", { phaseID: window.sessionStorage.getItem("phaseID") });
        if (updateCheck == '' || updateCheck == null || updateCheck == undefined) {
            updateCheck = {
                paramName: 'municipalities',
                data: data.map(element => ({ 'municipalitiesID': element }))
            }
        }

        let zone = [{}];

        if (data.length >= 1) (zone = await rest.post("getZonesByMunicipalities", { municipalitiesID: data }));

        setZoneData(zone[0]);
        setFormData({ ...formData, municipalitiesAllId: data, [updateCheck.paramName]: updateCheck.data });
        setZoneLoading(false);
    }

    const handleCheckboxList = async (paramName, data) => {
        setIsSubmitted(false)

        await setFormData({ ...formData, [paramName]: data });

        let updateCheck = { paramName: paramName, data: data };

        let allID = [];

        if (paramName == "country") {
            // data = { countryID: 51 }
            allID = data.map(item => item.countryID);
            if (allID.length > 1) { allID = [allID[allID.length - 1]]; updateCheck.data = [{ 'countryID': allID[0] }] }
            return setStateByCountry(allID, updateCheck);
        }

        if (paramName == "state") {
            // data = { stateID: 51 }
            setMunicipalitiLoading(true);

            allID = data.map(item => item.stateID);
            return setMunicipalitiesByStates(allID, updateCheck);
        }

        if (paramName == 'municipalities') {
            setZoneLoading(true);
            // data = { municipalitiesID: 51 }

            allID = data.map(item => item.municipalitiesID);
            return setZonesByMunicipalities(allID, updateCheck);
        }


    }
    const initCombo = (catalog, filterBy1, filterBy2) => {
        let status = [{ "id": 1, "status": "Activo" }, { "id": 0, "status": "Inactivo" }];
        if (catalog == "status") {
            let dato = status.filter((element) => { return element[filterBy1] == filterBy2 })[0];
            return dato;
        }
        else
            return null;
    }
    const initMultiCombo = (_data, paramName, idName) => {
        let formattedData = [];
        _data.forEach(element => {
            // formattedData.push({ [idName]: element[paramName] });
            formattedData.push({ [idName]: element });
        });
        return formattedData;
    }

    return (
        //TODO: Fix styles
        <section>
            <Modal size={"lg"} show={showModal} onHide={() => { handleClose() }} backdrop="static" centered >
                <Modal.Header className="no-border p-1" closeButton>
                    <Modal.Title className="pl-3"></Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div className="custom-card">
                        <div className="custom-card-header px-3 d-flex">
                            <h4 className="py-3">{edit ? "EDITAR" : "AGREGAR"} Ciudad</h4>
                            <div className=" py-2 d-flex justify-content-end">
                                {/*<button onClick={() => { setShowModal(true) }} className="btn btn-primary"><span className="d-inline-block pr-4">Nueva importación</span> +</button>*/}.
                            </div>
                        </div>
                        <div className="custom-card-body px-3">
                            <div className="row">
                                <div className="form-group with-message col-6">
                                    <label className="input-label form-label">
                                        * Nombre
                                    </label>
                                    <div className="">
                                        <input type="text" placeholder='Monterrey' value={formData.name} onChange={(e) => { onFormDataChange("name", e.target) }} maxLength="300" className="form-control black-border large-input" />
                                        {
                                            (isSubmitted && !formData.name) &&
                                            <p className="error">*Campo obligatorio.</p>
                                        }
                                    </div>
                                </div>
                                <div className="col-6 form-group with-message">
                                    <label className="input-label form-label">
                                        * Estatus
                                    </label>
                                    <div className="">
                                        {
                                            (isSubmitted && !formData.status) &&
                                            <p className="error">*Campo Estatus es obligatorio.</p>
                                        }
                                        {/*<input type="file" onChange={e => getFileBase64(e.target.files[0])} maxLength="50" className="form-control black-border large-input" />*/}
                                        <Dropdown value={formData.status} options={[{ "id": 1, "status": "Activo" }, { "id": 0, "status": "Inactivo" }]} onChange={(e) => { onFormDataChange("status", e.value) }} optionValue="id" optionLabel="status" className="w-100" />
                                    </div>
                                </div>
                                <div className="col-3 form-group with-message">
                                    <label className="input-label form-label">
                                        * País
                                    </label>
                                    <div className="">
                                        {
                                            (isSubmitted && !formData.country) &&
                                            <p className="error">*Campo país es obligatorio.</p>
                                        }
                                        <InputCheckboxList cssClass="modal-checkbox" handleData={handleCheckboxList} catalog={catalogs.countries} currentData={formData.country} idProperty="countryID" paramName="country" displayName="countryName" idx="countryID" />

                                    </div>
                                </div>
                                <div className="col-3 form-group with-message">
                                    <label className="input-label form-label">
                                        * Estados
                                    </label>
                                    <div className="">
                                        {
                                            (isSubmitted && !formData.state) &&
                                            <p className="error">*Campo de estados es obligatorio.</p>
                                        }
                                        <InputCheckboxList cssClass="modal-checkbox" handleData={handleCheckboxList} catalog={stateData} currentData={formData.state} idProperty="stateID" paramName="state" displayName="stateName" idx="stateID" />
                                    </div>
                                </div>
                                <div className="col-3 form-group with-message">
                                    <label className="input-label form-label">
                                        * Municipio
                                    </label>
                                    <div className="modal-checkbox">
                                        {
                                            (isSubmitted && !formData.municipalities) &&
                                            <p className="error">*Campo de Municipio es obligatorio.</p>
                                        }
                                        {municipalitiLoading == true ?
                                            <ItemLoading />
                                            :
                                            <InputCheckboxList cssClass="" handleData={handleCheckboxList} catalog={municipalitiesData} currentData={formData.municipalities} idProperty="municipalitiesID" paramName="municipalities" displayName="municipalitiesName" idx="municipalitiesID" />
                                        }
                                    </div>
                                    {/* <InputCheckboxList cssClass="modal-checkbox" handleData={handleCheckboxList} catalog={catalogs.countries} currentData={formData.country} idProperty="municipalityID" paramName="municipality" displayName="municipalityName" idx="municipalityID" /> */}
                                </div>
                                <div className="col-3 form-group with-message">
                                    <label className="input-label form-label">
                                        Zonas
                                    </label>
                                    <div className="modal-checkbox">
                                        {zoneLoading === true ? <ItemLoading /> : false}
                                        {(zoneLoading === false && zoneData.length >= 1) ? zoneData.map((element, key) =>
                                        (
                                            <p key={key} idZone={element.zone_id}>{element.zone_name}</p>
                                        )
                                        ) : false}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div
                        className="container d-flex justify-content-end mt-5">
                        <button className="btn btn-white " onClick={() => { handleClose() }}>Cancelar</button>

                        <button className="btn btn-primary ml-3" onClick={sendData}>{edit ? "Actualizar" : "Agregar"}</button>
                    </div>
                </Modal.Body>
            </Modal>
        </section>
    )
}
export default ModalCreateCity