import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
//ACTIONS AND CONTEXT
import { RestActions } from "../../actions";
import { useMainContext } from "../../contexts/MainContext";
import { useCatalogsContext } from "../../contexts/CatalogsContext";
//COMPONENTS
import SearchEngineFilters from './SearchEngineFilters';
import SearchEngineList from './SearchEngineList';
import ButtonSettings from '../../components/atoms/ButtonSettings';
import ButtonModalOneInputForm from '../../components/atoms/ButtonModalOneInputForm';
import ModalConfirmCloseSession from '../../components/atoms/ModalConfirmCloseSession';
//ASSETS
import './styles.css';
import logo from '../../assets/img/4SRealEstate_SingleLine.png';
import newProject from '../../assets/icons/new-project.svg';
import admin from '../../assets/icons/icon-admin.svg';


/**
    * Pagina Home
    * @component
*/
const Home = ({ preloadReady }) => {
    const rest = new RestActions();
    let mainContext = useMainContext();
    let catalogs = useCatalogsContext();

    const [dataTable, setDataTable] = useState({});
    const [pageLimit, setPageLimit] = useState(10);
    const [currentFilters, setCurrentFilters] = useState({});
    window.sessionStorage.removeItem('phaseID');

    useEffect(() => {
        if (preloadReady)
            findPhases(1);
    }, [preloadReady, currentFilters]);

    /**
        * Obtiene el catalogo de desarrolladores por medio de petición GET.
    */
    const onDeveloperAdded = async () => {
        catalogs.setDevelopers(await rest.get("getDevelopers"));
    }

    /**
        * Obtiene un listado de fases teneiendo en cuenta los filtros seleccioandos por el usuario
        * @param {number} _pageNumber - numero de pagina actual (por defecto 1)
        * 
    */
    const findPhases = async (_pageNumber) => {
        let onlyFavorites = (Object.keys(currentFilters).length) ? false : true;
        let params = Object.assign({}, currentFilters, { userID: mainContext.session.userID, onlyFavorites: onlyFavorites, pageLimit: pageLimit, pageNumber: _pageNumber })
        mainContext.setIsLoading(true);
        try {
            let response = await rest.post("findPhases", params);
            setDataTable(response);
            mainContext.setIsLoading(false);
        }
        catch (error) {/*TODO SOMETHING*/ }
    }

    return (
        (preloadReady) &&
        <section className="section-list">
            <div className="container">
                <header className="row py-4 px-md-5">
                    <div className="col-4 text-center text-md-left">
                        <img className="home-logo" src={logo} alt="" />
                    </div>
                    <div className="col-12 col-md-8 text-right pt-3">
                        <Link to="/liftingReview">
                            <button className="btn btn-primary mx-2"> <FontAwesomeIcon icon={faEye} /> Revisión de Campo</button>
                        </Link>
                        {mainContext.session.roleID == 1 ? (
                            <Link to="/admin/users">
                                <button className="btn btn-primary mx-2"><img className="mr-2" src={admin} alt="" />Administrador</button>
                            </Link>
                        ) : null}

                        <Link to="/create">
                            <button className="btn btn-primary mx-2"><img className="mr-2" src={newProject} alt="" /> Nuevo proyecto</button>
                        </Link>
                        {/*<ButtonModalOneInputForm buttonType="btn" title={"Nuevo desarrollador"} btnText="Nuevo desarrollador" property={"developerName"} action={"saveDeveloper"} handleOnSaved={onDeveloperAdded} />*/}
                        <ButtonSettings />
                    </div>
                </header>
                <div className="row mt-5 mb-4 px-5">
                    <div className="col col-12">
                        <h1>BIENVENIDO AL PORTAL <br />
                            <hr className="home-hr" /></h1>
                    </div>
                </div>
                <div className="row px-md-5 mb-5">
                    <div className="col col-12">
                        <div className="custom-card">
                            <div className="custom-card-header p-3">
                                <h4>BUSCADOR DE PROYECTOS</h4>
                            </div>
                            <div className="custom-card-body px-3">
                                <SearchEngineFilters handlerFilters={setCurrentFilters} />
                                <SearchEngineList handleRefresh={findPhases} data={dataTable} />
                            </div>
                        </div>
                    </div>
                </div>
                <ModalConfirmCloseSession />
            </div>
        </section>
    )
}
export default Home;