import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from "react-router-dom";

import { animateScroll as scroll } from 'react-scroll';
import _, { forEach } from 'lodash';
//ACTION && CONTEXT
import { RestActions } from "../../actions";
import { useMainContext } from "../../contexts/MainContext";
//COMPONENTS
import GeneralInformation from './GeneralInformation';
import GeneralLocation from './GeneralLocation';
import MenuPhases from '../../components/molecules/MenuPhases';

const General = ({ preloadReady, edition }) => {
    let phaseID = false;
    const rest = new RestActions();
    let mainContext = useMainContext();

    const location = useLocation();
    if (edition) {

        if (window.sessionStorage.getItem("phaseID") == "" || window.sessionStorage.getItem("phaseID") == undefined)
            window.sessionStorage.setItem("phaseID", location.state?.phaseID);
        else
            phaseID = window.sessionStorage.getItem("phaseID");
    }



    const [isSubmited, setIsSubmited] = useState(false);
    const [formData, setFormData] = useState({});
    const [images, setImages] = useState([]);
    const [phaseData, setPhaseData] = useState({});

    useEffect(() => {
        if (preloadReady)
            initPhase();
    }, [preloadReady]);

    const initPhase = async () => {
        mainContext.setIsLoading(true);
        mainContext.setIsDirty(false);
        try {
            if (phaseID) {
                let response = await rest.post("getPhases", { phaseID: parseInt(phaseID) });
                setPhaseData(response[0]);
            }
            else {
                let response = await rest.post("getPhaseCode", { "project_id": 0, "developer_id": 0 });
                setPhaseData({ phaseCode: response, projects: null, phaseID: null });
            }
            mainContext.setIsLoading(false);
        }
        catch (error) {/*TODO SOMETHING*/ }
    }

    const sendForm = async (goNext = false) => {
        setIsSubmited(true);
        if (await validateInfoForm()) {
            mainContext.setIsLoading(true);
            let params = { ...formData };
            params.userID = mainContext.session.userID;
            params.phaseName = params.phaseName.trim();
            params.developerID = params.developerID.id;
            params.developerComplexID = params.complexID.developers[0].developerComplex.developerComplexID;
            params.complexID = params.complexID.complexID;
            params.tradeStatusID = params.tradeStatusID.id;
            params.phaseCode = phaseData.phaseCode;
            //params.complexUses = JSON.stringify(params.complexUses); 
            params.services4S = params.services4S;
            params.marketingSchemeID = params.marketingSchemeID?.marketingSchemeID ? params.marketingSchemeID?.marketingSchemeID : null;
            //params.towers = JSON.stringify(params.towers);

            params.commercialBusinessUses = []
            if (params.towers)

                params.towers.forEach(element => {

                    params.commercialBusinessUses.push({
                        ...element, useTypeID: 3, classificationID: params.classificationsData[element.commercialBusinessID]?.classificationID ? params.classificationsData[element.commercialBusinessID]?.classificationID : null
                    })

                });
            if (params.complexUses)
                params.complexUses.forEach(element => {
                    params.commercialBusinessUses.push({
                        ...element, useTypeID: 2
                    })

                });
            if (params.masterPlanUses)
                params.masterPlanUses.forEach(element => {
                    params.commercialBusinessUses.push({
                        ...element, useTypeID: 1
                    })

                });


            params.phaseID = phaseData.phaseID;
            if (params.masterPlanID)
                params.masterPlanID = params.masterPlanID.masterPlanID
            if (params.classificationID)
                params.classificationID = params.classificationID.id;
            if (params.workProgressID)
                params.workProgressID = params.workProgressID.id;
            if (params.municipalityID)
                params.municipalityID = params.municipalityID.municipalityID;
            if (params.zoneID)
                params.zoneID = params.zoneID.zoneID;
            if (params.levels)
                params.levels = parseInt(params.levels);
            if (params.unitsPerLevel)
                params.unitsPerLevel = parseInt(params.unitsPerLevel);
            if (params.currencyID)
                params.currencyID = parseInt(params.currencyID.currencyID);
            delete params.countryID
            try {
                let response = await rest.post("savePhase", params);
                window.sessionStorage.setItem("phaseID", response.phaseID);
                for (let i = 0; i < images.length; i++) {
                    images[i]["phase_id"] = response.phaseID;
                    images[i]["user_id"] = mainContext.session.userID;
                    await rest.post("savePhaseImages", images[i]);
                }
                mainContext.setIsDirty(false);

                window.location = "/#/phase/general";
                if (edition)
                    initPhase();
                if (goNext)
                    requestNavigation();

            } catch (error) {
                mainContext.setIsLoading(false);
                mainContext.setMessageAlert(error.toString());
            }
        }
        else {
            scroll.scrollTo(180);
        }
    }

    const validateInfoForm = async () => {
        let requiredDataNotFuture = ["startDate", "countryID", "stateID", "municipalityID", "zoneID", "latitud", "longitud"];
        let requiredData = ["developerID", "complexID", "phaseName", "tradeStatusID"];
        let trimData = ["phaseName"];
        let isValid = true;
        requiredData.forEach((row) => {
            if (formData[row] == undefined || formData[row] == null || formData[row] == "")
                isValid = false;
        })

        trimData.forEach((row) => {
            if (formData[row] == undefined || formData[row] == null || formData[row].trim() == "")
                isValid = false;
        })

        if (formData.tradeStatusID && formData.tradeStatusID.id != 1) {
            requiredDataNotFuture.forEach((row) => {
                if (formData[row] == undefined || formData[row] == null || formData[row] == "") {
                    isValid = false;
                }
            })
        }
        return isValid;
    }

    const updateFormData = (_formData) => {
        let newData = Object.assign({}, formData, _formData);
        setFormData(newData);
    }

    const handlerImageData = (images) => {
        setImages(images);
    }

    const requestNavigation = () => {
        scroll.scrollTo(0);
        let goTo = "/#phase/amenidades";
        mainContext.setGoTo(goTo);
    }

    return (
        (preloadReady) &&
        <>
            <MenuPhases phaseID={phaseID} phaseData={formData} linkActive={"general"} hasUnits={phaseData.hasUnits} />
            <GeneralInformation initData={phaseData} handlerFormData={updateFormData} handlerImageData={handlerImageData} edit={edition} isSubmited={isSubmited} />
            <GeneralLocation initData={phaseData} handlerFormData={updateFormData} statusComercial={(formData.tradeStatusID)} edit={edition} isSubmited={isSubmited} />
            <div className="row d-flex justify-content-end mt-5">
                <div className="col-10 d-flex justify-content-end">
                    {
                        (mainContext.isDirty || !edition) ?
                            <button onClick={() => { sendForm() }} className="btn btn-white w-25 mr-4">Guardar</button>
                            :
                            <button className="btn btn-white w-25 mr-4 disabled">Guardar</button>
                    }
                    {
                        (edition) ?
                            <button onClick={() => { sendForm(true) }} className="btn btn-primary w-25">Continuar</button>
                            :
                            <button className="btn btn-primary w-25 disabled">Continuar</button>
                    }
                </div>
            </div>
        </>
    )
}
export default General;