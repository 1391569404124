import React from 'react';
import { HashRouter, Route, Switch } from "react-router-dom";
import { CommercialConditionsProvider } from "./contexts/CommercialConditionsContext";
import { GeneralInfoProvider } from "./contexts/GeneralInfoContext";
import { MainContextProvider } from "./contexts/MainContext";
import { CatalogsContextProvider } from "./contexts/CatalogsContext";

import { AmenitiesAndServicesProvider } from "./contexts/AmenitiesAndServicesContext";

import LoginPage from "./pages/LoginPage";
import Admin from "./pages/Admin/Index";
import LoginSuccessPage from "./pages/LoginSuccessPage";
import PagesManager from "./pages/PagesManager";

const Routes = () => {
  return (
    <HashRouter>
      <Switch>
        <Route exact component={() => <LoginPage googleRedirect={false} />} path="/" />
        <Route exact component={() => <LoginPage googleRedirect={true} />} path="/error/:loginErrorMessage" />
        <Route exact component={() => <LoginSuccessPage googleRedirect={true} />} path="/success/:googleCode" />
        <MainContextProvider>
          <CatalogsContextProvider>
            <Route component={() => <PagesManager />} path="/home" />
            <Route component={() => <Admin />} path="/admin/importer" />
            <Route component={() => <Admin />} path="/admin/users" />
            <Route component={() => <Admin />} path="/admin/general-catalogs" />
            <Route component={() => <Admin />} path="/admin/offices" />
            <Route component={() => <Admin />} path="/admin/cities" />
            <Route component={() => <PagesManager />} path="/create" />
            <Route component={() => <PagesManager />} path="/liftingReview" />
            <Route component={() => <PagesManager />} path="/phase/models" />
            <Route component={() => <PagesManager />} path="/phase/stories" />
            <Route component={() => <PagesManager />} path="/phase/units" />
            <Route component={() => <PagesManager />} path="/phase/salesStatus" />
            <Route component={() => <PagesManager />} path="/phase/conditions" />
            <Route component={() => <PagesManager />} path="/phase/control" />

            <CommercialConditionsProvider>
              <GeneralInfoProvider>
                <AmenitiesAndServicesProvider>
                  <Route component={() => <PagesManager />} path="/phase/general" />
                  <Route component={() => <PagesManager />} path="/phase/amenidades" />
                  <Route component={() => <PagesManager />} path="/phase/especificaciones" />
                </AmenitiesAndServicesProvider>
              </GeneralInfoProvider>
            </CommercialConditionsProvider>
          </CatalogsContextProvider>
        </MainContextProvider>

      </Switch>
    </HashRouter>
  )
}
export default Routes;
