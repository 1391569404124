import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import deleteImg from '../../../assets/icons/delete-files.svg';
import exclamationIcon from '../../../assets/icons/icon-exclamation-mark.svg';
import './styles.css'
import ImportIcon from '../../../assets/icons/icon-import.svg'
import Dropdown from "../Dropdown";
import { useMainContext } from "../../../contexts/MainContext";
import { useCatalogsContext } from "../../../contexts/CatalogsContext";
import InputCheckboxList from '../../../components/atoms/InputCheckboxList';

const ModalCreateUser = (props) => {

    let mainContext = useMainContext();
    let catalogs = useCatalogsContext();
    const { showModal, setShow, setCancelAction, handlerAccept, cancel, success, edit, dataUser, nameCatalog, hasStatus } = props
    const [nameFile, setNameFile] = useState("Seleccionar archivo")
    const [stringFile, setStringFile] = useState("")
    const [rol, setRol] = useState("")
    const [formData, setFormData] = useState({})
    const [stateData, setStateData] = useState([])
    const [isSubmitted, setIsSubmitted] = useState(false);


    /**
        * Cierra el modal.
    */
    const handleClose = () => {
        setNameFile("Seleccionar archivo")
        setShow(false);
    }

    const sendData = async () => {
        setIsSubmitted(true)
        let data = { ...formData }
        let states = []
        if (valitation()) {
            data.state.map((el) => {
                states.push(el.stateID)
            })
            data = { ...data, "state": states };

            await handlerAccept(data)
            await setFormData({})
        }

    }
    const valitation = () => {
        if (!formData
            || formData.name === undefined
            || formData.state === undefined
            || formData.country === undefined
            || formData.status === undefined
            || formData.name === ""
            || formData.state === ""
            || formData.country === ""
            || formData.status === ""
            || formData.name === null
            || formData.state === null
            || formData.country === null
            || formData.status === null)
            return false
        else
            return true
    }
    /**
        * Comfirmacion de borrado.
    */
    useEffect(() => {
        setIsSubmitted(false)
        setFormData({})
        setNameFile("Seleccionar archivo")

        if (edit) {
            let countriesArray
            let array = []
            dataUser.statesOffices.map((el) => {
                array.push(el.countries)
            })
            countriesArray = [...new Map(array.map(item =>
                [item.countryID, item])).values()]
            setStateByCountry(countriesArray);
            setFormData({
                country: initMultiCombo(countriesArray, "countryID", "countryID"),
                status: initCombo("status", "id", dataUser.status ? 1 : 0),
                state: initMultiCombo(dataUser.statesOffices, "stateID", "stateID"),
                officeID: dataUser.officeID,
                name: dataUser.name
            })

        }


    }, [showModal, dataUser])
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const getFileBase64 = async (event) => {
        setNameFile(event.name)
        const result = await toBase64(event);
        setStringFile(result)
    }

    const onFormDataChange = async (name, e) => {
        setIsSubmitted(false)
        if (name == "status")
            setFormData({ ...formData, [name]: e });
        else
            setFormData({ ...formData, [name]: e.value });
    }
    const setStateByCountry = (data) => {
        console.log("data ", data);
        let newData = [...new Map(data.map(item =>
            [item.countryID, item])).values()]
        console.log("newData ", newData);
        let stateBycountry = []
        newData.map((el) => {

            let result = catalogs.countries.find(({ countryID }) => countryID === el.countryID);
            console.log("formData ", formData.country);
            if (result.states) {
                result.states.map(state => {
                    stateBycountry.push(state)
                })
            }
            else {
                var newArray = formData.country.filter((item) => item.countryID !== el.countryID);
                console.log("newArray ", newArray);
                setFormData({ ...formData, country: newArray });
                mainContext.setMessageAlert(`El Pais ${result.countryName} no tiene estados`);
            }


        })

        console.log("stateBycountry ", stateBycountry);
        setStateData(stateBycountry)
    }
    const handleCheckboxList = async (paramName, data) => {
        setIsSubmitted(false)

        if (paramName == "country") {
            setStateByCountry(data);
        }

        setFormData({ ...formData, [paramName]: data });

    }
    const initCombo = (catalog, filterBy1, filterBy2) => {
        let status = [{ "id": 1, "status": "Activo" }, { "id": 0, "status": "Inactivo" }];
        if (catalog == "status") {
            let dato = status.filter((element) => { return element[filterBy1] == filterBy2 })[0];
            return dato;
        }
        else
            return null;
    }
    const initMultiCombo = (_data, paramName, idName) => {
        let formattedData = [];
        _data.forEach(element => {
            formattedData.push({ [idName]: element[paramName] });
        });
        return formattedData;
    }

    return (
        //TODO: Fix styles
        <section>
            <Modal size={"lg"} show={showModal} onHide={() => { handleClose() }} backdrop="static" centered >
                <Modal.Header className="no-border p-1" closeButton>
                    <Modal.Title className="pl-3"></Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div className="custom-card">
                        <div className="custom-card-header px-3 d-flex">
                            <h4 className="py-3">{edit ? "EDITAR" : "AGREGAR"} OFICINA</h4>
                            <div className=" py-2 d-flex justify-content-end">
                                {/*<button onClick={() => { setShowModal(true) }} className="btn btn-primary"><span className="d-inline-block pr-4">Nueva importación</span> +</button>*/}.
                            </div>
                        </div>
                        <div className="custom-card-body px-3">
                            <div className="row">
                                <div className="form-group with-message col-6">
                                    <label className="input-label form-label">
                                        * Nombre
                                    </label>
                                    <div className="">
                                        <input type="text" value={formData.name} onChange={(e) => { onFormDataChange("name", e.target) }} maxLength="300" className="form-control black-border large-input" />
                                        {
                                            (isSubmitted && !formData.name) &&
                                            <p className="error">*Campo obligatorio.</p>
                                        }
                                    </div>
                                </div>
                                <div className="col-6 form-group with-message">
                                    <label className="input-label form-label">
                                        * Estatus
                                    </label>
                                    <div className="">
                                        {
                                            (isSubmitted && !formData.status) &&
                                            <p className="error">*Campo Estatus es obligatorio.</p>
                                        }
                                        {/*<input type="file" onChange={e => getFileBase64(e.target.files[0])} maxLength="50" className="form-control black-border large-input" />*/}
                                        <Dropdown value={formData.status} options={[{ "id": 1, "status": "Activo" }, { "id": 0, "status": "Inactivo" }]} onChange={(e) => { onFormDataChange("status", e.value) }} optionValue="id" optionLabel="status" className="w-100" />
                                    </div>
                                </div>
                                <div className="col-3 form-group with-message">
                                    <label className="input-label form-label">
                                        * País
                                    </label>
                                    <div className="">
                                        {
                                            (isSubmitted && !formData.country) &&
                                            <p className="error">*Campo país es obligatorio.</p>
                                        }
                                        <InputCheckboxList cssClass="modal-checkbox" handleData={handleCheckboxList} catalog={catalogs.countries} currentData={formData.country} idProperty="countryID" paramName="country" displayName="countryName" idx="countryID" />

                                    </div>
                                </div>
                                <div className="col-9 form-group with-message">
                                    <label className="input-label form-label">
                                        * Estados
                                    </label>
                                    <div className="">
                                        {
                                            (isSubmitted && !formData.state) &&
                                            <p className="error">*Campo estado obligatorio.</p>
                                        }
                                        <InputCheckboxList cssClass="modal-checkbox2" handleData={handleCheckboxList} catalog={stateData} currentData={formData.state} idProperty="stateID" paramName="state" displayName="stateName" idx="stateID" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div
                        className="container d-flex justify-content-end mt-5">
                        <button className="btn btn-white " onClick={() => { handleClose() }}>Cancelar</button>

                        <button className="btn btn-primary ml-3" onClick={sendData}>{edit ? "Actualizar" : "Agregar"}</button>
                    </div>
                </Modal.Body>
            </Modal>
        </section>
    )
}
export default ModalCreateUser