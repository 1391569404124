import React, { useEffect, useState } from 'react';
// import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
// import Row from 'react-bootstrap/Row'
import InputGroup from 'react-bootstrap/InputGroup';
import NumberFormat from 'react-number-format';

import Dropdown from "../../../components/atoms/Dropdown";

import { useCatalogsContext } from "../../../contexts/CatalogsContext";

import { RestActions } from "../../../actions";
import { useMainContext } from "../../../contexts/MainContext";
import { isNull } from 'lodash';

function InteriorFeatures({ edit, handlerFormData, phaseData, handlerImageData, isSubmited, initData }) {

	const rest = new RestActions();
	let mainContext = useMainContext();
	const [formData, setFormData] = useState(null);
	const catalogs = useCatalogsContext();



	useEffect(() => {

		if (phaseData.phaseID) {
			initForm();
		} else {
			let formattedData = {
				wallFinishID: null,
				ceilingID: null,
				lightingID: null,
				systemACID: null,
				deckTileTypeID: null,
				deckTileOriginID: null,
				deckTileMeasureID: null,
				height: null,
				hasAcousticInsulator: null
			}

			setFormData({ ...formData, interiorFeatures: formattedData });
		}
		mainContext.setIsLoading(false);
	}, [initData])

	useEffect(() => {
		handlerFormData(formData);
	}, [formData])


	const initForm = async () => {
		const { wallFinishID,
			ceilingID,
			lightingID,
			systemACID,
			interiorDeckTileTypes,
			deckTileOriginID,
			deckTileMeasureID,
			height,
			hasAcousticInsulator } = phaseData.interiorFeatures;
		let formattedData = {
			wallFinishID: initCombo("wallFinish", "wallFinishID", wallFinishID),
			ceilingID: initCombo("ceiling", "ceilingID", ceilingID),
			lightingID: initCombo("lighting", "lightingID", lightingID),
			systemACID: initCombo("systemAC", "systemACID", systemACID),
			interiorDeckTileTypes: initComboMulti("deckTileTypes", "deckTileTypeID", interiorDeckTileTypes),
			deckTileOriginID: initCombo("deckTileOrigins", "deckTileOriginID", deckTileOriginID),
			deckTileMeasureID: initCombo("deckTileMeasures", "deckTileMeasureID", deckTileMeasureID),
			height: height ? height.toString() : "",
			hasAcousticInsulator: hasAcousticInsulator
		}

		setFormData({ ...formData, interiorFeatures: formattedData });


	}
	// deckTileTypes, deckTileTypeID, indefined
	const initComboMulti = (catalog, filterBy1, filterBy2) => {
		if (catalogs[catalog]) {
			let selectedOption = []
			catalogs[catalog].map((el) => {
				filterBy2.map((elSelected) => {
					if (el.deckTileTypeID == elSelected.deckTileTypeID)
						selectedOption.push({
							deckTileTypeID: elSelected.deckTileTypeID,
							description: el.description
						})

				})

			});
			return selectedOption
		} else {
			return null;
		}
	}

	const initCombo = (catalog, filterBy1, filterBy2) => {
		if (catalogs[catalog])
			return catalogs[catalog].filter((element) => { return element[filterBy1] == filterBy2 })[0];
		else
			return null;
	}

	// ? Input Event
	const onFormDataChange = async (name, e) => {


		let newData = { ...formData.interiorFeatures };

		if (name === 'height') {
			newData[name] = e;
		} else {
			newData[name] = e.value;
		}


		mainContext.setIsDirty(true);
		await setFormData({ ...formData, interiorFeatures: newData });

	}


	return (
		<div className="my-4">
			{formData != null ?
				<form autoComplete="off" className="custom-card">
					<div className="custom-card-header  d-flex justify-content-between align-items-center">
						<h4 className="px-3">CARACTERÍSTICAS INTERIORES</h4>
						{/*<div className="col-4 d-flex  align-items-center">
						<h4 className="text-nowrap">ACABADO BASE:</h4>
						<div className="w-100">
							<Dropdown optionLabel="description" optionValue="id" className="w-100" />
						</div>

						

				</div>*/}
					</div>
					<div className="custom-card-body px-3">
						<div className="row">
							<div className="col">
								<div className="form-group with-message">
									<label className="input-label form-label mb-4">Acabados en muros</label>
									<Dropdown optionLabel="description"
										value={formData.interiorFeatures.wallFinishID}
										options={catalogs.wallFinish}
										onChange={(e) => { onFormDataChange('wallFinishID', e); }}
										optionValue="wallFinishID"
										className="w-100" />
								</div>
							</div>
							<div className="col">
								<div className="form-group with-message">
									<label className="input-label form-label mb-4">Cielo</label>
									<Dropdown optionLabel="description"
										value={formData.interiorFeatures.ceilingID}
										options={catalogs.ceiling}
										onChange={(e) => { onFormDataChange('ceilingID', e); }}
										optionValue="ceilingID"
										className="w-100" />
								</div>
							</div>
							<div className="col">
								<div className="form-group with-message">
									<label className="input-label form-label mb-4">Iluminación</label>
									<Dropdown optionLabel="description"
										value={formData.interiorFeatures.lightingID}
										options={catalogs.lighting}
										onChange={(e) => { onFormDataChange('lightingID', e); }}
										optionValue="lightingID"
										className="w-100" />
								</div>
							</div>
							<div className="col">
								<div className="form-group with-message">
									<label className="input-label form-label mb-4">Sistema de A/C</label>
									<Dropdown optionLabel="description"
										value={formData.interiorFeatures.systemACID}
										options={catalogs.systemAC}
										onChange={(e) => { onFormDataChange('systemACID', e); }}
										optionValue="systemACID"
										className="w-100" />
								</div>
							</div>
						</div>
						{/* cambios select multiple */}
						<div className="row">
							<div className="col-3">
								<div className="form-group with-message">
									<label className="input-label form-label mb-4">Tipo de piso</label>
									<Dropdown
										optionLabel="description"
										value={formData.interiorFeatures.interiorDeckTileTypes}
										options={catalogs.deckTileTypes}
										onChange={(e) => { onFormDataChange('interiorDeckTileTypes', e); }}
										optionValue="deckTileTypeID"
										className="w-100"
										isMulti={true}
									/>

									{/* <Dropdown
										optionLabel="description"
										value={formData.buildingFeatures.buldingElevatorTypes}
										options={catalogs.elevatorTypes}
										onChange={(e) => { onFormDataChange('buldingElevatorTypes', e); }}
										optionValue="elevatorTypeID"
										className="w-100"
										isMulti={true}
									/> */}

								</div>
							</div>
							<div className="col-3">
								<div className="form-group with-message">
									<label className="input-label form-label mb-4">Origen de piso</label>
									<Dropdown optionLabel="description"
										value={formData.interiorFeatures.deckTileOriginID}
										options={catalogs.deckTileOrigins}
										onChange={(e) => { onFormDataChange('deckTileOriginID', e); }}
										optionValue="deckTileOriginID"
										className="w-100" />
								</div>
							</div>
							<div className="col-3">
								<div className="form-group with-message">
									<label className="input-label form-label mb-4">Medida de piso</label>
									<Dropdown optionLabel="description"
										value={formData.interiorFeatures.deckTileMeasureID}
										options={catalogs.deckTileMeasures}
										onChange={(e) => { onFormDataChange('deckTileMeasureID', e); }}
										optionValue="deckTileMeasureID" className="w-100" />
								</div>
							</div>

						</div>
						<div className="row">
							<div className="col-3">
								<div className="form-group with-message">
									<label className="input-label form-label mb-4">Altura interior en metros</label>
									<InputGroup>
										<NumberFormat
											decimalScale={2}
											isAllowed={(value) => {
												if (value.floatValue <= 99.99)
													return true
												else if (value.formattedValue === '')
													return true
												else
													return false;

											}}
											allowNegative={false}
											thousandSeparator={true}
											max={99.99}
											value={formData.interiorFeatures.height}
											className="form-control black-border "
											onChange={(e) => { onFormDataChange('height', e.target.value); }}
										/>
									</InputGroup>
								</div>
							</div>

							<div className="col-3">
								<div className="form-group with-message">
									<label className="input-label form-label mb-4">Aislante acústico</label>
									<Form.Check
										disabled={false}
										type="switch"
										id="regulations-switch"
										label=""
										checked={formData.interiorFeatures.hasAcousticInsulator}
										onChange={(e) => { onFormDataChange("hasAcousticInsulator", { value: !formData.interiorFeatures.hasAcousticInsulator }) }}
									/>
								</div>
							</div>
						</div>


					</div>
				</form>
				: null
			}

		</div >
	)
}

export default InteriorFeatures
