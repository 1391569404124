import React, { useEffect, useState } from 'react';
import { useMainContext } from "../../../contexts/MainContext";
import { RestActions } from "../../../actions";
import MenuPhases from '../../../components/molecules/MenuAdmin';
import TableCities from './TableCities';
import ModalSaveCity from '../../../components/atoms/ModalSaveCity';
import ModalConfirmImport from "../../../components/atoms/ModalConfirmImport";
// import ModalConfirmGeneral from "../../../components/atoms/ModalConfirmGeneral";
import exclamationIcon from '../../../assets/icons/icon-exclamation-mark.svg';
// import checkIcon from '../../../assets/icons/icon-check.svg';
// import warningIcon from '../../../assets/icons/icon-warning.svg';
import { useCatalogsContext } from "../../../contexts/CatalogsContext";
const Index = ({ preloadReady }) => {
	let catalogs = useCatalogsContext();


	const rest = new RestActions();
	const [showModal, setShowModal] = useState(false);
	const [dataTable, setDataTable] = useState({});
	const [pageLimit, setPageLimit] = useState(10);
	const [showModalLogs, setShowModalLogs] = useState(false);
	const [logsModalData, setLogsModalData] = useState(false);
	const [showModalImportError, setShowModalImportError] = useState(false);
	const [showModalError, setShowModalError] = useState(false);
	const [showModalImportSuccess, setShowModalImportSuccess] = useState(false);
	const [showModalImportWarnings, setShowModalImportWarnings] = useState(false);
	const [showModalImportWarningsList, setShowModalImportWarningsList] = useState(false);
	const [importedFileId, setImportedFileId] = useState("")
	const [edit, setEdit] = useState(false)
	const [dataUser, setDataUser] = useState({})
	const [showModalDelete, setShowModalDelete] = useState(false);
	const [idItem, setIdItem] = useState(false);
	const [deleteEndpoint, setDeleteEndpoint] = useState(false);
	let mainContext = useMainContext();

	useEffect(() => {
		if (preloadReady) {
			initTable(1)
		}

	}, [preloadReady]);

	const initTable = async (_pageNumber) => {
		mainContext.setIsLoading(true);
		try {
			// let response = await rest.post("getOffices", {
			// 	pageLimit: pageLimit, pageNumber: _pageNumber
			// });
			let response = await rest.post("getCities", {
				pageLimit: pageLimit, pageNumber: _pageNumber
			});

			setDataTable(response);
			mainContext.setIsLoading(false);

		}
		catch (error) {/*TODO SOMETHING*/ }
	}

	const openModalDelete = async (idCity, endpoint) => {
		setIdItem(idCity)
		setDeleteEndpoint(endpoint)
		setShowModalDelete(true)
	}
	const showModalEditUser = async (id) => {
		setEdit(true)
		mainContext.setIsLoading(true);
		try {
			let response = await rest.post("getCity", {
				id: id
			});

			setDataUser(response)
			setShowModal(true)
			mainContext.setIsLoading(false);

		}
		catch (error) {/*TODO SOMETHING*/ }

	}
	const saveUser = async (formaData) => {
		mainContext.setIsLoading(true);
		if (!edit)
			try {
				let response = await rest.post("createCity", {
					name: formaData.name.trim(),
					status: formaData.status.id == 1 ? true : false,
					states: formaData.state,
					municipalities: formaData.municipalities
				});
				await mainContext.setMessageAlert("Ciudad creada correctamente");
				await mainContext.setUpdateCatalogs(true)
				await initTable(1)
				closeModal()
			}
			catch (error) {
				closeModal()
				mainContext.setIsLoading(false);
				mainContext.setMessageAlert(error);
			}
		else
			try {
				let response = await rest.post("updateCity", {
					id: formaData.id,
					name: formaData.name.trim(),
					status: formaData.status.id == 1 ? true : false,
					states: formaData.state,
					municipalities: formaData.municipalities
				});

				mainContext.setMessageAlert("Ciudad actualizada correctamente");

				await mainContext.setUpdateCatalogs(true)
				await initTable(1)
				closeModal()
			}
			catch (error) {
				closeModal()
				mainContext.setIsLoading(false);
				mainContext.setMessageAlert(error);
			}
	}


	const acceptDeleteItem = async () => {
		let deleteParams = {}


		if (deleteEndpoint == "deleteCity")
			deleteParams = {
				id: idItem,
			}
		try {
			let response = await rest.post(deleteEndpoint, deleteParams);
			closeModal()
			mainContext.setMessageAlert("Elemento  borrado correctamente");
			if (deleteEndpoint == "deleteCity")
				initTable(1) // actualiza la tabla de catalogos

		}
		catch (error) {
			closeModal()
			mainContext.setMessageAlert(error);
		}
	}
	const closeModal = () => {
		setShowModalDelete(false)
		setShowModalImportError(false)
		setShowModalImportSuccess(false)
		setShowModalImportWarnings(false)
		setShowModal(false)
		setEdit(false)

	}
	return (
		<>
			<MenuPhases linkActive={"cities"} />
			<ModalSaveCity showModal={showModal} handlerAccept={saveUser} setShow={closeModal} dataUser={dataUser} edit={edit} />

			<ModalConfirmImport size="sm" show={showModalDelete} icon={exclamationIcon} handleCancel={closeModal} handleConfirm={acceptDeleteItem}>
				<div className="col">
					<p className="title-modal"><b>¿ Esta seguro de eliminar esta ciudad ?</b></p>
				</div>
			</ModalConfirmImport>
			<div className="custom-card">
				<div className="custom-card-header px-3 d-flex">
					<h4 className="py-3 w-50">CIUDADES</h4>
					<div className="w-50 py-2 d-flex justify-content-end">
						<button onClick={() => { setShowModal(true) }} className="btn btn-primary"><span className="d-inline-block pr-4">Nueva Ciudad</span> +</button>
					</div>
				</div>
				<div className="custom-card-body">
					<div className="table-scroll-y table-scroll-y-scrollable">
						<div className="table-responsive h-100">
							{/* <p>no funciona ? {JSON.stringify(dataTable[0])}</p> */}
							<TableCities handleRefresh={initTable} data={dataTable} openDeletModal={openModalDelete} openEditModal={showModalEditUser} />
						</div>
					</div>
				</div>
			</div>
		</>

	)
}

export default Index
