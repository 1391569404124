import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import * as moment from 'moment';
import Form from 'react-bootstrap/Form'
//ACTION && CONTEXT
import { useMainContext } from "../../../contexts/MainContext";
import { RestActions } from "../../../actions";
//COMPONENTS
import UploadImageComponent from '../../../components/atoms2/UploadImageComponent'
import InputNumeric from "../../../components/atoms/InputNumeric";
import Dropdown from "../../../components/atoms/Dropdown";
//ASSETS
import iconEdit from '../../../assets/icons/icon-edit.svg';
import iconSave from '../../../assets/icons/icon-save.svg';

const SalesPromotions = ({phaseID}) => 
{
    let mainContext = useMainContext();
    const rest = new RestActions();

    const BUCKET_URL = process.env.REACT_APP_BUCKET_URL;
    const viewOptions = [{id:"all", description:"Ver Todos"}, {id:"active", description:"Ver Activos"}];

    const [viewType, setViewType] = useState("all");
    const [promotionsList, setPromotionsList] = useState([]);
    const [seasonsCat, setSeasonsCat] = useState(null);
    const [dataTypesCat, setDataTypesCat] = useState(null);
    const [editIndex, setEditIndex] = useState(null);
    const [isAdding, setIsAdding] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [formData, setFormData] = useState(null);

    useEffect(() => 
    {
        getPromotions();
    }, [viewType])

    const getPromotions = async ()=>
    {
        try
        {
            let response = await rest.post("getPhasePromotions", {phaseID: parseInt(phaseID), filter:viewType});
            setPromotionsList(response);
            if(!seasonsCat)
            {
                setSeasonsCat(JSON.parse(await rest.get("getSeasons")));
                setDataTypesCat(JSON.parse(await rest.get("getDataTypes")));
            }
            mainContext.setIsLoading(false);
        }
        catch (error) {/*TODO SOMETHING*/}
    }

    const addPromotion = async ()=>
    {
        let promotionsArr = [...promotionsList];
        setIsSubmitted(false);
        if(!isAdding)
        {
            promotionsArr.push({phase_id: phaseID, dataTypes:dataTypesCat[0].id, seasons:seasonsCat[0].id, startDate:moment().format("DD/MM/yyyy"), endDate:moment().format("DD/MM/yyyy")});
            setEditIndex(promotionsArr.length -1);
            setFormData(promotionsArr[promotionsArr.length -1]);
        }
        else
        {
            promotionsArr.pop();
            setEditIndex(null);
            setFormData({});
        }
        setPromotionsList(promotionsArr);
        setIsAdding(!isAdding);
    }

    const toggleEdition = async (index)=>
    {
        if(isAdding)
            addPromotion();
        let data = {...promotionsList[index]}
        
        let idx = _.findIndex(seasonsCat, (element)=>{return element.id == data.seasons.seasonID})
        data.seasonID = seasonsCat[idx];
        idx = _.findIndex(dataTypesCat, (element)=>{return element.id == data.dataTypes.dataTypeID})
        data.dataTypeID = dataTypesCat[idx];
        setIsSubmitted(false);
        setFormData(data);
        setEditIndex(index);
    }

    const onFormDataChange = async (paramName, e) => 
    {
        let data = {...formData}
        data[paramName] = e.value;
        validateForm(data);
        if(parseFloat(data.partialPayment) == 0)
            data.paymetTerms = 0;

        setFormData(data);
    }

    const onChangeDataType = async (e) => 
    {
        let data = {...formData, dataTypeID:e.value}
        validateForm(data);
        if(data.amount && data.amount.toString().length > 3)
            data.amount = "";
        
        setFormData(data);
    }

    const formatStringDate = (oldDate, from, to) => 
    {
        let date = oldDate;
        if (from === "dd/mm/yyyy") {
          date = date.split("/");
          date = `${date[2]}-${date[1]}-${date[0]}`
        }
        date = new Date(date);
      
        let day = date.getUTCDate()
        let month = date.getUTCMonth() + 1
        let year = date.getUTCFullYear()
      
        day = day < 10 ? `0${day}` : day;
        month = month < 10 ? `0${month}` : month;
      
        let newDate = to.replace("dd", day.toString()).replace("mm", month.toString()).replace("yyyy", year.toString());
      
        return newDate;
    }

    const setImage = (image)=>
    {
        onFormDataChange("img_data", {value:image});
    }

    const validateForm = (data)=>
    {
        let required = ["seasonID","promotionName","startDate", "endDate", "dataTypeID", "amount", "description"];
        let _isValid = true;
        if(!data.promotionID)
            required.push("img_data"); 
        _.forEach(required, (row)=>{
            if(data[row] === undefined || data[row] === "" )
                _isValid = false;
        })
        setIsValid(_isValid);
        return _isValid;
    }

    const savePromotion = async ()=>
    {
        let action = "savePhasePromotion";
        let params = {...formData, user_id:mainContext.session.userID};
        setIsSubmitted(true);
        
        if(validateForm(params))
        {
            if(params.promotionID)
            {
                action = "updatePhasePromotion";
                params.promo_id = params.promotionID;
                params.isImageUpdate = false;
                if(params.img_data)
                    params.isImageUpdate = true;
            }

            if(promotionsList[editIndex].startDate == params.startDate)
                    params.start_date = params.startDate;
                else
                    params.start_date = formatStringDate(params.startDate, false, "dd/mm/yyyy");
                if(promotionsList[editIndex].endDate == params.endDate)
                    params.end_date = params.endDate;
                else
                params.end_date = formatStringDate(params.endDate, false, "dd/mm/yyyy");
            params.phase_id = parseInt(phaseID);
            params.promo_name = params.promotionName;
            params.season_id = params.seasonID;
            params.data_type_id = params.dataTypeID;
            params.amount = params.amount;
            params.season_id = params.seasonID.id;
            params.data_type_id = params.data_type_id.id;
            try
            {
                mainContext.setIsLoading(true);
                await rest.post(action, params);
                setEditIndex(null);
                setIsAdding(false);
                getPromotions();
                setIsSubmitted(false);
            }
            catch (error) {/*TODO SOMETHING*/}
        }
    }

    return (
        <>
            <div className="custom-card mt-4">
                <div className="custom-card-header px-3 d-flex">
                    <h4 className="py-3 w-50">PROMOCIONES</h4>
                    <div className="w-50 py-2 d-flex justify-content-end">
                        <Dropdown value={viewType} options={viewOptions} onChange={(e)=>{ setViewType(e.value); }} optionValue="id" optionLabel="description" placeholder="Seleccionar" isClearable={false} width="150px"/>
                        <button onClick={()=>{addPromotion()}} className="btn btn-primary w-75 ml-3"><span className="d-inline-block pr-1">Agregar promoción</span> +</button>
                    </div>
                </div>
                <div className="custom-card-body px-3">
                        <div className={`table-row-editable ${(editIndex != null || isAdding) && "expanded"}`}>
                            <table className={`text-center table table-striped table-hover mt-4`}>
                                <thead className={"th-font"}>
                                    <tr>
                                        <th></th>
                                        <th style={{borderTop: 'none'}}>Nombre</th>
                                        <th style={{borderTop: 'none'}}>Estación</th>
                                        <th style={{borderTop: 'none'}}>Fecha inicio</th>
                                        <th style={{borderTop: 'none'}}>Fecha fin</th>
                                        <th style={{borderTop: 'none'}}>Tipo dato</th>
                                        <th style={{borderTop: 'none'}}>%/$</th>
                                        <th style={{borderTop: 'none'}}>Imagen</th>
                                        <th style={{borderTop: 'none'}}>Descripción</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    promotionsList.map((promotion, index) => 
                                    (
                                        (editIndex == index) ? 
                                        (
                                            <tr key={`tr-${index}`}>
                                                <td>
                                                    <img onClick={() => {savePromotion()}} src={iconSave} width="25" className={"icon-image pointer mt-1"}/>
                                                </td>
                                                <td><input value={formData.promotionName} onChange={(e)=>{onFormDataChange("promotionName", e.target)}} className="form-control"/></td>
                                                <td>
                                                    <Dropdown value={formData.seasonID} options={seasonsCat} onChange={(e)=>{ onFormDataChange("seasonID", e) }} optionValue="id" optionLabel="description" placeholder="Seleccionar" width="150px" customClass="input-table-td"/>
                                                </td>
                                                <td>
                                                    <Form.Control value={formatStringDate(formData.startDate, "dd/mm/yyyy", "yyyy-mm-dd")} size={"sm"} type="date" placeholder="dd-mm-yyyy" className={"black-border"} onChange={async (e) =>{onFormDataChange("startDate", e.target)}} min={new Date()} />
                                                </td>
                                                <td>
                                                    <Form.Control value={formatStringDate(formData.endDate, "dd/mm/yyyy", "yyyy-mm-dd")} size={"sm"} type="date" placeholder="dd-mm-yyyy" className={"black-border"}
                                                            onChange={async (e) => {onFormDataChange("endDate", e.target)}} min={formData.start_date} />
                                                </td>
                                                <td>
                                                    <Dropdown value={formData.dataTypeID} options={dataTypesCat} onChange={(e)=>{ onChangeDataType(e); }} optionValue="id" optionLabel="description" placeholder="Seleccionar" width="150px" customClass="input-table-td"/> 
                                                </td>
                                                <td>
                                                {
                                                    (formData.dataTypeID == 1) ?
                                                        <InputNumeric paramName="amount" defaultValue={formData.amount} maxlength={3} decimal={2} handleValue={onFormDataChange}/>
                                                    :
                                                        <InputNumeric paramName="amount" defaultValue={formData.amount} maxlength={12} decimal={2} handleValue={onFormDataChange}/>
                                                }
                                                </td>
                                                <td>
                                                    <UploadImageComponent size={"sm"} propertyName={'promotion'} zone={"promotions"} setImage={setImage} /> 
                                                </td>
                                                <td><input value={formData.description} onChange={(e)=>{onFormDataChange("description", e.target)}} className="form-control"/></td>
                                            </tr>
                                        )
                                        : (
                                            <tr key={`tr-${index}`}>
                                                <td>
                                                    <img onClick={() => {toggleEdition(index)}} src={iconEdit} width="25" className={"pointer"}/>
                                                </td>
                                                <td>{promotion.promotionName}</td>
                                                <td>{promotion.seasons.description}</td>
                                                <td>{promotion.startDate}</td>
                                                <td>{promotion.endDate}</td>
                                                <td>{promotion.dataTypes.description}</td>
                                                <td>
                                                    {(promotion.dataTypes.dataTypeID == 1) ? promotion.amount + "%" : "$"+ promotion.amount}
                                                </td>
                                                <td>
                                                    {
                                                        (promotion.uri) &&
                                                            <img alt={`img-${promotion.promotionID}`} src={BUCKET_URL + promotion.uri} className={"icon-image"} />
                                                    }
                                                </td>
                                                <td>{promotion.description}</td>
                                            </tr>
                                        )
                                    ))
                                }
                                </tbody>
                            </table>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            {
                                (!isValid && isSubmitted) &&
                                <div className="error large auth__alert-error">
                                    Favor de llenar todos los campos
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SalesPromotions