import React, { createContext, useContext, useState } from 'react';

export const GeneralInfoContext = createContext({});

export const GeneralInfoProvider = (props) => {
    const [isLoading, setIsLoading] = useState(false)
    const [phaseCode, setPhaseCode] = useState("")
    const [id, setId] = useState("")
    const [locationData, setLocationData] = useState("")
    const [data, setData] = useState("")
    const [observations, setObservations] = useState({ observations: "" })
    const [regulationZones, setRegulationZones] = useState({ regulation_zone: "" })
    const [regulationName, setRegulationName] = useState("")
    const [regulationSection, setRegulationSection] = useState("")
    const [multiSelectObjects, setMultiSelectObjects] = useState([])
    const [images, setImages] = useState([])
    const [phaseIdCreated, setPhaseIdCreated] = useState(false);
    const [locationCopy, setLocationCopy] = useState({});
    const value = {
        data,
        setData,
        id,
        setId,
        phaseCode,
        setPhaseCode,
        locationData,
        setLocationData,
        images,
        setImages,
        multiSelectObjects,
        setMultiSelectObjects,
        phaseIdCreated,
        setPhaseIdCreated,
        regulationZones,
        setRegulationZones,
        observations,
        setObservations,
        locationCopy,
        setLocationCopy,
        regulationName, 
        setRegulationName,
        regulationSection,
        setRegulationSection,
        isLoading,
        setIsLoading
    }
    return <GeneralInfoContext.Provider value={value} {...props} />
}



export const useGeneralInfoContext = () => {
    const context = useContext(GeneralInfoContext);

    if (!context) {
        throw new Error("no existe el context")
    }

    return context;
}