import React, { useState, useEffect } from 'react';
//ACTIONS AND CONTEXT
import { useMainContext } from "../../../contexts/MainContext";

/**
    * Componente <MessageAlert /> Renderiza un mensaje de exito o de error.
    * Utiliza la variable "messageAlert" del contexto "MainContext" para mostrar un mensaje.
    * El mensaje se oculta despues de 4 segundos
*/
const MessageAlert = () => {
    let mainContext = useMainContext();
    const [lastTimer, setLastTimer] = useState(null);
    const [messagesToShow, setMessagesToShow] = useState([]);

    useEffect(() => {
        if (mainContext.messageAlert) {
            if (messagesToShow.indexOf(mainContext.messageAlert) == -1) {
                let msgs = [...messagesToShow];
                if (msgs.length > 3)
                    msgs.shift();
                msgs.push(mainContext.messageAlert);
                setMessagesToShow(msgs);
                if (lastTimer)
                    clearTimeout(lastTimer);
                let timer = setTimeout(() => {
                    let msgs = [...messagesToShow];
                    msgs.shift();
                    setMessagesToShow(msgs);
                    setLastTimer(null);
                    mainContext.setMessageAlert(null);
                }, 4000);
                setLastTimer(timer);
            }
        }
    }, [mainContext.messageAlert])

    /**
        * Calcula la posicion del mensje dependiendo la cantidad.
        * @param {number} index - indice del mensaje.
    */
    const getPosition = (index) => {
        let total = index * 80;
        return { bottom: total + "px" }
    }

    if (messagesToShow.length)
        return (
            <div className="container-message-alert">
                {
                    messagesToShow.map((element, index) => {
                        return (
                            <div data-cy="message-alert" className="message-alert text-center" key={index} style={getPosition(index)} >
                                {element}
                            </div>
                        )
                    })
                }
            </div>
        )
    else
        return (null)
}
export default MessageAlert;
