import React, { useState, useEffect } from 'react';

import { Tooltip } from 'primereact/tooltip';
import { InputText } from 'primereact/inputtext';

/**
    * Componente <InputNumeric /> renderiza un input de tipo numerico
    * @component
    * @param  props
    * @param {string} props.paramName - Nombre del campo
    * @param {function}  props.handleValue - Funcion que maneja el campo del valor
    * @param {number} props.defaultValue - Valor por defecto del campo
    * @param {bool} props.isDisabled - Boleano indica si el campo esta deshabilitado
    * @param {number} props.maxlength - Digitos maximos
    * @param {number} props.decimal - Decimales permitidos
    * @param {number} props.maxValue - Valor maximo
    * @param {string} props.className - Nombre de la clase css
    * @param {string} props.datacy - Nombre para agregar al campo para Test.
*/
const InputNumeric = ({ paramName, handleValue, defaultValue, isDisabled, maxlength, decimal, maxValue, className, datacy, hasTootip = false, tooltipText }) => {
    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        if (defaultValue != undefined) {
            setInputValue(format(defaultValue));
        }
    }, [defaultValue])

    /**
        * Valida si el valor es correcto segun las reglas de expresiones regulares
        * @param {object} event - Event con la propiedad value
    */
    const validateNumeric = async (e) => {
        let regExpString = "^[0-9]{0," + maxlength + "}";
        regExpString += (decimal) ? "([,.][0-9]{0," + decimal + "})?$" : "?$";
        let regExp = new RegExp(regExpString);
        let rawValue = e.value.replaceAll(",", "");
        if (regExp.test(rawValue)) {
            if (maxValue) {
                if (rawValue <= maxValue)
                    handleValue(paramName, { value: rawValue })

            }
            else {
                handleValue(paramName, { value: rawValue });
            }
        }
    }

    /**
        * Funcion para formateo de numeros.
        * @param {number} num - Valor a formatear.
    */
    function format(num) {
        return ('' + num).replace(
            /(\d)(?=(?:\d{3})+(?:\.|$))|(\.\d\d?)\d*$/g,
            function (m, s1, s2) {
                return s2 || (s1 + ',');
            }
        );
    }

    return (
        <>
            {hasTootip ?
                <InputText tooltip={tooltipText} tooltipOptions={{ event: 'focus', position: "top" }} data-cy={"numeric-" + datacy} value={inputValue} onChange={(e) => { validateNumeric(e.target); }} disabled={isDisabled} className={`form-control black-border large-input customClassName" ${className}`} /> :
                <InputText data-cy={"numeric-" + datacy} value={inputValue} onChange={(e) => { validateNumeric(e.target); }} disabled={isDisabled} className={`form-control black-border large-input customClassName" ${className}`} />}
        </>
    )
}
export default InputNumeric;
