import React, { useEffect, useState } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { faHeart as faHeartSolid, faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
//ACTIONS AND CONTEXT
import { RestActions } from "../../../../actions";
import { useMainContext } from "../../../../contexts/MainContext";
//ASSETS
import trashicon from '../../../../assets/icons/icon-trash.svg';
import ventas from '../../../../assets/icons/icon-ventas.svg';
import editUser from '../../../../assets/icons/edit-files.svg';

/**
    * Componente SearchEngineList
    * @component
    * @param  props
    * @param {array} props.data - Arreglo del Listado de fases.
    * @param {function} props.handleRefresh - Maneja el cambio en el listado de fases
    * 
*/
const SearchEngineList = ({ data, handleRefresh, openDeletModal, openEditModal }) => {
    const rest = new RestActions();
    let mainContext = useMainContext();
    const [dataFormatted, setDataFormatted] = useState([]);
    const [nextPage, setNextPage] = useState(null);
    const [prevPage, setPrevPage] = useState(null);
    const [currentPage, setCurrentPage] = useState(null);
    const [pages, setPages] = useState([1]);

    useEffect(() => {
        if (data && data.data != undefined) {
            proccessData();
            createPaginator();
        }
    }, [data])

    /**
        * Formatea el listado de datos para ser desplegado en la tabla.
        * @param {object} props.event - valor del campo pais en el formulario.
        * 
    */
    const proccessData = async () => {
        let newData = [];
        data.data.forEach(element => {
            newData.push({
                amenityID: parseInt(element.amenityID),
                orderID: parseInt(element.orderID),
                name: element.name,
                updatedAt: element?.updatedAt,
                createdAt: element?.createdAt,
                type: element.amenityTypes?.amenityTypeName,


            });
        });
        newData.sort((a, b) => (a.orderID > b.orderID) ? 1 : -1)
        setDataFormatted(newData);
    }

    /**
        * Crea el paginador segun los datos proporcionados en la variable data.
    */
    const createPaginator = async () => {
        let totalPages;
        if ((data.totalRows / data.pageLimit) % 1 == 0)
            totalPages = data.totalRows / data.pageLimit;
        else
            totalPages = Math.floor(data.totalRows / data.pageLimit) + 1;
        let pagesArray = [];
        let initPage = 1;
        let endPage = 1;
        let pagesShowed = (totalPages > 5) ? 5 : totalPages;
        if (data.currentPage < pagesShowed - 1) {
            initPage = 1;
            endPage = pagesShowed;
        }
        else if (data.currentPage > totalPages - pagesShowed) {
            if (totalPages > 5)
                initPage = totalPages - 5;
            endPage = totalPages;
        } else {
            initPage = data.currentPage - 2;
            endPage = data.currentPage + 2;
        }
        for (let i = initPage; i <= endPage; i++) {
            if (data.currentPage == i)
                pagesArray.push(<button onClick={() => { onChangePage(i) }} className="btn current-page" key={i.toString()}>Página {i}</button>);
            else
                pagesArray.push(<button onClick={() => { onChangePage(i) }} className="btn" key={i.toString()}>{i}</button>);
        }
        setPages(pagesArray);
        setNextPage(data.nextPage);
        setPrevPage(data.previousPage);
        setCurrentPage(data.currentPage);
    }

    /**
        * Selecciona una fase como favorita una fase.
        * @param {number} props.phaseID - ID de la fase.
    */
    const checkFavorite = async (phaseID) => {
        mainContext.setIsLoading(true);
        try {
            await rest.post("checkFavoritePhase", { phaseID: phaseID, userID: mainContext.session.userID });
            handleRefresh(1);
        }
        catch (error) {/*TODO SOMETHING*/ }
    }

    /**
        * selecciona una pagina de la tabla.
        * @param {number} props.page - ID de la fase.
    */
    const onChangePage = async (page) => {
        handleRefresh(page);
    }

    /**
        * Retorna el selector HTML <a> con la informacion de la fase seleccionada
        * Crea el vinculo hacia la ruta de informacion general.
        * @param {object} props.rowData - Datos de la fase.
    */
    const linkTableTemplate = (rowData) => {
        return <a href={"/#/phase/general"}>{rowData.towerName}</a>
    }

    /**
        * Retorna el selector HTML <a> con la informacion de la fase seleccionada.
        * Crea el vinculo hacia la ruta de ventas.
        * @param {object} props.rowData - Datos de la fase.
    */
    const openModalEdit = (id) => {

        return <a className="is-cursor" onClick={() => {
            openEditModal(id.amenityID)
        }}><img className="mr-2" src={editUser} alt="" /></a>
    }
    const openDeleteModal = (rowData) => {
        return <a className="is-cursor" onClick={() => {
            openDeletModal(rowData.amenityID, "deleteAmenityAdmin")
        }}><img className="mr-2" src={trashicon} alt="" width="20px" /></a>
    }

    /**
        * Retorna el selector HTML <span> con la informacion de la fase seleccionada.
        * Crea el boton para agregar a favoritos una fase.
        * @param {object} props.rowData - Datos de la fase.
    */
    const favoriteTableTemplate = (rowData) => {
        if (rowData.isFavorite)
            return <span className="btn-icon mt-3 color-red"><FontAwesomeIcon onClick={() => { checkFavorite(rowData.phaseID); }} icon={faHeartSolid} /></span>
        else
            return <span className="btn-icon mt-3"><FontAwesomeIcon onClick={() => { checkFavorite(rowData.phaseID); }} icon={faHeart} /></span>
    }

    return (
        <div className="datatable-responsive-demo mt-4">
            <DataTable tableStyle={{ 'table-layout': 'auto' }} value={dataFormatted} emptyMessage="No se encontraron datos" className="p-datatable-striped" rows={10}>
                <Column className="text-left  ellipsis" field="orderID" header="#" />
                <Column className="text-center" field="name" header="Nombre" />
                <Column className="text-center" field="type" header="Tipo" />
                <Column className="text-center w-220" field="updatedAt" header="Fecha de actualización" />
                <Column className="text-center" field="createdAt" header="Fecha de creación" />
                <Column className="text-center" field="amenityID" header="Editar" body={openModalEdit} />
                <Column className="text-center" field="amenityID" header="Eliminar" body={openDeleteModal} />
            </DataTable>

            <div className="mt-4 text-right">
                <button className="btn" onClick={() => { onChangePage(prevPage) }} disabled={(!prevPage)}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                </button>
                {pages}
                <button className="btn" onClick={() => { onChangePage(nextPage) }} disabled={(!nextPage)}>
                    <FontAwesomeIcon icon={faChevronRight} />
                </button>
            </div>
        </div>
    )
}
export default SearchEngineList