import React, { useEffect, useState } from 'react';
import Select from 'react-select';
//ACTIONS AND CONTEXT
import _ from 'lodash';

const Dropdown = ({ value, options, onChange, optionLabel, optionValue, disabled, placeholder = "Seleccionar", isClearable = true, width = "100%", height = "38px", customClass, isMulti = false, classNamePrefix, fontSize = "14px", hideItemBy }) => {

    const [optionsValues, setOptionsValues] = useState([]);
    /**
        * Css styles by default
    */
    const customStyles = {
        control: styles => ({
            ...styles, width: width, borderRadius: 0, border: "1px solid #000", "min-height": height, height: height, maxWidth: "100%"
        }),
        placeholder: styles => ({ ...styles, fontSize: fontSize }),
    }

    /**
        * Set value on dropdown change.
        * @param {object} event - Input event with the value property
    */
    const onSelectionChange = async (e) => {
        onChange({ value: e })
    }

    useEffect(() => {
        let arrOptions = [];
        console.log("options ", options);
        console.log("hideItemBy ", hideItemBy);
        if (options) {
            if (hideItemBy) {
                options.map((el) => {
                    if (el[hideItemBy])
                        arrOptions.push(el)

                })
            } else {
                arrOptions = options
            }

            if (arrOptions.length > 0) {

                arrOptions.sort((a, b) => {
                    // Esta validación es para impedir que el select 'Fecha de levantamiento' de la vista 'liftingReview' sea modificado
                    if (a.dateFormat != null && a.dateFormat != undefined && a.dateFormat != '' && a.gathering_at && a.gathering_at != undefined && a.gathering_at != '') {
                        return 0;
                    } else {
                        return (a[optionLabel] > b[optionLabel]) ? 1 : -1;
                    }
                });

                // arrOptions.sort((a, b) => (a[optionLabel] > b[optionLabel]) ? 1 : -1);
            }

        }
        setOptionsValues(arrOptions)
    }, [options]);



    return (
        <Select
            styles={customStyles}
            isClearable={isClearable}
            value={value}
            defaultValue={value}
            options={optionsValues}
            onChange={onSelectionChange}
            getOptionLabel={option => option[optionLabel] ? option[optionLabel] : option.description}
            getOptionValue={option => option[optionValue]}
            isDisabled={disabled}
            placeholder={placeholder}
            className={customClass}
            classNamePrefix={classNamePrefix}
            isMulti={isMulti}
            MultiValueLabel={false}
            closeMenuOnSelect={isMulti ? false : true}

        // getOptionValue={option => option.label}
        />
    )
}
export default Dropdown;