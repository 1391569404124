import React, { createContext, useContext, useState } from 'react';

export const CommercialConditionsContext = createContext({});

export const CommercialConditionsProvider = (props) => {
    const [salesData, setSalesData] = useState({ pool_parking: false, warehouse: false, additional_parking_spaces: false });
    const [exchangeRateData, setExchangeRateData] = useState({ is_preferencial_rate: false, is_curency: 1 }); //esta escrito como "preferencial" debido a que en back asi lo pide
    const [isCurrency, setIsCurrency] = useState(1);

    const value = {
        salesData,
        setSalesData,
        exchangeRateData,
        setExchangeRateData,
        isCurrency,
        setIsCurrency
    }

    return <CommercialConditionsContext.Provider value={value} {...props} />
}

export const useCommercialConditionsContext = () => {
    const context = useContext(CommercialConditionsContext);

    if (!context) {
        throw new Error("no existe el context")
    }

    return context;
}