import React, { createContext, useContext, useState } from 'react';
export const MainContext = createContext({});

export const MainContextProvider = (props) => {
    const [session, setSession] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isDirty, setIsDirty] = useState(false);
    const [goTo, setGoTo] = useState(null);
    const [phaseID, setPhaseID] = useState(null);
    const [catalogs, setCatalogs] = useState({ countries: [], developers: [], states: [], municipalities: [], zones: [] })
    const [showCloseSession, setShowCloseSession] = useState(false)
    const [preventNavigation, setPreventNavigation] = useState(false)
    const [messageAlert, setMessageAlert] = useState(null)
    const [updateCatalogs, setUpdateCatalogs] = useState(false)

    const value = {
        session,
        setSession,
        isLoading,
        setIsLoading,
        isDirty,
        setIsDirty,
        goTo,
        setGoTo,
        catalogs,
        setCatalogs,
        showCloseSession,
        setShowCloseSession,
        preventNavigation,
        setPreventNavigation,
        messageAlert,
        setMessageAlert,
        phaseID, setPhaseID,
        updateCatalogs, setUpdateCatalogs,
    }
    return <MainContext.Provider value={value} {...props} />
}

export const useMainContext = () => {
    const context = useContext(MainContext);
    if (!context) {
        throw new Error("no existe el context")
    }
    return context;
}