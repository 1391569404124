import React, { useEffect, useState } from 'react';
import { useRouteMatch } from "react-router-dom";
//ACTIONS AND CONTEXT
import { useMainContext } from "../../../contexts/MainContext";
//COMPONENTS
import ButtonModalFiles from '../ButtonModalFiles';
import ModalConfirmImport from "../../../components/atoms/ModalConfirmImport";
//ASSETS
import buildingIcon from '../../../assets/icons/icon-building.svg';
import homeImg from '../../../assets/icons/icon-home.svg';
import newProject from '../../../assets/icons/new-project.svg';
//ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGavel, faCheck } from '@fortawesome/free-solid-svg-icons';
import exclamationIcon from '../../../assets/icons/icon-exclamation-mark.svg'; // RED
import warningIcon from '../../../assets/icons/icon-warning.svg'; //YELLOW
import { element } from 'prop-types';


const MenuLifting = ({ rolID, sendChangeState, countCheckProyects, selectDate, updatePopUps, setUpdatePopUps, errorMessageApproved, setErrorMessageApproved, errorMessagePublished, setErrorMessagePublished }) => {
    let mainContext = useMainContext();
    let { path } = useRouteMatch();


    useEffect(() => {
        mainContext.setIsDirty(false);
    }, []);

    useEffect(() => {
        if (updatePopUps == true) {
            // Cerrar popUps
            setPopUpApproved(false);
            setPopUpPublished(false);

            setUpdatePopUps(false);
        }

    }, [updatePopUps]);

    const requestNavigation = (goTo) => {
        let goToArr = goTo.split("/");
        let pathArr = path.split("/");
        if (goToArr[goToArr.length - 1] == pathArr[pathArr.length - 1])
            return;

        window.location.href = goTo;
    }

    const [popUpApproved, setPopUpApproved] = useState(false);
    const [popUpPublished, setPopUpPublished] = useState(false);

    const showPopUpApproved = () => {
        if (countCheckProyects < 1) {
            mainContext.setMessageAlert("tienes que marcar al menos un levantamiento");
            return;
        }

        return setPopUpApproved(prevEvent => !prevEvent);
    }

    const showPopUpPublished = () => {
        if (countCheckProyects < 1) {
            mainContext.setMessageAlert("tienes que marcar al menos un levantamiento");
            return;
        }

        return setPopUpPublished(prevEvent => !prevEvent);
    }

    return (
        <>

            <div className="row" style={{ width: '100vw' }}>
                <div className="">
                    <h2 className="break-word">{"Gestión / Revisión de levantamiento"}</h2>
                </div>
                <div className="">
                    <div className="">
                        {/* <div className="contentModals"> */}
                        {/* Aprobar */}
                        <ModalConfirmImport size="lg" show={popUpApproved} icon={warningIcon} handleCancel={() => setPopUpApproved(prevEvent => !prevEvent)} handleConfirm={() => (setPopUpApproved(prevEvent => !prevEvent), sendChangeState('approved'))}>
                            <div className="col">
                                <p className="title-modal">
                                    Estás a punto de aprobar los levantamientos del <b>{selectDate}</b> para <b>{countCheckProyects}</b> proyectos.
                                    <br />
                                    <br />
                                    <b>Esta operación enviará los proyectos a revisión final para su publicación.</b>
                                    <br />
                                    <b>¿Deseas continuar?</b>
                                </p>
                            </div>
                        </ModalConfirmImport>
                        {/* Error Aprobar */}
                        <ModalConfirmImport size="lg" show={errorMessageApproved.status} icon={exclamationIcon} handleCancel={() => setErrorMessageApproved({ status: false, elements: [] })} hideConfirm={true}>
                            <div className="col">
                                <p className="title-modal">
                                    Estás intentando aprobar levantamientos del <b>{selectDate}</b> que no se encuentran cerrados o ya fueron aprobados.
                                    <br />
                                    <br />
                                    <b>Favor revisar los siguientes proyectos:</b>
                                    <br />
                                    <ul style={{ listStyle: "none" }}>
                                        {errorMessageApproved.elements.map(element =>
                                            <li>{element.nameProject} - {element.status}</li>
                                        )}
                                    </ul>
                                </p>
                            </div>
                        </ModalConfirmImport>
                        {/* Publicar */}
                        <ModalConfirmImport size="lg" show={popUpPublished} icon={warningIcon} handleCancel={() => setPopUpPublished(prevEvent => !prevEvent)} handleConfirm={() => (setPopUpPublished(prevEvent => !prevEvent), sendChangeState('published'))}>
                            <div className="col">
                                <p className="title-modal">
                                    Estás a punto de PUBLICAR los levantamientos del <b>{selectDate}</b> para <b>{countCheckProyects}</b> proyectos.
                                    <br />
                                    <br />
                                    <b>Esta operación hará que los levantamientos sean visibles en las aplicaciones conectadas.</b>
                                    <br />
                                    <b>¿Deseas continuar?</b>
                                </p>
                            </div>
                        </ModalConfirmImport>
                        {/* Error Publicar */}
                        <ModalConfirmImport size="lg" show={errorMessagePublished.status} icon={exclamationIcon} handleCancel={() => setErrorMessagePublished({ status: false, elements: [] })} hideConfirm={true}>
                            <div className="col">
                                <p className="title-modal">
                                    Estás intentando aprobar levantamientos del <b>{selectDate}</b> que no se encuentran APROBADOS o ya fueron aprobados.
                                    <br />
                                    <br />
                                    <b>Favor revisar los siguientes proyectos:</b>
                                    <br />
                                    <ul style={{ listStyle: "none" }}>
                                        {errorMessagePublished.elements.map(element =>
                                            <li>{element.nameProject} - {element.status}</li>
                                        )}
                                    </ul>
                                </p>
                            </div>
                        </ModalConfirmImport>
                        {/* </div> */}

                        <button className="btn btn-primary mx-2" disabled={rolID == 2 ? true : false} onClick={() => showPopUpApproved()}>
                            {/* Desactivado para usuarios CAPTURISTA */}
                            <FontAwesomeIcon className="mr-2" icon={faGavel} />
                            Aprobar Proyectos
                        </button>
                        <button className="btn btn-primary mx-2 buttonRed" disabled={rolID == 1 ? false : true} onClick={() => showPopUpPublished()}>
                            {/* Habilitado solo para usuarios rol administrador, clase .disabledButton */}
                            <FontAwesomeIcon className="mr-2" icon={faCheck} />
                            Publicar Proyectos
                        </button>
                        <button onClick={() => { requestNavigation("/#home") }} className="btn btn-white btn-submenu" >
                            <img className="" src={homeImg} alt="" />
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default MenuLifting;