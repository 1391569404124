import React, { useEffect, useState } from 'react'
import { Dropdown } from 'primereact/dropdown';
//ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faGavel, faPencilAlt, faLongArrowAltDown, faLongArrowAltUp, faFileDownload, faDownload, faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';

//ACTIONS AND CONTEXT
import { RestActions } from "../../../actions";
import { useMainContext } from "../../../contexts/MainContext";
//ASSETS
import envelopeCheck from '../../../assets/icons/icon-circle-check.svg';
import xmark from '../../../assets/icons/icon-xmark.svg';
//COMPONENTS
import InputCheckboxList from '../../../components/atoms2/InputCheckboxList';

import './style.css'

/**
    * Componente TableLiftingReview
    * @component
    * @param  props
    * @param {array} props.dataTable - Arreglo del Listado de fases.
    * @param {function} props.checkChecked - Estado de los elementos seleccionados.
    * @param {function} props.setCheckChecked - Actualiza el estado de los elementos seleccionados.
    * @param {object} props.dataHeader - Encabezado de datos.
    * @param {function} props.setCountCheckProyects - Actualiza la cuenta de proyectos seleccionados.
    * 
*/
const TableLiftingReview = ({ dataButtonExcel, createExcel, dataTable, checkChecked, setCheckChecked, dataHeader, setCountCheckProyects }) => {
    const rest = new RestActions();
    const [expandedRows, setExpandedRows] = useState([]);
    const [checkAll, setCheckAll] = useState(false);
    let mainContext = useMainContext();


    const changeCheck = (e, statusID, nameProject) => {

        if (statusID < 2) {
            mainContext.setMessageAlert("No se puede cambiar el estado de este levantamiento");
            return;
        }

        let value = e.currentTarget.checked;
        let id = parseInt(e.currentTarget.id, 10);
        let statusgathering = e.target.dataset.statusgathering;

        let arr = [...checkChecked];
        arr = arr.filter(item => item.id !== id);

        let conuntArray = arr.length;

        if (value) {
            arr.push({ id: parseInt(id), status: statusgathering, nameProject: nameProject });
            conuntArray = conuntArray + 1;
        }

        setCountCheckProyects(conuntArray);
        setCheckChecked(arr);
    }

    const changeAllCheck = () => {
        let documentCheck = document.querySelectorAll('.checkGathering');

        let allCheck = [];
        if (documentCheck.length !== 0 && checkAll === false) {
            // Convertir la NodeList a un array y luego mapear los elementos
            allCheck = Array.from(documentCheck).map(element =>
                parseInt(element.dataset.statusgathering) >= 2 && { id: parseInt(element.id), status: element.dataset.statusgathering, nameProject: element.dataset.nameproject }
            );
        }
        setCountCheckProyects(allCheck.length);

        setCheckChecked(allCheck);
        setCheckAll(checkAll === true ? false : true);
    };

    useEffect(() => {
        if (checkAll === true) { return setCheckAll(false) };
    }, [dataTable]);


    // useEffect(() => {
    //     if (data && data.data != undefined) {
    //         proccessData();
    //     }
    // }, [data])
    // const proccessData = async () => {
    //     let newData = [];
    //     data.data.forEach(element => {
    //         newData.push({
    //             id: parseInt(element.id),
    //             zone: element.zone,
    //             developer: element.developer,
    //             status: element.status,
    //             developer: element.developer,
    //             initDate: element.initDate,
    //             MonthsInMdo: element.MonthsInMdo,
    //             ut: element.ut,
    //             uv: element.ut,
    //             ui: element.ui,
    //             minimalPrice: element.minimalPrice,
    //             maximumPrice: element.maximumPrice,
    //             averagePrice: element.averagePrice,
    //             pricePerAverageM2: element.pricePerAverageM2,
    //             currency: element.currency,
    //             responsible: element.responsible,
    //             status: element.status,
    //             dataHeader1: element.dataHeader1,
    //             dataHeader2: element.dataHeader2,
    //             dataHeader3: element.dataHeader3,
    //             dataHeader4: element.dataHeader4

    //         });
    //     });
    //     setDataFormatted(newData);
    //     console.log(dataFormatted, newData, "hola");
    // }

    const handleCellClick = (rowIndex) => {
        // Comprueba si la fila está en la lista de filas expandidas
        const isRowExpanded = expandedRows.includes(rowIndex);

        // Actualiza el estado para mostrar u ocultar la fila
        setExpandedRows(isRowExpanded
            ? expandedRows.filter(row => row !== rowIndex)
            : [...expandedRows, rowIndex]
        );
    };

    const [idAllDataTable, setIdAllDataTable] = useState({
        allID: [],
        show: false
    });

    useEffect(() => {
        /**
         * Funcionalidad creada para poder desplegar todos los modelos al mismo tiempo, guardando todos los ID de phases en un arreglo
        */
        let allID = [];
        for (let i = 0; i < dataTable.length; i++) {
            if (dataTable[i].id) {
                allID.push(dataTable[i].id);
            }
        }

        setIdAllDataTable({
            allID: [...allID],
            show: false
        });

        setDataTableFilters([...dataTable]);
    }, [dataTable]);

    useEffect(() => {
        setExpandedRows(idAllDataTable.show
            ? [...idAllDataTable.allID]
            : []
        );
    }, [idAllDataTable]);

    const handleShowAllModels = () => {
        // Actualiza el estado para mostrar u ocultar la fila
        setIdAllDataTable({
            ...idAllDataTable,
            show: !idAllDataTable.show
        });
        // mainContext.setMessageAlert("La acción puede tardar unos segundos, si no se aplica el cambio intenta nuevamente");

    };

    const [dataTableFilters, setDataTableFilters] = useState(dataTable);
    const [appliedFilter, setAppliedFilter] = useState({
        who: '',
        sort: '' // DESC or ASC
    });

    const handleFilter = (field, value) => {
        const filteredData = dataTable.filter(row => {
            const fieldValue = row[field];

            // Manejo de precios
            if (['priceMin', 'priceMax', 'priceAvg', 'pricePerM2'].includes(field)) {
                const numericFieldValue = parseFloat(fieldValue.replace(/[^0-9.-]+/g, ""));
                const numericValue = parseFloat(value.replace(/[^0-9.-]+/g, ""));
                return numericFieldValue === numericValue;
            }

            // Manejo de fechas
            if (fieldValue instanceof Date) {
                const filterDate = new Date(value);
                return fieldValue.getTime() === filterDate.getTime();
            }

            // Manejo de otros tipos de datos
            return fieldValue.toString().toLowerCase().includes(value.toLowerCase());
        });
        setDataTableFilters(filteredData);
    };


    // Función para ordenar los registros
    const sortData = (field) => {
        const { sort } = appliedFilter;
        const nextSort = sort === 'ASC' ? 'DESC' : 'ASC';

        const sortedData = [...dataTableFilters].sort((a, b) => {
            let fieldA = a[field];
            let fieldB = b[field];

            if (fieldA === undefined || fieldA === null) fieldA = '';
            if (fieldB === undefined || fieldB === null) fieldB = '';

            const isPriceField = ['priceMin', 'priceMax', 'priceAvg', 'pricePerM2'].includes(field);
            const isDateField = fieldA instanceof Date && fieldB instanceof Date;
            const isNumeric = isPriceField || (!isNaN(parseFloat(fieldA)) && !isNaN(parseFloat(fieldB)));

            if (isPriceField) {
                fieldA = parseFloat(fieldA.replace(/[^0-9.-]+/g, ""));
                fieldB = parseFloat(fieldB.replace(/[^0-9.-]+/g, ""));
            } else if (isDateField) {
                fieldA = new Date(fieldA).getTime();
                fieldB = new Date(fieldB).getTime();
            } else if (isNumeric) {
                fieldA = parseFloat(fieldA);
                fieldB = parseFloat(fieldB);
            } else {
                fieldA = fieldA.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                fieldB = fieldB.toString().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            }

            if (nextSort === 'ASC') {
                return fieldA > fieldB ? 1 : fieldA < fieldB ? -1 : 0;
            } else {
                return fieldA < fieldB ? 1 : fieldA > fieldB ? -1 : 0;
            }
        });

        setAppliedFilter({ who: field, sort: nextSort });
        console.log(sortedData);
        setDataTableFilters(sortedData);
    };



    const handleSort = (field) => {
        sortData(field);
    };

    function isDateLessThanThreeMonths(date) {
        // Obtener la fecha actual
        const currentDate = new Date();

        // Crear una fecha hace tres meses desde la fecha actual
        const threeMonthsAgo = new Date();
        threeMonthsAgo.setMonth(currentDate.getMonth() - 3);

        // Comparar la fecha proporcionada con la fecha de hace tres meses
        return new Date(date) > threeMonthsAgo;
    }

    return (
        <div className="custom-card mt-4" style={{ /*overflowX: 'scroll',*/ width: '100%', position: 'relative' }}>
            <div className="custom-card-header" style={{ position: 'sticky', width: '100%', top: '0', left: '0' }}>
                <h4 className="py-3 px-3">
                    <button type='button' onClick={() => handleShowAllModels()} style={{
                        borderRadius: '5px',
                        border: '1px #b7b5b5 solid',
                        fontSize: '15px',
                        boxShadow: '1px 1px 3px 0px #958d8d',
                        marginRight: '15px',
                        lineHeight: 'normal',
                        transform: 'translate(0px, -1px)',
                        position: 'sticky',
                        left: '1em'
                    }}>show all</button>
                    <span style={{ position: 'sticky', left: '4.5em' }}>Resultado</span>
                    <button id='buttonDownloadFile' disabled={dataButtonExcel.disabled} onClick={() => createExcel()} style={{ position: 'sticky', left: '97.5vw' }}>
                        {dataButtonExcel.content == 'icon' ? <FontAwesomeIcon className="mr-2" icon={faFileDownload} /> : dataButtonExcel.content}
                    </button>
                </h4>
            </div>
            <div className="custom-card-body px-3">
                <div className="row">

                    {/* <table className="table-no-border table-striped table-levantamientos" > */}
                    <table className="" style={{ width: '100%', maxWidth: '100%', display: '' }}>
                        <thead style={{ position: 'sticky', top: '55px' }}>
                            <tr  >
                                <th className="text-center contentSmaller">
                                    {
                                        checkAll
                                            ?
                                            <input type="checkbox" onClick={() => changeAllCheck()} checked="checked" />
                                            :
                                            <input type="checkbox" onClick={() => changeAllCheck()} />
                                    }

                                    {/* <InputCheckboxList handleData={handleCheckboxList} dataTable={dataTable.id} /> */}
                                </th>

                                <th className="text-center contentMedium" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}> {/** id = ID */}
                                    <div onClick={() => handleSort('id')}>
                                        ID
                                        {appliedFilter.who === 'id' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                </th>
                                <th className="text-center contentMedium" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}> {/** trade_status = Estatus comercial */}
                                    <div onClick={() => handleSort('trade_status')}>
                                        Estatus comercial
                                        {appliedFilter.who === 'trade_status' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                </th>
                                <th className="text-center contentMedium" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}> {/** commercial_business_description = Giro */}
                                    <div onClick={() => handleSort('commercial_business_description')}>
                                        Giro
                                        {appliedFilter.who === 'commercial_business_description' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                </th>
                                <th className="text-center contentMedium" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}> {/** classifications_description = Segmento / Clasificación */}
                                    <div onClick={() => handleSort('classifications_description')}>
                                        Segmento / Clasificación
                                        {appliedFilter.who === 'classifications_description' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                </th>
                                <th className="text-center contentMedium" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}> {/** sales_end_date = Fecha de fin de venta */}
                                    <div onClick={() => handleSort('sales_end_date')}>
                                        Fecha de fin de venta
                                        {appliedFilter.who === 'sales_end_date' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                </th>
                                <th className="text-center contentMedium" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}> {/** levels = Número de niveles */}
                                    <div onClick={() => handleSort('levels')}>
                                        Número de niveles
                                        {appliedFilter.who === 'levels' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                </th>
                                <th className="text-center contentMedium" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}> {/** finished_types_description = Tipo de acabado */}
                                    <div onClick={() => handleSort('finished_types_description')}>
                                        Tipo de acabado
                                        {appliedFilter.who === 'finished_types_description' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                </th>
                                <th className="text-center contentMedium" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}> {/** latitud = Latitud */}
                                    <div onClick={() => handleSort('latitud')}>
                                        Latitud
                                        {appliedFilter.who === 'latitud' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                </th>
                                <th className="text-center contentMedium" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}> {/** longitud = Longitud */}
                                    <div onClick={() => handleSort('longitud')}>
                                        Longitud
                                        {appliedFilter.who === 'longitud' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                </th>

                                <th className="text-center contentMedium" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}>
                                    <div onClick={() => handleSort('zone')} >
                                        Zona
                                        {appliedFilter.who === 'zone' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                    {/* <input type="text" placeholder="Filtrar" onChange={(e) => handleFilter('zone', e.target.value)} /> */}
                                </th>
                                <th className="text-center contentMedium" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}>
                                    <div onClick={() => handleSort('developer')}>
                                        Desarrollador
                                        {appliedFilter.who === 'developer' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                    {/* <input type="text" placeholder="Filtrar" onChange={(e) => handleFilter('developer', e.target.value)} /> */}
                                </th>
                                <th className="text-center contentMedium" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}>
                                    <div onClick={() => handleSort('project')}>
                                        Proyecto
                                        {appliedFilter.who === 'project' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                    {/* <input type="text" placeholder="Filtrar" onChange={(e) => handleFilter('project', e.target.value)} /> */}
                                </th>
                                <th className="text-center contentMedium" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}>
                                    <div onClick={() => handleSort('initDateWithoutFormat')}>
                                        Fecha Inicio
                                        {appliedFilter.who === 'initDateWithoutFormat' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                    {/* <input type="text" placeholder="Filtrar" onChange={(e) => handleFilter('initDate', e.target.value)} /> */}
                                </th>
                                <th className="text-center contentSmaller" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}>
                                    <div onClick={() => handleSort('MonthsInMdo')}>
                                        Meses en Mdo
                                        {appliedFilter.who === 'MonthsInMdo' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                    {/* <input type="text" placeholder="Filtrar" onChange={(e) => handleFilter('MonthsInMdo', e.target.value)} /> */}
                                </th>


                                <th className="text-center contentMedium" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}> {/** total_m2 = m2 totales */}
                                    <div onClick={() => handleSort('total_m2')}>
                                        m2 totales
                                        {appliedFilter.who === 'total_m2' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                </th>
                                <th className="text-center contentMedium" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}> {/** indoor_m2 = m2 interiores */}
                                    <div onClick={() => handleSort('indoor_m2')}>
                                        m2 interiores
                                        {appliedFilter.who === 'indoor_m2' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                </th>
                                <th className="text-center contentMedium" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}> {/** terrace_m2 = m2 terraza */}
                                    <div onClick={() => handleSort('terrace_m2')}>
                                        m2 terraza
                                        {appliedFilter.who === 'terrace_m2' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                </th>
                                <th className="text-center contentMedium" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}> {/** yard_m2 = m2 jardín */}
                                    <div onClick={() => handleSort('yard_m2')}>
                                        m2 jardín
                                        {appliedFilter.who === 'yard_m2' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                </th>
                                <th className="text-center contentMedium" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}> {/** land_m2 = m2 terreno */}
                                    <div onClick={() => handleSort('land_m2')}>
                                        m2 terreno
                                        {appliedFilter.who === 'land_m2' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                </th>


                                <th className="text-center contentSmaller" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}>
                                    <div onClick={() => handleSort('ut')}>
                                        U.T.
                                        {appliedFilter.who === 'ut' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                    {/* <input type="text" placeholder="Filtrar" onChange={(e) => handleFilter('ut', e.target.value)} /> */}
                                </th>
                                <th className="text-center contentSmaller" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}>
                                    <div onClick={() => handleSort('uv')}>
                                        U.V.
                                        {appliedFilter.who === 'uv' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                    {/* <input type="text" placeholder="Filtrar" onChange={(e) => handleFilter('uv', e.target.value)} /> */}
                                </th>
                                <th className="text-center contentSmaller" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}>
                                    <div onClick={() => handleSort('ui')}>
                                        U.I.
                                        {appliedFilter.who === 'ui' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                    {/* <input type="text" placeholder="Filtrar" onChange={(e) => handleFilter('ui', e.target.value)} /> */}
                                </th>
                                <th className="text-center contentSmaller">{dataHeader.date1 ? dataHeader.date1 : '---'}</th> {/* 1 Jul 2022 */}
                                <th className="text-center contentSmaller">{dataHeader.date2 ? dataHeader.date2 : '---'}</th> {/* 1 Oct 2022 */}
                                <th className="text-center contentSmaller">{dataHeader.date3 ? dataHeader.date3 : '---'}</th> {/* 1 Ene 2023 */}
                                <th className="text-center contentSmaller">{dataHeader.date4 ? dataHeader.date4 : '---'}</th> {/* 1 Abr 2022 */}
                                <th className="text-center contentMedium" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}>
                                    <div onClick={() => handleSort('minimalPriceWithoutFormat')}>
                                        Precio Mínimo
                                        {appliedFilter.who === 'minimalPriceWithoutFormat' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                    {/* <input type="text" placeholder="Filtrar" onChange={(e) => handleFilter('minimalPriceWithoutFormat', e.target.value)} /> */}
                                </th>
                                <th className="text-center contentMedium" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}>
                                    <div onClick={() => handleSort('maximumPriceWithoutFormat')}>
                                        Precio Máximo
                                        {appliedFilter.who === 'maximumPriceWithoutFormat' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                    {/* <input type="text" placeholder="Filtrar" onChange={(e) => handleFilter('maximumPriceWithoutFormat', e.target.value)} /> */}
                                </th>
                                <th className="text-center contentMedium" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}>
                                    <div onClick={() => handleSort('averagePriceWithoutFormat')}>
                                        Precio Promedio
                                        {appliedFilter.who === 'averagePriceWithoutFormat' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                    {/* <input type="text" placeholder="Filtrar" onChange={(e) => handleFilter('averagePriceWithoutFormat', e.target.value)} /> */}
                                </th>
                                <th className="text-center contentMedium" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}>
                                    <div onClick={() => handleSort('pricePerAverageM2WithoutFormat')}>
                                        Precio por M2 Promedio
                                        {appliedFilter.who === 'pricePerAverageM2WithoutFormat' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                    {/* <input type="text" placeholder="Filtrar" onChange={(e) => handleFilter('pricePerAverageM2WithoutFormat', e.target.value)} /> */}
                                </th>


                                <th className="text-center contentMedium" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}> {/** price_increment = Incremento % precio */}
                                    <div onClick={() => handleSort('price_increment')}>
                                        Incremento % precio
                                        {appliedFilter.who === 'price_increment' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                </th>
                                <th className="text-center contentMedium" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}> {/** price_m2_min = Precio M2 mínimo */}
                                    <div onClick={() => handleSort('price_m2_minWithoutFormat')}>
                                        Precio M2 mínimo
                                        {appliedFilter.who === 'price_m2_minWithoutFormat' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                </th>
                                <th className="text-center contentMedium" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}> {/** price_m2_max = Precio M2 máximo */}
                                    <div onClick={() => handleSort('price_m2_max')}>
                                        Precio M2 máximo
                                        {appliedFilter.who === 'price_m2_max' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                </th>
                                <th className="text-center contentMedium" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}> {/** m2_price_increment = Incremento % precio m2 */}
                                    <div onClick={() => handleSort('m2_price_increment')}>
                                        Incremento % precio m2
                                        {appliedFilter.who === 'm2_price_increment' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                </th>


                                <th className="text-center contentMedium" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}>
                                    <div onClick={() => handleSort('currency')}>
                                        Moneda
                                        {appliedFilter.who === 'currency' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                    {/* <input type="text" placeholder="Filtrar" onChange={(e) => handleFilter('currency', e.target.value)} /> */}
                                </th>

                                <th className="text-center contentMedium" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}> {/** recamaras = Recámaras */}
                                    <div onClick={() => handleSort('recamaras')}>
                                        Recámaras
                                        {appliedFilter.who === 'recamaras' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                </th>
                                <th className="text-center contentMedium" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}> {/** estancias = Recámaras */}
                                    <div onClick={() => handleSort('estancias')}>
                                        Estancias
                                        {appliedFilter.who === 'estancias' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                </th>
                                <th className="text-center contentMedium" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}> {/** banos_completos = Baños completos */}
                                    <div onClick={() => handleSort('banos_completos')}>
                                        Baños completos
                                        {appliedFilter.who === 'banos_completos' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                </th>
                                <th className="text-center contentMedium" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}> {/** medios_banos = Medios baños */}
                                    <div onClick={() => handleSort('medios_banos')}>
                                        Medios baños
                                        {appliedFilter.who === 'medios_banos' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                </th>
                                <th className="text-center contentMedium" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}> {/** cuarto_servicio = Cuarto de servicio  */}
                                    <div onClick={() => handleSort('cuarto_servicio')}>
                                        Cuarto de servicio
                                        {appliedFilter.who === 'cuarto_servicio' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                </th>
                                <th className="text-center contentMedium" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}> {/** bano_servicio = Baño de servicio */}
                                    <div onClick={() => handleSort('bano_servicio')}>
                                        Baño de servicio
                                        {appliedFilter.who === 'bano_servicio' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                </th>
                                <th className="text-center contentMedium" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}> {/** estacionamientos = Estacionamientos  */}
                                    <div onClick={() => handleSort('estacionamientos')}>
                                        Estacionamientos
                                        {appliedFilter.who === 'estacionamientos' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                </th>
                                <th className="text-center contentMedium" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}> {/** bodega = Bodega  */}
                                    <div onClick={() => handleSort('bodega')}>
                                        Bodega
                                        {appliedFilter.who === 'bodega' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                </th>

                                <th className="text-center contentMedium" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}>
                                    <div onClick={() => handleSort('responsible')}>
                                        Responsable
                                        {appliedFilter.who === 'responsible' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                    {/* <input type="text" placeholder="Filtrar" onChange={(e) => handleFilter('responsible', e.target.value)} /> */}
                                </th>
                                <th className="text-center contentSmaller" style={{ cursor: 'pointer', maxWidth: 'fit-content', minWidth: '100px', overflowWrap: 'break-word' }}>
                                    <div onClick={() => handleSort('gathering_status')}>
                                        Estatus
                                        {appliedFilter.who === 'gathering_status' && (
                                            appliedFilter.sort === 'ASC' ?
                                                <FontAwesomeIcon icon={faLongArrowAltUp} /> :
                                                <FontAwesomeIcon icon={faLongArrowAltDown} />
                                        )}
                                    </div>
                                    {/* <input type="text" placeholder="Filtrar" onChange={(e) => handleFilter('gathering_status', e.target.value)} /> */}
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {dataTableFilters.map((item) => (
                                <>
                                    <tr className="colorWhite" key={item.id} style={{ color: item.trade_status === 'Stand By' ? '#c5c0c0' : '' }}>
                                        <td className="text-center contentSmaller breakWord">
                                            {(item.gatheringID != null && item.gatheringID != '' && item.gathering_status != 4) ? <input type="checkbox" id={item.gatheringID} onClick={(e) => changeCheck(e, item.gathering_status, item.project)} checked={checkChecked.some(obj => obj.id === item.gatheringID)} className='checkGathering' data-statusgathering={item.gathering_status} data-nameproject={item.project} /> : ''}
                                        </td>
                                        <td className="text-center contentMedium breakWord" title="ID de phase">{item.id}</td>{/** ID */}
                                        <td className="text-center contentMedium breakWord" title={item.trade_status}>{item.trade_status}</td>{/* trade_status = Estatus comercial*/}

                                        <td className="text-center contentMedium breakWord" title={item.commercial_business_description}>{item.commercial_business_description}</td>{/** commercial_business_description = Giro */}
                                        <td className="text-center contentMedium breakWord" title={item.classifications_description}>{item.classifications_description}</td>{/** classifications_description = Segmento / Clasificación */}
                                        <td className="text-center contentMedium breakWord" title={item.sales_end_date}>{item.sales_end_date}</td>{/** sales_end_date = Fecha de fin de venta */}
                                        <td className="text-center contentMedium breakWord" title={item.levels}>{item.levels}</td>{/** levels = Número de niveles */}
                                        <td className="text-center contentMedium breakWord" title={item.finished_types_description}>{item.finished_types_description}</td>{/** finished_types_description = Tipo de acabado */}
                                        <td className="text-center contentMedium breakWord" title={item.latitud}>{item.latitud}</td>{/** latitud = Latitud */}
                                        <td className="text-center contentMedium breakWord" title={item.longitud}>{item.longitud}</td>{/** longitud = Longitud */}

                                        <td className="text-center contentMedium breakWord" title={item.zone}>{item.zone}</td>
                                        <td className="text-center contentMedium breakWord" title={item.developer}>{item.developer}</td>
                                        <td className="text-center contentMedium CellClick breakWord" style={{ color: isDateLessThanThreeMonths(item.initDateWithoutFormat) ? 'green' : isDateLessThanThreeMonths(item.created_at) ? '#e5e502' : 'initial', fontWeight: 'bold', border: "1px black solid" }} title={item.project} onClick={() => handleCellClick(item.id)}> {item.project}</td>
                                        <td className="text-center contentSmaller breakWord">{item.initDate}</td>
                                        <td className="text-center contentSmaller breakWord">{item.MonthsInMdo}</td>

                                        <td className="text-center contentMedium breakWord" title={item.total_m2}>{item.total_m2}</td>{/** total_m2 = m2 totales */}
                                        <td className="text-center contentMedium breakWord" title={item.indoor_m2}>{item.indoor_m2}</td>{/** indoor_m2 = m2 interiores */}
                                        <td className="text-center contentMedium breakWord" title={item.terrace_m2}>{item.terrace_m2}</td>{/** terrace_m2 = m2 terraza */}
                                        <td className="text-center contentMedium breakWord" title={item.yard_m2}>{item.yard_m2}</td>{/** yard_m2 = m2 jardín */}
                                        <td className="text-center contentMedium breakWord" title={item.land_m2}>{item.land_m2}</td>{/** land_m2 = m2 terreno */}

                                        <td className="text-center contentSmaller breakWord">{item.ut}</td>
                                        <td className="text-center contentSmaller breakWord"> {item.uv} </td>
                                        <td className="text-center contentSmaller breakWord">{item.ui}</td>

                                        <td className="text-center contentSmaller breakWord">{item.date1 ? item.date1 : '--'}</td>
                                        <td className="text-center contentSmaller breakWord">{item.date2 ? item.date2 : '--'}</td>
                                        <td className="text-center contentSmaller breakWord">{item.date3 ? item.date3 : '--'}</td>
                                        <td className="text-center contentSmaller breakWord">{item.date4 ? item.date4 : '--'}</td>

                                        <td className="text-center contentMedium breakWord">
                                            {item.minimalPrice}
                                        </td>
                                        <td className="text-center contentMedium breakWord">
                                            {item.maximumPrice}
                                        </td>
                                        <td className="text-center contentMedium breakWord">
                                            {item.averagePrice}
                                        </td>
                                        <td className="text-center contentMedium breakWord">
                                            {item.pricePerAverageM2}
                                        </td>

                                        <td className="text-center contentMedium breakWord" title={item.price_increment}>{item.price_increment}</td> {/** price_increment = Incremento % precio */}
                                        <td className="text-center contentMedium breakWord" title={item.price_m2_min}>{item.price_m2_min}</td> {/** price_m2_min = Precio M2 mínimo */}
                                        <td className="text-center contentMedium breakWord" title={item.price_m2_max}>{item.price_m2_max}</td> {/** price_m2_max = Precio M2 máximo */}
                                        <td className="text-center contentMedium breakWord" title={item.m2_price_increment}>{item.m2_price_increment}</td> {/** m2_price_increment = Incremento % precio m2 */}

                                        <td className="text-center contentSmaller breakWord">
                                            {item.currency ? item.currency : '--'}
                                        </td>

                                        <td className="text-center contentMedium breakWord" title={item.recamaras}>{item.recamaras}</td> {/** recamaras = Recámaras */}
                                        <td className="text-center contentMedium breakWord" title={item.estancias}>{item.estancias}</td> {/** estancias = Recámaras */}
                                        <td className="text-center contentMedium breakWord" title={item.banos_completos}>{item.banos_completos}</td> {/** banos_completos = Baños completos */}
                                        <td className="text-center contentMedium breakWord" title={item.medios_banos}>{item.medios_banos}</td> {/** medios_banos = Medios baños */}
                                        <td className="text-center contentMedium breakWord" title={item.cuarto_servicio}>{item.cuarto_servicio}</td> {/** cuarto_servicio = Cuarto de servicio  */}
                                        <td className="text-center contentMedium breakWord" title={item.bano_servicio}>{item.bano_servicio}</td> {/** bano_servicio = Baño de servicio */}
                                        <td className="text-center contentMedium breakWord" title={item.estacionamientos}>{item.estacionamientos}</td> {/** estacionamientos = Estacionamientos  */}
                                        <td className="text-center contentMedium breakWord" title={item.bodega}>{item.bodega}</td> {/** bodega = Bodega  */}

                                        <td className="text-center contentMedium breakWord">
                                            {item.responsible}
                                            {/* <Dropdown optionValue="responsible" optionLabel="description" className="dropdown-color" /> */}
                                        </td>
                                        <td className="text-center contentSmaller">
                                            {item.gathering_status === 4 ? (
                                                <img className="" src={envelopeCheck} alt="" /> // Publicado
                                            ) : item.gathering_status === 3 ? (
                                                <FontAwesomeIcon className="mr-2" icon={faGavel} /> // Aprobado
                                            ) : item.gathering_status === 1 ? (
                                                <FontAwesomeIcon icon={faPencilAlt} /> // En curso 
                                            ) : item.gathering_status === 0 ? (
                                                <img className="" src={xmark} alt="" /> // Sin levantamiento
                                            ) : (
                                                <FontAwesomeIcon icon={faLock} /> //cerrado
                                            )}

                                        </td>
                                    </tr>


                                    {/* Filas adicionales para mostrar u ocultar */}
                                    {item.models.map((model, index) => (
                                        <>
                                            {
                                                expandedRows.includes(item.id) && (

                                                    <tr className="colorGray" key={`model_${index}`} >
                                                        <td className="text-center breakWord"></td>
                                                        <td className="text-center breakWord" title='ID de modelo'>{model.model_id}</td>{/** ID */}
                                                        <td className="text-center breakWord"></td> {/** trade_status = Estatus comercial */}
                                                        <td className="text-center breakWord" title={model.commercial_business_description}>{model.commercial_business_description}</td>{/** commercial_business_description = Giro */}
                                                        <td className="text-center breakWord"></td>{/** classifications_description = Segmento / Clasificación */}
                                                        <td className="text-center breakWord"></td>{/** sales_end_date = Fecha de fin de venta */}
                                                        <td className="text-center breakWord" title={model.levels}>{model.levels}</td>{/** levels = Número de niveles */}
                                                        <td className="text-center breakWord"></td>{/** finished_types_description = Tipo de acabado */}
                                                        <td className="text-center breakWord"></td>{/** latitud = Latitud */}
                                                        <td className="text-center breakWord"></td>{/** longitud = Longitud */}
                                                        <td className="text-center breakWord"></td>{/** zone = Zona */}
                                                        <td className="text-center breakWord"></td>{/** developer = Desarrollador */}
                                                        <td className="text-center breakWord">{model.name}</td>
                                                        <td className="text-center breakWord"></td>
                                                        <td className="text-center breakWord"></td>

                                                        <td className="text-center breakWord" title={model.total_m2}>{model.total_m2}</td> {/** total_m2 = m2 totales */}
                                                        <td className="text-center breakWord" title={model.indoor_m2}>{model.indoor_m2}</td> {/** indoor_m2 = m2 interiores */}
                                                        <td className="text-center breakWord" title={model.terrace_m2}>{model.terrace_m2}</td> {/** terrace_m2 = m2 terraza */}
                                                        <td className="text-center breakWord" title={model.yard_m2}>{model.yard_m2}</td> {/** yard_m2 = m2 jardín */}
                                                        <td className="text-center breakWord" title={model.land_m2}>{model.land_m2}</td> {/** land_m2 = m2 terreno */}

                                                        <td className="text-center breakWord">{model.ut}</td>
                                                        <td className="text-center breakWord">{model.uv}</td>
                                                        <td className="text-center breakWord">{model.ui}</td>

                                                        <td className="text-center breakWord">{model.date1 ? model.date1 : '--'} </td>
                                                        <td className="text-center breakWord">{model.date2 ? model.date2 : '--'}</td>
                                                        <td className="text-center breakWord">{model.date3 ? model.date3 : '--'}</td>
                                                        <td className="text-center breakWord">{model.date4 ? model.date4 : '--'}</td>

                                                        <td className="text-center breakWord">{model.minimalPrice}</td>
                                                        <td className="text-center breakWord">{model.maximumPrice}</td>
                                                        <td className="text-center breakWord">{model.averagePrice}</td>
                                                        <td className="text-center breakWord">{model.pricePerAverageM2}</td>

                                                        <td className="text-center breakWord" title={model.price_increment}>{model.price_increment}</td> {/** price_increment = Incremento % precio */}
                                                        <td className="text-center breakWord" title={model.price_m2_min}>{model.price_m2_min}</td> {/** price_m2_min = Precio M2 mínimo */}
                                                        <td className="text-center breakWord" title={model.price_m2_max}>{model.price_m2_max}</td> {/** price_m2_max = Precio M2 máximo */}
                                                        <td className="text-center breakWord" title={model.m2_price_increment}>{model.m2_price_increment}</td> {/** m2_price_increment = Incremento % precio m2 */}

                                                        <td className="text-center"></td>

                                                        <td className="text-center breakWord" title={model.recamaras}>{model.recamaras}</td> {/** recamaras = Recámaras */}
                                                        <td className="text-center breakWord" title={model.estancias}>{model.estancias}</td> {/** estancias = Recámaras */}
                                                        <td className="text-center breakWord" title={model.banos_completos}>{model.banos_completos}</td> {/** banos_completos = Baños completos */}
                                                        <td className="text-center breakWord" title={model.medios_banos}>{model.medios_banos}</td> {/** medios_banos = Medios baños */}
                                                        <td className="text-center breakWord" title={model.cuarto_servicio}>{model.cuarto_servicio}</td> {/** cuarto_servicio = Cuarto de servicio  */}
                                                        <td className="text-center breakWord" title={model.bano_servicio}>{model.bano_servicio}</td> {/** bano_servicio = Baño de servicio */}
                                                        <td className="text-center breakWord" title={model.estacionamientos}>{model.estacionamientos}</td> {/** estacionamientos = Estacionamientos  */}
                                                        <td className="text-center breakWord" title={model.bodega}>{model.bodega}</td> {/** bodega = Bodega  */}

                                                        <td className="text-center"></td>
                                                        <td className="text-center"></td>
                                                    </tr>
                                                )
                                            }
                                        </>
                                    ))}
                                </>
                            ))}
                        </tbody>

                    </table>

                </div>
            </div>
        </div >
    )
}
export default TableLiftingReview;