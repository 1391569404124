import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { animateScroll as scroll } from 'react-scroll';
import _ from 'lodash';
import NumberFormat from 'react-number-format';
import { Tooltip } from 'primereact/tooltip';
//ACTIONS AND CONTEXT
import { RestActions } from "../../../actions";
import { useMainContext } from "../../../contexts/MainContext";
import { useCatalogsContext } from "../../../contexts/CatalogsContext";
//COMPONENTS
import Dropdown from "../../../components/atoms/Dropdown";
import InputFormCol from "../../../components/atoms/InputFormCol";
import InputNumeric from "../../../components/atoms/InputNumeric";
import InputRadioList from '../../../components/atoms/InputRadioList';
import InputCheckboxList from "../../../components/atoms/InputCheckboxList";
import ModalConfirmCloseModal from "../../../components/atoms/ModalConfirmCloseModal";
import InputDropZoneImage from '../../../components/atoms/InputDropZoneImage';

const MoedelsForm = ({ phaseID, modelID, showModal, handleShowModal, handleModelId, handleRefreshList }) => {
    const rest = new RestActions();
    let mainContext = useMainContext();
    let catalogs = useCatalogsContext();

    const [formData, setFormData] = useState({ status: true, longInteriorCorridor: false, customizableFacade: false, preparationExpandBedroom: false, preparationAdditionalLevel: false });
    const [isSubmited, setIsSubmited] = useState(false);
    const [modelName, setModelName] = useState("NOMBRE DEL MODELO");
    const [m2Totales, setM2Totales] = useState("");
    const [showConfirmClose, setShowConfirmClose] = useState(false);
    const [modelTypesOptions, setModelTypesOptions] = useState(false);
    const ref = useRef()



    useEffect(() => {
        if (showModal) {
            if (modelID)
                getModel();
            else
                initFeatures();
        }
    }, [showModal])

    useEffect(() => {
        calculateM2Totals();
        if (formData.name != undefined)
            setModelName(formData.name);

    }, [formData])

    const onFormDataChange = async (paramName, e) => {
        mainContext.setIsDirty(true);
        if (paramName === "commercialBusinessID" && e.value != null) {
            let options = _.filter(catalogs.modelTypes, function (item) {
                return item.commercialBusinessID === e.value.commercialBusinessID;
            });
            setModelTypesOptions(options)
            // await setFormData({ ...formData, modelTypeID: "", [paramName]: e.value });
            setFormData({ ...formData, modelTypeID: "", [paramName]: e.value });

        }
        else if (paramName === "commercialBusinessID" && e.value === null) {
            setModelTypesOptions([]);
            setFormData({ ...formData, modelTypeID: "", [paramName]: e.value });
        }
        else {
            setFormData({ ...formData, [paramName]: e.value });
        }
        //catalogs.modelTypes
    }

    const handleCheckboxRadioList = async (paramName, data) => {
        mainContext.setIsDirty(true);
        setFormData({ ...formData, [paramName]: data });
    }

    const onFeaturesChange = async (index, e) => {
        let newData = [...formData.houseDistributions];
        if (e.value)
            newData[index].value = parseInt(e.value);
        else
            newData[index].value = 0;

        mainContext.setIsDirty(true);
        setFormData({ ...formData, houseDistributions: newData });
    }

    //Actualizador de valores en el estado JSON del form
    const onFeaturesChangeLand = async (index, e) => {
        let newData = [...formData.landDistributions];
        if (e.value)
            newData[index].value = e.value;
        else
            newData[index].value = 0;

        mainContext.setIsDirty(true);
        setFormData({ ...formData, landDistributions: newData });
    }


    const getImageHandler = (image) => {
        mainContext.setIsDirty(true);
        setFormData({ ...formData, dataImage: image.body });
    }

    const getModel = async () => {
        mainContext.setIsLoading(true);
        try {
            let typeTower = await rest.post("getTypeTower", { phaseID: parseInt(phaseID) });

            catalogs.setUsoTorre(typeTower.usoTorre)
            let response = await rest.post("getModels", { phaseID: parseInt(phaseID), modelID: modelID });
            let modelTypeIndex = _.findIndex(catalogs.modelTypes, (row) => { return response[0].modelTypes?.modelTypeID == row.modelTypeID })
            let floorTypeIndex = _.findIndex(catalogs.floorTypes, (row) => { return response[0].floorTypeID == row.floorTypeID })
            let numberViewIndex = _.findIndex(catalogs.numberViews, (row) => { return response[0].numberViewID == row.numberViewID })
            let walkInRoomIndex = _.findIndex(catalogs.walkInRooms, (row) => { return response[0].walkInRoomID == row.walkInRoomID })
            let cupboardTypeIndex = _.findIndex(catalogs.cupboardTypes, (row) => { return response[0].cupboardTypeID == row.cupboardTypeID })
            let laundryRoomIndex = _.findIndex(catalogs.laundryRooms, (row) => { return response[0].laundryRoomID == row.laundryRoomID })
            let commercialBusinessIndex = _.findIndex(catalogs.commercialBusiness, (row) => { return response[0].modelTypes?.commercialBusinessID == row.commercialBusinessID })
            let options = _.filter(catalogs.modelTypes, function (item) {
                return item.commercialBusinessID == response[0].modelTypes?.commercialBusinessID;
            });


            setModelTypesOptions(options)

            response[0].modelTypeID = catalogs.modelTypes[modelTypeIndex];
            response[0].floorTypeID = catalogs.floorTypes[floorTypeIndex];
            response[0].numberViewID = catalogs.numberViews[numberViewIndex];
            response[0].walkInRoomID = catalogs.walkInRooms[walkInRoomIndex];
            response[0].cupboardTypeID = catalogs.cupboardTypes[cupboardTypeIndex];
            response[0].laundryRoomID = catalogs.laundryRooms[laundryRoomIndex];
            response[0].commercialBusinessID = catalogs.commercialBusiness[commercialBusinessIndex];
            response[0].totalLevels = response[0].totalLevels ? response[0].totalLevels : 1;
            initForm(response[0]);
            mainContext.setIsLoading(false);
        }
        catch (error) {/*TODO SOMETHING*/ }
    }

    const initForm = (response) => {

        setFormData(response);
    }

    //Iniciar valores para enviar en el post del form
    // (Sin esto no se muetsran los datos)
    const initFeatures = async () => {
        let initData = [];
        let initDataLand = [];
        let typeTower = await rest.post("getTypeTower", { phaseID: parseInt(phaseID) });
        catalogs.setUsoTorre(typeTower.usoTorre)

        catalogs.houseDistributions.forEach((element) => {
            initData.push({ houseDistributionID: element.houseDistributionID, value: null });
        });
        // setFormData({ ...formData, houseDistributions: initData, totalLevels: 1 });

        // //insertar JSON al estado del Form para ser despues ser enviado JACA
        catalogs.landDistributions.forEach((element) => {
            //Envia los identificadores vacios JACA
            initDataLand.push({ landDistributionID: element.landDistributionID, value: null });
        });
        // setFormData({ ...formData, landDistributions: initDataLand});
        setFormData({ ...formData, houseDistributions: initData, landDistributions: initDataLand, totalLevels: 1 });

    }

    const calculateM2Totals = async (name, e) => {
        let total = 0;
        if (formData.indoorM2) total += parseFloat(formData.indoorM2);
        if (formData.terraceM2) total += parseFloat(formData.terraceM2);
        if (formData.yardM2) total += parseFloat(formData.yardM2);

        total = ('' + total).replace(/(\d)(?=(?:\d{3})+(?:\.|$))|(\.\d\d?)\d*$/g,
            function (m, s1, s2) {
                return s2 || (s1 + ',');
            }
        );
        setM2Totales(total);
    }

    const isAbleToClose = async () => {
        if (mainContext.isDirty)
            setShowConfirmClose(true);
        else
            resetModal();
    }

    const resetModal = async () => {
        setFormData({ status: true, longInteriorCorridor: false, customizableFacade: false, preparationExpandBedroom: false, preparationAdditionalLevel: false });
        setModelName("Nombre del modelo");
        setShowConfirmClose(false);
        handleShowModal(false);
        handleModelId(null);
        setIsSubmited(false);
        mainContext.setIsLoading(false);
        mainContext.setIsDirty(false);
    }

    const validateForm = () => {
        setIsSubmited(true);
        let requiredData = ["name", "modelTypeID", "indoorM2", "commercialBusinessID", "totalLevels"];
        let isValid = true;
        requiredData.forEach((row) => {
            if (formData[row] == undefined || formData[row] == null || formData[row] == "")
                isValid = false;
        })
        if (formData.name?.trim() == "") {
            isValid = false;
        }

        return isValid;
    }

    //Enviar el formulario
    const sendForm = async () => {
        setIsSubmited(true);
        if (validateForm()) {
            mainContext.setIsLoading(true);
            let action = "createModel";
            let params = {
                ...formData,
                userID: mainContext.session.userID,
                phaseID: parseInt(phaseID),
            };
            let integers = ["yardM2", "terraceM2", "indoorM2", "totalLevels"];
            _.forEach(params, function (value, key) {
                if (value == null)
                    delete params[key];
                else if (integers.indexOf(key) != -1) {
                    params[key] = parseFloat(value);
                }
            });

            _.forEach(params.houseDistributions, function (value, key) {
                if (value.value == null)
                    params.houseDistributions[key].value = 0;
            });

            //https://lodash.com/docs/4.17.15#forEach JACA
            //Tiene la misma funcionalidad de un .map JACA
            //Limpiando la informacion despues de ser enviada JACA
            _.forEach(params.landDistributions, function (value, key) {
                if (value.value == null)
                    params.landDistributions[key].value = 0;
            });

            if (modelID) {
                params.modelID = modelID;
                action = "updateModel";
                if (params.dataImage)
                    params.isUpdateImage = true;
            }
            params.name = params.name.trim();
            if (params.modelTypeID) params.modelTypeID = params.modelTypeID.modelTypeID;
            if (params.commercialBusinessID) params.commercialBusinessID = params.commercialBusinessID.commercialBusinessID;
            if (params.walkInRoomID) params.walkInRoomID = params.walkInRoomID.walkInRoomID;
            if (params.numberViewID) params.numberViewID = params.numberViewID.numberViewID;
            if (params.laundryRoomID) params.laundryRoomID = params.laundryRoomID.laundryRoomID;
            if (params.floorTypeID) params.floorTypeID = params.floorTypeID.floorTypeID;
            if (params.cupboardTypeID) params.cupboardTypeID = params.cupboardTypeID.cupboardTypeID;

            try {
                await rest.post(action, params);
                mainContext.setMessageAlert("El registro se guardó correctamente");
                handleRefreshList();
                resetModal();
            }
            catch (error) {
                mainContext.setMessageAlert(error.toString());
                mainContext.setIsLoading(false);
            }
        } else {
            ref.current.scrollIntoView({ behavior: 'smooth' })
            mainContext.setMessageAlert("Es necesario llenar todos los campos obligatorios (*)");
        }
    }

    return (
        <Modal className="px-4 modal-model" size={"models"} show={showModal} onHide={isAbleToClose} backdrop="static">
            <ModalConfirmCloseModal show={showConfirmClose} handleShowClose={setShowConfirmClose} handleConfirmClose={resetModal} />
            <Modal.Header className="no-border " closeButton>
                <Modal.Title className="pl-3"></Modal.Title>
            </Modal.Header>
            <Modal.Body ref={ref} className="px-5">
                <div className="custom-card" >
                    <div className="custom-card-header">
                        <h4 className="py-3 px-3">{modelName}</h4>
                    </div>
                    <div className="custom-card-body px-3">
                        <div className="row">
                            <InputFormCol col={"col-3"} label="* Modelo" value={formData.name} isSubmited={isSubmited} trim={true}>
                                <input value={formData.name} onChange={(e) => { onFormDataChange("name", e.target) }} maxLength="50" className="form-control black-border large-input" type="text" />
                            </InputFormCol>
                            <InputFormCol col={"col-3"} label="* Giro o Uso" value={formData.commercialBusinessID} isSubmited={isSubmited}>
                                <Dropdown
                                    value={formData.commercialBusinessID}
                                    options={catalogs.usoTorre ? catalogs.usoTorre : catalogs.commercialBusiness}
                                    onChange={(e) => { onFormDataChange('commercialBusinessID', e); }}
                                    optionValue="commercialBusinessID"
                                    optionLabel="description"
                                />
                            </InputFormCol>

                            <InputFormCol col={"col-3"} label="* Tipo" value={formData.modelTypeID} isSubmited={isSubmited}>
                                <Dropdown
                                    value={formData.modelTypeID}
                                    options={modelTypesOptions}
                                    onChange={(e) => { onFormDataChange('modelTypeID', e); }}
                                    optionValue="modelTypeID"
                                    optionLabel="description"
                                    key={formData.modelTypeID}
                                />
                            </InputFormCol>
                            <InputFormCol col={"col-1"} label={<span>* Niveles</span>} value={formData.totalLevels} isSubmited={isSubmited}>
                                <Tooltip target=".levels" mouseTrack mouseTrackLeft={10} showDelay={100} />
                                <NumberFormat
                                    decimalScale={0}
                                    isAllowed={(value) => {
                                        if (value.floatValue <= 5 && value.formattedValue >= 1)
                                            return true
                                        else if (value.formattedValue === '')
                                            return true
                                        else if (value.formattedValue == 0)
                                            return false
                                        else
                                            return false;

                                    }}
                                    placeholder={"-----"}
                                    allowNegative={false}
                                    thousandSeparator={true}
                                    min={0}
                                    max={5}
                                    value={formData.totalLevels}
                                    className="form-control black-border levels "
                                    data-pr-tooltip="Rango válido del 1 - 5" data-pr-position="left"
                                    onChange={(e) => { onFormDataChange('totalLevels', e.target); }}
                                />
                            </InputFormCol>

                            <InputFormCol col={"col-2"} label="Estatus de modelo">
                                <Form.Check onClick={(e) => { onFormDataChange("status", { value: !formData.status }) }} defaultChecked={formData.status} type="switch" label="" id="status" className="mt-2" />
                            </InputFormCol>
                        </div>
                        <div className="row">
                            <InputFormCol label={<span>* M<sup>2</sup> Interiores</span>} value={formData.indoorM2} isSubmited={isSubmited}>
                                <InputNumeric defaultValue={formData.indoorM2} paramName="indoorM2" maxlength={12} decimal={2} handleValue={onFormDataChange} />
                            </InputFormCol>

                            <InputFormCol label={<span>M<sup>2</sup> Terraza</span>}>
                                <InputNumeric defaultValue={formData.terraceM2} paramName="terraceM2" maxlength={12} decimal={2} handleValue={onFormDataChange} />
                            </InputFormCol>

                            <InputFormCol label={<span>M<sup>2</sup> Jardín</span>}>
                                <InputNumeric defaultValue={formData.yardM2} paramName="yardM2" maxlength={12} decimal={2} handleValue={onFormDataChange} />
                            </InputFormCol>

                            <InputFormCol label={<span>M<sup>2</sup> Totales</span>}>
                                <input value={m2Totales} maxLength="50" type="text" disabled className="form-control black-border large-input disabled" />
                            </InputFormCol>
                        </div>
                    </div>
                </div>

                <div className="custom-card mt-4">
                    <div className="custom-card-header">
                        <h4 className="py-3 px-3">FUNCIONALIDADES</h4>
                    </div>
                    <div className="custom-card-body px-3">
                        <div className="row">
                            {
                                (catalogs && formData?.houseDistributions?.length > 0) &&
                                catalogs.houseDistributions.map((element, index) => {
                                    return (
                                        <InputFormCol key={element.houseDistributionID} label={element.description}>
                                            <InputNumeric defaultValue={formData.houseDistributions[index].value} paramName={index} maxlength={9} handleValue={onFeaturesChange} />
                                        </InputFormCol>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>

                {/* JACA */}
                <div className="custom-card mt-4">
                    <div className="custom-card-header">
                        <h4 className="py-3 px-3">TERRENO</h4>
                    </div>
                    <div className="custom-card-body px-3">
                        <div className="row">
                            {
                                (catalogs && formData?.landDistributions?.length > 0) &&
                                catalogs.landDistributions.map((element, index) => {
                                    return (
                                        <InputFormCol key={element.landDistributionID} label={element.description}>
                                            <InputNumeric defaultValue={formData.landDistributions[index].value} paramName={index} maxlength={9} decimal={2} handleValue={onFeaturesChangeLand} />
                                        </InputFormCol>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>

                <div className="custom-card mt-4">
                    <div className="custom-card-header">
                        <h4 className="py-3 px-3">FUNCIONALIDAD AMPLIADA</h4>
                    </div>
                    <div className="custom-card-body px-3">
                        <div className="row">
                            <InputFormCol label="Tipo de planta">
                                <Dropdown value={formData.floorTypeID} options={catalogs.floorTypes} onChange={(e) => { onFormDataChange('floorTypeID', e); }} optionValue="floorTypeID" optionLabel="description" />
                            </InputFormCol>

                            <InputFormCol label="Número de vistas">
                                <Dropdown value={formData.numberViewID} options={catalogs.numberViews} onChange={(e) => { onFormDataChange('numberViewID', e); }} optionValue="numberViewID" optionLabel="description" />
                            </InputFormCol>

                            <InputFormCol label="Recamaras con walk in closet">
                                <Dropdown value={formData.walkInRoomID} options={catalogs.walkInRooms} onChange={(e) => { onFormDataChange('walkInRoomID', e); }} optionValue="walkInRoomID" optionLabel="description" />
                            </InputFormCol>

                            <InputFormCol label="Pasillo interior largo">
                                <Form.Check onClick={(e) => { onFormDataChange("longInteriorCorridor", { value: !formData.longInteriorCorridor }) }} defaultChecked={formData.longInteriorCorridor} type="switch" label="" id="longInteriorCorridor" className="mt-2" />
                            </InputFormCol>
                        </div>
                        <div className="row">
                            <InputFormCol label="Espacio alacena">
                                <Dropdown value={formData.cupboardTypeID} options={catalogs.cupboardTypes} onChange={(e) => { onFormDataChange('cupboardTypeID', e); }} optionValue="cupboardTypeID" optionLabel="description" />
                                <label className="input-label form-label mt-4 pt-2">
                                    Tipo de cuarto de lavado
                                </label>
                                <Dropdown value={formData.laundryRoomID} options={catalogs.laundryRooms} onChange={(e) => { onFormDataChange('laundryRoomID', e); }} optionValue="laundryRoomID" optionLabel="description" />
                            </InputFormCol>

                            <InputFormCol label="Servicios">
                                <InputCheckboxList handleData={handleCheckboxRadioList} catalog={catalogs.modelServices} currentData={formData.modelServices} idProperty="modelServiceID" paramName="modelServices" displayName="description" cssClass={"checkbox-container"} />
                            </InputFormCol>

                            <InputFormCol label="Especiales">
                                <InputCheckboxList handleData={handleCheckboxRadioList} catalog={catalogs.modelSpecials} currentData={formData.modelSpecials} idProperty="modelSpecialID" paramName="modelSpecials" displayName="description" cssClass={"checkbox-container"} />
                            </InputFormCol>

                            <InputFormCol label="Espacios con ventilación natural">
                                <InputCheckboxList handleData={handleCheckboxRadioList} catalog={catalogs.naturalVentilationSpaces} currentData={formData.naturalVentilationSpaces} idProperty="naturalVentilationSpaceID" paramName="naturalVentilationSpaces" displayName="description" cssClass={"checkbox-container"} />
                            </InputFormCol>
                        </div>

                        <div className="row">
                            <InputFormCol label="Fachada personalizable">
                                <Form.Check onClick={(e) => { onFormDataChange("customizableFacade", { value: !formData.customizableFacade }) }} checked={formData.customizableFacade} type="switch" label="" id="customizableFacade" className="mt-2" />
                            </InputFormCol>

                            <InputFormCol label="Preparación p. ampliar recámara(s)">
                                <Form.Check onClick={(e) => { onFormDataChange("preparationExpandBedroom", { value: !formData.preparationExpandBedroom }) }} checked={formData.preparationExpandBedroom} type="switch" label="" id="preparationExpandBedroom" className="mt-2" />
                            </InputFormCol>

                            <InputFormCol label="Preparación p. nivel adicional">
                                <Form.Check onClick={(e) => { onFormDataChange("preparationAdditionalLevel", { value: !formData.preparationAdditionalLevel }) }} checked={formData.preparationAdditionalLevel} type="switch" label="" id="preparationAdditionalLevel" className="mt-2" />
                            </InputFormCol>

                            <InputFormCol label="Espacios con doble altura">
                                <InputCheckboxList handleData={handleCheckboxRadioList} catalog={catalogs.doubleHeightSpaces} currentData={formData.doubleHeightSpaces} idProperty="doubleHeightSpacesID" paramName="doubleHeightSpaces" displayName="description" cssClass={"checkbox-container"} />
                            </InputFormCol>
                        </div>

                        <div className="row mt-4">
                            <div className="col">
                                <h4 title="Seleccionar una opción por columna">
                                    Distribución de la cocina
                                </h4>
                            </div>
                        </div>
                        <div className="row">
                            <InputRadioList handleData={handleCheckboxRadioList} catalog={catalogs.kitchenDistributions.filter((row) => { return row.order == 1 })} index={0} idProperty="kitchenDistributionID" paramName="kitchenDistributions" currentData={formData.kitchenDistributions} selectedValues={null} displayName="description" col="col" />
                            <InputRadioList handleData={handleCheckboxRadioList} catalog={catalogs.kitchenDistributions.filter((row) => { return row.order == 2 })} index={1} idProperty="kitchenDistributionID" paramName="kitchenDistributions" currentData={formData.kitchenDistributions} selectedValues={null} displayName="description" col="col" />
                            <InputRadioList handleData={handleCheckboxRadioList} catalog={catalogs.kitchenDistributions.filter((row) => { return row.order == 3 })} index={2} idProperty="kitchenDistributionID" paramName="kitchenDistributions" currentData={formData.kitchenDistributions} selectedValues={null} displayName="description" col="col" />
                            <InputRadioList handleData={handleCheckboxRadioList} catalog={catalogs.kitchenDistributions.filter((row) => { return row.order == 4 })} index={3} idProperty="kitchenDistributionID" paramName="kitchenDistributions" currentData={formData.kitchenDistributions} selectedValues={null} displayName="description" col="col" />
                            <InputRadioList handleData={handleCheckboxRadioList} catalog={catalogs.kitchenDistributions.filter((row) => { return row.order == 5 })} index={4} idProperty="kitchenDistributionID" paramName="kitchenDistributions" currentData={formData.kitchenDistributions} selectedValues={null} displayName="description" col="col" />
                        </div>
                    </div>
                </div>

                <div className="custom-card mt-4">
                    <div className="custom-card-header">
                        <h4 className="py-3 px-3">ADJUNTAR IMAGEN DE PLANO</h4>
                    </div>
                    <div className="custom-card-body px-3">
                        <div className="col-12 models-dropzone-container">
                            <InputDropZoneImage image={formData.uri} propertyName={'front'} getImageHandler={getImageHandler} />
                            <p>Formatos admitidos: JPG, PNG, JPEG...</p>
                        </div>
                    </div>
                </div>

                <div className="custom-card mt-4">
                    <div className="custom-card-header">
                        <h4 className="py-3 px-3">NOTAS / OBSERVACIONES</h4>
                    </div>
                    <div className="custom-card-body px-3">
                        <InputFormCol label="" col="col-12" withMessage={false}>
                            <textarea value={formData.observations} onChange={(e) => { onFormDataChange("observations", e.target) }} maxLength="1000" className="form-control"></textarea>
                            <span>{(formData.observations) ? formData.observations.length : "0"}/1000</span>
                        </InputFormCol>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-2 offset-10 d-flex justify-content-end">
                        {
                            (mainContext.isDirty) ?
                                <button onClick={sendForm} className="btn btn-primary w-100">Guardar</button>
                                :
                                <button className="btn btn-primary w-100 disabled">Guardar</button>
                        }
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
export default MoedelsForm