import React, { useState, useEffect } from 'react'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Dropdown from "../../../components/atoms/Dropdown";
import InputCheckboxList from '../../../components/atoms2/InputCheckboxList';
import { useMainContext } from "../../../contexts/MainContext";
import { useCatalogsContext } from "../../../contexts/CatalogsContext";

const Delivery = ({ edit, handlerFormData, phaseData, handlerImageData, isSubmited, initData }) => {



	/* ==========================================================================
	** Component Setup
	** ========================================================================== */
	// --------------------------------------
	// Component State and Contexts
	// --------------------------------------
	const mainContext = useMainContext();
	const catalogs = useCatalogsContext();
	const [formData, setFormData] = useState(null);
	const [finishedTypeName, setFinishedTypeName] = useState(null);
	// --------------------------------------
	// Component Load, set Values when the
	// init data is available
	// --------------------------------------
	useEffect(() => {

		if (phaseData.phaseID) {

			initForm();
		} else {
			const data = {
				finishedTypes: '',
				finishedTypeID: null,
				finishedFeatures: null,
				finishedTypesFeature: null,
				finishedDeliveryType: null

			}
			setFormData({ ...formData, delivery: data });
		}
		mainContext.setIsLoading(false);
	}, [initData]);


	// --------------------------------------
	// Update the Form Data
	// --------------------------------------
	useEffect(() => {
		handlerFormData(formData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formData])




	// --------------------------------------
	// Set Initial Values
	// --------------------------------------
	const initForm = async () => {

		// ? Get values

		const { finishedTypeID, finishedFeatures, finishedDeliveryTypeID } = phaseData;

		let formattedData = {

			finishedTypeID: initCombo('finishedTypes', 'finishedTypeID', finishedTypeID),
			finishedFeatures: finishedFeatures,
			finishedTypesFeature: '',
			finishedDeliveryType: catalogs.finishedDeliveryTypes.find(element => element.finishedDeliveryTypeID == finishedDeliveryTypeID)


		}


		setFormData({ ...formData, delivery: formattedData });
		if (formattedData.finishedTypeID)
			setFinishedTypeName(formattedData.finishedTypeID.description)
		//handlerFormData(formData);
	}


	// --------------------------------------
	// Get initial Select Value
	// --------------------------------------
	const initCombo = (catalog, filterBy1, filterBy2) => {

		if (catalogs[catalog])
			return catalogs[catalog].filter((element) => { return element[filterBy1] === filterBy2 })[0];
		else
			return null;


	}





	/* ==========================================================================
	** Event Listeners
	** ========================================================================== */

	// --------------------------------------
	// CheckBox toggle Event
	// --------------------------------------
	const handleCheckboxList = async (paramName, data) => {

		// const {finishedFeatures} = catalogs;



		mainContext.setIsDirty(true);
		let newData = { ...formData.delivery };
		newData[paramName] = data;
		const finishedDeliveryTypeObj = calculateFeaturesSumValues(data);

		newData.finishedDeliveryType = finishedDeliveryTypeObj[0];
		newData.finishedTypeID = finishedDeliveryTypeObj[1];



		setFormData({ ...formData, delivery: newData });

	}


	const disbaleCheckboxes = (formData?.delivery?.finishedFeatures?.length >= 0 && formData.delivery.finishedTypeID !== null) ? false : true
	let finishedDeliveryTypeName = formData?.delivery?.finishedDeliveryType?.description || null;

	// --------------------------------------
	// Text input and Select Change Event
	// --------------------------------------
	const onFormDataChange = async (name, e) => {
		let newData = { ...formData.delivery };
		finishedDeliveryTypeName = ""
		if (name === 'finishedTypeID') {
			newData.finishedFeatures = setFeaturedItemsBasedOnFinishedType(e.value.finishedTypeID);
			newData.finishedDeliveryType = ""
			setFinishedTypeName(e.value.description)

		}
		if (name === 'finishedDeliveryType')
			newData[name] = e;
		else
			newData[name] = e.value;

		mainContext.setIsDirty(true);
		await setFormData({ ...formData, delivery: newData });
	}


	// --------------------------------------
	// Determine which Tipos de Entrega match 
	// with Acabado Base
	// --------------------------------------
	const setFeaturedItemsBasedOnFinishedType = (filterValue = null) => {

		const { finishedFeatures, finishedTypesFeatures } = catalogs;

		const featuresMatched = finishedTypesFeatures.filter((feat) => {
			return feat.finishedTypeID === filterValue
		}).map((match) => match.finishedFeatureID)


		const activeItems = finishedFeatures.filter((finishedFeatureItem) => featuresMatched.includes(finishedFeatureItem.finishedFeatureID))

		return activeItems;
	}

	// --------------------------------------
	// Filter Types
	// --------------------------------------

	const getFinishedDeliveryTypeID = (typeName, searchData) => {
		// const {finishedDeliveryTypes, finishedTypes} = catalogs;
		setFinishedTypeName(typeName)
		const selectedType = catalogs[searchData].find(fin => fin.description.toLowerCase() === typeName.toLowerCase())
		return selectedType;
	}



	// --------------------------------------
	// Count the number of active features 
	// and set values for ACABADO BASE:
	// and TIPO DE ENTREGA:
	// TODO : MAKE THIS CLEANER
	// --------------------------------------
	const calculateFeaturesSumValues = (featuresData) => {

		if (!formData || !formData.delivery)
			return;


		const activeFeatures = featuresData.length;

		let finishedDeliveryType = null;
		let finishedType = null;

		// ? Not using switch cause is significantly slower when using ranges, 
		// ? even though would be more easy to read

		catalogs.finishedDeliveryTypes.map(el => {
			if ((activeFeatures === el.min || activeFeatures === el.max))
				finishedDeliveryType = getFinishedDeliveryTypeID(el.description, 'finishedDeliveryTypes');
		})

		catalogs.finishedTypes.map(el => {
			if ((activeFeatures >= el.min && activeFeatures <= el.max))
				finishedType = getFinishedDeliveryTypeID(el.description, 'finishedTypes');
		})



		return [finishedDeliveryType, finishedType]

		// onFormDataChange('finishedDeliveryType', finishedDeliveryType)

	}




	/* ==========================================================================
	** Component Render
	** ========================================================================== */


	if (!formData && formData === null)
		return null;




	return (
		<form autoComplete="off" className="custom-card">
			<div className="custom-card-header  d-flex justify-content-between align-items-center">
				<h4 className="px-3 m-0">ENTREGA</h4>
				<div className="col-4 d-flex  align-items-center">
					<h4 className="text-nowrap m-0 mr-2">ACABADO BASE:</h4>
					<div className="w-100">
						<Dropdown
							optionLabel="description"
							optionValue="finishedTypeID"
							className="w-100"
							options={catalogs.finishedTypes}
							value={formData.delivery.finishedTypeID}
							onChange={(e) => { onFormDataChange('finishedTypeID', e); }}
							isClearable={false}
						/>
					</div>
				</div>
			</div>
			<div className="custom-card-body px-3">
				<Row>
					<Col md={12}>
						<label className="input-label form-label mb-4">
							TIPO DE ENTREGA: {finishedDeliveryTypeName ? finishedDeliveryTypeName : finishedTypeName}
						</label>
					</Col>
				</Row>

				<Row>
					<div className="col">
						<div className="form-group with-message">
							<InputCheckboxList
								handleData={handleCheckboxList}
								catalog={catalogs.finishedFeatures}
								currentData={formData.delivery.finishedFeatures}
								idProperty="finishedFeatureID"
								paramName="finishedFeatures"
								displayName="description"
								idx="finishedFeatureID"
								cssClass="features-checkList row"
								columns={4}
								renderColumns={true}
								disableCheckBoxes={disbaleCheckboxes}
							/>
						</div>
					</div>
				</Row>
			</div>
		</form>
	)
}


export default Delivery
