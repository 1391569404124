//styles
import './styles.css'

//Actions
import { AmenitiesAndServicesActions, CatalogsActions } from "../../../actions"
import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from "react-router-dom";

import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import AddToCatalogForm from "../../molecules/Forms/AddToCatalog"
import ButtonModalOneInputForm from "../../atoms/ButtonModalOneInputForm";
//Molecules
import AmenitiesTable from "../../molecules/AmenitiesAndServicesSection/AmenitiesTable";
import Col from "react-bootstrap/Col";
//Atoms
import ModalBase from "../../atoms2/ModalBase";
import Dropdown from "../../atoms/Dropdown";
import Row from "react-bootstrap/Row"
import ServicesSection from "../../molecules/AmenitiesAndServicesSection/ServicesSection";
//Utils
import { isEmpty } from "../../../utils/utils";
import { useAmenitiesAndServicesContext } from '../../../contexts/AmenitiesAndServicesContext'
//Contexts
import { useGeneralInfoContext } from "../../../contexts/GeneralInfoContext"
import { useMainContext } from "../../../contexts/MainContext"
import { RestActions } from "../../../actions";

//COMPONENTS
import MenuPhases from '../../molecules/MenuPhases';
const TEST_PHASE_ID = 11

const AmenititiesAndServices = (props) => {
  const { ws, data, preloadReady } = props
  const rest = new RestActions();
  let mainContext = useMainContext();
  //let { phaseID } = useParams();
  const location = useLocation();
  if (window.sessionStorage.getItem("phaseID") == "" || window.sessionStorage.getItem("phaseID") == undefined)
    window.sessionStorage.setItem("phaseID", location.state.phaseID);
  const phaseID = window.sessionStorage.getItem("phaseID");

  const [phaseData, setPhaseData] = useState({});

  let generalInfoContext = useGeneralInfoContext()
  let AmenitiesAndServicesContext = useAmenitiesAndServicesContext()

  const catalogActions = new CatalogsActions();
  const servicesActions = new AmenitiesAndServicesActions();

  const [catalogs, setCatalogs] = useState({})
  const [showModal, setShowModal] = useState(false);
  const [amenitySubmmited, setAmenitySubmmited] = useState(false);
  const [showAmenityModal, setShowAmenityModal] = useState(false);
  const [amenitySelected, setAmenitySelected] = useState(null);
  const [updateServices, setUpdateServices] = useState(false);
  const [addNewAmenity, setAddNewAmenity] = useState()

  useEffect(() => {
    if (preloadReady) {
      getCatalogs();
      initPhase();
    }
  }, [preloadReady]);

  const initPhase = async () => {
    mainContext.setIsLoading(true);
    mainContext.setIsDirty(false);
    try {
      let response = await rest.post("getPhases", { phaseID: parseInt(phaseID) });
      setPhaseData(response[0]);
      mainContext.setIsLoading(false);
    }
    catch (error) {/*TODO SOMETHING*/ }
  }



  const getCatalogs = async () => {
    const catalog = {}
    catalog.amenityLocation = await catalogActions.getCatalog("getAmenityLocations")
    catalog.amenityLocationTypes = await catalogActions.getCatalog("getPhaseAmenityLocationTypes", { phaseID: parseInt(phaseID) })
    catalog.amenityTypes = await catalogActions.getCatalog("getAmenityTypes");
    let types = {}
    catalog.amenityTypes.forEach(type => {
      types[type.id] = false;
    });
    setAddNewAmenity(types);
    catalog.amenities = await catalogActions.getCatalog("getAmenities");

    let catalogModal = [];
    catalog.amenities.forEach(amenity => {
      let amenityType = catalog.amenityTypes.filter((type) => { return amenity.amenity_type_id == type.id });
      amenity.description += " (" + amenityType[0].description + ")";
    });
    catalog.catalogModal = catalogModal;

    setCatalogs(catalog);
    let services = await servicesActions.getPhaseServices({ phaseID: parseInt(phaseID) });
    let newServices = [];
    services.forEach(element => {
      newServices.push(element.serviceID);
    });
    await AmenitiesAndServicesContext.setServices(newServices);
  }

  const handleAddService = async (service) => {

    await setUpdateServices(!updateServices)
  }

  const handleSaveInfo = async () => {
    mainContext.setIsLoading(true);
    try {
      let _data = {
        phaseID: parseInt(phaseID),
        servicesID: AmenitiesAndServicesContext.services,
        userID: 1
      };
      await servicesActions.savePhaseServices(_data);
      mainContext.setIsLoading(false);
      mainContext.setIsDirty(false);
    } catch (error) {
      throw error;
    }
  }

  const addAmenity = async () => {
    setAmenitySubmmited(true);
    if (amenitySelected) {
      setAddNewAmenity({ ...amenitySelected, [amenitySelected.amenity_type_id]: amenitySelected.id });
      setShowAmenityModal(false);
      setTimeout(() => {
        scroller.scrollTo('goto' + amenitySelected.amenity_type_id, {
          duration: 200,
          smooth: true
        })
      }, 100)
    }
  }

  const openAddAmenityModal = async () => {
    if (phaseData.levels < 1) {
      mainContext.setMessageAlert("No es posible crear amenidades, por que el campo niveles de proyecto no ha sido capturado");
      return;
    }
    let amenities = await catalogActions.getCatalog("getAmenities");

    let catalogModal = [];
    amenities.forEach(amenity => {
      let amenityType = catalogs.amenityTypes.filter((type) => { return amenity.amenity_type_id == type.id });
      amenity.description += " (" + amenityType[0].description + ")";
    });
    setCatalogs({ ...catalogs, amenities: amenities, catalogModal: catalogModal })
    setAmenitySubmmited(false);
    setAmenitySelected(null);
    setShowAmenityModal(true);
  }

  const resetAddNewAmenity = async (id) => {
    setAddNewAmenity({ ...amenitySelected, [id]: null });
  }

  const requestNavigation = () => {
    scroll.scrollTo(0);
    let goTo = "/#phase/especificaciones";
    mainContext.setGoTo(goTo);
  }
  return (
    (preloadReady) &&
    <>
      <MenuPhases phaseID={phaseID} phaseData={phaseData} linkActive={"amenidades"} />
      <ModalBase showModal={showAmenityModal} setShow={setShowAmenityModal} size={"addCatalog"} >
        <div className="custom-card pb-3">
          <div className="custom-card-header">
            <h4 className="p-3">AGREGAR TIPO DE AMENIDAD</h4>
          </div>
          <div className="custom-card-body px-3">
            <label><b>Ingresa el nombre de la amenidad</b></label>
            <Dropdown value={amenitySelected} options={catalogs.amenities} onChange={(e) => { setAmenitySelected(e.value) }} optionLabel="description" optionValue="id" />
            {
              (!amenitySelected && amenitySubmmited) &&
              <span className="error color-red">*Campo requerido</span>
            }
          </div>
        </div>
        <div class="mt-5 mb-3 row">
          <div class="text-right col-md-3 offset-md-9">
            <button onClick={addAmenity} class="btn btn-primary btn-block">Agregar</button>
          </div>
        </div>

      </ModalBase>

      <ModalBase showModal={showModal} setShow={setShowModal} title={"Agregar servicio"} size={"addCatalog"} >
        <AddToCatalogForm catalogName={"servicio"} handleSubmit={handleAddService} />
      </ModalBase>
      <div className="row">
        <div className="col d-flex justify-content-end">
          <button onClick={openAddAmenityModal} type="button" className="mx-1 btn btn-primary mb-3">Agregar amenidad +</button>
        </div>
      </div>

      {catalogs.amenityTypes ? catalogs.amenityTypes.map(({ description, id }) => {
        return (
          <AmenitiesTable addHere={addNewAmenity[id]} handleAddHere={resetAddNewAmenity} phaseID={parseInt(phaseID)} ws={ws} catalogs={catalogs} title={description.toUpperCase()} amenityType={id} />
        )
      }) : null}

      <section className="custom-card">
        <div className="custom-card-header px-3 d-flex">
          <h4 className="py-3 w-50">SERVICIOS</h4>
          <div className="w-50 py-2 d-flex justify-content-end">
            {/*<ButtonModalOneInputForm buttonType="btnPrimary" title="Agregar servicio" btnText="Agregar servicio +" property={"description"} action={"saveServices"} handleOnSaved={handleAddService} />*/}
          </div>
        </div>

        <div className="custom-card-body px-3">
          <ServicesSection phaseID={parseInt(phaseID)} update={updateServices} />
        </div>
      </section>
      <div className="row d-flex justify-content-end mt-5">
        <div className="col-10 d-flex justify-content-end">
          {
            (mainContext.isDirty) ?
              <button onClick={() => { handleSaveInfo() }} className="btn btn-white w-25 mr-4">Guardar</button>
              :
              <button className="btn btn-white w-25 mr-4 disabled">Guardar</button>
          }
          <button onClick={requestNavigation} className="btn btn-primary w-25">Continuar</button>

        </div>
      </div>
    </>
  )
}
export default AmenititiesAndServices