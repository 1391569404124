import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
/**
    * Componente <ModalConfirmCloseModal /> Abre un modal de confirmación
    *  en caso de que existan datos sin guardar en un formulario.
    * @component
    * @param  props
    * @param {bool} props.show - Muestra/oculta el modal
    * @param {function}  props.handleShowClose - Funcion que mostrar/ocultar modal.
    * @param {function} props.handleConfirmClose - Funcion que maneja la confirmacion.
*/
const ModalConfirmCloseModal = ({show, handleShowClose, handleConfirmClose}) => 
{
    const onConfirm = () => 
    {
        handleConfirmClose();
    }

    const onCancel = () => 
    {
        handleShowClose(false)
    }
    
    return (
            <Modal size={"logout"} show={show} backdrop="static" centered>
                <Modal.Body>
                    <div className="row my-5 py-2">
                        <div className="col col-md-4 offset-md-4">
                            <div className="border-image text-center col-md-8 offset-md-2">
                                <img src={'./assets/icons/icon-logout.svg'} className={"icon-logout my-3"} />
                            </div>
                        </div> 
                    </div>
                    <div className="row text-center mb-5">
                        <div className="col">
                            <p className="title-modal">¿Seguro que deseas salir de la pantalla?</p>
                            <p className="content-modal px-5">Al continuar, se perderá la información no guardada.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 offset-md-2">
                            <button className="btn btn-outline-link" onClick={() => { onCancel() }} >Cancelar</button>
                        </div>
                        <div className="col-md-4 offset-md-1">
                            <button className="btn btn-primary btn-block" onClick={() => { onConfirm() }}>Aceptar</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
    )
}
export default ModalConfirmCloseModal;