import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Form from 'react-bootstrap/Form'
//ACTION && CONTEXT
import { useMainContext } from "../../../contexts/MainContext";
import { RestActions } from "../../../actions";
//COMPONENTS
import InputNumeric from "../../../components/atoms/InputNumeric";
import Dropdown from "../../../components/atoms/Dropdown";
//ASSETS
import iconEdit from '../../../assets/icons/icon-edit.svg';
import iconSave from '../../../assets/icons/icon-save.svg';

const SalesSchemas = ({ phaseID }) => {
    let mainContext = useMainContext();
    const rest = new RestActions();

    const viewOptions = [{ id: null, description: "Ver Todos" }, { id: true, description: "Ver Activos" }];

    const [viewType, setViewType] = useState();
    const [schemasList, setSchemasList] = useState([]);
    const [schemaTypeList, setSchemaTypeList] = useState(null);
    const [editIndex, setEditIndex] = useState(null);
    const [isAdding, setIsAdding] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [formData, setFormData] = useState(null);

    useEffect(() => {
        getSchemas();
    }, [viewType])

    const getSchemas = async () => {
        try {
            let params = { phaseID: phaseID };
            if (viewType)
                params.status = viewType;

            let response = await rest.post("getSchemas", params);
            setSchemasList(response);
            if (!schemaTypeList) {
                let responseTypes = await rest.get("getSchemaTypes");
                setSchemaTypeList(JSON.parse(responseTypes));
            }
            mainContext.setIsLoading(false);
        }
        catch (error) {/*TODO SOMETHING*/ }
    }

    const addSchema = async () => {
        let schemasArr = [...schemasList];
        setIsSubmitted(false);
        if (!isAdding) {
            schemasArr.push({ phaseID: phaseID, schemaTypes: { schemaTypeID: schemaTypeList[0].id }, status: true });
            setEditIndex(schemasArr.length - 1);
            setFormData(schemasArr[schemasArr.length - 1]);
        }
        else {
            schemasArr.pop();
            setEditIndex(null);
            setFormData({});
        }
        setSchemasList(schemasArr);
        setIsAdding(!isAdding);
    }

    const toggleEdition = async (index) => {
        if (isAdding)
            addSchema();
        let data = { ...schemasList[index] }
        let idx = _.findIndex(schemaTypeList, (element) => { return element.id == data.schemaTypes.schemaTypeID })
        data.schemaTypeID = schemaTypeList[idx];
        setIsSubmitted(false);
        setFormData(data);
        setEditIndex(index);
    }

    const onFormDataChange = async (paramName, e) => {
        let data = { ...formData }
        data[paramName] = e.value;
        data.deed = calculateDeed(data);
        if (parseFloat(data.partialPayment) == 0)
            data.paymetTerms = 0;
        validateForm(data);

        setFormData(data);
    }

    const calculateDeed = (schema) => {
        if (schema.initialPayment && schema.partialPayment)
            return 100.00 - parseFloat(schema.initialPayment) - parseFloat(schema.partialPayment);
        else
            return 0;
    }

    const changeFilter = (value) => {
        setViewType(value);
    }

    const validateForm = (data) => {
        let required = ["schemaTypeID", "initialPayment", "partialPayment", "paymetTerms", "discount"];
        let _isValid = true;
        _.forEach(required, (row) => {
            if (data[row] === undefined || data[row] === "") {
                _isValid = false;
            }
        })
        setIsValid(_isValid);
        return _isValid;
    }

    const saveSchema = async () => {
        let action = "createSchema";
        let params = { ...formData, userID: mainContext.session.userID };
        setIsSubmitted(true);

        if (validateForm(params)) {
            if (params.schemaID) {
                action = "updateSchema";
            }
            params.schemaTypeID = params.schemaTypeID.id;
            delete params.schemaTypes;
            try {
                mainContext.setIsLoading(true);
                await rest.post(action, params);
                setEditIndex(null);
                setIsAdding(false);
                getSchemas();
                setIsSubmitted(false);
            }
            catch (error) {/*TODO SOMETHING*/ }
        }
    }

    return (
        <>
            <div className="custom-card">
                <div className="custom-card-header px-3 d-flex">
                    <h4 className="py-3 w-50">ESQUEMAS</h4>
                    <div className="w-50 py-2 d-flex justify-content-end">
                        <Dropdown value={viewType} options={viewOptions} onChange={(e) => { changeFilter(e.value); }} optionValue="id" optionLabel="description" placeholder="Seleccionar" isClearable={false} width="150px" />
                        <button onClick={() => { addSchema() }} className="btn btn-primary w-75 ml-3"><span className="d-inline-block pr-4">Agregar esquema</span> +</button>
                    </div>
                </div>
                <div className="custom-card-body px-3">
                    <div className={`table-row-editable ${(editIndex != null || isAdding) && "expanded"}`}>
                        <table className={`text-center table table-striped table-hover`}>
                            <thead className={"th-font"}>
                                <tr>
                                    <th style={{ borderTop: 'none' }}></th>
                                    <th style={{ borderTop: 'none' }}>#Esquemas</th>
                                    <th style={{ borderTop: 'none' }}>Tipos de esquema</th>
                                    <th style={{ borderTop: 'none' }}>% Enganche</th>
                                    <th style={{ borderTop: 'none' }}>% Pagos parciales</th>
                                    <th style={{ borderTop: 'none' }} title="Cálculo basado con la fecha de creación del esquema"># Pagos</th>
                                    <th style={{ borderTop: 'none' }}># Pagos restantes</th>
                                    <th style={{ borderTop: 'none' }}>% Escrituras</th>
                                    <th style={{ borderTop: 'none' }}>% Descuento</th>
                                    <th style={{ borderTop: 'none' }}>Fecha de creación</th>
                                    <th style={{ borderTop: 'none' }}>Estatus</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    schemasList.map((schema, index) =>
                                    (
                                        (editIndex == index) ?
                                            (
                                                <tr key={`tr-${index}`}>
                                                    <td>
                                                        <img onClick={() => { saveSchema() }} src={iconSave} width="25" className={"icon-image pointer mt-1"} />
                                                    </td>
                                                    <td>{formData.schemaID}</td>
                                                    <td>
                                                        <Dropdown value={formData.schemaTypeID} options={schemaTypeList} onChange={(e) => { onFormDataChange("schemaTypeID", e) }} optionValue="id" optionLabel="description" placeholder="Seleccionar" width="180px" customClass="input-table-td" />
                                                    </td>
                                                    <td>
                                                        <div className="form-group">
                                                            <div className="input-group">
                                                                <InputNumeric defaultValue={formData.initialPayment} paramName="initialPayment" maxlength={2} decimal={2} handleValue={onFormDataChange} />
                                                                <div className="input-group-append"><span className="input-group-text">%</span></div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-group">
                                                            <div className="input-group">
                                                                <InputNumeric defaultValue={formData.partialPayment} paramName="partialPayment" maxlength={2} decimal={2} handleValue={onFormDataChange} />
                                                                <div className="input-group-append"><span className="input-group-text">%</span></div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-group">
                                                            <div className="input-group">
                                                                <div className="input-group-append"><span className="input-group-text">#</span></div>
                                                                <InputNumeric defaultValue={formData.paymetTerms} paramName="paymetTerms" maxlength={2} decimal={0} handleValue={onFormDataChange} />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-group">
                                                            <div className="input-group">
                                                                <input value={formData.paymentTermsRemaining} disabled className="form-control" />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-group">
                                                            <div className="input-group">
                                                                <input value={calculateDeed(formData)} disabled className="form-control" />
                                                                <div className="input-group-append"><span className="input-group-text">%</span></div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-group">
                                                            <div className="input-group">
                                                                <InputNumeric defaultValue={formData.discount} paramName="discount" maxlength={2} decimal={2} handleValue={onFormDataChange} />
                                                                <div className="input-group-append"><span className="input-group-text">%</span></div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="form-group">
                                                            <div className="input-group">
                                                                <input value={formData.createdAt} disabled className="form-control" />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <Form.Check checked={formData.status} onChange={() => { onFormDataChange("status", { value: !schema.status }) }} type="switch" id={`switch-${index}`} label="" className="mt-2" />
                                                    </td>
                                                </tr>
                                            )
                                            : (
                                                <tr key={`tr-${index}`}>
                                                    <td>
                                                        <img onClick={() => { toggleEdition(index) }} src={iconEdit} width="25" className={"pointer"} />
                                                    </td>
                                                    <td>{schema.schemaID}</td>
                                                    <td>{schema.schemaTypes.description}</td>
                                                    <td>{schema.initialPayment}%</td>
                                                    <td>{schema.partialPayment}%</td>
                                                    <td title="Cálculo basado con la fecha de creación del esquema">{schema.paymetTerms}</td>
                                                    <td>{schema.paymentTermsRemaining}</td>
                                                    <td>{calculateDeed(schema)}%</td>
                                                    <td>{schema.discount}%</td>
                                                    <td>{schema.createdAt}</td>
                                                    <td>
                                                        <Form.Check type="switch" readOnly={true} id={`switch-${index}`} label="" checked={schema.status} />
                                                    </td>
                                                </tr>
                                            )
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            {
                                (!isValid && isSubmitted) &&
                                <div className="error large auth__alert-error">
                                    Favor de llenar todos los campos
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SalesSchemas