import React, { useState, useEffect, useRef } from 'react';
//ACTIONS AND CONTEXT
import { useMainContext } from "../../../contexts/MainContext";

/**
    * Renders a <ButtonSettings /> Button home with setting options
    * @component
*/
const ButtonSettings = () => 
{
    let mainContext = useMainContext();
    const node = useRef();
    
    const [showMenu, setShowMenu] = useState(false);
    
    useEffect(() => 
    {
        document.addEventListener("mousedown", handleClick);
        return () => 
        {
          document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    /**
        * Toggle menu
        * @param {object} event - Input event with the value property
    */
    const handleClick = e => 
    {
        if (node.current.contains(e.target))
          return;
        setShowMenu(false);
    };

    return (
        <div ref={node} className="ml-2 d-inline-block position-relative menu-settings">
            <button className="btn btn-primary" onClick={() => setShowMenu(!showMenu)}>
                <i className="pi pi-cog"></i>
            </button>
            {
                (showMenu) &&
                <div className="settings-drop-down position-absolute">
                    <button onClick={()=>{mainContext.setShowCloseSession(true)}} className="btn btn-primary w-100 text-left">Cerrar sesión</button>
                </div>
            }
        </div>
    )
}
export default ButtonSettings;