

const axios = require('axios');
const url = process.env.REACT_APP_SERVER_URL + "/api/";

class AmenitiesAndServicesActions {
  constructor() { }
  async saveAmenityLocation(action, data) {
    try {
      const response = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_SERVER_URL}/api/${action}`,
        data: data
      })
        .then(response => {
          if (typeof response.data.data === "string") {
            return JSON.parse(response.data.data)
          } else {
            return response.data.data
          }
        })
      return response;
    }
    catch (error) {
      throw error
    }
  }

  async deleteAmenityLocation(action, data) {
    try {
      const response = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_SERVER_URL}/api/${action}`,
        data: data
      })
        .then(response => {
          if (typeof response.data.data === "string") {
            return JSON.parse(response.data.data)
          } else {
            return response.data.data
          }
        })
      return response;
    }
    catch (error) {
      throw error
    }
  }

  async getAmenityLocations(action, data) {
    let url = window.location.protocol + "//" + window.location.host;
    try {
      const response = await axios({
        method: 'post',
        url: `${url}/api/${action}`,
        data: data
      })
      .then(response => {
        if (typeof response.data.data === "string") {
          return JSON.parse(response.data.data)
        } else {
          return response.data.data
        }

      })
      return response;
    }
    catch (error) {
      throw error
    }
  }

  async saveService( data ) {
    try {
      const response = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_SERVER_URL}/api/saveServices`,
        data: data
      })
        .then(response => {
          if (typeof response.data.data === "string") {
            return JSON.parse(response.data.data)
          } else {
            return response.data.data
          }
        })
      return response;
    }
    catch (error) {
      throw error.response.data.error
    }
  }
  async saveAmenity( data ) {
    try {
      const response = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_SERVER_URL}/api/saveAmenity`,
        data: data
      })
        .then(response => {
          if (typeof response.data.data === "string") {
            return JSON.parse(response.data.data)
          } else {
            return response.data.data
          }
        })
      return response;
    }
    catch (error) {
      throw error.response.data.error
    }
  }

  async getPhaseServices( data ) {
    try {
      const response = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_SERVER_URL}/api/getPhaseServices`,
        data: data
      })
        .then(response => {
          if (typeof response.data.data === "string") {
            return JSON.parse(response.data.data)
          } else {
            return response.data.data
          }
        })
      return response;
    }
    catch (error) {
      throw error.response.data.error
    }
  }
  
  async savePhaseServices( data ) {
    try {
      const response = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_SERVER_URL}/api/savePhaseServices`,
        data: data
      })
        .then(response => {
          if (typeof response.data.data === "string") {
            return JSON.parse(response.data.data)
          } else {
            return response.data.data
          }
        })
      return response;
    }
    catch (error) {
      throw error.response.data.error
    }
  }
}

export default AmenitiesAndServicesActions
