
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import _ from 'lodash';

/**
    * Componente <InputUploadFiles /> Renderiza un campo de tipo archivo
    * @component
    * @param  props
    * @param {string} props.type - Tipo de archivo.
    * @param {function}  props.setFile - Funcion que maneja la seleccion del archivo.
    * @param {string} props.propertyName - Nombre del parametro.
    * @param {bool} props.required - Boleano indica si el campo es requerido
    * @param {file} props.file - Archivos
*/
const InputUploadFiles = ({ type, setFile, propertyName, required, file }) => 
{
    const [filename, setFilename] = useState(false);
    const {acceptedFiles, fileRejections, getRootProps, getInputProps} = useDropzone(
    {
        maxFiles: 1,
        multiple: false,
        onDrop: async acceptedFiles => 
        {
            getBase64(acceptedFiles[0]).then(async (result) => 
            {
                result = result.toString().replace("data:application/pdf;base64,","");
                result = result.toString().replace("data:application/msword;base64,","");
                result = result.toString().replace("data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,","");
                result = result.toString().replace("data:application/vnd.ms-excel;base64,","");
                result = result.toString().replace("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,","");
                let imageObject = {
                    body: result,
                    name: acceptedFiles[0].name
                }
                setFilename(_.truncate(acceptedFiles[0].name, { length: 13, separator: "..." }))
                await setFile(imageObject);
            })
        }
    });

    useEffect(() => {
        setFilename(false);
    }, [type])

    /**
        * Convierte un archivo en formato base64.
        * @param {file} file - Archivo a convertir.
    */
    const getBase64 = async (file) => 
    {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      return new Promise((resolve, reject) => 
      {
          reader.onload = () => {
              resolve(reader.result)
          };
          reader.onerror = function (error) {
              reject(error)
          };
      })
    }

    return (
        <div {...getRootProps({ className: 'dropzone form-control pointer custom custom-file' })}>
        <input {...getInputProps()} className={"custom-file-input display-block"} required={required} />          <label className="custom-file-label">{filename || "Agregar archivo"}</label>
        </div>
    );
}

export default InputUploadFiles