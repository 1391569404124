import React, { useEffect, useState } from 'react';
import _ from 'lodash';

const InputCheckboxList = ({
    catalog,
    currentData,
    paramName,
    idProperty,
    displayName,
    handleData,
    cssClass,
    idx,
    renderColumns = false,
    columns = 1,
    disableCheckBoxes = false
}) => {
    const [data, setData] = useState(null);

    useEffect(() => {
        if (data) {
            handleData(paramName, data);
        }
    }, [data])

    const setCheck = async (id) => {
        
        let newData = [];
        if (currentData && currentData.length)
            newData = [...currentData];

        let index = _.findIndex(newData, function (o) { return o[idProperty] === id; });

        if (index !== -1)
            newData.splice(index, 1);
        else
            newData.push({ [idProperty]: id });

        
        setData(newData);
    }

    return (
        <div className={cssClass}>
            {
                (catalog) ?
                    catalog.map((element) => {


                        if (renderColumns === true) {
                            // if(index % columns === 0)
                            return (
                                <div key={element[idx]} className={`check-col col-md-${Math.round(12 / columns)} col-sm-6`} >
                                    <p>
                                        <input
                                            onChange={() => { setCheck(element[idx]) }}
                                            checked={
                                                (currentData && currentData.filter((row) => {
                                                    return element[idx] === row[idProperty]
                                                }).length)
                                            }
                                            type="checkbox"
                                            disabled={disableCheckBoxes}
                                            className="mr-2"
                                        />
                                        {element[displayName]}
                                    </p>
                                </div>
                            )
                        }
                        else

                            return (
                                <p key={element[idx]}>
                                    <input
                                        onChange={() => { setCheck(element[idx]) }}
                                        checked={
                                            (currentData && currentData.filter((row) => { return element[idx] == row[idProperty] }).length)
                                        }
                                        disabled={disableCheckBoxes}
                                        type="checkbox" /> {element[displayName]}
                                </p>
                            )
                    })
                    : null
            }
        </div>
    )
}
export default InputCheckboxList;