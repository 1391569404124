import React, { createContext, useContext, useState } from 'react';

export const AmenitiesAndServicesContext = createContext({});

export const AmenitiesAndServicesProvider = (props) => {
    const [socialAmenities, setSocialAmenities] = useState([]);
    const [enterntainmentAmenities, setEnterntainmentAmenities] = useState([]);
    const [fitnessAmenities, setFitnessAmenities] = useState([]);
    const [relaxationAmenities, setRelaxationAmenities] = useState([]);
    const [services, setServices] = useState([]);

    const value = {
        socialAmenities,
        setSocialAmenities,
        enterntainmentAmenities,
        setEnterntainmentAmenities,
        fitnessAmenities,
        setFitnessAmenities,
        relaxationAmenities,
        setRelaxationAmenities,
        services,
        setServices
    }

    return <AmenitiesAndServicesContext.Provider value={value} {...props} />
}

export const useAmenitiesAndServicesContext = () => {
    const context = useContext(AmenitiesAndServicesContext);

    if (!context) {
        throw new Error("no existe el context")
    }

    return context;
}