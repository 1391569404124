/* @flow */
import * as React from 'react'
import classnames from 'classnames'

import styles from './style.css'

export const AlertType = {
  ERROR: 'error',
}

export const AlertTheme = {
  ERROR: 'error',
}

export const AlertSize = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
}

const Alert = (props) => {
  const { type, onClick, color, children, theme, size, className, disabled } = props

  const classProps = classnames(
    theme, size, className, color,

  )
  return (
    <div data-cy="div_alert" type={type} disabled={disabled} className={classProps}>
      {children}
    </div>
  )
}

Alert.defaultProps = {
  type: AlertType.ERROR,
  theme: AlertTheme.ERROR,
  size: AlertSize.MEDIUM,
  className: '',
  disabled: false,
}

export default Alert
