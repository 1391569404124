
const axios = require('axios');

class AuthActions {
  constructor(data = false) {
    this.data = data;
  }

  async post(action) {
    try {
      const response = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_SERVER_URL}/api/${action}`,
        data: this.data
      })
        .then(response => {
          return response.data.data;
        })
        .catch(error => {
          throw error.response.data.error;
        })
      return response;
    }
    catch (error) {
      throw error;
    }
  }

  async getGoogleToken() {
    try {
      const response = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_SERVER_URL}/api/authenticate`,
        data: this.data
      })
        .then(response => {
          return response.data.data;
        })
        .catch(error => {
          throw error.response.data.error;
        })

      console.log("response ", response);
      return response;

    } catch (error) {
      throw error;
    }
  }

  async logout() {
    try {
      const response = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_SERVER_URL}/api/logout`,
        data: this.data
      })
      return response;

    } catch (error) {
      throw error;
    }
  }
}

export default AuthActions
