import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Dropdown } from 'primereact/dropdown';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { animateScroll as scroll } from 'react-scroll';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
//ACTIONS AND CONTEXT
import { RestActions } from "../../../actions";
import { useMainContext } from "../../../contexts/MainContext";
import { useCatalogsContext } from "../../../contexts/CatalogsContext";
//COMPONENTS
import InputFormCol from "../../../components/atoms/InputFormCol";
import InputNumeric from "../../../components/atoms/InputNumeric";
import Alert from '../../../components/atoms2/Alert';
import ModalConfirmCloseModal from "../../../components/atoms/ModalConfirmCloseModal";
import ModalConfirmGeneral from "../../../components/atoms/ModalConfirmGeneral";
import exclamationIcon from '../../../assets/icons/icon-exclamation-mark.svg';

const ControlForm = ({ phaseID, gatheringInformationID, showModal, handleNewRegisterModal, handleGatheringInfoID, handleRefreshList, phaseData }) => {
    const rest = new RestActions();
    let mainContext = useMainContext();
    let catalogs = useCatalogsContext();

    const [formData, setFormData] = useState({ amount: null, listNumber: null, typicalSeparationValue: null, priceM2Surplus: null, levels: [], isPreferencialRate: false, isSeparationPercentage: false, confirmedListSales: false });
    const [isSubmited, setIsSubmited] = useState(false);
    const [showConfirmSave, setShowConfirmSave] = useState(false);
    const [isClosable, setIsClosable] = useState(null);
    const [invalidModels, setInvalidModels] = useState({});
    const [showConfirmSaveAndClose, setShowConfirmSaveAndClose] = useState(false);
    const [showConfirmCloseModal, setShowConfirmCloseModal] = useState(false);
    const [showConfirmSeparation, setShowConfirmSeparation] = useState(false);
    const [gatheringAtDate, setGatheringAtDate] = useState(false)
    const [validDate, setValidDate] = useState(true)
    const [categoryByGathering, setCategoryByGathering] = useState({})

    const [dataFilter, setDataFilter] = useState({
        dataBeforeFilter: [], // Objeto que se renderiza para mostrar el HTML
        backUpDataBeforeFilter: [], // Objeto de respalso sin modificaciones de filtro
        appliedfilter: { 'Modelo': 0, 'Estatus Venta': 0, 'Estatus Renta': 0, 'Piso': 0 }, // 1 Si fue aplicado un filtro sobre este
    });

    const [selectModelo, setSelectModelo] = useState({
        optionFilterText: "--Todos--", //Opción seleccionada del filtro Modelo
        dataModelFilter: [{ "textOption": "--Todos--" }], //Datos o opciones de el select de Modelo 
    });

    const [selectFloor, setSelectFloor] = useState({
        optionFilterText: "--Todos--",
        dataFloorFilter: [{ "textOption": "--Todos--" }],
    });

    const [selectSaleStatus, setSelectSaleStatus] = useState({
        optionFilterNumber: 0,
        dataSaleStatusFilter: [{ saleStatusID: 0, description: "--Todos--" }, ...catalogs.saleStatus],
    });

    const [selectRentStatus, setSelectRentStatus] = useState({
        optionFilterNumber: 0,
        dataRentStatusFilter: [{ rentalStatusID: 0, description: "--Todos--" }, ...catalogs.rentalStatus],
    });


    function deepClone(obj, hash = new WeakMap()) {
        // Do not try to clone primitives or functions
        if (Object(obj) !== obj || obj instanceof Function) return obj;
        if (hash.has(obj)) return hash.get(obj); // Cyclic reference
        try { // Try to run constructor (without arguments, as we don't know them)
            var result = new obj.constructor();
        } catch (e) { // Constructor failed, create object without running the constructor
            result = Object.create(Object.getPrototypeOf(obj));
        }
        // Optional: support for some standard constructors (extend as desired)
        if (obj instanceof Map)
            Array.from(obj, ([key, val]) => result.set(deepClone(key, hash),
                deepClone(val, hash)));
        else if (obj instanceof Set)
            Array.from(obj, (key) => result.add(deepClone(key, hash)));
        // Register in hash    
        hash.set(obj, result);
        // Clone and assign enumerable own properties recursively
        return Object.assign(result, ...Object.keys(obj).map(
            key => ({ [key]: deepClone(obj[key], hash) })));
    }


    const createOptionsSelectModelo = (dataLevels) => {
        if (dataLevels.length > 0) {

            let newDataModelFilter = [{ "textOption": "--Todos--" }];

            dataLevels.forEach(level => {
                level.units.forEach(unit => {
                    let value = unit.models.name;
                    newDataModelFilter.push({ "textOption": value });
                });
            });

            newDataModelFilter = newDataModelFilter.filter((item, index, array) => {
                return array.map(mapItem => mapItem["textOption"])
                    .indexOf(item["textOption"]) === index;
            });

            setSelectModelo({ ...selectModelo, dataModelFilter: newDataModelFilter });
        }
    }

    const createOptionsFloorModelo = (dataLevels) => {
        if (dataLevels.length > 0) {

            let newDataModelFilter = [{ "textOption": "--Todos--" }];

            dataLevels.forEach(level => {
                let value = level.levels.levelName;
                newDataModelFilter.push({ "textOption": value });
            });

            setSelectFloor({ ...selectFloor, dataFloorFilter: newDataModelFilter });
        }
    }

    const onFilterModel = (text_to_filter, dataForChange) => {

        let newDataAfterFilter = dataForChange;

        if (text_to_filter != "--Todos--") {

            newDataAfterFilter = dataForChange.map(item => {
                // Filtrar los elementos de units para cada objeto en newDataBeforeFilter
                if (item.units && Array.isArray(item.units)) {
                    item.units = item.units.filter(unit => {
                        return unit.models && unit.models.name === text_to_filter;
                    });
                }
                return item;
            });
        }

        // Cambio el valor del select del Modelo 
        setSelectModelo({ ...selectModelo, optionFilterText: text_to_filter });

        return newDataAfterFilter;
    }

    const onFilterfloor = (text_to_filter, dataForChange) => {

        let newDataAfterFilter = dataForChange;

        // text_to_filter = 0 (--Todos--)
        if (text_to_filter != "--Todos--") {
            // Filtrar el objeto 'newDataAfterFilter' con en 'saleStatusID' igual a 'text_to_filter'
            newDataAfterFilter = dataForChange.filter(item => {
                // Verifica si el objeto cumple con la condición
                return item.levels && item.levels.levelName === text_to_filter;
            });
        }


        // Cambio el valor del select del estado de renta 
        setSelectFloor({ ...selectFloor, optionFilterText: text_to_filter });

        return newDataAfterFilter;
    }


    const onFilterSaleStatus = (number_to_filter, dataForChange) => {

        let newDataAfterFilter = dataForChange;

        // number_to_filter = 0 (--Todos--)
        if (number_to_filter != 0) {
            // Filtrar el objeto 'newDataAfterFilter' con en 'saleStatusID' igual a 'number_to_filter'
            newDataAfterFilter = dataForChange.map(item => {
                // Filtrar los elementos de units para cada objeto en newDataAfterFilter
                if (item.units && Array.isArray(item.units)) {
                    item.units = item.units.filter(unit => {
                        return unit.models && unit.unitsGatheringInformation.saleStatusID === number_to_filter;
                    });
                }
                return item;
            });
        }


        // Cambio el valor del select del estado de venta 
        setSelectSaleStatus({ ...selectSaleStatus, optionFilterNumber: number_to_filter });

        return newDataAfterFilter;
    }

    const onFilterRentStatus = (number_to_filter, dataForChange) => {

        let newDataAfterFilter = dataForChange;

        // number_to_filter = 0 (--Todos--)
        if (number_to_filter != 0) {
            // Filtrar el objeto 'newDataAfterFilter' con en 'saleStatusID' igual a 'number_to_filter'
            newDataAfterFilter = dataForChange.map(item => {
                // Filtrar los elementos de units para cada objeto en newDataAfterFilter
                if (item.units && Array.isArray(item.units)) {
                    item.units = item.units.filter(unit => {
                        return unit.models && unit.unitsGatheringInformation.rentalStatusID === number_to_filter;
                    });
                }
                return item;
            });
        }


        // Cambio el valor del select del estado de renta 
        setSelectRentStatus({ ...selectRentStatus, optionFilterNumber: number_to_filter });

        return newDataAfterFilter;
    }



    const onApliOtherFilters = async (filterIgnore, dataForChange) => {

        let text_to_filter;
        let number_to_filter;

        if (filterIgnore != 'Modelo' && dataFilter.appliedfilter['Modelo'] == 1) {
            text_to_filter = selectModelo.optionFilterText;
            dataForChange = await onFilterModel(text_to_filter, dataForChange);
        }

        if (filterIgnore != 'Estatus Venta' && dataFilter.appliedfilter['Estatus Venta'] == 1) {
            number_to_filter = selectSaleStatus.optionFilterNumber;
            dataForChange = await onFilterSaleStatus(number_to_filter, dataForChange);
        }

        if (filterIgnore != 'Estatus Renta' && dataFilter.appliedfilter['Estatus Renta'] == 1) {
            number_to_filter = selectRentStatus.optionFilterNumber;
            dataForChange = await onFilterRentStatus(number_to_filter, dataForChange);
        }

        if (filterIgnore != 'Piso' && dataFilter.appliedfilter['Piso'] == 1) {
            text_to_filter = selectFloor.optionFilterText;
            dataForChange = await onFilterfloor(text_to_filter, dataForChange);
        }

        return dataForChange;
        //Este verifica que otros filtros existen y los aplicara nuevamente
        // Se omite el filtro que viene en la variable filterIgnore, ya que este se aplicara despues de este filtro
    }


    const onApliFilter = async (tituloFiltro, whoApplied, objectDataValues) => {

        // Texto o numero a usar como filtro
        let text_number_to_filter = tituloFiltro;

        // Realizo copia de datos sin modificaciones
        var dataForChange = objectDataValues ? deepClone(objectDataValues) : deepClone(dataFilter.backUpDataBeforeFilter);

        let statusModel;

        switch (whoApplied) {
            case 'Modelo':

                statusModel = (text_number_to_filter === "--Todos--") ? 0 : 1;

                // Limpio filtro de modelo y aplico otros filtros si existen 
                dataForChange = await onApliOtherFilters('Modelo', dataForChange);
                //Aplico filtro de Modelo
                dataForChange = await onFilterModel(text_number_to_filter, dataForChange);

                // Asigno objeto con filtros y estatus de filtro para modelo
                setDataFilter({ ...dataFilter, dataBeforeFilter: dataForChange, appliedfilter: { ...dataFilter.appliedfilter, 'Modelo': statusModel } });
                break;

            case 'Estatus Venta':

                statusModel = (text_number_to_filter == 0) ? 0 : 1;

                // Limpio filtro de Estatus Venta y aplico otros filtros si existen 
                dataForChange = await onApliOtherFilters('Estatus Venta', dataForChange);
                //Aplico filtro de Estatus Venta
                dataForChange = await onFilterSaleStatus(text_number_to_filter, dataForChange);

                // Asigno objeto con filtros y estatus de filtro para Estatus Venta
                setDataFilter({ ...dataFilter, dataBeforeFilter: dataForChange, appliedfilter: { ...dataFilter.appliedfilter, 'Estatus Venta': statusModel } });
                break;

            case 'Estatus Renta':

                statusModel = (text_number_to_filter == 0) ? 0 : 1;

                // Limpio filtro de Estatus Renta y aplico otros filtros si existen 
                dataForChange = await onApliOtherFilters('Estatus Renta', dataForChange);
                //Aplico filtro de Estatus Renta
                dataForChange = await onFilterRentStatus(text_number_to_filter, dataForChange);

                // Asigno objeto con filtros y estatus de filtro para Estatus Renta
                setDataFilter({ ...dataFilter, dataBeforeFilter: dataForChange, appliedfilter: { ...dataFilter.appliedfilter, 'Estatus Renta': statusModel } });
                break;
            case 'Piso':

                statusModel = (text_number_to_filter === "--Todos--") ? 0 : 1;

                // Limpio filtro de modelo y aplico otros filtros si existen 
                dataForChange = await onApliOtherFilters('Piso', dataForChange);
                //Aplico filtro de Modelo
                dataForChange = await onFilterfloor(text_number_to_filter, dataForChange);

                // Asigno objeto con filtros y estatus de filtro para modelo
                setDataFilter({ ...dataFilter, dataBeforeFilter: dataForChange, appliedfilter: { ...dataFilter.appliedfilter, 'Piso': statusModel } });
                break;


            default:
                dataForChange = await onApliOtherFilters('', dataForChange);
                setDataFilter({ ...dataFilter, dataBeforeFilter: dataForChange, backUpDataBeforeFilter: deepClone(objectDataValues) });
                break;
        }
    }


    useEffect(() => {
        if (showModal) {
            getInfo();
        }
    }, [showModal, gatheringInformationID])

    const getInfo = async () => {
        mainContext.setIsLoading(true);
        let params = { phaseID: parseInt(phaseID), isNew: true }
        if (gatheringInformationID) {
            params.gatheringInformationID = gatheringInformationID;
            params.isNew = false;
        }
        try {
            let response = await rest.post("getGatheringInformation", params);
            console.log("response", response)
            if (gatheringInformationID) {
                response[0].levels = groupBy(response[0].units);
                setFormData(response[0]);


                createOptionsSelectModelo(response[0].levels);
                createOptionsFloorModelo(response[0].levels);
                setDataFilter({ ...dataFilter, dataBeforeFilter: response[0].levels, backUpDataBeforeFilter: deepClone(response[0].levels) });


                var dateParts = response[0].gatheringAt.split("/");
                let initgatheringAtDate = response[0].gatheringAt ? new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]) : ""


                setGatheringAtDate(initgatheringAtDate)

            }
            else {
                response.levels = groupBy(response.units);
                delete response.typicalSeparationValue;
                setFormData(response);

                createOptionsSelectModelo(response.levels);
                createOptionsFloorModelo(response.levels);
                setDataFilter({ ...dataFilter, dataBeforeFilter: response.levels, backUpDataBeforeFilter: deepClone(response.levels) });

            }
            mainContext.setIsLoading(false);
        }
        catch (error) {/*TODO SOMETHING*/ }
    }

    const onFormDataChange = async (paramName, e) => {
        mainContext.setIsDirty(true);
        setFormData({ ...formData, [paramName]: e.value });
    }

    const onFormChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setFormData({ ...formData, [name]: value });
    }

    const onSeparationChange = async (paramName, e) => {
        let data = { ...formData }
        _.forEach(data.levels, (level, indexLevel) => {
            _.forEach(level.units, (unit, indexUnit) => {
                if (unit.unitsGatheringInformation.separationValue == formData.typicalSeparationValue && !isSold(unit))
                    data.levels[indexLevel].units[indexUnit].unitsGatheringInformation.separationValue = e.value;
            });
        });
        data.typicalSeparationValue = e.value;

        mainContext.setIsDirty(true);
        setFormData(data);
    }

    const onPriceM2Surplus = (paramName, e) => {
        let data = { ...formData }
        data.priceM2Surplus = e.value;
        // mainContext.setIsDirty(true);
        setFormData(data);
    }

    const onSeparationPercentChange = async () => {
        setShowConfirmSeparation(false);
        let data = { ...formData }
        _.forEach(data.levels, (level, indexLevel) => {
            _.forEach(level.units, (unit, indexUnit) => {
                data.levels[indexLevel].units[indexUnit].unitsGatheringInformation.separationValue = "";
            });
        });
        data.typicalSeparationValue = "";
        data.isSeparationPercentage = !data.isSeparationPercentage;

        mainContext.setIsDirty(true);
        setFormData(data);
    }

    /**
     * Función para actualizar los datos escritos o seleccionados en el formData
     * @param {*} event Evento de donde se extrae la data 
     * @param {*} name Nombre al grupo de tipo de dato al que pertenece
     * @param {*} unitID ID único que representa el piso/Nivel/Unidad a la cual pertenece
     * @param {*} indexUnit Posición en la que se encuentra el activador del evento para su cambio
     */
    const onUnitChange = (event, name, unitID, indexUnit) => {

        let levels = [...formData.levels];

        // Buscar el nivel que contiene la unidad con el unitID dado
        const indexLevel = levels.findIndex(level =>
            level.units.some(unit => unit.unitID === unitID)
        );


        // Buscar la unidad con el identificador unico
        const indexLevelFind = levels[indexLevel].units.findIndex(unit => unit.unitID == indexUnit);


        let row = levels[indexLevel].units[indexLevelFind];
        row.unitsGatheringInformation[name] = event.target.value;
        if (name == "saleStatusID" && event.value == 5) {
            row.unitsGatheringInformation["actualPrice"] = "";
        }
        if (name == "rentalStatusID") {

            if (event.target.value == 1) {
                row.unitsGatheringInformation["subCategoryID"] = 2
            }
            if (event.target.value == 3) {
                row.unitsGatheringInformation["subCategoryID"] = 3
            }
            if (event.target.value !== 4) {
                row.unitsGatheringInformation["saleStatusID"] = 5
            } else {
                row.unitsGatheringInformation["saleStatusID"] = 1
            }
        } else if (name == "saleStatusID") {
            if (event.target.value !== 5) {
                row.unitsGatheringInformation["rentalStatusID"] = 4
            } else {
                row.unitsGatheringInformation["rentalStatusID"] = 1
            }
        }
        levels[indexLevel].units[indexLevelFind] = row;

        setFormData({ ...formData, levels: levels });
        // await setDataFilter({ ...dataFilter, dataBeforeFilter: levels, backUpDataBeforeFilter: deepClone(levels) });
        onApliFilter('', '', levels);
    }

    /**
     * Función para actualizar los datos Numericos escritos o seleccionados en el formData
     * @param {*} event Evento de donde se extrae la data 
     * @param {*} name Nombre al grupo de tipo de dato al que pertenece
     * @param {*} unitID ID único que representa el piso/Nivel/Unidad a la cual pertenece
     * @param {*} indexUnit Posición en la que se encuentra el activador del evento para su cambio
     */
    
    const onUnitChangeNumeric = (event, name, unitID, indexUnit) => {

        let levels = [...formData.levels];
        let levelsBack = [...dataFilter.backUpDataBeforeFilter];
        let levelsFront = [...dataFilter.dataBeforeFilter];


        // Buscar el nivel que contiene la unidad con el unitID dado
        const indexLevel = levels.findIndex(level =>
            level.units.some(unit => unit.unitID === unitID)
        );

        // Buscar la unidad con el identificador unico
        const indexLevelFind = levels[indexLevel].units.findIndex(unit => unit.unitID == indexUnit);

        if (indexLevel !== -1) {
            let level = { ...levels[indexLevel] };

            if (indexLevelFind !== -1) {
                let unit = { ...level.units[indexLevelFind] };

                // Actualizar el valor deseado
                unit.unitsGatheringInformation[name] = event.value;

                // Actualizar la unidad dentro del nivel
                level.units[indexLevelFind] = unit;

                // Actualizar el nivel dentro de la formData
                levels[indexLevel] = level;

                // Actualizar el estado final
                // formData.levels = levels;
                setFormData({ ...formData, levels: levels });
                onApliFilter('', '', levels);
            }
        }


        // setFormData({ ...formData, levels: levels });
        // await setDataFilter({ ...dataFilter, dataBeforeFilter: levels, backUpDataBeforeFilter: deepClone(levels) });
        // onApliFilter('', '', levels);
    }

    const groupBy = (arr) => {
        let optionsByUnit = {}
        _.forEach(arr, (row, index) => {

            if (row.unitsGatheringInformation) {

                arr[index].unitsGatheringInformation = row.unitsGatheringInformation;
            } else {

                if (phaseData.hasGatheringInformation) {
                    arr[index].unitsGatheringInformation = {
                        saleStatusID: row.saleStatusID,
                        rentalStatusID: row.rentalStatusID != null ? row.rentalStatusID : 1,
                        rentalPrice: row.rentalPrice,
                        actualPrice: row.actualPrice,
                        separationValue: row.separationValue,
                        subCategoryID: row.subCategoryID,
                        brandName: row.brandName
                    };
                } else {
                    arr[index].unitsGatheringInformation = {
                        saleStatusID: row.models.modelTypes.commercialBusinessID == 1 ? 1 : row.models.modelTypes.commercialBusinessID == 6 ? 1 : 5,
                        rentalStatusID: row.models.modelTypes.commercialBusinessID == 1 ? 4 : row.models.modelTypes.commercialBusinessID == 6 ? 4 : 1,
                        rentalPrice: row.rentalPrice,
                        actualPrice: row.actualPrice, separationValue: row.separationValue,
                        subCategoryID: row.subCategoryID,
                        brandName: row.brandName
                    };
                }

            }

            let options = _.filter(catalogs.subCategories, function (item) {

                return item.commercialBusinessID == row.models.modelTypes.commercialBusinessID;
            });
            optionsByUnit = { ...optionsByUnit, [row.unitID]: options }

        })
        setCategoryByGathering(optionsByUnit)

        let group = _.uniqBy(arr, (row) => { return row.levels.levelNumber; });
        _.forEach(group, (row, index) => {
            group[index].units = _.filter(arr, (row2) => { return row2.levels.levelNumber == row.levels.levelNumber });
        })
        return group;
    }

    const isAbleToClose = async () => {
        if (mainContext.isDirty)
            setShowConfirmCloseModal(true);
        else
            resetModal();
    }

    const resetModal = async () => {
        // Remuevo filtros
        const textInit = "--Todos--";
        const numberInit = 0;
        setSelectModelo({ ...selectModelo, optionFilterText: textInit });
        setSelectSaleStatus({ ...selectSaleStatus, optionFilterNumber: numberInit });
        setSelectSaleStatus({ ...selectSaleStatus, optionFilterNumber: numberInit });
        setSelectRentStatus({ ...selectRentStatus, optionFilterNumber: numberInit });
        setDataFilter({ ...dataFilter, appliedfilter: { 'Modelo': 0, 'Estatus Venta': 0, 'Estatus Renta': 0, 'Piso': 0 } })
        //End remover filtros
        setShowConfirmCloseModal(false);
        setShowConfirmSaveAndClose(false);
        setShowConfirmSave(false);
        setIsSubmited(false);
        setIsClosable(null);
        setInvalidModels({});
        mainContext.setIsLoading(false);
        mainContext.setIsDirty(false);
        setGatheringAtDate(null)
        setFormData({ amount: null, listNumber: null, typicalSeparationValue: null, levels: [], isPreferencialRate: false, isSeparationPercentage: false, confirmedListSales: false });
        handleGatheringInfoID(null);
        handleNewRegisterModal(false);
    }

    const isSold = (unit) => {
        if (unit.unitsGatheringInformation.saleStatusID == 2 || unit.unitsGatheringInformation.saleStatusID == 3 || unit.unitsGatheringInformation.saleStatusID == 5)
            return true;
        return false;
    }

    const sendForm = async () => {
        setIsSubmited(true);
        if (await validateInfoForm()) {
            mainContext.setIsLoading(true);
            mainContext.setIsDirty(false);
            let action = "createGatheringInformation";

            let params = {
                ...formData,
                userID: mainContext.session.userID,
                phaseID: parseInt(phaseID),
                isNew: true
            };
            if (gatheringInformationID) {
                action = "updateGatheringInformation";
                params.gatheringInformationID = gatheringInformationID;
                params.isNew = false;
            }
            let unitsGatheringInformation = [];
            _.forEach(params.levels, (level) => {
                _.forEach(level.units, (unit) => {
                    unitsGatheringInformation.push({
                        unitID: unit.unitID,
                        saleStatusID: (unit.unitsGatheringInformation.saleStatusID) ? parseFloat(unit.unitsGatheringInformation.saleStatusID) : null,
                        rentalStatusID: (unit.unitsGatheringInformation.rentalStatusID) ? parseFloat(unit.unitsGatheringInformation.rentalStatusID) : null,
                        rentalPrice: (unit.unitsGatheringInformation.rentalPrice) ? parseFloat(unit.unitsGatheringInformation.rentalPrice) : null,
                        actualPrice: (unit.unitsGatheringInformation.actualPrice) ? parseFloat(unit.unitsGatheringInformation.actualPrice) : null,
                        subCategoryID: (unit.unitsGatheringInformation.subCategoryID) ? parseFloat(unit.unitsGatheringInformation.subCategoryID) : null,
                        brandName: (unit.unitsGatheringInformation.brandName) ? unit.unitsGatheringInformation.brandName : null,
                        separationValue: (unit.unitsGatheringInformation.separationValue) ? parseFloat(unit.unitsGatheringInformation.separationValue) : null,
                    });
                })
            });
            params.unitsGatheringInformation = unitsGatheringInformation;
            params.amount = parseFloat(params.amount);
            params.isPreferencialRate = (params.isPreferencialRate == null) ? false : params.isPreferencialRate;
            params.isSeparationPercentage = (params.isSeparationPercentage == null) ? false : params.isSeparationPercentage;
            params.confirmedListSales = (params.confirmedListSales == null) ? false : params.confirmedListSales;
            params.gatheringAt = formData.gatheringAt
            delete params.levels;
            delete params.units;
            try {
                let response = await rest.post(action, params);
                await handleRefreshList();
                if (!gatheringInformationID) {
                    handleGatheringInfoID(response.gatheringInformationID);
                    handleNewRegisterModal(false);
                } else {
                    await getInfo();
                    await setShowConfirmSave(false);
                }
                mainContext.setMessageAlert("El registro se guardó correctamente");
                mainContext.setIsDirty(false);

            }
            catch (error) {
                mainContext.setMessageAlert(error.toString());
                mainContext.setIsLoading(false);

            }
            await resetModal();
        } else {
            scroll.scrollTo(180);
        }
    }

    const validateInfoForm = () => {
        let requiredData = ["gatheringAt"]
        let isValid = true;
        requiredData.forEach((row) => {
            if (formData[row] == undefined || formData[row] == null || formData[row] == "")
                isValid = false;
        })

        return isValid;

    }

    const validateCloseForm = async () => {
        let allUnits = [];
        _.forEach(formData.levels, (level) => {
            allUnits.push(level.units);
        });
        allUnits = _.flatten(allUnits);

        let availableUnits = _.filter(allUnits, (row) => { return row.unitsGatheringInformation.saleStatusID == 1 });
        if (availableUnits.length <= 1)
            return true;

        let availableUnitsWithPrice = _.filter(availableUnits, (unit) => {
            return unit.unitsGatheringInformation.actualPrice && unit.unitsGatheringInformation.actualPrice != ""
        });

        if (availableUnitsWithPrice.length == 0)
            return true;

        let models = {}
        _.forEach(availableUnits, (mainUnit) => {
            models[mainUnit.models.name] = _.filter(availableUnits, (row) => { return (mainUnit.models.modelID == row.models.modelID) });
        })

        let modelsResults = {}
        _.forEach(Object.keys(models), (key) => {
            modelsResults[key] = null;
            if (models[key].length == 1)
                modelsResults[key] = true;
            else {
                let units = [...models[key]];
                _.forEach(models[key], (mainUnit) => {
                    let unitsToCompare = _.filter(units, (unit) => { return mainUnit.levels.levelNumber != unit.levels.levelNumber })
                    units.shift();
                    _.forEach(unitsToCompare, (unit) => {
                        if (mainUnit.unitsGatheringInformation.actualPrice && unit.unitsGatheringInformation.actualPrice)
                            modelsResults[key] = true;
                    });

                    if (modelsResults[key] == null) {
                        _.forEach(unitsToCompare, (unit) => {
                            if (mainUnit.unitsGatheringInformation.actualPrice && !unit.unitsGatheringInformation.actualPrice ||
                                !mainUnit.unitsGatheringInformation.actualPrice && unit.unitsGatheringInformation.actualPrice
                            ) {
                                modelsResults[key] = false;
                            }
                        });
                    }

                });

                if (modelsResults[key] == null)
                    modelsResults[key] = true;
            }
        })

        let isValid = true;
        _.forEach(Object.keys(modelsResults), (key) => {
            if (!modelsResults[key])
                isValid = false;
        })
        setInvalidModels(modelsResults);
        if (isValid) {
            setIsClosable(true);

            return true;
        }
        else {
            setIsClosable(false);
            mainContext.setMessageAlert("No es posible agregar/cerrar el levantamiento, no cumple con las reglas de cierre");
            return false;
        }
    }

    const closeForm = async () => {
        mainContext.setIsLoading(true);
        let params = {
            phaseID: parseInt(phaseID),
            gatheringInformationID: gatheringInformationID,
            isNew: false,
            userID: mainContext.session.userID
        }
        try {
            let response = await rest.post("closeGatheringInformation", params);
            mainContext.setMessageAlert("El registro se guardó correctamente");
            await handleRefreshList();
            await resetModal();
        }
        catch (error) {
            mainContext.setMessageAlert(error.toString());
            mainContext.setIsLoading(false);
        }
    }

    function format(num, _decimal = 2) {
        if (!num)
            return num;
        if (!isNaN(parseFloat(num)))
            num = parseFloat(num).toFixed(_decimal);
        return ('' + num).replace(
            /(\d)(?=(?:\d{3})+(?:\.|$))|(\.\d\d?)\d*$/g,
            function (m, s1, s2) {
                return s2 || (s1 + ',');
            }
        );
    }

    const formatDate = (date) => {
        let year = date.getFullYear()
        let month = date.getMonth() + 1
        month = `${month}`
        month = month.length === 1 ? `0${month}` : month
        let formatedDate = `01/${month}/${year}`
        return formatedDate;
    }

    const handleDateChangeRaw = (e) => {
        e.preventDefault();
    }
    return (
        <Modal className="px-4" size={"models"} show={showModal} onHide={isAbleToClose} backdrop="static">
            <ModalConfirmCloseModal show={showConfirmCloseModal} handleShowClose={setShowConfirmCloseModal} handleConfirmClose={resetModal} />
            <Modal.Header className="no-border" closeButton>
                <Modal.Title className="pl-3"></Modal.Title>
            </Modal.Header>

            <ModalConfirmGeneral handleConfirm={closeForm} handleCancel={setShowConfirmSaveAndClose} show={showConfirmSaveAndClose} icon={exclamationIcon} >
                <div className="col">
                    <p className="title-modal"><b>¿Seguro que deseas cerrar el levantamiento?</b></p>
                    <p>Una vez cerrado el levantamiento cualquier cambio constituye una edición a un dato historico</p>
                </div>
            </ModalConfirmGeneral>

            <ModalConfirmGeneral handleConfirm={sendForm} handleCancel={setShowConfirmSave} show={showConfirmSave} icon={exclamationIcon} >
                <div className="col">
                    <p className="title-modal"><b>¿Seguro que deseas actualizar el levantamiento?</b></p>
                    <p>Se modificará el registro de levantamiento de manera permanente</p>
                </div>
            </ModalConfirmGeneral>

            <ModalConfirmGeneral handleConfirm={onSeparationPercentChange} handleCancel={setShowConfirmSeparation} show={showConfirmSeparation} icon={exclamationIcon} >
                <div className="col">
                    <p className="title-modal"><b>¿Estas seguro de cambiar este campo?</b></p>
                    <p>Se borraran los campos de separación típica y separación de cada unidad</p>
                </div>
            </ModalConfirmGeneral>


            <Modal.Body className="mx-3">
                <div className="custom-card">
                    <div className="custom-card-header">
                        <h4 className="py-3 px-3">
                            {(gatheringInformationID) ?
                                "EDITAR LEVANTAMIENTO"
                                :
                                "NUEVO LEVANTAMIENTO"
                            }
                        </h4>
                    </div>
                    <div className="custom-card-body px-3">
                        <form autocomplete="off">
                            {(formData.closedAt != "-" && gatheringInformationID && formData.saleStatus) &&
                                <>
                                    <div className="row mt-4 input-height-30">
                                        <InputFormCol label="Unidades totales" value={formData.name} col="col-4" withMessage={false}>
                                            <input value={format(formData.saleStatus[0].totalUnits)} disabled={true} className="form-control black-border large-input w-75" type="text" />
                                        </InputFormCol>
                                        <InputFormCol label="Área vendible" value={formData.name} col="col-4" withMessage={false}>
                                            <input value={format(formData.saleStatus[0].totalM2)} disabled={true} className="form-control black-border large-input w-75" type="text" />
                                        </InputFormCol>
                                        <InputFormCol label="Precio promedio inv." value={formData.name} col="col-4" withMessage={false}>
                                            <input value={format(formData.saleStatus[0].avgAmountForSale)} disabled={true} className="form-control black-border large-input w-75" type="text" />
                                        </InputFormCol>
                                    </div>
                                    <div className="row input-height-30">
                                        <InputFormCol label="Unidades vendidas" value={formData.name} col="col-4" withMessage={false}>
                                            <input value={format(formData.saleStatus[0].soldUnits)} disabled={true} className="form-control black-border large-input w-75" type="text" />
                                        </InputFormCol>
                                        <InputFormCol label="Área vendida" value={formData.name} col="col-4" withMessage={false}>
                                            <input value={format(formData.saleStatus[0].soldArea)} disabled={true} className="form-control black-border large-input w-75" type="text" />
                                        </InputFormCol>
                                        <InputFormCol label={<span>$/M<sup>2</sup></span>} value={formData.name} col="col-4" withMessage={false}>
                                            <input value={format(formData.saleStatus[0].avgPriceM2)} disabled={true} className="form-control black-border large-input w-75 d-block" type="text" />
                                        </InputFormCol>
                                    </div>
                                    <div className="row input-height-30">
                                        <InputFormCol label="% Avance ventas" value={formData.name} col="col-4">
                                            <input value={format(formData.saleStatus[0].saleAdvance)} disabled={true} className="form-control black-border large-input w-75" type="text" />
                                        </InputFormCol>
                                        <InputFormCol label="% Avance área vendida" value={formData.name} col="col-4">
                                            <input value={format(formData.saleStatus[0].advanceSoldArea)} disabled={true} className="form-control black-border large-input w-75" type="text" />
                                        </InputFormCol>
                                    </div>
                                    <hr />
                                </>
                            }
                            <div className="row input-height-30">

                                <InputFormCol label="Tasa preferencial" col="col-4" withMessage={false}>
                                    <input onChange={onFormChange} name="isPreferencialRate" checked={formData.isPreferencialRate} className="d-block" type="checkbox" />
                                </InputFormCol>

                                <InputFormCol label="Valor de cambio" col="col-4" withMessage={false}>
                                    <InputNumeric paramName="amount" defaultValue={formData.amount} maxlength={12} decimal={2} handleValue={onFormDataChange} className="w-75" />
                                </InputFormCol>

                                <InputFormCol label="* Fecha de levantamiento" col="col-4" withMessage={true}>
                                    {/*<input value={formData.closedAt} name="closedAt" maxLength="50" className="form-control black-border large-input w-75" type="text" />*/}
                                    <DatePicker
                                        selected={gatheringAtDate}
                                        onChangeRaw={handleDateChangeRaw}
                                        onChange={date => {
                                            if (date != null) {
                                                setGatheringAtDate(date)
                                                setFormData({ ...formData, gatheringAt: formatDate(new Date(date)) })
                                                let today = new Date();
                                                let curretnDate = Date(date)
                                                if (curretnDate <= today) {
                                                    setValidDate(true)
                                                } else {
                                                    setValidDate(false)
                                                }
                                            } else {
                                                mainContext.setMessageAlert("El campo Fecha no puede  estar vacio");
                                            }



                                        }}

                                        maxDate={new Date()}
                                        dateFormat="MM/yyyy"
                                        showMonthYearPicker
                                        className={"small-input black-border form-control"}
                                        placeholderText="Seleccionar"
                                        required={true}
                                        id={"startDate"}
                                        autocomplete="nope"
                                    />
                                    {
                                        (!formData.gatheringAt && isSubmited) &&
                                        < Alert className="input__alert-error mt-2" theme={"error"} type="error" size={"large"}>
                                            *Campo obligatorio.
                                        </Alert>
                                    }
                                </InputFormCol>
                            </div>
                            <div className="row input-height-30">
                                <InputFormCol label="¿Separación en %?" col="col-4" withMessage={false}>
                                    <Form.Check checked={formData.isSeparationPercentage} onChange={() => { setShowConfirmSeparation(true) }} type="switch" label="" id="isSeparationPercentage" className="mt-2" />
                                </InputFormCol>
                                <InputFormCol label="Separación típica" col="col-4" withMessage={false}>
                                    <div className="input-group w-75">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                {(formData.isSeparationPercentage) ? "%" : "$"}
                                            </span>
                                        </div>
                                        {(formData.isSeparationPercentage) ?
                                            <InputNumeric defaultValue={formData.typicalSeparationValue} paramName="typicalSeparationValue" maxlength={3} decimal={2} handleValue={onSeparationChange} maxValue={100} />
                                            :
                                            <InputNumeric defaultValue={formData.typicalSeparationValue} paramName="typicalSeparationValue" maxlength={12} decimal={2} handleValue={onSeparationChange} />
                                        }
                                    </div>
                                </InputFormCol>

                                <InputFormCol label="Lista No." col="col-4" withMessage={false}>
                                    <InputNumeric defaultValue={formData.listNumber} paramName="listNumber" maxlength={12} decimal={2} handleValue={onFormDataChange} className="w-75 d-block" />
                                </InputFormCol>
                            </div>
                            {/* Campos nuevos */}
                            <div className="row input-height-30">

                                <InputFormCol label="¿Ventas confirmadas de lista?" col="col-4" withMessage={false}>
                                    <Form.Check checked={formData.confirmedListSales} onChange={() => { setFormData({ ...formData, confirmedListSales: !formData.confirmedListSales }) }} type="switch" label="" id="confirmedListSales" className="mt-2" />
                                </InputFormCol>

                                <InputFormCol label="Precio por M2 de excedente" col="col-4" withMessage={false}>
                                    <div className="input-group w-75">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                {(formData.isSeparationPercentage) ? "%" : "$"}
                                            </span>
                                        </div>
                                        <InputNumeric defaultValue={formData.priceM2Surplus} paramName="priceM2Surplus" maxlength={12} decimal={2} handleValue={onPriceM2Surplus} />
                                    </div>
                                </InputFormCol>

                            </div>
                        </form>

                    </div>
                </div>

                <div className="custom-card mt-4">
                    {/* <div className="custom-card-header">
                        <h4 className="py-3 px-3">
                            ASIGNACIÓN DE LEVANTAMIENTOS
                        </h4>
                    </div> */}

                    <div className="custom-card-header  d-flex justify-content-between align-items-center">
                        <h4 className="px-3 m-0">
                            ASIGNACIÓN DE LEVANTAMIENTOS
                        </h4>
                        <FontAwesomeIcon icon={faFilter} size='2x' />
                        <div className="col-2 d-flex  align-items-center">
                            <h4 className="text-nowrap m-0 mr-1">Modelo: </h4>
                            <div className="w-100">
                                {/* <Dropdown value={formData.marketingSchemeID} options={catalogs.marketingSchemes} onChange={(e) => { onFormDataChange('marketingSchemeID', e); }} optionLabel="description" optionValue="marketingSchemeID" /> */}
                                {/* <Dropdown value={optionFilterID} options={dataModelFilter} optionLabel="textOption" onChange={(e) => { onFormDataChange('marketingSchemeID', e); }} optionValue="dataModelFilterID" placeholder="--Todos--" className="w-100" /> */}
                                <Dropdown value={selectModelo.optionFilterText} options={selectModelo.dataModelFilter} onChange={(e) => { onApliFilter(e.value, 'Modelo') }} optionLabel="textOption" optionValue="textOption" className="w-100" />
                            </div>
                        </div>
                        <div className="col-2 d-flex  align-items-center">
                            <h4 className="text-nowrap m-0 mr-1">Estatus Venta: </h4>
                            <div className="w-100">
                                <Dropdown value={selectSaleStatus.optionFilterNumber} options={selectSaleStatus.dataSaleStatusFilter} onChange={(e) => { onApliFilter(e.value, 'Estatus Venta') }} optionValue="saleStatusID" optionLabel="description" className="w-100" />
                                {/* <Dropdown value={optionFilterText} options={dataModelFilter} onChange={(e) => { onApliFilter(e.value) }} optionLabel="textOption" optionValue="textOption" className="w-100" /> */}
                            </div>
                        </div>
                        <div className="col-2 d-flex  align-items-center">
                            <h4 className="text-nowrap m-0 mr-1">Estatus Renta: </h4>
                            <div className="w-100">
                                <Dropdown value={selectRentStatus.optionFilterNumber} options={selectRentStatus.dataRentStatusFilter} onChange={(e) => { onApliFilter(e.value, 'Estatus Renta') }} optionValue="rentalStatusID" optionLabel="description" className="w-100" />
                            </div>
                        </div>
                        <div className="col-2 d-flex  align-items-center">
                            <h4 className="text-nowrap m-0 mr-1">Piso: </h4>
                            <div className="w-100">
                                <Dropdown value={selectFloor.optionFilterText} options={selectFloor.dataFloorFilter} onChange={(e) => { onApliFilter(e.value, 'Piso') }} optionLabel="textOption" optionValue="textOption" className="w-100" />
                            </div>
                        </div>
                    </div>

                    <div className="custom-card-body px-3">
                        <div className="row">
                            <table className="table-no-border table-striped table-levantamientos">
                                <thead>
                                    <tr>
                                        <th className="fixedth1">Nombre</th>
                                        <th className="text-center fixedth2">Modelo</th>
                                        <th className="text-center fixedth3">M<sup>2</sup> totales</th>
                                        <th className="text-center">Tipo</th>
                                        <th className="text-center">Último precio registrado</th>
                                        <th className="text-center">Fecha del último registro</th>
                                        <th className="text-center ">
                                            Precio actual
                                            <span title="Es obligatorio capturar por lo menos dos precios para un mismo modelo, excepto si es la ultima unidad disponible">
                                                <FontAwesomeIcon icon={faQuestionCircle} />
                                            </span>
                                            <br />
                                            <small className="font-weight-bold"><FontAwesomeIcon icon={faUserCircle} /> = Monto capturado</small>
                                        </th>
                                        <th className="text-center">Precio por M<sup>2</sup></th>
                                        <th className="text-center ">Estado venta <span title="Es necesario actualizar el estado de venta de todos los inmuebles de cada levantamiento"><FontAwesomeIcon icon={faQuestionCircle} /></span></th>
                                        <th className="text-center">Precio de renta</th>
                                        <th className="text-center column-gray">Estado de renta <span title="Es necesario actualizar el estado de venta de todos los inmuebles de cada levantamiento"><FontAwesomeIcon icon={faQuestionCircle} /></span></th>
                                        <th className="text-center">Categoria <br /> (Comercial/oficina) </th>
                                        <th className="text-center">Marca / Empresa </th>
                                        <th className="text-center">Separación</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {/* {formData.levels.map((level, indexLevel) => */}
                                    {dataFilter.dataBeforeFilter.map((level, indexLevel) =>
                                    (
                                        <>
                                            {(indexLevel = level.unitID, null)}
                                            <tr key={`row-${indexLevel}`} className="table-row-border-top">
                                                <td className="fixed1" colSpan="6"><b>{level.levels.levelName}</b></td>
                                                <td className="column-border"></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td className=""></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                            {level.units.map((unit, indexUnit) =>
                                            (
                                                <>
                                                    {(indexUnit = unit.unitID, null)}
                                                    < tr key={`row-${indexUnit}`} className={(isClosable == false && invalidModels[unit.models.name] == false) ? "invalid-unit" : ""}>
                                                        <td className="text-center fixed1">{unit.code}</td>
                                                        <td className="text-center fixed2">{unit.models.name}</td>
                                                        <td className="text-center fixed3">{unit.models.totalM2}</td>
                                                        <td className="text-center">{unit.models.modelTypes.description}</td>
                                                        <td className="text-center">{format(unit.lastPrice, 0)}</td>
                                                        <td className="text-center">{unit.lastDate}</td>
                                                        <td className="text-center column-border d-flex">
                                                            {(isSold(unit) && !gatheringInformationID) ?
                                                                <InputNumeric paramName="actualPrice" defaultValue={unit.unitsGatheringInformation.actualPrice ? unit.unitsGatheringInformation.actualPrice : ''} maxlength={12} decimal={2} isDisabled={true} datacy={unit.code} />
                                                                :
                                                                <InputNumeric paramName="actualPrice" defaultValue={unit.unitsGatheringInformation.actualPrice ? unit.unitsGatheringInformation.actualPrice : ''} maxlength={12} decimal={2} handleValue={(paramName, e) => { onUnitChangeNumeric(e, paramName, indexLevel, indexUnit) }} isDisabled={isSold(unit)} datacy={unit.code} />
                                                            }
                                                            {unit.unitsGatheringInformation?.actualPrice != "" && unit.unitsGatheringInformation?.actualPrice != null && unit.unitsGatheringInformation.isInterpolated == false ?
                                                                <span className="ml-1"><FontAwesomeIcon icon={faUserCircle} /></span> : <span className="ml-3"></span>

                                                            }

                                                        </td>
                                                        <td className="text-center">{(unit.models.priceM2) ? format(unit.models.priceM2, 0) : "-"}</td>
                                                        <td className="text-center">
                                                            <Dropdown value={unit.unitsGatheringInformation.saleStatusID} options={catalogs.saleStatus} onChange={(e) => { onUnitChange(e, "saleStatusID", indexLevel, indexUnit) }} optionValue="saleStatusID" optionLabel="description" className={`dropdown-color-${unit.unitsGatheringInformation.saleStatusID}`} />
                                                        </td>
                                                        <td className="text-center">
                                                            <InputNumeric paramName="rentalPrice" defaultValue={unit.unitsGatheringInformation.rentalPrice ? unit.unitsGatheringInformation.rentalPrice : ''} maxlength={12} decimal={2} handleValue={(paramName, e) => { onUnitChangeNumeric(e, paramName, indexLevel, indexUnit) }} datacy={unit.code} />
                                                        </td>
                                                        <td className="text-center ">
                                                            <Dropdown value={unit.unitsGatheringInformation.rentalStatusID} options={catalogs.rentalStatus} onChange={(e) => { onUnitChange(e, "rentalStatusID", indexLevel, indexUnit) }} optionValue="rentalStatusID" optionLabel="description" className={`dropdown-color-rent-${unit.unitsGatheringInformation.rentalStatusID}`} />
                                                        </td>
                                                        <td className="text-center w-300">
                                                            <Dropdown value={unit.unitsGatheringInformation.subCategoryID} options={categoryByGathering[`${unit.unitID}`]} onChange={(e) => { onUnitChange(e, "subCategoryID", indexLevel, indexUnit) }} optionValue="subCategoryID" optionLabel="description" />
                                                        </td>
                                                        <td className="text-center w-300">
                                                            <input value={unit.unitsGatheringInformation.brandName} onChange={(e) => { onUnitChangeNumeric(e.target, 'brandName', indexLevel, indexUnit) }} name="brandName" className="d-block w-300" type="text" maxLength="100" />
                                                        </td>
                                                        <td className="text-center">
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text">
                                                                        {(formData.isSeparationPercentage) ? "%" : "$"}
                                                                    </span>
                                                                </div>
                                                                {(isSold(unit) && !gatheringInformationID) ?
                                                                    <InputNumeric paramName="separationValue" defaultValue={unit.unitsGatheringInformation.separationValue ? unit.unitsGatheringInformation.separationValue : ''} isDisabled={true} />
                                                                    :
                                                                    <InputNumeric paramName="separationValue" defaultValue={unit.unitsGatheringInformation.separationValue ? unit.unitsGatheringInformation.separationValue : ''} maxlength={(formData.isSeparationPercentage) ? 3 : 12} decimal={(formData.isSeparationPercentage) ? 0 : 2} handleValue={(paramName, e) => { onUnitChangeNumeric(e, paramName, indexLevel, indexUnit) }} isDisabled={isSold(unit)} maxValue={(formData.isSeparationPercentage) ? 100 : false} />
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr >
                                                </>
                                            ))}
                                        </>
                                    )
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="row mt-5">
                    <hr />
                    <div className="col-6 offset-6 d-flex justify-content-end">
                        {(!formData.closedAt || formData.closedAt == "-") ?
                            <>
                                <button onClick={() => { sendForm() }} data-cy="saveControl" className="btn btn-primary w-100 mx-3">Guardar</button>
                                <button onClick={async () => {

                                    if (await validateCloseForm()) {
                                        await setShowConfirmSaveAndClose(true)
                                    } else {
                                        setIsSubmited(true);
                                    }

                                }} disabled={!(gatheringInformationID)} data-cy="closeControl" className="btn btn-primary w-100">Cerrar levantamiento</button>
                            </>
                            :
                            <>
                                <button onClick={() => { resetModal() }} className="btn btn-primary w-100 mx-3">Cancelar</button>
                                <button onClick={async () => {
                                    if (await validateCloseForm()) {
                                        setShowConfirmSave(true)

                                    } else {
                                        setIsSubmited(true);
                                    }

                                }} data-cy="updateControl" className="btn btn-primary w-100">Actualizar levantamiento</button>
                            </>
                        }
                    </div>
                </div>
            </Modal.Body>
        </Modal >
    )
}
export default ControlForm