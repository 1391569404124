import React, { useEffect, useState } from 'react';
import { useRouteMatch } from "react-router-dom";
//ACTIONS AND CONTEXT
import { useMainContext } from "../../../contexts/MainContext";
//COMPONENTS
import ButtonModalFiles from '../../molecules/ButtonModalFiles';
//ASSETS
import buildingIcon from '../../../assets/icons/icon-building.svg';
import homeImg from '../../../assets/icons/icon-home.svg';

const MenuSales = ({ linkActive, phaseID, phaseData, hasUnits }) => {
    let mainContext = useMainContext();
    let { path } = useRouteMatch();

    const [phaseName, setPhaseName] = useState("");

    useEffect(() => {
        mainContext.setIsDirty(false);
    }, []);

    useEffect(() => {
        if (phaseData.phaseID)
            setTitle();
        else
            createTitle();
    }, [phaseData]);

    const setTitle = () => {
        let name = "COMPLEJO - NUEVA TORRE";
        if (phaseData.developerComplex)
            name = phaseData.developerComplex.complex.complexName;
        name += " - " + phaseData.phaseName;
        setPhaseName(name);
    }

    const createTitle = () => {
        let name = "COMPLEJO - NUEVA TORRE";
        if (phaseData.complexID)
            name = phaseData.complexID.complexName;
        if (phaseData.complexID)
            name += " - " + phaseData.phaseName;
        setPhaseName(name);
    }

    const requestNavigation = (goTo) => {
        let goToArr = goTo.split("/");
        let pathArr = path.split("/");
        if (goToArr[goToArr.length - 1] == pathArr[pathArr.length - 1])
            return;
        if (phaseID || goTo == "/#home")
            mainContext.setGoTo(goTo);
    }

    //Menu
    const getCSSClass = (link) => {
        let _className = "";
        if (linkActive == link)
            _className += "active-tab ";
        if (!phaseID)
            _className += "disabled";
        //_className = "btn btn-white mr-3 btn-submenu ";

        return _className;
    }

    return (
        <>
            <div className="row">
                <h2 className="col py-5 break-word">{phaseName}</h2>
            </div>
            <div className="row mb-5">
                <div className="col col-8">
                    <div className={`nav nav-fill`}>
                        <div className="nav-item">
                            <a onClick={() => { requestNavigation("/#phase/salesStatus") }} className={`nav-link pointer ${getCSSClass("salesStatus")}`} data-cy="link-status"><span>Estado de ventas</span></a>
                        </div>
                        <div className="nav-item">
                            <a onClick={() => { requestNavigation("/#phase/conditions") }} className={`nav-link pointer ${getCSSClass("conditions")}`} data-cy="link-conditions"><span>Condiciones comerciales</span></a>
                        </div>
                        <div className="nav-item">
                            <a onClick={() => { requestNavigation("/#phase/control") }} className={`nav-link pointer ${getCSSClass("control")}`} to="/" data-cy="link-control"><span>Control de levantamientos</span></a>
                        </div>
                    </div>
                </div>
                <div className="col col-4">
                    <div className="d-flex justify-content-end">
                        <ButtonModalFiles phaseID={phaseID} projectID={(phaseData.projects) ? phaseData.projects.projectID : null} />
                        <button onClick={() => { requestNavigation("/#phase/general") }} className={`btn btn-white mr-3 btn-submenu`}>
                            <img src={buildingIcon} alt="" />
                        </button>
                        <button onClick={() => { requestNavigation("/#home") }} className="btn btn-white btn-submenu">
                            <img className="" src={homeImg} alt="" />
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default MenuSales;