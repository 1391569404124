const axios = require('axios');
const SERVER_URL = `${process.env.REACT_APP_SERVER_URL}/api`;

class AttachedFiles {
  constructor() { }

  async getFileTypes() {
    try {
      const response = await axios({
        method: 'get',
        url: SERVER_URL + "/getFileTypes",
      })
        .then(response => {
          return JSON.parse(response.data.data);
        })
        .catch(error => {
          throw error.response.data.error;
        })
      return response;

    } catch (error) {
      throw error;
    }
  }

  async getAttachedFiles(data) {
    try {
      const response = await axios({
        method: 'post',
        url: SERVER_URL + "/getPhaseFiles",
        data: data
      })
        .then(response => {
          return response.data.data;
        })
        .catch(error => {
          throw error.response.data.error;
        })
      return response;

    } catch (error) {
      throw error;
    }
  }

  async saveAttachedFile(data) {
    try {
      const response = await axios({
        method: 'post',
        url: SERVER_URL + "/savePhaseFiles",
        data: data
      })
        .then(response => {
          return response.data.data;
        })
        .catch(error => {
          throw error.response.data.error;
        })
      return response;

    } catch (error) {
      throw error;
    }
  }

  async deleteAttachedFile(data) {
    try {
      const response = await axios({
        method: 'post',
        url: SERVER_URL + "/deletePhaseFiles",
        data: data
      })
        .then(response => {
          return response.data.data;
        })
        .catch(error => {
          throw error.response.data.error;
        })
      return response;

    } catch (error) {
      throw error;
    }
  }
}
export default AttachedFiles