

import React, { createRef, useEffect, useState, useRef, useCallback } from 'react';

import './styles.css'
import Axios from 'axios';
import GoogleMapReact from 'google-map-react';
import context from 'react-bootstrap/esm/AccordionContext';
import { GoogleMap, DrawingManager, Polygon, Marker, StandaloneSearchBox } from "@react-google-maps/api";

const AnyReactComponent = () => <><img style={{ height: "15px" }} src={require('../../../assets/icons/pin.svg')} /></>;

const icon = {
  path: "../../../assets/icons/pin.svg'",
  scale: 4,
  fillColor: "#ff5050", //<-- I want to change this after creation
  fillOpacity: 1,
  strokeWeight: 1,
  rotation: 0 //<-- I want to change this after creation
};

const MapView = (props) => {

  const polygonRef = useRef(null)
  const listenersRef = useRef([]);
  const { generalInfoContext, handler, handlerPolygon, center, zoom, initMarker, initPolygon } = props

  const [currentLocation, setCurrentLocation] = useState({ lat: 25.695143, lng: -100.311003 })
  const [marker, setMarker] = useState({ lat: 25.695143, lng: -100.311003 })
  const [polygonArray, setPolygonArray] = useState([])
  const [initPolygonComp, setInitPolygonComp] = useState(false)
  const [centerMap, setCenterMap] = useState({ lat: 25.695143, lng: -100.311003 })
  const [drawingControlOption, setDrawingControlOption] = useState(true)
  const [drawingModeDefault, setDrawingModeDefault] = useState(null)
  const [searchBox, setSearchBox] = useState(null)
  const [zoomMap, setZoomMap] = useState(null)


  const [managerOptions, setManagerOptions] = useState({
    style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
    position: window.google.maps.ControlPosition.TOP_CENTER,
    drawingModes: [
      window.google.maps.drawing.OverlayType.POLYGON,
    ]
  })

  useEffect(() => {
    setZoomMap(zoom)
    if (initMarker)
      setMarker(initMarker);
    else
      setMarker({ lat: 25.695143, lng: -100.311003 });

    if (initPolygon) {
      setPolygonArray(initPolygon)
      setManagerOptions({ ...managerOptions, drawingModes: [] })
      setDrawingControlOption(false)
      setInitPolygonComp(true)
    }
  }, [initMarker, initPolygon])

  useEffect(() => {
    if (center == null) {
      setCenterMap({ lat: 25.695143, lng: -100.311003 })
    } else {
      setCenterMap(center)
    }
  }, [center])



  const newPolygon = () => {
    setDrawingModeDefault(window.google.maps.drawing.OverlayType.POLYGON)
    setManagerOptions({ ...managerOptions, drawingModes: [window.google.maps.drawing.OverlayType.POLYGON,] })
    setDrawingControlOption(true)
    setInitPolygonComp(false)

  }

  const deletePolygon = () => {
    setDrawingModeDefault(null)
    setManagerOptions({ ...managerOptions, drawingModes: [window.google.maps.drawing.OverlayType.POLYGON,] })
    handlerPolygon(null)
    setDrawingControlOption(true)
    setInitPolygonComp(false)
    setPolygonArray([])
    handler("", "")
  }
  const onMarkerComplete = marker => {
  }

  const onPolygonComplete = polygon => {
    let path = polygon.getPath()
    let polygonArrayComplete = [];
    let center = getPolygonCenter(polygon);
    setMarker(center)
    handler(center.lat, center.lng)
    polygon.getPath().forEach((point) => {
      polygonArrayComplete.push({
        lat: point.lat(),
        lng: point.lng()
      })
    })

    handlerPolygon(JSON.stringify(polygonArrayComplete))
    setPolygonArray(polygonArrayComplete)

    path.addListener("remove_at", () => {

    })
    path.addListener("insert_at", () => {

    })
    path.addListener("set_at", () => {

    })

    setManagerOptions({ ...managerOptions, drawingModes: [] })
    setDrawingControlOption(false)
    setDrawingModeDefault(null)
    polygon.setMap(null);
  }

  const getPolygonCenter = (polygon) => {
    // Getting lowest and highest point
    const lowest = { lat: null, lng: null };
    const highest = { lat: null, lng: null };
    let lat, lng;
    polygon.getPath().forEach((point) => {

      lat = point.lat();
      lng = point.lng();
      if (lowest.lat === null || lat < lowest.lat) {
        lowest.lat = lat;
      }
      if (lowest.lng === null || lng < lowest.lng) {
        lowest.lng = lng;
      }
      if (highest.lat === null || lat > highest.lat) {
        highest.lat = lat;
      }
      if (highest.lng === null || lng > highest.lng) {
        highest.lng = lng;
      }
    });
    const center = { lat: null, lng: null };
    center.lat = lowest.lat + ((highest.lat - lowest.lat) / 2);
    center.lng = lowest.lng + ((highest.lng - lowest.lng) / 2);
    return center;
  }

  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => latLng.toJSON());
      setPolygonArray(nextPath);
      handlerPolygon(JSON.stringify(nextPath))
      let center = getPolygonCenter(polygonRef.current);
      setMarker(center)
      handler(center.lat, center.lng)
    }
  }, [setPolygonArray]);

  const onLoad = useCallback(
    (polyline) => {
      polygonRef.current = polyline;
      const path = polyline.getPath();
      listenersRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", onEdit),
        path.addListener("remove_at", onEdit)
      );
    },
    [onEdit]
  );

  const onLoadSearchBox = ref => setSearchBox(ref);


  const onPlacesChanged = () => {

    let place = searchBox.getPlaces()
    let numPlaces = place[0]?.address_components?.length
    let zoomBySearch = {
      "2": 7,
      "3": 12,
      "4": 13,
      "5": 14,
      "6": 18,
      "7": 20
    }
    setZoomMap(numPlaces <= 7 ? zoomBySearch[numPlaces] : 20)
    handler(place[0].geometry.location.lat(), place[0].geometry.location.lng())
  };

  return (
    <div style={{ height: '600px', width: '100%', border: '1px solid #000' }}>
      {drawingControlOption ? null : (<div className="new-polygon">
        <button onClick={() => { newPolygon() }} className="btn  btn-action-map">Nuevo </button>
        {polygonArray.length > 0 ? <button onClick={() => { deletePolygon() }} className="btn btn-action-map">Borrar</button> : null}
        {/*<button onClick={() => { }} className="btn btn-white ">buscar</button>*/}
      </div>)}

      <GoogleMap
        center={centerMap}
        zoom={zoomMap}

        mapContainerStyle={{ height: "600px", width: "100%" }}
      >
        <DrawingManager
          options={{
            drawingMode: drawingModeDefault,
            drawingControl: drawingControlOption,
            drawingControlOptions: managerOptions,
            polygonOptions: {
              fillColor: "#199ee0",
              fillOpacity: 0.2,
              strokeWeight: 2,
              strokeColor: "#113460",
              clickable: true,
              editable: true,
              geodesic: false,
              visible: true,
              zIndex: 1
            },

          }}
          onPolygonComplete={onPolygonComplete}
          onMarkerComplete={onMarkerComplete}
        />
        <Marker
          position={marker}
          icon={require('../../../assets/icons/pin.png')}

          animation={'DROP'}

        />
        <Polygon
          path={polygonArray}
          ref={polygonRef}
          key={1}
          editable={true}
          options={{
            fillColor: "#199ee0",
            fillOpacity: 0.2,
            strokeWeight: 2,
            strokeColor: "#113460",
            clickable: true,
            editable: true,
            geodesic: false,
            visible: true,
            zIndex: 1
          }}
          onMouseUp={onEdit}
          onLoad={onLoad}

        />

        <StandaloneSearchBox
          onLoad={onLoadSearchBox}
          onPlacesChanged={
            onPlacesChanged
          }
        >
          <input
            type="text"
            placeholder="Buscar"
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `350px`,
              height: `39px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
              position: "relative",
              left: "50%",
              marginTop: "10px",
              marginLeft: "100px",
              zIndex: 10
            }}
          />
        </StandaloneSearchBox>
      </GoogleMap>
    </div>
  );
}


export default MapView;