import './styles.css'

import React, { useEffect, useState } from 'react'

import { useDropzone } from 'react-dropzone'
import { useGeneralInfoContext } from "../../../contexts/GeneralInfoContext";

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 0
};

const thumb = {
  lg: {
    display: 'inline-flex',
    borderRadius: 2,
    width: 'auto',
    height: '138px',
    boxSizing: 'border-box'
  },
  sm: {
    display: 'inline-flex',
    borderRadius: 2,
    width: 'auto',
    height: '40px',
    boxSizing: 'border-box'
  }
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
  maxHeight: "97%"
};

const img = {
  lg: {
    display: 'block',
    width: '100%',
    height: '100%'
  },
  sm: {
    display: 'block',
    width: '30px',
    height: '40px'
  }
};
const UploadImageComponent = (props) => {
  const { images,handleSetImages, propertyName, size, zone, getImageHandler, image } = props
  const [files, setFiles] = useState([]);
  let generalInfoContext = useGeneralInfoContext()
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: 'image/*',
    onDrop: async acceptedFiles => {
      if (acceptedFiles.length > 0) {
      }
      getBase64(acceptedFiles[0]).then((result) => {
        let imageObject = {
          body: result,
          name: propertyName
        }
        switch (zone) {
          case "generalInfo":
            let imagesContext = [...images];
            let indexImage = imagesContext.findIndex((image) => image.name === propertyName);
            if (indexImage >= 0) {
              imagesContext[indexImage] = imageObject
            } else {
              imagesContext.push(imageObject)
            }
            //handleSetImages(imagesContext);
            if(getImageHandler)
              getImageHandler(imagesContext);
            break;
          case "promotions":
            props.setImage(imageObject)
          case "amenities":
            props.setImage(imageObject.body)
        }

      })

      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });


  const getBase64 = async (file) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);

    return new Promise((resolve, reject) => {
      reader.onload = () => {
        resolve(reader.result)
      };
      reader.onerror = function (error) {
        reject(error)
      };
    })
  }

  const thumbs = files.map(file => (
    <div style={thumb[size]} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img[size]}
        />
      </div>
    </div>
  ));

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />

        <div className={`upload-image upload-image-${size}`}>
          <aside style={thumbsContainer}>
            {(files.length) ? thumbs :


              <>
                {
                  (image != null) &&
                  <div style={thumb[size]}>
                    <div style={thumbInner}>
                      <img style={img[size]} src={process.env.REACT_APP_BUCKET_URL + image} />
                    </div>
                  </div>
                }
              </>
            }
          </aside>
        </div>
      </div>
    </>
  );
}

export default UploadImageComponent