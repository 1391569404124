import React, { useEffect, useState } from 'react';
import { useRouteMatch } from "react-router-dom";
//ACTIONS AND CONTEXT
import { useMainContext } from "../../../contexts/MainContext";
//COMPONENTS
import ButtonModalFiles from '../ButtonModalFiles';
//ASSETS
import buildingIcon from '../../../assets/icons/icon-building.svg';
import homeImg from '../../../assets/icons/icon-home.svg';

const MenuAdmin = ({ linkActive, name }) => {
    let mainContext = useMainContext();
    let { path } = useRouteMatch();

    const [pageName, setPageName] = useState(name);

    useEffect(() => {
        mainContext.setIsDirty(false);
    }, []);

    useEffect(() => {

    }, []);





    const requestNavigation = (goTo) => {
        let goToArr = goTo.split("/");
        let pathArr = path.split("/");
        if (goToArr[goToArr.length - 1] == pathArr[pathArr.length - 1])
            return;

        window.location.href = goTo;
    }

    //Menu
    const getCSSClass = (link) => {
        let _className = "";
        if (linkActive == link)
            _className += "active-tab ";
        //_className = "btn btn-white mr-3 btn-submenu ";

        return _className;
    }

    return (
        <>
            <div className="row">
                <h2 className="col py-5 break-word">{"Administración"}</h2>
            </div>
            <div className="row mb-5">
                <div className="col col-8">
                    <div className={`nav nav-fill`}>
                        <div className="nav-item">
                            <a onClick={() => { requestNavigation("#/admin/users") }} className={`nav-link pointer ${getCSSClass("users")}`} data-cy="link-status"><span>Usuarios</span></a>
                        </div>
                        <div className="nav-item">
                            <a onClick={() => { requestNavigation("#/admin/offices") }} className={`nav-link pointer ${getCSSClass("office")}`} data-cy="link-status"><span>Oficinas</span></a>
                        </div>
                        <div className="nav-item">
                            <a onClick={() => { requestNavigation("#/admin/cities") }} className={`nav-link pointer ${getCSSClass("cities")}`} data-cy="link-status"><span>Ciudades</span></a>
                        </div>
                        <div className="nav-item">
                            <a onClick={() => { requestNavigation("#/admin/general-catalogs") }} className={`nav-link pointer ${getCSSClass("general-catalogs")}`} data-cy="link-status"><span>Catálogos Generales</span></a>
                        </div>
                        <div className="nav-item">
                            <a onClick={() => { requestNavigation("#/admin/importer") }} className={`nav-link pointer ${getCSSClass("importer")}`} data-cy="link-status"><span>Importador</span></a>
                        </div>


                    </div>
                </div>
                <div className="col col-4">
                    <div className="d-flex justify-content-end">


                        <button onClick={() => { requestNavigation("/#home") }} className="btn btn-white btn-submenu">
                            <img className="" src={homeImg} alt="" />
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default MenuAdmin;