import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearchLocation, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
//ACTIONS AND CONTEXT
import { useCatalogsContext } from "../../../contexts/CatalogsContext";
import Dropdown from "../../../components/atoms/Dropdown";
var _ = require('lodash');

/**
    * Componente SearchEngineFilters
    * @component
    * @param  props
    * @param {function} props.handlerFilters - Maneja el cambio de datos 
    * 
*/
const SearchEngineFilters = ({handlerFilters}) => 
{
    let catalogs = useCatalogsContext();
    
    const [localCatalogs, setLocalCatalogs] = useState({states:[], municipalities:[], zones:[]});
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedMunicipality, setSelectedMunicipality] = useState(null);
    const [selectedZone, setSelectedZone] = useState(null);
    const [selectedDeveloper, setSelectedDeveloper] = useState(null);
    const [phaseName, setPhaseName] = useState('');

    /**
        * Maneja el cambio de datps en el campo pais del formulario
        * @param {object} props.event - valor del campo pais en el formulario.
        * 
    */
    const onCountryChange = async (e) => 
    {
        setSelectedCountry(e.value);
        onStateChange({value:null});
        if(e.value != null && e.value.states != null)
        {
            await setLocalCatalogs({...localCatalogs, states:e.value.states});
        }
        else
        {
            setLocalCatalogs({...localCatalogs, states:[], municipalities:[], zones:[]});
        }
    }

    /**
        * Maneja el cambio de datps en el campo estado del formulario
        * @param {object} props.event - valor del campo estado en el formulario.
        * 
    */
    const onStateChange = async (e) => 
    {
        setSelectedState(e.value);
        onMunicipalityChange({value:null});
        if(e.value != null)
        {
            await setLocalCatalogs({...localCatalogs, municipalities:e.value.municipalities, zones:_.uniqBy(e.value.zones,'zoneID')});
        }
        else
        {
            setLocalCatalogs({...localCatalogs, municipalities:[], zones:[]});
            onZoneChange({value:null});
        }
    }

    /**
        * Maneja el cambio de datps en el campo municipio del formulario
        * @param {object} props.event - valor del campo municipio en el formulario.
        * 
    */
    const onMunicipalityChange = async (e) => 
    {
        setSelectedMunicipality(e.value);
    }

    /**
        * Maneja el cambio de datps en el campo zona del formulario
        * @param {object} props.event - valor del campo zona en el formulario.
        * 
    */
    const onZoneChange = async (e) => 
    {
        setSelectedZone(e.value);
    }

    /**
        * Maneja el cambio de datps en el campo desarrollador del formulario
        * @param {object} props.event - valor del campo desarrollador en el formulario.
        * 
    */
    const onDeveloperChange = async (e) => 
    {
        setSelectedDeveloper(e.value);
    }

    /**
        * Crea un objeto con los filtros seleccionados.
        * y ejecuta la funcion handlerFilters
    */
    const search = async () => 
    {
        let filters = {};
        if(phaseName != "") filters["phaseName"] = phaseName;
        if(selectedCountry) filters["countryID"] = selectedCountry.countryID;
        if(selectedState) filters["stateID"] = selectedState.stateID;
        if(selectedMunicipality) filters["municipalityID"] = selectedMunicipality.municipalityID;
        if(selectedZone) filters["zoneID"] = selectedZone.zoneID;
        if(selectedDeveloper) filters["developerID"] = selectedDeveloper.developerID;
        filters["pageNumber"] = 1;
        handlerFilters(filters);
    }

    /**
        * Limpia el formulario.
        * y ejecuta la funcion handlerFilters.
    */
    const cleanFilters = async () => 
    {
        onCountryChange({value:null});
        setSelectedDeveloper(null);
        setPhaseName("");
        handlerFilters({}, true);
    }
    
    return (
        <div className="row">
            <div className="col col-5 d-flex">
                <div className="mr-3 w-50">
                    <label><b>Nombre proyecto</b></label>
                    <input value={phaseName} onChange={e => setPhaseName(e.target.value)} className="form-control"/>  
                </div>
                <div className="mr-5 w-50">
                    <label><b>Desarrollador</b></label>
                    <Dropdown value={selectedDeveloper} options={catalogs.developers} onChange={onDeveloperChange} optionLabel="developerName" optionValue="developerID" disabled={(!catalogs.developers)}/>
                </div>
            </div>
            <div className="col col-7 d-flex">
                <div className="mr-3 w-25">
                    <label><b>País</b></label>
                    <Dropdown value={selectedCountry} options={catalogs.countries} onChange={onCountryChange} optionLabel="countryName" optionValue="countryID"/>
                </div>

                <div className="mr-3 w-25">
                    <label><b>Estado</b></label>
                    <Dropdown value={selectedState} options={localCatalogs.states} onChange={onStateChange} optionLabel="stateName" optionValue="stateID" disabled={(!localCatalogs.states.length)}/>
                </div>
                <div className="mr-3 w-50 position-relative">
                    <div className="mr-3 filter-two-rows-text">
                        <label className="po"><b>Delegación / Municipio</b></label>
                        <Dropdown value={selectedMunicipality} options={localCatalogs.municipalities} onChange={onMunicipalityChange} optionLabel="municipalityName" optionValue="municipalityID" disabled={(!localCatalogs.municipalities.length)}/>  
                    </div>
                </div>
                <div className="w-100px pt-3">
                    <span className="btn-icon mt-3 mr-3"><FontAwesomeIcon size="2x" onClick={search} icon={faSearchLocation} /></span>
                    <span className="btn-icon mt-3"><FontAwesomeIcon size="2x" onClick={cleanFilters} icon={faTrashAlt} /></span>
                </div>
            </div>
        </div>
    )
}
export default SearchEngineFilters