import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 0
};

const thumb = {
  lg: {
    display: 'inline-flex',
    borderRadius: 2,
    width: 'auto',
    height: '138px',
    boxSizing: 'border-box'
  },
  sm: {
    display: 'inline-flex',
    borderRadius: 2,
    width: 'auto',
    height: '40px',
    boxSizing: 'border-box'
  }
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
  maxHeight: "97%"
};

const img = {
  lg: {
    display: 'block',
    width: '100%',
    height: '100%'
  },
  sm: {
    display: 'block',
    width: '30px',
    height: '40px'
  }
};
/**
    * Renders a <InputDropZoneImage /> List of checkboxes
    * @component
    * @param  props
    * @param {string} props.propertyName - Value name.
    * @param {array}  props.size - Image size (css).
    * @param {function} props.getImageHandler - Recieve image data.
    * @param {string} props.image - Image name.
*/
const InputDropZoneImage = ({ propertyName, size, getImageHandler, image }) => 
{
    const [files, setFiles] = useState([]);
    
    useEffect(() => () => 
    {
        files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    /**
        * Dropzone function
    */
    const { getRootProps, getInputProps } = useDropzone({
        multiple: false,
        accept: 'image/*',
        onDrop: async acceptedFiles => 
        {
            getBase64(acceptedFiles[0]).then((result) => 
            {
                let imageObject = {
                    body: result,
                    name: propertyName
                }
                getImageHandler(imageObject);
            })

            setFiles(acceptedFiles.map(file => Object.assign(file, {
              preview: URL.createObjectURL(file)
            })));
        }
    });

    /**
        * Convert image in base64 format.
        * @param {file} file - Image file.
        * @return {base64} File in base64 format.
    */
    const getBase64 = async (file) => 
    {
        var reader = new FileReader();
        reader.readAsDataURL(file);

        return new Promise((resolve, reject) => {
            reader.onload = () => {
                resolve(reader.result)
            };
            reader.onerror = function (error) {
                reject(error)
            };
        })
    }

    /**
        * Renders thumbnails list.
    */
  const thumbs = files.map(file => (
      <div className="dropzone-thumb d-flex justify-content-center">
        <img
          src={file.preview}
          style={img[size]}
        />
      </div>
  ));


  return (
    <>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />

        <div className={`upload-image upload-image-${size}`}>
            {(files.length) ? thumbs :
              <>
                {
                  (image) &&
                    <div className="dropzone-thumb d-flex justify-content-center">
                        <img style={img[size]} src={process.env.REACT_APP_BUCKET_URL + image} />
                    </div>
                }
              </>
            }
        </div>
      </div>
    </>
  );
}

export default InputDropZoneImage