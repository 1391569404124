import React from "react";
import './index.css';

function ItemLoading() {
    return (
        <React.Fragment>
            <li className='itemLoading'>
                <p>Cargando...</p>
            </li>
            <li className='itemLoading'>
                <p>Cargando...</p>
            </li>
            <li className='itemLoading'>
                <p>Cargando....</p>
            </li>
            <li className='itemLoading'>
                <p>Cargando....</p>
            </li>
            <li className='itemLoading'>
                <p>Cargando....</p>
            </li>
        </React.Fragment>
    );
}
export { ItemLoading };