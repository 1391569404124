import React, { useEffect, useState } from 'react';

import { useMainContext } from "../../../contexts/MainContext";
import { RestActions } from "../../../actions";
import MenuPhases from '../../../components/molecules/MenuAdmin';
import TableUsers from './TableUsers';
import ModalCreateUser from '../../../components/atoms/ModalCreateUser';
import ModalConfirmImport from "../../../components/atoms/ModalConfirmImport";
import ModalConfirmGeneral from "../../../components/atoms/ModalConfirmGeneral";
import exclamationIcon from '../../../assets/icons/icon-exclamation-mark.svg';
import checkIcon from '../../../assets/icons/icon-check.svg';
import warningIcon from '../../../assets/icons/icon-warning.svg';

const Index = ({ preloadReady }) => {
	const rest = new RestActions();
	const [showModal, setShowModal] = useState(false);
	const [dataTable, setDataTable] = useState({});
	const [pageLimit, setPageLimit] = useState(10);
	const [showModalLogs, setShowModalLogs] = useState(false);
	const [logsModalData, setLogsModalData] = useState(false);
	const [showModalImportError, setShowModalImportError] = useState(false);
	const [showModalError, setShowModalError] = useState(false);
	const [showModalImportSuccess, setShowModalImportSuccess] = useState(false);
	const [showModalImportWarnings, setShowModalImportWarnings] = useState(false);
	const [showModalImportWarningsList, setShowModalImportWarningsList] = useState(false);
	const [importedFileId, setImportedFileId] = useState("")
	const [edit, setEdit] = useState(false)
	const [dataUser, setDataUser] = useState({})
	const [showModalDelete, setShowModalDelete] = useState(false);
	const [idItem, setIdItem] = useState(false);
	const [deleteEndpoint, setDeleteEndpoint] = useState(false);
	let mainContext = useMainContext();

	useEffect(() => {
		if (preloadReady) {
			initTable(1)
		}

	}, [preloadReady])

	const initTable = async (_pageNumber) => {
		mainContext.setIsLoading(true);
		try {
			let response = await rest.post("getUsers", {
				pageLimit: pageLimit, pageNumber: _pageNumber
			});
			setDataTable(response);
			mainContext.setIsLoading(false);

		}
		catch (error) {/*TODO SOMETHING*/ }
	}

	const shwoModalLogs = (logs) => {
		setShowModalLogs(true)
		setLogsModalData(logs.logs)
	}
	const showModalEditUser = async (id) => {
		setEdit(true)
		mainContext.setIsLoading(true);
		try {
			let response = await rest.post("getUser", {
				userID: id
			});

			setDataUser(response)
			setShowModal(true)
			mainContext.setIsLoading(false);

		}
		catch (error) {/*TODO SOMETHING*/ }

	}
	const saveUser = async (formaData) => {
		console.log("formaData ", formaData);
		mainContext.setIsLoading(true);
		if (!edit)
			try {
				let response = await rest.post("createUser", {
					fullName: formaData.fullName,
					officeID: formaData.office.officeID,
					roleID: formaData.rol.roleID,
					status: formaData.status.id == 1 ? true : false,
					createdBy: mainContext.session.userID,
					userName: formaData.userName
				});
				closeModal()
				mainContext.setMessageAlert("Usuario creado correctamente");
				initTable(1)
			}
			catch (error) {
				console.log("error ", error);
				closeModal()
				mainContext.setMessageAlert(error);
				initTable(1)
			}
		else
			try {
				let response = await rest.post("updateUser", {
					fullName: formaData.fullName.trim(),
					officeID: formaData?.office?.officeID,
					roleID: formaData.rol.roleID,
					status: formaData.status.id == 1 ? true : false,
					userID: formaData.userID,
					userName: formaData.userName.trim()
				});
				closeModal()
				mainContext.setMessageAlert("Usuario actualizado correctamente");
				initTable(1)
			}
			catch (error) {
				closeModal()
				mainContext.setIsLoading(false);
				mainContext.setMessageAlert(error);
			}
	}

	const openModalDelete = async (itemID, endpoint) => {
		setIdItem(itemID)
		setDeleteEndpoint(endpoint)
		setShowModalDelete(true)
	}
	const acceptDeleteItem = async () => {
		let deleteParams = {}


		if (deleteEndpoint == "deleteUser")
			deleteParams = {
				userID: idItem,
			}



		try {
			let response = await rest.post(deleteEndpoint, deleteParams);
			closeModal()
			mainContext.setMessageAlert("Elemento  borrado correctamente");
			initTable(1) // actualiza la tabla de catalogos

		}
		catch (error) {
			closeModal()
			mainContext.setIsLoading(false);
			mainContext.setMessageAlert(error);
		}
	}
	const closeModal = () => {
		setShowModalDelete(false)
		setShowModalImportError(false)
		setShowModalImportSuccess(false)
		setShowModalImportWarnings(false)
		setShowModal(false)
		setEdit(false)
		mainContext.setIsLoading(false);
	}
	return (
		<>
			<MenuPhases linkActive={"users"} />
			<ModalCreateUser showModal={showModal} handlerAccept={saveUser} setShow={closeModal} dataUser={dataUser} edit={edit} />
			<ModalConfirmImport show={showModalImportError} icon={exclamationIcon} handleConfirm={closeModal} footerButtons={false}>
				<div className="col">
					<p className="title-modal m-0">{showModalImportWarningsList.modelsCount} Modelos</p>
					<p className="title-modal m-0">{showModalImportWarningsList.projectCount} Proyectos</p>
					<p className="title-modal"><b>Errores</b></p>
					<p className="modal-content-list overflow-auto h-200">
						{showModalImportWarningsList.errorMsg && showModalImportWarningsList.errorMsg.map((el) => <li className="text-left mb-2">{el}</li>)}
					</p>

					{showModalImportWarningsList.warningMsg && (
						<>
							<p className="title-modal"><b>Warnings</b></p>
							<p className="modal-content-list overflow-auto h-200">
								{showModalImportWarningsList.warningMsg && showModalImportWarningsList.warningMsg.map((el) => <li className="text-left mb-2">{el}</li>)}
							</p>
						</>
					)}
				</div>
			</ModalConfirmImport>

			<ModalConfirmImport size="sm" show={showModalDelete} icon={exclamationIcon} handleCancel={closeModal} handleConfirm={acceptDeleteItem}>
				<div className="col">
					<p className="title-modal"><b>¿ Esta seguro de eliminar este Usuario ?</b></p>
				</div>
			</ModalConfirmImport>
			{/*<ModalConfirmImport show={showModalLogs} footerButtons={true} size="sm" onCancel={() => { setShowModalLogs(false) }} handleConfirm={() => { confirmDeleteUser() }}>
				<div className="col">

					<h4 className="py-3">¿Desea eliminar el usuario?	</h4>

				</div>
			</ModalConfirmImport>*/}
			<div className="custom-card">
				<div className="custom-card-header px-3 d-flex">
					<h4 className="py-3 w-50">USUARIOS	</h4>
					<div className="w-50 py-2 d-flex justify-content-end">
						<button onClick={() => { setShowModal(true) }} className="btn btn-primary"><span className="d-inline-block pr-4">Nuevo usuario</span> +</button>
					</div>
				</div>
				<div className="custom-card-body">
					<div className="table-scroll-y table-scroll-y-scrollable">
						<div className="table-responsive h-100">
							<TableUsers handleRefresh={initTable} data={dataTable} openDeletModal={openModalDelete} openEditModal={showModalEditUser} />

						</div>
					</div>
				</div>
			</div>
		</>

	)
}

export default Index
