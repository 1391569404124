import React, { useState, useEffect } from 'react'
// import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
// import Row from 'react-bootstrap/Row'
import InputGroup from 'react-bootstrap/InputGroup';
import NumberFormat from 'react-number-format';
import Dropdown from "../../../components/atoms/Dropdown";
import { useMainContext } from "../../../contexts/MainContext";
import { useCatalogsContext } from "../../../contexts/CatalogsContext";


const AdditionalFeatures = ({ edit, handlerFormData, phaseData, handlerImageData, isSubmited, initData }) => {



	// --------------------------------------
	// Component State and Contexts
	// --------------------------------------
	const mainContext = useMainContext();
	const catalogs = useCatalogsContext();
	const [formData, setFormData] = useState(null);

	const initCombo = (catalog, filterBy1, filterBy2) => {
		if (catalogs[catalog])
			return catalogs[catalog].filter((element) => { return element[filterBy1] === filterBy2 })[0];
		else
			return null;
	}
	const initComboMulti = (catalog, filterBy1, filterBy2) => {

		if (catalogs[catalog]) {
			let selectedOption = []
			catalogs[catalog].map((el) => {
				filterBy2.map((elSelected) => {
					if (el.elevatorTypeID == elSelected.elevatorTypeID)
						selectedOption.push({
							elevatorTypeID: elSelected.elevatorTypeID,
							description: el.description
						})

				})

			});
			return selectedOption
		} else {
			return null;
		}
	}


	// --------------------------------------
	// Component Load, set Values when the
	// init data is available
	// --------------------------------------
	useEffect(() => {

		if (phaseData.phaseID) {
			initForm();
		} else {
			const data = {
				totalElevators: null,
				elevatorTypeID: null,
				buildingTypeID: null,
				facadeTypeID: null,
				interiorWallTypeID: null,
				dividenWallTypeID: null,
				windowTypeID: null,
				totalParkingLots: null,
				totalVisitorParkingLots: null,
				hasFireProtectionSystem: false
			}
			setFormData({ ...formData, buildingFeatures: data });
		}
		mainContext.setIsLoading(false);
	}, [initData]);


	// --------------------------------------
	// Update the Form Data
	// --------------------------------------
	useEffect(() => {
		handlerFormData(formData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formData])



	// --------------------------------------
	// Set Initial Values
	// --------------------------------------
	const initForm = async () => {

		// ? Get values
		const {

			buldingElevatorTypes, buildingTypeID, facadeTypeID,
			totalElevators, interiorWallTypeID, dividenWallTypeID,
			windowTypeID, totalParkingLots, totalVisitorParkingLots,
			hasFireProtectionSystem
		} = phaseData.buildingFeatures;

		let formattedData = {
			totalElevators: (totalElevators !== null && totalElevators !== undefined) ? totalElevators?.toString() : "",
			buldingElevatorTypes: initComboMulti('elevatorTypes', 'elevatorTypeID', buldingElevatorTypes),
			buildingTypeID: initCombo('buildingTypes', 'buildingTypeID', buildingTypeID),
			facadeTypeID: initCombo('facadeTypes', 'facadeTypeID', facadeTypeID),
			interiorWallTypeID: initCombo('interiorWallTypes', 'interiorWallTypeID', interiorWallTypeID),
			dividenWallTypeID: initCombo('dividenWallTypes', 'dividenWallTypeID', dividenWallTypeID),
			windowTypeID: initCombo('windowTypes', 'windowTypeID', windowTypeID),
			totalParkingLots: (totalParkingLots !== null && totalParkingLots !== undefined) ? totalParkingLots?.toString() : "",
			// totalParkingLots: (totalParkingLots !== "" || totalParkingLots !== null || totalParkingLots !== false) ? `${totalParkingLots}` : "",
			totalVisitorParkingLots: (totalVisitorParkingLots !== null && totalVisitorParkingLots !== undefined) ? totalVisitorParkingLots?.toString() : "",
			hasFireProtectionSystem: hasFireProtectionSystem
		}



		setFormData({ ...formData, buildingFeatures: formattedData });
		//handlerFormData(formData);


	}


	// --------------------------------------
	// Set Data Values on Input Event
	// --------------------------------------
	const onFormDataChange = async (name, event) => {

		const newData = { ...formData.buildingFeatures };

		if (name === 'totalElevators' || name === 'totalParkingLots' || name === 'totalVisitorParkingLots')
			newData[name] = event.target.value;
		else
			newData[name] = event.value;


		mainContext.setIsDirty(true);
		await setFormData({ ...formData, buildingFeatures: newData });

	}


	if (!formData && formData === null)
		return null;

	return (
		<div className="my-4">
			<form autoComplete="off" className="custom-card">
				<div className="custom-card-header  d-flex justify-content-between align-items-center">
					<h4 className="px-3">CARACTERÍSTICAS ESTRUCTURALES Y ADICIONALES</h4>
				</div>
				<div className="custom-card-body px-3">
					<div className="row">
						<div className="col">
							<div className="form-group with-message">
								<label className="input-label form-label mb-4"># de elevadores</label>

								<InputGroup>
									<NumberFormat
										decimalScale={0}
										isAllowed={(value) => {
											if (value.floatValue <= 999)
												return true
											else if (value.formattedValue === '')
												return true
											else
												return false;

										}}
										allowNegative={false}
										thousandSeparator={true}
										max={999}
										value={formData.buildingFeatures.totalElevators}
										className="form-control black-border "
										onChange={(e) => { onFormDataChange('totalElevators', e); }}
									/>
								</InputGroup>
							</div>
						</div>
						<div className="col">
							<div className="form-group with-message">
								<label className="input-label form-label mb-4">Tipo de elevador</label>
								<Dropdown
									optionLabel="description"
									options={catalogs.elevatorTypes}
									value={formData.buildingFeatures.buldingElevatorTypes}
									onChange={(e) => { onFormDataChange('buldingElevatorTypes', e); }}
									optionValue="elevatorTypeID"
									className="w-100"
									isMulti={true}
								/>
							</div>
						</div>
						<div className="col">
							<div className="form-group with-message">
								<label className="input-label form-label mb-4">Tipo de estructura</label>
								<Dropdown
									optionLabel="description"
									options={catalogs.buildingTypes}
									value={formData.buildingFeatures.buildingTypeID}
									onChange={(e) => { onFormDataChange('buildingTypeID', e); }}
									optionValue="buildingTypeID"
									className="w-100"
								/>
							</div>
						</div>
						<div className="col">
							<div className="form-group with-message">
								<label className="input-label form-label mb-4">Tipo de fachada</label>
								<Dropdown
									optionLabel="description"
									options={catalogs.facadeTypes}
									value={formData.buildingFeatures.facadeTypeID}
									onChange={(e) => { onFormDataChange('facadeTypeID', e); }}
									optionValue="facadeTypeID"
									className="w-100"
								/>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-3">
							<div className="form-group with-message">
								<label className="input-label form-label mb-4">Características de muros interiores</label>
								<Dropdown
									optionLabel="description"
									options={catalogs.interiorWallTypes}
									value={formData.buildingFeatures.interiorWallTypeID}
									onChange={(e) => { onFormDataChange('interiorWallTypeID', e); }}
									optionValue="interiorWallTypeID"
									className="w-100"
								/>
							</div>
						</div>
						<div className="col-3">
							<div className="form-group with-message">
								<label className="input-label form-label mb-4">Características de muros divisorios</label>
								<Dropdown
									optionLabel="description"
									options={catalogs.dividenWallTypes}
									value={formData.buildingFeatures.dividenWallTypeID}
									onChange={(e) => { onFormDataChange('dividenWallTypeID', e); }}
									optionValue="dividenWallTypeID"
									className="w-100"
								/>
							</div>
						</div>
						<div className="col-3">
							<div className="form-group with-message">
								<label className="input-label form-label mb-4">Ventanas</label>
								<Dropdown
									optionLabel="description"
									options={catalogs.windowTypes}
									value={formData.buildingFeatures.windowTypeID}
									onChange={(e) => { onFormDataChange('windowTypeID', e); }}
									optionValue="windowTypeID"
									className="w-100"
								/>
							</div>
						</div>

					</div>
					<div className="row">
						<div className="col-3">
							<div className="form-group with-message">
								<label className="input-label form-label mb-4" >Cajones totales</label>
								<InputGroup>
									<NumberFormat
										decimalScale={0}
										isAllowed={(value) => {
											if (value.floatValue <= 9999)
												return true
											else if (value.formattedValue === '')
												return true
											else
												return false;

										}}
										allowNegative={false}
										thousandSeparator={true}
										max={9999}
										value={formData.buildingFeatures.totalParkingLots}
										className="form-control black-border "
										onChange={(e) => { onFormDataChange('totalParkingLots', e); }}
									// customInput={Form.Control}
									/>
								</InputGroup>
							</div>
						</div>
						<div className="col-3">
							<div className="form-group with-message">
								<label className="input-label form-label mb-4">Cajones de visita</label>

								<InputGroup>
									<NumberFormat
										decimalScale={0}
										isAllowed={(value) => {
											if (value.floatValue <= 9999)
												return true
											else if (value.formattedValue === '')
												return true
											else
												return false;

										}}
										allowNegative={false}
										thousandSeparator={true}
										max={9999}
										value={formData.buildingFeatures.totalVisitorParkingLots}
										className="form-control black-border "
										onChange={(e) => { onFormDataChange('totalVisitorParkingLots', e); }}
									// customInput={Form.Control}
									/>

								</InputGroup>
							</div>
						</div>

						<div className="col-3">
							<div className="form-group with-message">
								<label className="input-label form-label mb-4">Sistema contra incendios</label>
								<Form.Check
									disabled={false}
									type="switch"
									id="hasFireProtectionSystem-switch"
									label=""
									checked={formData.buildingFeatures.hasFireProtectionSystem}
									onChange={(e) => {
										onFormDataChange("hasFireProtectionSystem", {
											value: !formData.buildingFeatures.hasFireProtectionSystem
										})
									}}
								/>
							</div>
						</div>
					</div>


				</div>
			</form>
		</div >
	)
}

export default AdditionalFeatures
