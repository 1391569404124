import React, { useEffect, useState } from 'react';

import { useMainContext } from "../../../contexts/MainContext";
import { RestActions } from "../../../actions";
import MenuPhases from '../../../components/molecules/MenuAdmin';
import SearchEngineList from '../SearchEngineList';
import ModalImportFile from '../../../components/atoms/ModalImportFile';
import ModalConfirmImport from "../../../components/atoms/ModalConfirmImport";
import ModalConfirmGeneral from "../../../components/atoms/ModalConfirmGeneral";
import exclamationIcon from '../../../assets/icons/icon-exclamation-mark.svg';
import checkIcon from '../../../assets/icons/icon-check.svg';
import warningIcon from '../../../assets/icons/icon-warning.svg';

import { useCatalogsContext } from "../../../contexts/CatalogsContext";
const Index = ({ preloadReady }) => {
	const rest = new RestActions();
	let catalogs = useCatalogsContext();
	const [showModal, setShowModal] = useState(false);
	const [dataTable, setDataTable] = useState({});
	const [pageLimit, setPageLimit] = useState(10);
	const [showModalLogs, setShowModalLogs] = useState(false);
	const [logsModalData, setLogsModalData] = useState(false);
	const [showModalImportError, setShowModalImportError] = useState(false);
	const [showModalError, setShowModalError] = useState(false);
	const [showMsgError, setShowMsgError] = useState(false);

	const [showModalImportSuccess, setShowModalImportSuccess] = useState(false);
	const [showModalImportWarnings, setShowModalImportWarnings] = useState(false);
	const [showModalImportWarningsList, setShowModalImportWarningsList] = useState(false);
	const [importedFileId, setImportedFileId] = useState("")

	let mainContext = useMainContext();

	useEffect(() => {
		if (preloadReady) {
			initTable(1)
		}

	}, [preloadReady])

	const initTable = async (_pageNumber) => {
		mainContext.setIsLoading(true);
		try {
			let response = await rest.post("gettImportedFileList", {
				pageLimit: pageLimit, pageNumber: _pageNumber
			});
			setDataTable(response);
			mainContext.setIsLoading(false);

		}
		catch (error) {/*TODO SOMETHING*/ }
	}

	const shwoModalLogs = (logs) => {
		setShowModalLogs(true)
		setLogsModalData(logs.logs)
	}
	const acceptImport = async () => {
		mainContext.setIsLoading(true);
		try {
			let response = await rest.post("acceptImportedFile", {
				importedFileID: importedFileId
			});


			setShowModalImportError(false)
			setShowModalImportSuccess(false)
			setShowModalImportWarnings(false)
			setShowModal(false)
			mainContext.setIsLoading(false);
			mainContext.setMessageAlert("Archivo importado correctamente");
			initTable(1)
		}
		catch (error) {/*TODO SOMETHING*/ }
	}
	const cancelImport = async () => {
		try {
			let response = await rest.post("cancelImportedFile", {
				importedFileID: importedFileId
			});

			setShowModalImportError(false)
			setShowModalImportSuccess(false)
			setShowModalImportWarnings(false)
			setShowModal(false)
		}
		catch (error) {/*TODO SOMETHING*/ }
	}
	const validateExcel = async (file, fileName) => {
		mainContext.setIsLoading(true);
		if (file) {
			try {
				let response = await rest.post("validateExcel", {
					fileName: fileName,
					fileData: file
				});
				setImportedFileId(response.imported_file_id)
				setShowModalImportWarningsList(response)
				if (response.errorCount > 0) {
					setShowModalImportError(true)
				}
				else if (response.warningCount > 0) {
					setShowModalImportWarnings(true)
				}
				else {
					setShowModalImportSuccess(true)
				}
				mainContext.setIsLoading(false);

			}
			catch (error) {
				closeModal();
				setShowMsgError(error);
				setShowModalError(true)
			}
		} else {
			mainContext.setIsLoading(false);

			mainContext.setMessageAlert("Selecciona un archivo para importar");
		}
	}

	const closeModal = () => {
		setShowModalImportError(false)
		setShowModalImportSuccess(false)
		setShowModalImportWarnings(false)
		setShowModal(false)
		mainContext.setIsLoading(false);
		setShowModalError(false)
	}
	return (
		<>
			<MenuPhases linkActive={"importer"} />
			<ModalImportFile showModal={showModal} handlerAccept={validateExcel} setShow={setShowModal} />
			<ModalConfirmImport size={"lg"} show={showModalImportError} icon={exclamationIcon} handleConfirm={closeModal} footerButtons={false}>
				<div className="col">
					<p className="title-modal m-0">{showModalImportWarningsList?.modelsCount} Modelos</p>
					<p className="title-modal m-0">{showModalImportWarningsList?.projectCount} Proyectos</p>
					<p className="title-modal"><b>Errores</b></p>
					<p className="modal-content-list overflow-auto h-200">
						{showModalImportWarningsList.errorMsg && showModalImportWarningsList.errorMsg.map((el) => <li className="text-left mb-2">{el}</li>)}
					</p>

					{showModalImportWarningsList.warningMsg && (
						<>
							<p className="title-modal"><b>Warnings</b></p>
							<p className="modal-content-list overflow-auto h-200">
								{showModalImportWarningsList.warningMsg && showModalImportWarningsList.warningMsg.map((el) => <li className="text-left mb-2">{el}</li>)}
							</p>
						</>
					)}
				</div>
			</ModalConfirmImport>
			<ModalConfirmImport size={"sm"} show={showModalError} icon={exclamationIcon} handleConfirm={closeModal} footerButtons={false}>
				<div className="col">
					<p className="title-modal"><b>Error</b></p>
					<p className="modal-content-list overflow-auto h-200">
						{showMsgError}
					</p>
				</div>
			</ModalConfirmImport>

			{/*modal archivo correcto*/}
			<ModalConfirmImport show={showModalImportSuccess} icon={checkIcon} handleCancel={cancelImport} handleConfirm={acceptImport}>
				<div className="col">
					<p className="title-modal"><b>El archivo es correcto</b></p>
					<p className="title-modal m-0">{showModalImportWarningsList.modelsCount} Modelos</p>
					<p className="title-modal m-0">{showModalImportWarningsList.projectCount} Proyectos</p>
				</div>
			</ModalConfirmImport>
			<ModalConfirmImport show={showModalImportWarnings} icon={warningIcon} handleCancel={cancelImport} handleConfirm={acceptImport}>
				<div className="col">
					<p className="title-modal m-0">{showModalImportWarningsList.modelsCount} Modelos</p>
					<p className="title-modal m-0">{showModalImportWarningsList.projectCount} Proyectos</p>
					<p className="title-modal"><b>Warnings</b></p>
					<p className="modal-content-list overflow-auto h-400 border border-dark p-3">
						{showModalImportWarningsList.warningMsg && showModalImportWarningsList.warningMsg.map((el) => <li className="text-left mb-2">{el}</li>)}
					</p>

				</div>
			</ModalConfirmImport>
			<ModalConfirmImport show={showModalLogs} footerButtons={false} handleConfirm={() => { setShowModalLogs(false) }}>
				<div className="col">
					<div className="custom-card">
						<div className="custom-card-header px-3 d-flex">
							<h4 className="py-3">Logs	</h4>

						</div>
						<div className="custom-card-body">
							<p className="modal-content-list overflow-auto h-400 border border-dark p-3">
								{logsModalData && logsModalData.map((el) => <li className="text-left mb-2">{el}</li>)}
							</p>
						</div>
					</div>
				</div>
			</ModalConfirmImport>
			<div className="custom-card">
				<div className="custom-card-header px-3 d-flex">
					<h4 className="py-3 w-50">IMPORTACIONES	</h4>
					<div className="w-50 py-2 d-flex justify-content-end">
						<button onClick={() => { setShowModal(true) }} className="btn btn-primary"><span className="d-inline-block pr-4">Nueva importación</span> +</button>
					</div>
				</div>
				<div className="custom-card-body">
					<div className="table-scroll-y table-scroll-y-scrollable">
						<div className="table-responsive h-100">
							<SearchEngineList handleRefresh={initTable} data={dataTable} openLog={shwoModalLogs} />

						</div>
					</div>
				</div>
			</div>
		</>

	)
}

export default Index
