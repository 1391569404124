import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Dropdown } from 'primereact/dropdown';
import _ from 'lodash';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
//ACTION && CONTEXT
import { RestActions } from "../../actions";
import { useMainContext } from "../../contexts/MainContext";
import { useCatalogsContext } from "../../contexts/CatalogsContext";
//COMPONENTS
import MenuSales from '../../components/molecules/MenuSales';
//ASSETS

const SalesStatus = ({ preloadReady }) => {
    const rest = new RestActions();
    let mainContext = useMainContext();
    //let { phaseID } = useParams();
    const location = useLocation();
    if (window.sessionStorage.getItem("phaseID") == "" || window.sessionStorage.getItem("phaseID") == undefined)
        window.sessionStorage.setItem("phaseID", location.state.phaseID);
    const phaseID = window.sessionStorage.getItem("phaseID");
    let catalogs = useCatalogsContext();

    const [saleStatus, setSaleStatus] = useState({});
    const [models, setModels] = useState([]);
    const [levels, setLevels] = useState([]);
    const [retailSummary, setRetailSummary] = useState([]);
    const [totalOffice, setTotalOffice] = useState([]);
    const [totalRetail, setTotalRetail] = useState([]);
    const [officeSummary, setOfficeSummary] = useState([]);
    const [rentSummary, setRentSummary] = useState({});
    const [phaseData, setPhaseData] = useState({});

    useEffect(() => {
        if (preloadReady) {
            initPhase();
            getSalesStatus();
        }
    }, [preloadReady]);

    const initPhase = async () => {
        mainContext.setIsLoading(true);
        mainContext.setIsDirty(false);
        try {
            let response = await rest.post("getPhases", { phaseID: parseInt(phaseID) });
            setPhaseData(response[0]);
        }
        catch (error) {/*TODO SOMETHING*/ }
    }

    const getSalesStatus = async () => {
        try {
            let response = await rest.post("getSaleStatus", { phaseID: parseInt(phaseID) });
            setSaleStatus(response.saleStatus[0]);
            setModels(response.models);
            setOfficeSummary(response.officeSummary)
            setRetailSummary(response.retailSummary)
            let totalUnidadR = 0, totalM2R = 0, totalUnidadO = 0, totalM2O = 0;
            response.retailSummary.map((item) => {
                totalUnidadR = totalUnidadR + parseInt(item.totalUnits)
                totalM2R = totalM2R + parseFloat(item.totalM2)
            })
            response.officeSummary.map((item) => {
                totalUnidadO = totalUnidadO + parseInt(item.totalUnits)
                totalM2O = totalM2O + parseFloat(item.totalM2)
            })

            setTotalRetail({ totalUnidadR, totalM2R })
            setTotalOffice({ totalUnidadO, totalM2O })


            let datarentSummary;
            response.rentSummary.map((item) => {
                if (item.commercialBussinesID == 2) {
                    datarentSummary = { ...datarentSummary, 'retail': item }
                } else {
                    datarentSummary = { ...datarentSummary, 'office': item }
                }

            })
            setRentSummary(datarentSummary)




            setLevels(groupBy(response.levels[0].units));
            mainContext.setIsLoading(false);
        }
        catch (error) {
            mainContext.setIsLoading(false);
        }
    }

    const groupBy = (arr) => {
        let levels = _.uniqBy(arr, (row) => { return row.levels.levelNumber; });
        let groups = [];
        _.forEach(levels, (row, index) => {
            groups.push({ levelName: row.levels.levelName, units: [] })
            groups[index].units = _.filter(arr, (row2) => { return row2.levels.levelNumber == row.levels.levelNumber });
        })
        return groups;
    }

    function format(num, _decimal = 2) {
        if (!num)
            return num;
        if (!isNaN(parseFloat(num)))
            num = parseFloat(num).toFixed(_decimal);
        return ('' + num).replace(
            /(\d)(?=(?:\d{3})+(?:\.|$))|(\.\d\d?)\d*$/g,
            function (m, s1, s2) {
                return s2 || (s1 + ',');
            }
        );
    }

    return (
        <>
            <MenuSales phaseID={phaseID} phaseData={phaseData} linkActive={"salesStatus"} />
            <div className="custom-card">
                <div className="custom-card-header px-3 d-flex">
                    <h4 className="py-3 w-50">RESUMEN VENTA</h4>
                    <div className="w-50 py-3 d-flex justify-content-end">
                        <b className="mr-4">Fecha de último levantamiento:</b> {saleStatus.lastDate}
                    </div>
                </div>
                <div className="custom-card-body px-3">
                    <div className="row">
                        <div className="col-12">
                            <table className="w-100 font-size-13">
                                <tbody>
                                    <tr>
                                        <td className="py-3"><b>Unidades totales:</b></td>
                                        <td>{format(saleStatus.totalUnits, 0)}</td>
                                        <td><b>Área vendible:</b></td>
                                        <td>{format(saleStatus.totalM2)} M<sup>2</sup></td>
                                        <td><b>Potencial de ventas:</b></td>
                                        <td>$ {format(saleStatus.saleAmount, 0)}</td>
                                        <td><b>Precio prom inv:</b></td>
                                        <td>$ {format(saleStatus.avgAmountForSale, 0)}</td>
                                    </tr>
                                    <tr>
                                        <td className="py-3"><b>Unidades vendidas:</b></td>
                                        <td>{format(saleStatus.soldUnits, 0)}</td>
                                        <td><b>Área vendida:</b></td>
                                        <td>{format(saleStatus.soldArea, 0)} M<sup>2</sup></td>
                                        <td><b>Ventas acumuladas:</b></td>
                                        <td>$ {format(saleStatus.advanceSoldAmount, 0)}</td>
                                        <td><b>$/M<sup>2</sup> promedio inv:</b></td>
                                        <td>$ {format(saleStatus.avgPriceM2, 0)}</td>
                                    </tr>
                                    <tr>
                                        <td className="py-3"><b>% Avance ventas:</b></td>
                                        <td>{format(saleStatus.saleAdvance, 0)}%</td>
                                        <td><b>% Área vendida:</b></td>
                                        <td>{format(saleStatus.advanceSoldArea, 0)}%</td>
                                        <td><b>Monto por vender:</b></td>
                                        <td>$ {format(saleStatus.amountForSale, 0)}</td>
                                    </tr>
                                    <tr>
                                        <td className="py-3"><b>Tasa preferencial:</b></td>
                                        <td>{format(saleStatus.isPreferencialRate)}</td>
                                        <td><b>Última tasa:</b></td>
                                        <td>$ {format(saleStatus.amount)}</td>
                                        <td><b>¿Separación en %?:</b></td>
                                        <td>{format(saleStatus.isSeparationPercentage)}</td>
                                        <td><b>Última separación típica:</b></td>
                                        <td>{format(saleStatus.typicalSeparationValue, 0)}</td>
                                    </tr>
                                    <tr>
                                        <td className="py-3"><b>Absorción histórica:</b></td>
                                        <td>{format(saleStatus.soldUnitsOverTime)}</td>
                                        <td><b>Tiempo en mercado:</b></td>
                                        <td>{saleStatus.timeMarket}</td>
                                        <td><b>Meses de inventario:</b></td>
                                        <td>{saleStatus.inventoryMonths}</td>
                                        <td><b>Avance de obra:</b></td>
                                        <td>{saleStatus.workProgressDescription}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div className="custom-card mt-4">
                <div className="custom-card-header px-3 d-flex">
                    <h4 className="py-3 w-50">RESUMEN RENTA</h4>
                    <div className="w-50 py-3 d-flex justify-content-end">
                        <b className="mr-4">Fecha de último levantamiento:</b> {saleStatus.lastDate}
                    </div>
                </div>
                <div className="custom-card-body px-3">
                    <div className="row">
                        <div className="col-6 px-0">
                            <table className="w-100 font-size-13 px-5">
                                <thead>
                                    <tr>
                                        <th className="text-center table-row-border-bottom" colspan="4">RETAIL</th>
                                    </tr>
                                </thead>
                                <tbody >
                                    <tr>
                                        <td className="py-3 px-2"><b>Unidades totales:</b></td>
                                        <td>{format(rentSummary?.retail?.totalUnits, 0)}</td>
                                        <td><b>M<sup>2</sup> Rentables:</b></td>
                                        <td className="column-border-right">{format(rentSummary?.retail?.totalM2)} M<sup>2</sup></td>

                                    </tr>
                                    <tr>
                                        <td className="py-3 px-2"><b>Unidades ocupados:</b></td>
                                        <td>{format(rentSummary?.retail?.rentedUnits, 0)}</td>
                                        <td><b>M<sup>2</sup> Ocupados:</b></td>
                                        <td className="column-border-right">{format(rentSummary?.retail?.["rentedM2"])} M<sup>2</sup></td>

                                    </tr>
                                    <tr>
                                        <td className="py-3 px-2"><b>Unidades disponibles:</b></td>
                                        <td>{format(rentSummary?.retail?.availableUnits, 0)}</td>
                                        <td><b>M<sup>2</sup> Disponibles:</b></td>
                                        <td className="column-border-right">{format(rentSummary?.retail?.availableM2)} M<sup>2</sup></td>

                                    </tr>
                                    <tr>
                                        <td className="py-3 px-2"><b>Unidades indefinidos:</b></td>
                                        <td>{rentSummary?.retail?.undefinedUnits}</td>
                                        <td><b>M<sup>2</sup> Indefinidos:</b></td>
                                        <td className="column-border-right">{format(rentSummary?.retail?.undefinedM2)} M<sup>2</sup></td>

                                    </tr>
                                    <tr>
                                        <td className="py-3 px-2"><b>% Ocupación:</b></td>
                                        <td>{format(rentSummary?.retail?.["rentedUnits%"])} %</td>
                                        <td><b>% Ocupación M<sup>2</sup>:</b></td>
                                        <td className="column-border-right">{format(rentSummary?.retail?.["rentedM2%"])} %</td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-6 px-0">
                            <table className="w-100 font-size-13 px-5">
                                <thead>
                                    <tr>
                                        <th className="text-center table-row-border-bottom" colspan="4">OFICINAS</th>
                                    </tr>
                                </thead>
                                <tbody >
                                    <tr>
                                        <td className="py-3 px-2"><b>Unidades totales:</b></td>
                                        <td>{format(rentSummary?.office?.totalUnits, 0)}</td>
                                        <td><b>M<sup>2</sup> Rentables:</b></td>
                                        <td className="column-border-right">{format(rentSummary?.office?.totalM2)} M<sup>2</sup></td>

                                    </tr>
                                    <tr>
                                        <td className="py-3 px-2"><b>Unidades ocupados:</b></td>
                                        <td>{format(rentSummary?.office?.rentedUnits, 0)}</td>
                                        <td><b>M<sup>2</sup> Ocupados:</b></td>
                                        <td className="column-border-right">{format(rentSummary?.office?.["rentedM2"])} M<sup>2</sup></td>

                                    </tr>
                                    <tr>
                                        <td className="py-3 px-2"><b>Unidades disponibles:</b></td>
                                        <td>{format(rentSummary?.office?.availableUnits, 0)}</td>
                                        <td><b>M<sup>2</sup> Disponibles:</b></td>
                                        <td className="column-border-right">{format(rentSummary?.office?.availableM2)} M<sup>2</sup></td>

                                    </tr>
                                    <tr>
                                        <td className="py-3 px-2"><b>Unidades indefinidos:</b></td>
                                        <td>{rentSummary?.office?.undefinedUnits}</td>
                                        <td><b>M<sup>2</sup> Indefinidos:</b></td>
                                        <td className="column-border-right">{format(rentSummary?.office?.undefinedM2)} M<sup>2</sup></td>

                                    </tr>
                                    <tr>
                                        <td className="py-3 px-2"><b>% Ocupación:</b></td>
                                        <td>{format(rentSummary?.office?.["rentedUnits%"])} %</td>
                                        <td><b>% Ocupación M<sup>2</sup>:</b></td>
                                        <td className="column-border-right">{format(rentSummary?.office?.["rentedM2%"])} %</td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div className="custom-card mt-4">
                <div className="custom-card-header px-3 d-flex">
                    <h4 className="py-3">MODELOS VENTAS</h4>
                </div>
                <div className="custom-card-body px-3">
                    <div className="row">
                        <div className="col-12 overflow-x-auto">
                            <table className="w-100 table-no-border table-striped">
                                <thead>
                                    <tr>
                                        <th className=""></th>
                                        <th className="text-center column-gray" colSpan="3">Unidades</th>
                                        <th className="text-center" colSpan="2">Precio anterior</th>
                                        <th className="text-center column-gray" colSpan="3">Precio inventario</th>
                                        <th className="text-center" colSpan="3">Ventas</th>
                                    </tr>
                                    <tr className="table-row-border-bottom">
                                        <th className="">Nombre</th>
                                        <th className="text-center column-gray">Tot.</th>
                                        <th className="text-center column-gray">Vend.</th>
                                        <th className="text-center column-gray">Inv.</th>
                                        <th className="text-center">Mínimo</th>
                                        <th className="text-center">Máximo</th>
                                        <th className="text-center column-gray">Mínimo</th>
                                        <th className="text-center column-gray">Máximo</th>
                                        <th className="text-center column-gray">Promedio</th>
                                        <th className="text-center">Últimas</th>
                                        <th className="text-center">Máximas</th>
                                        <th className="text-center">Fecha máx.</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {models.map((model, index) =>
                                    (
                                        <tr key={`rowModel-${index}`} className="text-center">
                                            <td>{model.modelName}</td>
                                            <td className="column-border-left">{format(model.totalUnits, 0)}</td>
                                            <td className="">{format(model.soldUnits, 0)}</td>
                                            <td className="column-border-right">{format(model.forSaleUnits, 0)}</td>
                                            <td>{format(model.minPrice, 0)}</td>
                                            <td>{format(model.maxPrice, 0)}</td>
                                            <td className="column-border-left">{format(model.minPriceForSale, 0)}</td>
                                            <td className="">{format(model.maxPriceForSale, 0)}</td>
                                            <td className="column-border-right">{format(model.avgPrice, 0)}</td>
                                            <td>{format(model.soldUnitsOverTime)}</td>
                                            <td>{format(model.maxsoldunits)}</td>
                                            <td>{model.maxDate}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="custom-card mt-4">
                <div className="custom-card-header px-3 d-flex">
                    <h4 className="py-3">UNIDADES RENTAS</h4>
                </div>
                <div className="custom-card-body px-3">
                    <div className="row">
                        <div className="col-6  px-0">
                            <table className="w-100 table-no-border table-striped">
                                <thead>
                                    <tr>
                                        <th className="text-center" colspan="4">RETAIL</th>

                                    </tr>
                                    <tr className="table-row-border-bottom">
                                        <th className="text-center">Tipo</th>
                                        <th className="text-center ">Categoría</th>
                                        <th className="text-center ">Unidades</th>
                                        <th className="text-center ">M2</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        retailSummary.map((model, index) =>
                                        (
                                            <tr key={`rowModel-${index}`} className="text-center">
                                                <td>{model.modelTypeDescription}</td>
                                                <td className="column-border-left">{model.subCategoryDescription}</td>
                                                <td className="">{model.totalUnits}</td>
                                                <td className="column-border-right">{model.totalM2}</td>
                                            </tr>
                                        ))

                                    }
                                    <tr >
                                        <td colspan="2" ><strong>Total</strong></td>

                                        <td className="text-center"><strong>{totalRetail.totalUnidadR}</strong></td>
                                        <td className="column-border-right text-center"><strong>{totalRetail.totalM2R}</strong></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-6  px-0">
                            <table className="w-100 table-no-border table-striped">
                                <thead>
                                    <tr>
                                        <th className="text-center" colspan="4">OFICINAS</th>

                                    </tr>
                                    <tr className="table-row-border-bottom">
                                        <th className="text-center">Tipo</th>
                                        <th className="text-center ">Categoría</th>
                                        <th className="text-center ">Unidades</th>
                                        <th className="text-center ">M2</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        officeSummary.map((model, index) =>
                                        (
                                            <tr key={`rowModel-${index}`} className="text-center">
                                                <td>{model.modelTypeDescription}</td>
                                                <td className="column-border-left">{model.subCategoryDescription}</td>
                                                <td className="">{model.totalUnits}</td>
                                                <td className="column-border-right">{model.totalM2}</td>
                                            </tr>
                                        ))

                                    }
                                    <tr >
                                        <td colspan="2" ><strong>Total</strong></td>

                                        <td className="text-center"><strong>{totalOffice.totalUnidadO}</strong> </td>
                                        <td className="column-border-right text-center"><strong>{totalOffice.totalM2O}</strong></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>



            <div className="custom-card mt-4">
                <div className="custom-card-header px-3 d-flex">
                    <h4 className="py-3">VENTAS</h4>
                </div>
                <div className="custom-card-body px-3">
                    <div className="row overflow-x-auto">
                        <table className="table-no-border table-striped table-levantamientos">
                            <thead>
                                <tr>
                                    <th className="fixedth1">Nombre</th>
                                    <th className="text-center fixedth2">Modelo</th>
                                    <th className="text-center fixedth3">M<sup>2</sup> totales</th>
                                    <th className="text-center">Tipo</th>
                                    <th className="text-center">Último precio registrado</th>
                                    <th className="text-center">Fecha del último registro</th>
                                    <th className="text-center column-gray">

                                        <span>Precio actual  <FontAwesomeIcon title="Es obligatorio capturar por lo menos dos precios para un mismo modelo, excepto si es la ultima unidad." icon={faQuestionCircle} /></span><br />
                                        <small className="font-weight-bold"><FontAwesomeIcon icon={faUserCircle} /> = Monto capturado</small>
                                    </th>
                                    <th className="text-center">Precio por M<sup>2</sup></th>
                                    <th className="text-center ">Estado venta <span title="Es necesario actualizar el estado de venta de todos los inmuebles de cada levantamiento"><FontAwesomeIcon icon={faQuestionCircle} /></span></th>
                                    <th className="text-center">Precio de renta</th>
                                    <th className="text-center column-gray">Estado de renta <span title="Es necesario actualizar el estado de venta de todos los inmuebles de cada levantamiento"><FontAwesomeIcon icon={faQuestionCircle} /></span></th>
                                    <th className="text-center">Categoria <br /> (Comercial/oficina) </th>
                                    <th className="text-center">Marca / Empresa </th>
                                    <th className="text-center">Separación</th>
                                </tr>
                            </thead>
                            <tbody>
                                {levels.map((level, indexLevel) =>
                                (
                                    <>
                                        <tr key={`rowLevel-${indexLevel}`} className="table-row-border-top">
                                            <td className="fixed1" colSpan="6"><b>{level.levelName}</b></td>
                                            <td className="column-border"></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className=""></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        {level.units.map((unit, indexUnit) =>
                                        (
                                            <tr key={`rowUnit-${indexUnit}`}>
                                                <td className="text-center fixed1">{unit.code}</td>
                                                <td className="text-center fixed2">{unit.models.name}</td>
                                                <td className="text-center fixed3">{unit.models.totalM2}</td>
                                                <td className="text-center">{unit.models.modelTypes.description}</td>
                                                <td className="text-center">{format(unit.lastPrice, 0)}</td>
                                                <td className="text-center">{unit.lastDate}</td>
                                                <td className="text-center column-border">
                                                    {format(unit.unitsGatheringInformation.actualPrice, 0)}
                                                    {unit.unitsGatheringInformation?.actualPrice != "" && unit.unitsGatheringInformation?.actualPrice != null && unit.unitsGatheringInformation.isInterpolated == false ?
                                                        <span className="ml-1"><FontAwesomeIcon icon={faUserCircle} /></span> : <span className="ml-3"></span>

                                                    }

                                                </td>
                                                <td className="text-center">{format(unit.models.priceM2, 0)}</td>
                                                <td className="text-center">
                                                    <Dropdown value={unit.unitsGatheringInformation.saleStatusID} disabled={true} options={catalogs.saleStatus} optionValue="saleStatusID" optionLabel="description" className={`dropdown-color-${unit.unitsGatheringInformation.saleStatusID}`} />
                                                </td>
                                                <td className="text-center">{format(unit.rentalPrice, 0)}</td>
                                                <td className="text-center w-300">
                                                    <Dropdown value={unit.unitsGatheringInformation.rentalStatusID} disabled={true} options={catalogs.rentalStatus} optionValue="rentalStatusID" optionLabel="description" className={`dropdown-color-${unit.unitsGatheringInformation.rentalStatusID}`} />
                                                </td>
                                                <td className="text-center w-300">
                                                    <Dropdown value={unit.unitsGatheringInformation.subCategoryID} disabled={true} options={catalogs.subCategories} optionValue="subCategoryID" optionLabel="description" />
                                                </td>
                                                <td className="text-center">{unit.unitsGatheringInformation.brandName}</td>
                                                <td className="text-center">
                                                    {(saleStatus.isSeparationPercentage == "Sí" && unit.unitsGatheringInformation.separationValue)
                                                        ? format(unit.unitsGatheringInformation.separationValue, 0) + "%"
                                                        : (unit.unitsGatheringInformation.separationValue) && "$" + format(unit.unitsGatheringInformation.separationValue, 0)}
                                                </td>
                                            </tr>
                                        ))}
                                    </>
                                )
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <button data-cy="scrollTop" className="btn-go-up" onClick={() => { scroll.scrollTo(180); }} >
                <FontAwesomeIcon icon={faChevronUp} />
            </button>
        </>
    )
}
export default SalesStatus;