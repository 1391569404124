import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useMainContext } from "../../../contexts/MainContext";
import { RestActions } from "../../../actions";
import Cookies from 'universal-cookie';

/**
    * Componente <ModalConfirmCloseSession /> Muestra un campo de confirmacion al intentar cerrar sesion.
    * @component
*/
const ModalConfirmCloseSession = () => 
{
    const rest = new RestActions();
    let mainContext = useMainContext();
    const cookies = new Cookies();

    /**
        * Cierra el modal
    */
    const closeModal = () => 
    {
        mainContext.setShowCloseSession(false);
    }

    /**
        * Cierra la sesion y redirige a la pantalla de login.
    */
    const logout = async () => 
    {
        try
        {
            let response = JSON.parse(await rest.post("logout", {}));
        }
        catch (error) {/*TODO SOMETHING*/}
        cookies.remove('token');
        mainContext.setShowCloseSession(false);
        window.location.href = "/";
    }
    
    return (
        <section>
            <Modal size={"logout"} show={mainContext.showCloseSession} id="close-session-modal" backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered >
                <Modal.Body className={"text-center"}>
                    <div className="row my-5 py-2">
                        <div className="col col-md-4 offset-md-4">
                            <div className="border-image text-center col-md-8 offset-md-2">
                                <img src={'./assets/icons/icon-logout.svg'} className={"icon-logout my-3"} />
                            </div>
                        </div>
                    </div>
                    <div className="row text-center mb-5">
                        <div className="col">
                            <p className="title-modal">¿Seguro que deseas cerrar sesión?</p>
                            <p className="content-modal px-5">Al continuar, regresará a la pantalla de ingreso de usuario.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 offset-md-2">
                            <button className="btn btn-outline-link" onClick={() => { closeModal() }} >Cancelar</button>
                        </div>
                        <div className="col-md-4 offset-md-1">
                            <button className="btn btn-primary btn-block" onClick={() => { logout() }}>Aceptar</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </section>
    )
}
export default ModalConfirmCloseSession;