import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { animateScroll as scroll } from 'react-scroll';
//ACTION && CONTEXT
import { RestActions } from "../../actions";
import { useMainContext } from "../../contexts/MainContext";
//COMPONENTS
import MenuPhases from '../../components/molecules/MenuPhases';
import ModelsForm from './ModelsForm';
//ASSETS
import iconEdit from '../../assets/icons/icon-edit.svg';
import './styles.css';

const Models = ({ preloadReady }) => {
    const rest = new RestActions();
    let mainContext = useMainContext();
    //let { phaseID } = useParams();
    const location = useLocation();
    if (window.sessionStorage.getItem("phaseID") == "" || window.sessionStorage.getItem("phaseID") == undefined)
        window.sessionStorage.setItem("phaseID", location.state.phaseID);
    const phaseID = window.sessionStorage.getItem("phaseID");

    const [phaseData, setPhaseData] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [modelsList, setModelsList] = useState([]);
    const [modelID, setModelID] = useState();

    useEffect(() => {
        if (modelID)
            setShowModal(true);
    }, [modelID]);

    useEffect(() => {
        if (preloadReady) {
            initPhase();
            getModels();
        }
    }, [preloadReady]);

    const initPhase = async () => {
        mainContext.setIsLoading(true);
        mainContext.setIsDirty(false);
        try {
            let response = await rest.post("getPhases", { phaseID: parseInt(phaseID) });
            setPhaseData(response[0]);
        }
        catch (error) {/*TODO SOMETHING*/ }
    }

    const getModels = async () => {
        try {
            let response = await rest.post("getModels", { phaseID: parseInt(phaseID) });
            setModelsList(response);
            mainContext.setIsLoading(false);
        }
        catch (error) {/*TODO SOMETHING*/ }
    }

    const requestNavigation = () => {
        scroll.scrollTo(0);
        let goTo = "/#phase/stories";
        mainContext.setGoTo(goTo);
    }

    const linkEditModelTemplate = (rowData) => {
        return <span className="btn-icon"><img onClick={() => { setModelID(rowData.modelID) }} src={iconEdit} /></span>
    }

    return (
        (preloadReady) &&
        <>
            <MenuPhases phaseID={phaseID} phaseData={phaseData} linkActive={"modelos"} />
            <ModelsForm modelID={modelID} phaseID={phaseID} showModal={showModal} handleShowModal={setShowModal} handleModelId={setModelID} handleRefreshList={getModels} />
            <div className="custom-card">
                <div className="custom-card-header px-3 d-flex">
                    <h4 className="py-3 w-50">MODELOS</h4>
                    <div className="w-50 py-2 d-flex justify-content-end">
                        <button onClick={() => { setShowModal(true) }} className="btn btn-primary"><span className="d-inline-block pr-4">Nuevo modelo</span> +</button>
                    </div>
                </div>
                <div className="custom-card-body">
                    <div className="row">
                        <div className="col">
                            <DataTable value={modelsList} emptyMessage="No se encontraron datos" className="p-datatable-no-border p-datatable-striped" rows={10000}>
                                <Column className="text-center px-0 w-50px" field="orderID" header="#" />
                                <Column className="text-center px-1 ellipsis" field="name" header="Modelo" />
                                <Column className="text-center px-1 ellipsis" field="indoorM2" header={<span>M<sup>2</sup> Interiores</span>} />
                                <Column className="text-center px-1 ellipsis" field="terraceM2" header={<span>M<sup>2</sup> Terraza</span>} />
                                <Column className="text-center px-1 ellipsis" field="yardM2" header={<span>M<sup>2</sup> Jardín</span>} />
                                <Column className="text-center px-1 ellipsis" field="bedrooms" header="Dormitorios" />
                                <Column className="text-center px-1 ellipsis" field="livingRooms" header="Estancias" />
                                <Column className="text-center px-1 ellipsis" field="bathrooms" header="Baños completos" />
                                <Column className="text-center px-1 ellipsis" field="parkingSpaces" header="Cajones" />
                                <Column className="text-center px-1 ellipsis" field="warehouses" header="Bodega" />
                                <Column className="text-center px-1 ellipsis" field="status" header="Estatus" />
                                <Column className="text-center px-1 w-50px" field="" header="" body={linkEditModelTemplate} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-12 d-flex justify-content-end">
                    <button onClick={requestNavigation} className="btn btn-primary w-25">Continuar</button>
                </div>
            </div>
        </>
    )
}
export default Models;