const checkPageLoaded = (param, type) => 
{
  let isLoaded = true;
  switch(type){
    case "home":
      let requiredCatalogs = ["countries", "developers"];
      requiredCatalogs.forEach(element => 
      {
        if(param[element] != null)
          isLoaded = false;
      });
  }
  return isLoaded;
}

const isEmpty = (param) => {
  if (param === null || param === undefined || param === false || param === "") {
    return true;
  }
  return false;
}


const formatStringDate = (oldDate, from, to) => {
  let date = oldDate;
  if (from === "dd/mm/yyyy") {
    date = date.split("/");
    date = `${date[2]}-${date[1]}-${date[0]}`
  }
  date = new Date(date);

  let day = date.getUTCDate()
  let month = date.getUTCMonth() + 1
  let year = date.getUTCFullYear()

  day = day < 10 ? `0${day}` : day;
  month = month < 10 ? `0${month}` : month;

  let newDate = to.replace("dd", day.toString()).replace("mm", month.toString()).replace("yyyy", year.toString());

  return newDate;
}

const isNumber = (n) => {
  if (typeof n === "number") {
    return true;
  }
  return false;
}

function kFormatter(num) {
  return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
}

const activePromotion = (date) => {
  let now = new Date();
  date = new Date(formatStringDate(date, 'dd/mm/yyyy', 'yyyy/mm/dd'));
  if (date < now) {
    return false;
  }
  return true;
}

const symbolCurrency = (currency) => {
  let symbols = {
    "MXN - Pesos Mexicanos": "$",
    "COP - Pesos Colombianos": "$",
    "PEN - Soles Peruanos": "S/",
    "USD - Dólares": "$",
    "Tasa Preferencial": "$"
  }

  return symbols[currency];
}

const getIconTypeFile = (type) => {
  type = type.split("-");
  type = type[0].trim();

  return `${type}.svg`;
}

const UtilsAcceptTypeFiles = (type) => {
  if (type) {
    type = type.split("-");
    type = type[0].trim();
  }
  const types = {
    "DOC": ".doc",
    "DOCX": ".docx",
    "PDF": ".pdf",
    "JPG": ".jpg",
    "PNG": ".png",
    "XLS": ".xls",
    "XLSX": ".xlsx"
  }
  return types[type];
}

const acceptTypeFiles = (type) => {
  if (type) {
    type = type.split("-");
    type = type[0].trim();
  }
  const types = {
    "DOC": ".doc",
    "DOCX": ".docx",
    "PDF": ".pdf",
    "JPG": ".jpg",
    "PNG": ".png",
    "XLS": ".xls",
    "XLSX": ".xlsx"
  }
  return types[type];
}
const removeAccents = (str) => {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

}
export {
  isEmpty,
  formatStringDate,
  isNumber,
  kFormatter,
  activePromotion,
  symbolCurrency,
  getIconTypeFile,
  acceptTypeFiles,
  UtilsAcceptTypeFiles,
  checkPageLoaded,
  removeAccents
}