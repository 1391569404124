import React, { useEffect, useState } from 'react';
//ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import loupeIcon from '../../../assets/icons/icon-loupe.svg';
//ACTIONS AND CONTEXT
import { useCatalogsContext } from "../../../contexts/CatalogsContext";
import Dropdown from "../../../components/atoms/Dropdown";
// import { Alert } from 'bootstrap';
var _ = require('lodash');

/**
    * Componente SearchLiftingReviewFilters
    * @component
    * @param  props
    * @param {function} props.handlerFilters - Maneja el cambio de datos 
    * 
*/
const SearchLiftingReviewFilters = ({ dataDatesGathering, loadDataInit, formData, setFormData, dataFilters, mainContext }) => {

    const [dataZonas, setDataZonas] = useState();
    const [dataClassification, setDataClassification] = useState();

    const onFormDataChange = async (name, e) => {
        let data = formData;

        if (name == 'cities') {
            await onUpdateZonas(e);
        } else if (name == 'giro') {
            await onUpdateClassification(e)
        }

        setFormData({ ...formData, [name]: e });
    }

    const onUpdateZonas = (value) => {
        setFormData({ ...formData, zonas: '' })
        let data = value ? dataFilters.zonas.filter(zona => zona.cityid == value.citiesid) : '';
        setDataZonas(data);
    }

    const onUpdateClassification = (value) => {
        setFormData({ ...formData, classification: '' })
        let data = value ? dataFilters.classification.filter(classification => classification.giroid == value.giroid) : '';
        setDataClassification(data);
    }

    // citiesAndGiro
    const [dataGiroAndCities, setDataGiroAndCities] = useState({
        cities: [],
        giro: []
    });
    const applyFilter = (type, data) => {

        if (type == 'Giro') {
            // dataFilters.cities

            if (data == null) { onFormDataChange("giro", data); return setDataGiroAndCities({ ...dataGiroAndCities, cities: [...dataFilters?.cities] }) };

            let IDCities = [];
            dataFilters.citiesAndGiro.map(item => item.id_giro == data.giroid ? IDCities.push(item.id_cities) : '');
            let dataUpdate = dataFilters.cities.filter(city => IDCities.includes(city.citiesid));
            setDataGiroAndCities({ ...dataGiroAndCities, cities: [...dataUpdate] });
            onFormDataChange("giro", data);

        } else if (type == 'City') {
            // dataFilters.giro

            if (data == null) { onFormDataChange("cities", data); return setDataGiroAndCities({ ...dataGiroAndCities, giro: [...dataFilters?.giro] }) };

            let IDGiros = [];
            dataFilters.citiesAndGiro.map(item => item.id_cities == data.citiesid ? IDGiros.push(item.id_giro) : '');
            let dataUpdate = dataFilters.giro.filter(giro => IDGiros.includes(giro.giroid));
            setDataGiroAndCities({ ...dataGiroAndCities, giro: [...dataUpdate] });
            onFormDataChange("cities", data);
        }
    }

    useEffect(() => {
        setDataGiroAndCities({ cities: dataFilters?.cities, giro: dataFilters?.giro });
    }, [dataFilters]);

    React.useEffect(() => {
        const elementActive = document.getElementById('contentMaxVGM');
        if (elementActive) {
            const container = document.querySelector('.container.bg-white');
            if (container) {
                container.style.margin = '0';
                container.style.width = '100%';
                container.style.minWidth = '100%';
                container.style.height = '100%'
            }
            const body = document.body;
            if (body) body.style.overflow = 'auto';

            const containerGeneral = document.getElementsByClassName('container');
            if (containerGeneral[0]) containerGeneral[0].style.display = 'none';

            const section = document.querySelector('.main-section.pb-5');
            if (section) section.style.width = '246vw';



        }
    }, []);

    return (
        <section id='contentMaxVGM'>
            <div className="row">
                <div className="contentFilter">
                    <div className="contentLabelCheck">
                        <label><b><span style={{ color: 'red' }}>*</span> GIRO:</b></label>
                        <Dropdown value={formData.giro} options={dataGiroAndCities.giro} onChange={(e) => { applyFilter('Giro', e.value) }} optionLabel="gironame" optionValue="giroid" />
                    </div>
                </div>
                <div className="contentFilter">
                    <div className="contentLabelCheck">
                        <label><b>DESARROLLADOR:</b></label>
                        <Dropdown placeholder="-- Todos --" isMulti={true} value={formData.developer} options={dataFilters.developer} onChange={(e) => { onFormDataChange("developer", e.value) }} optionLabel="developersname" optionValue="developersid" />
                    </div>
                </div>
                <div className="contentFilter">
                    <div className="contentLabelCheck">
                        <label><b>ESTATUS PROYECTO:</b></label>
                        <Dropdown placeholder="-- Todos --" isMulti={true} value={formData.projectStatus} options={dataFilters.projectStatus} onChange={(e) => { onFormDataChange("projectStatus", e.value) }} optionLabel="projectstatusname" optionValue="projectstatusid" />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="contentFilter">
                    <div className="contentLabelCheck">
                        <label><b><span style={{ color: 'red' }}>*</span> CIUDAD:</b></label>
                        <Dropdown value={formData.cities} options={dataGiroAndCities.cities} onChange={(e) => { applyFilter('City', e.value) }} optionLabel="citiesname" optionValue="citiesid" />
                    </div>
                </div>
                <div className="contentFilter">
                    <div className="contentLabelCheck">
                        <label><b>OFICINA:</b></label>
                        <Dropdown placeholder="-- Todos --" isMulti={true} value={formData.offices} options={dataFilters.offices} onChange={(e) => { onFormDataChange("offices", e.value) }} optionLabel="officesname" optionValue="officesid" />
                    </div>
                </div>
                <div className="contentFilter">
                    <div className="contentLabelCheck">
                        <label><b>ESQUEMA: </b></label>
                        <Dropdown placeholder="-- Todos --" isMulti={true} value={formData.esquema} options={dataFilters.esquema} onChange={(e) => { onFormDataChange("esquema", e.value) }} optionLabel="esquemaname" optionValue="esquemaid" />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="contentFilter">
                    <div className="contentLabelCheck">
                        <label><b><span style={{ color: 'red' }}>*</span> FECHA LEVANTAMIENTO:</b></label>
                        <Dropdown value={formData.gatheringAT} options={dataDatesGathering.surveyDate} onChange={(e) => { onFormDataChange("gatheringAT", e.value) }} optionLabel="dateFormat" optionValue="gathering_at" disabled={dataDatesGathering.disabled} placeholder={`${dataDatesGathering.placeholder}`} />
                    </div>
                </div>
                <div className="contentFilter">
                    <div className="contentLabelCheck">
                        <label><b>ZONA:</b></label>
                        <Dropdown placeholder="-- Todos --" isMulti={true} value={formData.zonas} options={dataZonas} onChange={(e) => { onFormDataChange("zonas", e.value) }} optionLabel="zonesname" optionValue="zonesid" />
                    </div>
                </div>
                <div className="contentFilter">
                    <div className="contentLabelCheck">
                        <label><b>CLASIFICACIÓN: </b></label>
                        <Dropdown placeholder="-- Todos --" isMulti={true} value={formData.classification} options={dataClassification} onChange={(e) => { onFormDataChange("classification", e.value) }} optionLabel="classificationsname" optionValue="classificationsid" />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="contentFilter">
                    <div className="contentLabelCheck">
                        <label><b></b></label>
                        <Dropdown optionLabel="developersname" optionValue="developersid" />
                    </div>
                </div>
                <div className="contentFilter">
                    <div className="contentLabelCheck">
                        <label><b>ESTATUS LEVANTAMIENTO:</b></label>
                        <Dropdown placeholder="-- Todos --" isMulti={true} value={formData.surveyStatus} options={dataFilters.surveyStatus} onChange={(e) => { onFormDataChange("surveyStatus", e.value) }} optionLabel="surveyStatusName" optionValue="surveyStatusID" />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="contentFilter">
                    <div className="contentLabelCheck">
                        <span onClick={() => loadDataInit()}> <img className="" src={loupeIcon} alt="" /></span>
                    </div>
                </div>
            </div>
        </section >
    )
}
export default SearchLiftingReviewFilters