import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Dropdown } from 'primereact/dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import NumberFormat from 'react-number-format';
import _ from 'lodash';
//ACTIONS AND CONTEXT
import { RestActions } from "../../../actions";
import { useMainContext } from "../../../contexts/MainContext";
import { useCatalogsContext } from "../../../contexts/CatalogsContext";
//COMPONENTS
import InputFormCol from "../../../components/atoms/InputFormCol";
import InputNumeric from "../../../components/atoms/InputNumeric";
import InputCheckboxList from "../../../components/atoms/InputCheckboxList";
import ModalConfirmCloseModal from "../../../components/atoms/ModalConfirmCloseModal";
import ModalConfirmGeneral from '../../../components/atoms/ModalConfirmGeneral';
import exclamationIcon from '../../../assets/icons/icon-exclamation-mark.svg';


const StoriesForm = ({ phaseID, floorTypeID, showModal, handleShowModal, handleFloorTypeID, handleRefreshList, storiesList, levelList }) => {
    const rest = new RestActions();
    let mainContext = useMainContext();
    let catalogs = useCatalogsContext();

    const [formData, setFormData] = useState({
        name: "",
        areaModels: [],
        areaM2: null,
        isTypePlant: false,
        status: true,
        isUnderground: false
    });
    const [isSubmited, setIsSubmited] = useState(false);
    const [storyName, setStoryName] = useState("Nombre tipo de planta");
    const [showConfirmClose, setShowConfirmClose] = useState(false);
    const [showConfirmAreasNumber, setShowConfirmAreasNumber] = useState(false);
    const [isAbleToChangeUnderground, setIsAbleToChangeUnderground] = useState(true);

    useEffect(() => {
        if (showModal) {
            if (floorTypeID)
                getStory();
        }
    }, [showModal])

    useEffect(() => {
        if (formData.name !== undefined)
            setStoryName(formData.name);

    }, [formData])

    const onFormDataChange = async (paramName, e) => {
        mainContext.setIsDirty(true);
        setFormData({ ...formData, [paramName]: e.value });
    }

    const onChangeTypePlant = async () => {
        let typePlant = _.filter(storiesList, (row) => { return (row.isTypePlant) });
        if (!formData.isTypePlant && typePlant.length && typePlant[0].floorTypeID != floorTypeID)
            mainContext.setMessageAlert("Actualmente ya existe una “Planta tipo” en el proyecto, si guardas estos cambios ahora esta “Tipo de planta” será la “Planta tipo” del proyecto.");

        setFormData({ ...formData, isTypePlant: !formData.isTypePlant });
        mainContext.setIsDirty(true);
    }

    const onChangeIsUnderground = async () => {
        let isUnderground = _.filter(levelList, (row) => { return (floorTypeID && row.floorTypeID == floorTypeID) });

        if (isUnderground.length)
            mainContext.setMessageAlert("Quitar la asignacion del tipo de planta para poder cambiar el campo 'Es subterraneo?'");
        else {
            setFormData({ ...formData, isUnderground: !formData.isUnderground });
            mainContext.setIsDirty(true);
        }
    }


    const onDataLocationsChange = async (paramName, index, e) => {
        mainContext.setIsDirty(true);
        let areaModels = [...formData.areaModels];
        areaModels[index][paramName] = e.value;
        setFormData({ ...formData, areaModels });
    }

    const handleCheckboxList = async (paramName, data) => {
        mainContext.setIsDirty(true);
        setFormData({ ...formData, [paramName]: data });
    }

    const getStory = async () => {
        mainContext.setIsLoading(true);
        try {
            let response = await rest.post("getFloorTypes", { phaseID: parseInt(phaseID), floorTypeID: floorTypeID });

            let _isAbleToChangeUnderground = true;
            if (floorTypeID) {
                _.forEach(levelList, (row) => {
                    if (row.floorTypeID && row.floorTypeID.floorTypeID == floorTypeID)
                        _isAbleToChangeUnderground = false;
                })
            }
            setIsAbleToChangeUnderground(_isAbleToChangeUnderground);
            setFormData(response[0]);
            mainContext.setIsLoading(false);
        }
        catch (error) { console.log(error);/*TODO SOMETHING*/ }
    }

    const generateTable = async () => {
        let areaModels = [];
        for (let i = 0; i < parseInt(formData.areas); i++) {
            areaModels.push({ orderID: i + 1, modelID: null, comment: "", areaLocationID: null });
        }
        setShowConfirmAreasNumber(false);
        setFormData({ ...formData, areaModels: areaModels });
    }

    const isAbleToClose = async () => {
        if (mainContext.isDirty)
            setShowConfirmClose(true);
        else
            resetModal();
    }

    const resetModal = async () => {
        setFormData({ name: "", areaModels: [], isTypePlant: false, status: true, isUnderground: false });
        setStoryName("Nombre tipo de planta");
        setShowConfirmClose(false);
        handleShowModal(false);
        handleFloorTypeID(null);
        setIsSubmited(false);
        mainContext.setIsLoading(false);
        mainContext.setIsDirty(false);
    }

    const getErrorClass = (value) => {
        if (isSubmited && (value == "" || !value))
            return "error-border";
        else
            return "";
    }

    const getCommentErrorClass = (index, value) => {
        if (formData.areaModels[index].modelID) {
            let model = _.filter(catalogs.models, (model) => { return model.modelID === formData.areaModels[index].modelID })[0];

            if (model.modelTypeID === 0 && isSubmited && (value == undefined || value == null || value.trim() == ""))
                return "error-border";
            else
                return "";
        }
    }

    const validateForm = () => {
        let requiredData = ["name", "areas", "floorTypeUses"];
        let trimData = ["name"];
        let isValid = true;
        requiredData.forEach((row) => {
            if (formData[row] == undefined || formData[row] == null || formData[row] == "")
                isValid = false;
        })
        trimData.forEach((row) => {
            if (formData[row] == undefined || formData[row] == null || formData[row].trim() == "")
                isValid = false;
        })

        if (formData.areaModels.length) {
            _.forEach(formData.areaModels, (area) => {
                let requiredDataAreas = ["areaLocationID", "modelID"];
                requiredDataAreas.forEach((property) => {
                    if (area[property] == undefined || area[property] == null || area[property] == "")
                        isValid = false;
                })

                if (isValid) {
                    let model = _.filter(catalogs.models, (model) => { return model.modelID === area.modelID })[0];
                    if (model.modelTypeID === 0 && (area.comment == undefined || area.comment == null || area.comment.trim() == ""))
                        isValid = false;
                }
            })
        }
        else {
            isValid = false;
            mainContext.setMessageAlert("Es necesario generar la tabla de asignación de modelos");
        }

        if (!isValid)
            mainContext.setMessageAlert("Es necesario llenar todos los campos obligatorios (*)");
        return isValid;
    }

    const sendForm = async () => {
        setIsSubmited(true);
        if (!validateForm())
            return false;

        mainContext.setIsLoading(true);
        let params = {
            ...formData,
            userID: mainContext.session.userID,
            phaseID: parseInt(phaseID),
        };

        if (params.areaM2 === '')
            params.areaM2 = null;

        let integers = ["areas", "areaM2"];
        _.forEach(integers, function (row) {
            let sincomas;
            if (typeof params[row] === 'string') {
                sincomas = params[row].replace(/,/g, "")
            } else {
                sincomas = params[row]
            }
            params[row] = parseInt(sincomas);
        });

        let action = "createFloorType";
        if (floorTypeID) {
            params.floorTypeID = floorTypeID;
            action = "updateFloorType";
            if (params.dataImage)
                params.isUpdateImage = true;
        }
        try {
            await rest.post(action, params);
            mainContext.setMessageAlert("El registro se guardó correctamente");
            handleRefreshList();
            resetModal();
        }
        catch (error) {
            mainContext.setMessageAlert(error.toString());
            mainContext.setIsLoading(false);
        }
    }

    return (
        <>
            <ModalConfirmGeneral show={showConfirmAreasNumber} handleConfirm={generateTable} handleCancel={setShowConfirmAreasNumber} icon={exclamationIcon}>
                <div className="col">
                    <p className="title-modal"><b>¿Estas seguro que el número de áreas es correcto?</b></p>
                    <p className="">Al aceptar no se podrá modificar</p>
                </div>
            </ModalConfirmGeneral>

            <Modal className="px-4" size={"models"} show={showModal} onHide={isAbleToClose} backdrop="static">
                <ModalConfirmCloseModal show={showConfirmClose} handleShowClose={setShowConfirmClose} handleConfirmClose={resetModal} />
                <Modal.Header className="no-border" closeButton>
                    <Modal.Title className="pl-3"></Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-5">
                    <div className="custom-card">
                        <div className="custom-card-header">
                            <h4 className="py-3 px-3">{storyName}</h4>
                        </div>
                        <div className="custom-card-body px-3">
                            <div className="row">
                                <InputFormCol col="col-2" label="* Nombre del tipo de planta" value={formData.name} isSubmited={isSubmited} trim={false}>
                                    <input value={formData.name} onChange={(e) => { onFormDataChange("name", e.target) }} maxLength="50" className="form-control black-border large-input" type="text" />
                                </InputFormCol>

                                <InputFormCol col="col-2" label="* Áreas por planta" value={formData.areas} isSubmited={isSubmited}>
                                    <InputNumeric maxlength="3" paramName="areas" decimal={0} handleValue={onFormDataChange} defaultValue={formData.areas} isDisabled={(formData.areaModels.length)} />
                                </InputFormCol>

                                <InputFormCol col="col-2" label="Superficie en M2" value={formData.areaM2} isSubmited={false}>
                                    <NumberFormat
                                        decimalScale={0}
                                        isAllowed={(value) => {
                                            if (value.floatValue <= 10000000)
                                                return true
                                            else if (value.formattedValue === '')
                                                return true
                                            else
                                                return false;

                                        }}
                                        placeholder={"-----"}
                                        allowNegative={false}
                                        thousandSeparator={true}
                                        min={0}
                                        max={10000000}
                                        value={formData.areaM2}
                                        className="form-control black-border levels "
                                        data-pr-tooltip="Rango válido del 1 - 5" data-pr-position="left"
                                        onChange={(e) => { onFormDataChange('areaM2', e.target); }}
                                    />
                                    {/*<InputNumeric maxlength="8" paramName="areaM2" decimal={2} handleValue={onFormDataChange} defaultValue={formData.areaM2} isDisabled={false} />*/}
                                </InputFormCol>

                                <InputFormCol label="¿Es la planta tipo?" col="col-2">
                                    <Form.Check checked={formData.isTypePlant} onChange={onChangeTypePlant} type="switch" label="" id="isTypePlant" className="mt-2" />
                                </InputFormCol>

                                <InputFormCol label="Estatus de planta" col="col-2">
                                    <Form.Check checked={formData.status} onChange={(e) => { onFormDataChange("status", { value: !formData.status }) }} type="switch" label="" id="status" className="mt-2" />
                                </InputFormCol>
                                {(isAbleToChangeUnderground) ?
                                    <InputFormCol label={"¿Es subterráneo?"} col="col-2">
                                        <Form.Check checked={formData.isUnderground} onChange={(e) => { onChangeIsUnderground() }} type="switch" label="" id="isUnderground" className="mt-2" />
                                    </InputFormCol>
                                    :
                                    <InputFormCol label={<span title="No es posible cambiar el valor debido a que existen niveles relacionados">¿Es subterráneo? <FontAwesomeIcon icon={faQuestionCircle} /></span>} col="col-2">
                                        <Form.Check checked={formData.isUnderground} onChange={(e) => { onChangeIsUnderground() }} type="switch" label="" id="isUnderground" className="mt-2" disabled={true} />
                                    </InputFormCol>
                                }
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <label class="input-label form-label mx-3">* Usos de la planta</label>
                                    <InputCheckboxList handleData={handleCheckboxList} catalog={catalogs.floorTypeUses} currentData={formData.floorTypeUses} idProperty="floorTypeUseID" paramName="floorTypeUses" displayName="description" cssClass={"checkbox-container-row d-flex flex-wrap justify-content-between"} />
                                    {
                                        (isSubmited && formData.floorTypeUses && !formData.floorTypeUses.length) &&
                                        <p className="col-12 with-message mx-3">
                                            <small className="error">*Campo obligatorio</small>
                                        </p>
                                    }
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-2 offset-10 d-flex justify-content-end">
                                    <button onClick={() => { setShowConfirmAreasNumber(true) }} className={`btn btn-primary w-100`} disabled={(!formData.areas || formData.areaModels.length)}>
                                        Generar tabla
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="custom-card mt-4">
                        <div className="custom-card-header">
                            <h4 className="py-3 px-3">ASIGNACIÓN DE NIVELES</h4>
                        </div>
                        <div className="custom-card-body px-3">
                            <div className="row">
                                <div className="col-12">
                                    <table className="w-100 table-no-border table-striped">
                                        <thead>
                                            <tr>
                                                <th className="text-center" width="90">Áreas</th>
                                                <th>Modelo</th>
                                                <th>Ubicación</th>
                                                <th>Otros / Comentarios</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                formData.areaModels.map((area, index) =>
                                                (
                                                    <tr key={`row-${index}`}>
                                                        <td className="text-center">
                                                            {area.orderID}
                                                        </td>
                                                        <td>
                                                            <Dropdown value={formData.areaModels[index].modelID} options={catalogs.models} onChange={(e) => { onDataLocationsChange('modelID', index, e); }} optionValue="modelID" optionLabel="name" placeholder="Seleccionar" className={`${getErrorClass(formData.areaModels[index].modelID)}`} style={{ width: "250px" }} />
                                                        </td>
                                                        <td>
                                                            <Dropdown value={formData.areaModels[index].areaLocationID} options={catalogs.areaLocations} onChange={(e) => { onDataLocationsChange('areaLocationID', index, e); }} optionValue="areaLocationID" optionLabel="description" placeholder="Seleccionar" className={`${getErrorClass(formData.areaModels[index].areaLocationID)}`} style={{ width: "250px" }} />
                                                        </td>
                                                        <td>
                                                            <input value={formData.areaModels[index].comment} onChange={(e) => { onDataLocationsChange('comment', index, e.target); }} className={`form-control ${getCommentErrorClass(index, formData.areaModels[index].comment)}`} type="text" />
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-2 offset-10 d-flex justify-content-end">
                            <button onClick={sendForm} disabled={!mainContext.isDirty} className="btn btn-primary w-100">Guardar</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default StoriesForm