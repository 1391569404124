import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import './styles.css'
/**
    * Componente <ModalConfirmImport /> Abre un modal de confirmación customizable
    * @component
    * @param  props
    * @param {bool} props.show - Muestra/oculta el modal.
    * @param {function} props.handleCancel - Maneja la cancelación.
    * @param {function} props.handleConfirm - Maneja la confirmacion.
    * @param {string}  props.icon - Icono que se mostrara en el modal.
    * @param {object}  props.children - Contenido del modal.
*/
const ModalConfirmImport = ({ show, handleCancel, handleConfirm, icon, children, footerButtons = true, size = "lg", hideCancel = false, hideConfirm = false }) => {
    /**
        * Confimacion.
    */
    const onConfirm = () => {
        handleConfirm();
    }

    /**
        * Cancelacion.
    */
    const onCancel = () => {
        handleCancel(false)
    }

    return (
        <Modal size={size} show={show} backdrop="static" centered>
            <Modal.Body>
                <div className="row my-3 py-1">
                    <div className="col col-md-4 offset-md-4 text-center">
                        <img src={icon} className={"icon-logout"} />
                    </div>
                </div>
                <div className="row text-center mb-5">
                    {children}
                </div>
                {footerButtons ? (
                    <div className="row justify-content-end">
                        <div className="mx-2">
                            {!hideCancel ? (<button className="btn btn-white" onClick={() => { onCancel() }} data-cy="btnCancelConfirm">Cancelar</button>)
                                : null}
                        </div>
                        <div className="mx-2">
                            {!hideConfirm ? (<button className="btn btn-primary btn-block" onClick={() => { onConfirm() }} data-cy="btnConfirm">Aceptar</button>)
                                : null}
                        </div>
                    </div>
                ) : (
                    <div className="row justify-content-end">
                        <div className="mx-2">
                            <button className="btn btn-primary btn-block" onClick={() => { onConfirm() }} data-cy="btnConfirm">Aceptar</button>
                        </div>
                    </div>
                )}

            </Modal.Body>
        </Modal >
    )
}
export default ModalConfirmImport;