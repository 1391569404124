import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Form from 'react-bootstrap/Form'
//ACTION && CONTEXT
import { RestActions } from "../../../actions";
import { useMainContext } from "../../../contexts/MainContext";
import { useCatalogsContext } from "../../../contexts/CatalogsContext";
//COMPONENTS
import { LoadScript } from "@react-google-maps/api";
import MapView from '../../../components/atoms2/MapcustomComp';
import { removeAccents } from "../../../utils/utils";
import { statesMexico } from "../../../utils/statesMexico";
import Dropdown from "../../../components/atoms/Dropdown";
import InputFormCol from "../../../components/atoms/InputFormCol";
import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";
import './styles.css'
import { useGeneralInfoContext } from "../../../contexts/GeneralInfoContext";
import InputNumeric from "../../../components/atoms/InputNumeric";


const LocationInfoForm = ({ edit, handlerFormData, isSubmited, initData, statusComercial }) => {
    const restActions = new RestActions();
    let mainContext = useMainContext();
    let catalogs = useCatalogsContext();
    const lib = ["places", "drawing"];
    const key = process.env.REACT_APP_GOOGLE_API; // PUT GMAP API KEY HERE
    //GOOGLE MAPS
    const [mapZoom, setMapZoom] = useState(8);
    const [bounds, setBounds] = useState();
    //VALUES
    const [formData, setFormData] = useState({ latitud: "", longitud: "", useRegulation: false, countryID: null, stateID: null, municipalityID: null, zoneID: null, regulationZoneID: null, jsonGoogle: null, isForeign: false });
    const [allCatalogs, setAllCatalogs] = useState({ states: [], municipalities: [] });
    const [regulationName, setRegulationName] = useState("");
    const [regulationSection, setRegulationSection] = useState("");
    const [dataInitialized, setDataInitialized] = useState(false);
    const [initMarker, setInitMarker] = useState(null);
    const [initPolygon, setInitPolygon] = useState([]);
    const [notesWordCount, setNotesWordCount] = useState(0);

    useEffect(() => {
        if (edit && initData.phaseID) {
            initForm();
        } else if (!edit) {
            mainContext.setIsLoading(false);
        }
    }, [initData])

    useEffect(() => {
        handlerFormData(formData);
        setNotesWordCount(formData?.observations?.length);
    }, [formData])


    const onFormDataChange = async (name, e) => {
        mainContext.setIsDirty(true);
        setFormData({ ...formData, [name]: e.value });
    }

    const initForm = async () => {
        let currencyIndex = _.findIndex(catalogs.currencies, (row) => { return row.currencyID == initData.currencyID });
        if (initData.locations == null) {
            setFormData({
                currencyID: catalogs.currencies[currencyIndex],
                isForeign: initData.isForeign,
                observations: initData.observations
            });
            return;
        }

        if (initData.locations.regulationZones) {
            setRegulationName(initData.locations.regulationZones.regulationNames.regulationName);
            setRegulationSection(initData.locations.regulationZones.description);
        }
        if (initData.locations.municipalities) {
            setMapZoom(15);
            let country = initCombo(catalogs.countries, "countryID", initData.locations.municipalities.states.countries.countryID)
            let state = initCombo(country.states, "stateID", initData.locations.municipalities.states.stateID);
            let municipality = initCombo(state.municipalities, "municipalityID", initData.locations.municipalities.municipalityID)

            let zones = await getZones(initData.locations.latitud, initData.locations.longitud);
            let zone = initCombo(zones, "zoneID", initData.locations?.zones?.zoneID);

            setBounds({ lat: parseFloat(initData.locations.latitud), lng: parseFloat(initData.locations.longitud) });
            setInitMarker({ lat: parseFloat(initData.locations.latitud), lng: parseFloat(initData.locations.longitud) });
            setInitPolygon(JSON.parse(initData.locations.polygon))
            setFormData({
                useRegulation: initData.useRegulation,
                latitud: initData.locations.latitud,
                longitud: initData.locations.longitud,
                cadastralAdress: initData.locations.cadastralAdress,
                countryID: country,
                stateID: state,
                municipalityID: municipality,
                zoneID: zone,
                regulationZoneID: (initData.locations.regulationZones) ? initData.locations.regulationZones.regulationZoneID : null,
                currencyID: catalogs.currencies[currencyIndex],
                isForeign: initData.isForeign,
                observations: initData.observations
            });
            setAllCatalogs({ ...allCatalogs, states: country.states, municipalities: state.municipalities, zones: zones });
        } else if (initData.locations.latitud) {
            setMapZoom(19);
            let zones = await getZones(initData.locations.latitud, initData.locations.longitud);
            let zone = initCombo(zones, "zoneID", initData.locations?.zones?.zoneID);
            setBounds({ lat: parseFloat(initData.locations.latitud), lng: parseFloat(initData.locations.longitud) });
            setInitMarker({ lat: parseFloat(initData.locations.latitud), lng: parseFloat(initData.locations.longitud) });
            setFormData({
                currencyID: catalogs.currencies[currencyIndex],
                isForeign: initData.isForeign,
                zoneID: zone,
                observations: initData.observations,
                latitud: initData.locations.latitud,
                longitud: initData.locations.longitud,
            });
        } else {
            setFormData({
                currencyID: catalogs.currencies[currencyIndex],
                isForeign: initData.isForeign,
                observations: initData.observations
            });
        }
        setDataInitialized(true);
    }

    const initCombo = (catalog, filterBy1, filterBy2) => {
        if (catalog)
            return catalog.filter((element) => { return element[filterBy1] == filterBy2 })[0];
        else
            return null;
    }

    const onCountryChange = async (e) => {
        mainContext.setIsDirty(true);
        setFormData({ ...formData, countryID: e.value, stateID: null, municipalityID: null, zoneID: null });
        if (e.value != null) {
            await setAllCatalogs({ ...allCatalogs, states: e.value.states == null ? [] : e.value.states });
            onChangeCombos(e.value, "country");
        }
        else {
            setAllCatalogs({ ...allCatalogs, states: [], municipalities: [], zones: [] });
        }
    }

    const onStateChange = async (e) => {
        mainContext.setIsDirty(true);
        setFormData({ ...formData, stateID: e.value, municipalityID: null, zoneID: null, latitud: "", longitud: "" });
        if (e?.value != null) {
            await setAllCatalogs({ ...allCatalogs, municipalities: e.value.municipalities, zones: [] });
            onChangeCombos(e.value, "state");
        }
        else {
            setAllCatalogs({ ...allCatalogs, municipalities: [], zones: [] });
        }
    }

    const onMunicipalityChange = async (e) => {
        mainContext.setIsDirty(true);
        setFormData({ ...formData, municipalityID: e.value });
    }

    const onZoneChange = async (e) => {
        if (e.value) {
            let regulationZoneID = await getRegulations(formData.latitud, formData.longitud);
            let useRegulation = formData.useRegulation;
            if (!regulationZoneID)
                useRegulation = false;
            setFormData({ ...formData, zoneID: e.value, useRegulation: useRegulation });
        }
        else {
            setFormData({ ...formData, zoneID: e.value, useRegulation: false });
            setRegulationName("");
            setRegulationSection("");
        }
        mainContext.setIsDirty(true);
    }

    const onChangeCombos = async (value, type) => {
        let address;
        let zoom;
        if (type == "country") {
            address = value.countryName + "," + value.countryName;
            (mapZoom == 5) ? zoom = 6 : zoom = 5;
        }
        else if (type == "state") {
            address = value.stateName + "," + formData.countryID.countryName;
            (mapZoom == 10) ? zoom = 11 : zoom = 10;
        }

        let request = new RestActions();
        try {
            let response = await request.getURL(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${process.env.REACT_APP_GOOGLE_API}`);
            await setBounds(response.data.results[0].geometry.location);
            setMapZoom(zoom);
        }
        catch (error) {
            console.log("error ", error);

        }
    }

    const handleChangeCoordinates = async (lat, lng) => {
        await getDirectionByPoint(lat, lng);
    }
    const handlePolygon = async (polygon) => {
        setFormData({ ...formData, polygon: polygon })
        mainContext.setIsDirty(true);
    }

    const getZones = async (lat, lng) => {
        let request = new RestActions();
        let zones = [];
        try {
            zones = await request.post("getZoneByPoint", { "latitude": lat, "longitude": lng });
            if (zones[0].id == 0) {
                zones[0] = { ...zones[0], name: "Zona fuera de rango" };
            }
        }
        catch (error) {
            console.log("error ", error);
        }
        return zones;
    }

    const getRegulations = async (lat, lng) => {
        let request = new RestActions();
        try {
            let regulations = await request.post("getRegulationZoneByPoint", { "latitude": lat, "longitude": lng });
            //if (regulations[0].id != 0) {
            setRegulationName(regulations[0].regulationNames.name);
            setRegulationSection(regulations[0].name);
            setFormData({ ...formData, regulationZoneID: regulations[0].id });
            return regulations[0].id;
            //} else {
            //    setRegulationName("");
            //    setRegulationSection("");
            //    setFormData({ ...formData, regulationZoneID: null });
            //    return null;
            //}
        }
        catch (error) {
            console.log("error ", error);

        }
        mainContext.setIsDirty(true);
    }

    const getDirectionByPoint = async (lat, lng) => {
        let request = new RestActions();
        mainContext.setIsLoading(true);

        if (lat != "" && lng != "") {
            try {
                await setBounds({ lat: lat, lng: lng });
                let response = await request.getURL("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + lat + "," + lng + "&key=" + process.env.REACT_APP_GOOGLE_API);
                let newData = { country: null, state: null, stateCat: [], municipality: null, municipalityCat: [], zonesCat: [], lat: lat, lng: lng, useRegulation: formData.useRegulation, jsonGoogle: response }
                if (response.data.results.length) {
                    let address = response.data.results[0].address_components;

                    newData.cadastralAdress = response.data.results[0].formatted_address;
                    let countryIndex = await address.findIndex(element => element.types[0] == "country");
                    if (countryIndex == -1) {
                        setRegulationName("");
                        setRegulationSection("");
                        newData.useRegulation = false;
                        setLocationsAtOnce(newData);
                        return;
                    }
                    countryIndex = catalogs.countries.findIndex(element => removeAccents(element.countryName) == removeAccents(address[countryIndex].long_name));
                    newData.country = catalogs.countries[countryIndex];

                    newData.stateCat = newData.country.states;
                    let stateIndex = address.findIndex(element => element.types[0] == "administrative_area_level_1");
                    if (newData.country && stateIndex != -1) {
                        if (newData.country.countryName === "México") {
                            let currentState = removeAccents(address[stateIndex].long_name)
                            let currentState2 = statesMexico[removeAccents(address[stateIndex].long_name).toLowerCase()];

                            stateIndex = await newData.country.states.findIndex(element => removeAccents(element.stateName) == removeAccents(currentState2))
                        } else {
                            stateIndex = await newData.country.states.findIndex(element => removeAccents(element.stateName) == removeAccents(address[stateIndex].long_name));
                        }
                        newData.state = newData.country.states[stateIndex];

                        newData.municipalityCat = newData.state.municipalities;
                    }

                    let municipalityIndex = await address.findIndex(address => address.types[0] == "locality");
                    if (newData.state && municipalityIndex != -1) {
                        municipalityIndex = await newData.state.municipalities.findIndex(element => removeAccents(element.municipalityName) == removeAccents(address[municipalityIndex].long_name));
                        newData.municipality = newData.state.municipalities[municipalityIndex];
                    }

                    newData.zonesCat = await getZones(lat, lng);
                    newData.zone = newData.zonesCat[0];
                    newData.regulationZoneID = await getRegulations(lat, lng);
                    if (!newData.regulationZoneID)
                        newData.useRegulation = false;
                }
                setLocationsAtOnce(newData);
                mainContext.setIsLoading(false);
            }
            catch (error) {
                console.log("error getDirectionByPoint ", error);
                mainContext.setIsLoading(false);
            }

        }
        else {
            let newData = { country: null, state: null, stateCat: [], municipality: null, cadastralAdress: "", municipalityCat: [], zonesCat: [], lat: lat, lng: lng, useRegulation: formData.useRegulation, jsonGoogle: {} }
            setLocationsAtOnce(newData);
            mainContext.setIsLoading(false);
        }
    }

    const setLocationsAtOnce = (newData) => {
        setAllCatalogs({ ...allCatalogs, states: newData.stateCat, municipalities: newData.municipalityCat, zones: newData.zonesCat });
        setFormData(
            {
                ...formData,
                countryID: newData.country,
                stateID: newData.state,
                municipalityID: newData.municipality,
                //zoneID: newData.zone,
                latitud: newData.lat,
                longitud: newData.lng,
                regulationZoneID: newData.regulationZoneID,
                useRegulation: newData.useRegulation,
                jsonGoogle: newData.jsonGoogle,
                cadastralAdress: newData.cadastralAdress
            });
        mainContext.setIsDirty(true);
    }

    let generalInfoContext = useGeneralInfoContext()


    return (
        <>
            <section className="mt-4 custom-card">
                <div className="custom-card-header py-3">
                    <h4 className="px-3">UBICACIÓN</h4>
                </div>
                <div className="custom-card-body px-3">
                    <div className="row my-3">
                        <div className="col">
                            <LoadScript googleMapsApiKey={key} libraries={lib}>
                                <MapView
                                    zoom={mapZoom}
                                    initMarker={initMarker}
                                    initPolygon={initPolygon}
                                    center={bounds}
                                    generalInfoContext={generalInfoContext}
                                    handler={handleChangeCoordinates}
                                    handlerPolygon={handlePolygon} />
                            </LoadScript>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="form-group with-message">
                                <label className="form-label">* País</label>
                                <Dropdown value={formData.countryID} options={catalogs.countries} onChange={onCountryChange} optionLabel="countryName" optionValue="countryID" />
                                {
                                    (isSubmited && !formData.countryID && (statusComercial && statusComercial.id != 1)) &&
                                    <span className="error">*Campo obligatorio</span>
                                }
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group with-message">
                                <label className="form-label">* Estado</label>
                                <Dropdown value={formData.stateID} options={allCatalogs.states} onChange={onStateChange} optionLabel="stateName" optionValue="stateID" />
                                {
                                    (isSubmited && !formData.stateID && (statusComercial && statusComercial.id != 1)) &&
                                    <span className="error">*Campo obligatorio</span>
                                }
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group with-message">
                                <label className="form-label">* Delegación / Municipio</label>
                                <Dropdown value={formData.municipalityID} options={allCatalogs.municipalities} onChange={onMunicipalityChange} optionLabel="municipalityName" optionValue="municipalityID" />
                                {
                                    (isSubmited && !formData.municipalityID && (statusComercial && statusComercial.id != 1)) &&
                                    <span className="error">*Campo obligatorio</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="form-group with-message">
                                <label className="form-label">* Zona</label>
                                <Dropdown value={formData.zoneID} options={allCatalogs.zones} onChange={onZoneChange} optionLabel="zoneName" optionValue="zoneID" disabled={false} />
                                {
                                    (isSubmited && !formData.zoneID) &&
                                    <span className="error">*Campo obligatorio</span>
                                }
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group with-message">
                                <label className="input-label form-label">
                                    * Latitud
                                </label>
                                <input type="text" value={formData.latitud} disabled className="form-control black-border large-input" />
                                {
                                    (isSubmited && !formData.latitud && (statusComercial && statusComercial.id != 1)) &&
                                    <span className="error">*Campo obligatorio</span>
                                }
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group with-message">
                                <label className="input-label form-label">
                                    * Longitud
                                </label>
                                <input type="text" value={formData.longitud} disabled className="form-control black-border large-input" />
                                {
                                    (isSubmited && !formData.longitud && (statusComercial && statusComercial.id != 1)) &&
                                    <span className="error">*Campo obligatorio</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="form-group with-message">
                                <label className="input-label form-label">
                                    * Dirección catastral
                                </label>
                                <input onChange={(e) => { onFormDataChange("cadastralAdress", e.target) }} value={formData.cadastralAdress} type="text" className="form-control black-border large-input" />
                                {
                                    (isSubmited && !formData.cadastralAdress && (statusComercial && statusComercial.id != 1)) &&
                                    <span className="error">*Campo obligatorio</span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="row mt-4">
                <div className="col-6">
                    <div className="custom-card p-0 h-100">
                        <div className="custom-card-header py-3">
                            <h4 className="px-3">REGULACIONES</h4>
                        </div>
                        <div className="custom-card-body px-3">
                            <div className="form-group">
                                <label className="input-label form-label">Aprovechó regulación</label>
                                <Form.Check
                                    disabled={(regulationName != "") ? false : true}
                                    type="switch"
                                    id="regulations-switch"
                                    label=""
                                    checked={formData.useRegulation}
                                    onChange={(e) => {
                                        setFormData({ ...formData, useRegulation: !formData.useRegulation })
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <label className="input-label form-label">Nombre de regulación</label>
                                <input value={regulationName} type="text" disabled className="fblack-border form-control" />
                            </div>
                            <div className="form-group">
                                <label className="input-label form-label">Sección de regulación</label>
                                <input value={regulationSection} type="text" disabled className="fblack-border form-control" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="custom-card p-0 h-100">
                        <div className="custom-card-header py-3">
                            <h4 className="px-3">MONEDA DE VENTA</h4>
                        </div>
                        <div className="custom-card-body px-3">
                            <InputFormCol label="¿Es moneda extranjera?" col="col-12" withMessage={false}>
                                <Form.Check checked={formData.isForeign} onChange={(e) => { onFormDataChange("isForeign", { value: !formData.isForeign }) }} type="switch" id="exchange-switch" label="" />
                            </InputFormCol>
                            <InputFormCol label="Moneda" col="col-12">
                                <Dropdown value={formData.currencyID} options={catalogs.currencies} onChange={(e) => { onFormDataChange("currencyID", e) }} optionValue="currencyID" optionLabel="description" />
                            </InputFormCol>
                        </div>
                    </div>
                </div>
            </section>
            <section className="row mt-4">
                <div className="col-12">
                    <div className="custom-card p-0 h-100">
                        <div className="custom-card-header py-3">
                            <h4 className="px-3">NOTAS / OBSERVACIONES</h4>
                        </div>
                        <div className="custom-card-body px-3">
                            <textarea onChange={(e) => { onFormDataChange("observations", e.target) }} value={formData.observations} maxLength="200" className="black-border form-control rounded-0" />
                            <span>{notesWordCount ? notesWordCount : "0"}/200</span>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default LocationInfoForm