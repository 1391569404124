import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
//Atoms.
import Alert from '../../../atoms2/Alert';

const AddToCatalog = (props) => {
  const { catalogName, handleSubmit } = props;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [validated, setValidated] = useState(false);
  const [description, setDescription] = useState("");
  const [erroMessage, setErroMessage] = useState(false);
  
  const cleanForm = async () => {
    await setDescription("");
    await setErroMessage(false);
  }

  const handler = async (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    setIsSubmitted(true);
    setDescription(description.trim());
    form.checkValidity();
    if(description.trim() != ""){
      try {
        
        await handleSubmit(description);
        await setValidated(false);
        await cleanForm();

      } catch (error) {
        await setErroMessage( error );
        return false;
      }
    }
    await setValidated(true);
    return false;
  }
  return (
    <Form autocomplete="off" noValidate validated={validated} onSubmit={ handler } >
        <div className="custom-card pb-3">
          <div className="custom-card-header">
            <h4 className="p-3">AGREGAR TIPO DE AMENIDAD</h4>
          </div>
          <div className="custom-card-body px-3">
            
          <Form.Group controlId="catalogName">
              <Form.Label>Ingrese el nombre del {catalogName}</Form.Label>
              <Form.Control required type="text" placeholder="" value={ description } maxlength={30} onChange={(e) => {
                setDescription(e.currentTarget.value);
              }} />
              <Form.Control.Feedback type="invalid">
                *Campo obligatorio
              </Form.Control.Feedback>
              {erroMessage && 
                <Alert className="input__alert-error mt-2" theme={"error"} type="error" size={"large"}>
                  { erroMessage }
                </Alert>
              }
            </Form.Group>
          </div>
        </div>
        <Row className={"mt-5 mb-3"}>
          <Col md={{span:3, offset:9}} className={"text-right"}>
            <Button variant={"primary"} type={"submit"} block >
              Guardar
            </Button>
          </Col>
        </Row>
    </Form>
  )

}

export default AddToCatalog