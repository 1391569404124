import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
//ACTIONS AND CONTEXT
import { RestActions } from "../../../actions";
import { useMainContext } from "../../../contexts/MainContext";
import { useCatalogsContext } from "../../../contexts/CatalogsContext";
//COMPONENTS
import ModalConfirmDelete from '../../atoms/ModalConfirmDelete';
import InputUploadFiles from '../../atoms/InputUploadFiles';
import Dropdown from '../../atoms/Dropdown';
//ASSETS
import editImg from '../../../assets/icons/edit-files.svg';
import deleteImg from '../../../assets/icons/delete-files.svg';
import downloadImg from '../../../assets/icons/download-files.svg';
import saveImg from '../../../assets/icons/icon-save.svg';
import clipImg from '../../../assets/icons/icon-clip.svg';

/**
    * Componente <ButtonModalFiles /> Muestra un boton que abre el modal de admnistración de archivos.
    * @component
    * @param  props
    * @param {number} props.phaseID - ID de la fase.
    * @param {number}  props.projectID - ID del proyecto.
*/
const ButtonModalFiles = ({ phaseID, projectID }) => 
{
    const rest = new RestActions();
    let mainContext = useMainContext();
    let catalogs = useCatalogsContext();

    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({description:"", fileTypeID:null, userID:1});
    const [formDataEdition, setFormDataEdition] = useState({description:"", fileTypeID:null, userID:1});
    const [inEditMode, setInEditMode] = useState(false);
    const [idToDelete, setIdToDelete] = useState(0);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    //FILE
    const [acceptTypes, setAcceptTypes] = useState(false)
    const [file, setFile] = useState(null);
    const [allFiles, setAllFiles] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => 
    {
        if(phaseID)
            getFiles();
    }, [])

    /**
        * Obtiene el listado de archivos por medio de una peticion POST.
    */
    const getFiles = async () => 
    {
        try
        {
            let response = await rest.post("getPhaseFiles", {phaseID:parseInt(phaseID)});
            await setAllFiles(response);
        }
        catch (error) {/*TODO SOMETHING*/}
    }

    /**
        * Guarda el archivo por medio de una peticion POST.
        * @param {number} orderID - ID del archivo.
    */
    const saveAttachedFile = async (orderID) => 
    {
        let _data = {...formData}
        if(inEditMode)
            _data = {...formDataEdition};
        let required = ["fileTypeID","description"];
        let isValid = true;
        setIsSubmitted(true);
        required.forEach((element)=>{
            if(_data[element] == null || _data[element] =="")
                isValid = false;
        });
        if(orderID == 0 && file == null){
            isValid = false;
        }
        if(isValid)
        {
            setIsSubmitted(false);
            setInEditMode(false);
            let params = {..._data, 
                phaseID:parseInt(phaseID),
                projectID:projectID,
                orderID: orderID,
                fileTypeID: _data.fileTypeID.id,
                fileName: file ? file.name : false,
                fileData: file ? file.body : false,
                isFileUpdate: file ? 1 : 0,
            }
            try
            {
                mainContext.setIsLoading(true);
                await rest.post("savePhaseFiles", params);
                setFormData({...formData, fileTypeID:null, description:"" });
                setFile(null);
                setAcceptTypes(!acceptTypes);
                mainContext.setIsLoading(false);
                getFiles();
            }
            catch (error) {
                mainContext.setIsLoading(true);
                /*TODO SOMETHING*/
            }
        }
    }

    /**
        * Selecciona un archivo para edición.
        * @param {object} data - Informacion del archivo seleccionado
    */
    const editFile = async (data) => 
    {
        setIsSubmitted(false);
        await setInEditMode({ status: true, rowKey: data.orderID });
        let fileTypeID = catalogs.fileTypes.filter((element)=>{ return element.id == data.fileTypes.fileTypeID})[0];
        setFormDataEdition({...formDataEdition, description:data.description, fileTypeID:fileTypeID});
    }

    /**
        * Selecciona un archivo para borrar.
        * @param {number} id - ID del archivo.
    */
    const requestDelete = async (id) => 
    {
        setIdToDelete(id); 
        setShowDeleteModal(true);
    }

    /**
        * Borrar un archivo por medio de una petición POST.
    */
    const deleteFile = async () => 
    {
        setShowDeleteModal(false);
        try
        {
            await rest.post("deletePhaseFiles", {phaseID:parseInt(phaseID), orderID:idToDelete});
            getFiles();
        }
        catch (error) {/*TODO SOMETHING*/}
    }

    /**
        * Maneja los cambios en el formulario.
    */
    const onFormDataChange = async (name, e) => 
    {
        if(inEditMode)
            setFormDataEdition({...formDataEdition, [name]:e.value});
        else
            setFormData({...formData, [name]:e.value});
    }
    /**
        * Maneja los cambios en el campo tipo de archivo del formulario.
    */
    const handleChangeType = async (e) => 
    {
        onFormDataChange("fileTypeID", e);
    }


    return (
        <>
            <ModalConfirmDelete handlerAcceptDelete={deleteFile} showModal={showDeleteModal} setShow={setShowDeleteModal} title={"logout"} />
            <button onClick={()=>{ setShowModal(true)}} type="button" className="btn btn-white btn-submenu mr-3">
                <img src={clipImg} className="" alt={"Editar"}/>
            </button>

            <Modal size={ (phaseID) ? "addFiles" : "logout" } show={showModal} onHide={setShowModal} backdrop="static" centered>
                <Modal.Header className="no-border p-1" closeButton>
                    <Modal.Title className="pl-3"></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="custom-card h-100 mx-3">
                        <div className="custom-card-header">
                            <h4 className="py-3 px-3">ARCHIVOS ADJUNTOS</h4>
                        </div>
                        <div className="custom-card-body px-3 custom-card-body-files">
                            {   (!phaseID) ? 
                                    <div className="text-center mp-5 col">
                                        <span className={"title-modal"}>Necesitas crear una fase / torre.</span>
                                    </div>
                                :
                                <div className="container h-100 pb-3">
                                    <div className="row">
                                        <div className="col-2">
                                            <label className="input-label form-label">Tipo de archivo</label>
                                        </div>
                                        <div className="col-2">
                                            <label className="input-label form-label">Descripción</label>
                                        </div>
                                        <div className="col-3">
                                            <label className="input-label form-label">Archivo</label>
                                        </div>
                                    </div>
                                    <div className="row adjuntos">
                                        <div className="col-2 with-message">
                                            <Dropdown value={formData.fileTypeID} options={catalogs.fileTypes} onChange={(e)=>{handleChangeType(e)}} optionLabel="description" optionValue="id" placeholder="Tipo de Archivo"/>  
                                            {
                                                (isSubmitted && !formData.fileTypeID && !inEditMode) &&
                                                    <span className="error">*Campo obligatorio</span>
                                            }
                                        </div>
                                        <div className="col-2 with-message">
                                            <input type="text" value={formData.description} onChange={(e)=>{onFormDataChange("description", e.target)}} maxLength="50" placeholder="Descripción" className="form-control black-border large-input" />
                                            {
                                                (isSubmitted && !formData.description && !inEditMode) &&
                                                    <span className="error">*Campo obligatorio</span>
                                            }
                                        </div>
                                        <div className="col-3 with-message">
                                            <InputUploadFiles className="cursor-pointer" type={acceptTypes} setFile={setFile} />
                                            {
                                                (isSubmitted && !file && !inEditMode) &&
                                                    <span className="error">*Campo obligatorio</span>
                                            }
                                        </div>
                                        <div className="col-2 with-message"></div>
                                        <div className="col-3 with-message text-right">
                                            <button onClick={()=>{saveAttachedFile(0)}} className="btn btn-primary d-inline-block">Agregar adjunto +</button>
                                        </div>
                                    </div>

                                    <div className="table-scroll-y mt-3 table-scroll-y-scrollable">
                                        <div className="table-responsive h-100">
                                            <table id={"attachedFiles"} size={"lg"} className={"table text-center table-scroll-y table-striped"}>
                                                <thead>
                                                    <tr> 
                                                    <th>Proyecto</th>
                                                    <th>Tipo de archivo</th>
                                                    <th>Descripción</th>
                                                    <th>Fecha de carga</th>
                                                    <th>Edición</th>
                                                    <th>Eliminar</th>
                                                    <th>Descarga</th>
                                                    </tr>
                                                </thead>
                                                <tbody >
                                                {allFiles && allFiles.map(file => 
                                                (
                                                    <tr key={`tr-${file.orderID}`} className={inEditMode.status && inEditMode.rowKey === file.orderID ? "static-form text-left": ""}>
                                                        <td className="text-center">{file.phases.developerComplex.complex.complexName}</td>
                                                        <td width="200" >
                                                        {
                                                            (inEditMode.status && inEditMode.rowKey === file.orderID)
                                                            ?
                                                                <Dropdown value={formDataEdition.fileTypeID} options={catalogs.fileTypes} onChange={(e)=>{handleChangeType(e)}} optionLabel="description" optionValue="id" placeholder="Tipo de Archivo"/>
                                                            :
                                                                <span style={{width: "160px"}}>{file.fileTypes.description}</span>
                                                        }
                                                        </td>
                                                        <td width="200">
                                                        {
                                                            (inEditMode.status && inEditMode.rowKey === file.orderID) 
                                                            ?
                                                                <>
                                                                    <input type="text" value={formDataEdition.description} onChange={(e)=>{onFormDataChange("description", e.target)}} maxLength="50" className="form-control black-border large-input" />
                                                                    {
                                                                        (isSubmitted && !formDataEdition.description) &&
                                                                            <span className="error-red">*Campo obligatorio</span>
                                                                    }
                                                                </>
                                                            :
                                                                <span> {file.description}</span>
                                                        }
                                                        </td>
                                                        { inEditMode.status && inEditMode.rowKey === file.orderID ? "" : <td>{file.createdAt.split(" ")[0]}</td>}

                                                        { 
                                                            (inEditMode.status && inEditMode.rowKey === file.orderID)
                                                            ? "" 
                                                            : 
                                                                <td>
                                                                    <img onClick={() => {editFile(file); }} src={editImg} className="icon-files pointer" alt={"Editar"}/>
                                                                </td>
                                                        }
                                                        { 
                                                            (inEditMode.status && inEditMode.rowKey === file.orderID) 
                                                            ? "" 
                                                            :
                                                            <td>
                                                                <img  onClick={async () => { requestDelete(file.orderID)}} className={"icon-files pointer"} src={deleteImg} alt={"Eliminar"} id={"delete-file"}/>
                                                            </td>
                                                        }
                                                        { 
                                                            (inEditMode.status && inEditMode.rowKey === file.orderID) 
                                                            ? "" 
                                                            : <td>
                                                                <a href={`${process.env.REACT_APP_BUCKET_URL}${file.uri}`}>
                                                                    <img src={downloadImg} className={"icon-files pointer"} alt={"Download"} id={"download-file"}/>
                                                                </a>
                                                            </td>
                                                        }
                                                        { 
                                                            (inEditMode.status && inEditMode.rowKey === file.orderID) &&
                                                                <td colSpan={3} >
                                                                    <InputUploadFiles type={acceptTypes} setFile={setFile} required={false} />
                                                                </td>
                                                        }
                                                        {
                                                            (inEditMode.status && inEditMode.rowKey === file.orderID) &&
                                                                <td colSpan={2} className={"text-center"}>
                                                                    <img onClick={()=>{saveAttachedFile(file.orderID)}} src={saveImg} alt={`img-save-${file.orderID}`} className={"icon-image pointer"}
                                                                    />
                                                                </td>
                                                        }
                                                    </tr>

                                                    ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default ButtonModalFiles