import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import deleteImg from '../../../assets/icons/delete-files.svg';
import exclamationIcon from '../../../assets/icons/icon-exclamation-mark.svg';
import './styles.css'
import ImportIcon from '../../../assets/icons/icon-import.svg'
import { useMainContext } from "../../../contexts/MainContext";

const ModalImportFile = (props) => {
    let mainContext = useMainContext();
    const { showModal, setShow, setCancelAction, handlerAccept, cancel, success, size = "lg", } = props
    const [nameFile, setNameFile] = useState("Seleccionar archivo")
    const [stringFile, setStringFile] = useState("")
    /**
        * Cierra el modal.
    */
    const handleClose = () => {
        setNameFile("Seleccionar archivo")
        setShow(false);
    }

    /**
        * Comfirmacion de borrado.
    */
    useEffect(() => {
        setNameFile("Seleccionar archivo")
    }, [showModal])
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const getFileBase64 = async (event) => {
        var extension = event.name.replace(/^.*\./, '')
        extension = extension.toLowerCase();
        //aqui puedes incluir todas las extensiones que quieres permitir
        if ((extension != 'xlsx')) {
            mainContext.setMessageAlert("Tipo de archino no valido");
        } else {
            setNameFile(event.name)
            const result = await toBase64(event);
            setStringFile(result)
        }

    }
    return (
        //TODO: Fix styles
        <section>
            <Modal size={size} show={showModal} onHide={() => { handleClose() }} backdrop="static" centered >
                <Modal.Header className="no-border p-1" closeButton>
                    <Modal.Title className="pl-3"></Modal.Title>
                </Modal.Header>
                <Modal.Body className={"text-center"} >
                    <div className="custom-card">
                        <div className="custom-card-header px-3 d-flex">
                            <h4 className="py-3">IMPORTAR ARCHIVO</h4>
                            <div className=" py-2 d-flex justify-content-end">
                                {/*<button onClick={() => { setShowModal(true) }} className="btn btn-primary"><span className="d-inline-block pr-4">Nueva importación</span> +</button>*/}.
                            </div>
                        </div>
                        <div className="custom-card-body px-3 d-flex">
                            <div className="form-group with-message w-50">
                                <label className="input-label form-label">
                                    Subir archivo
                                </label>
                                <div className="">
                                    <label for="file-upload" className="custom-file-upload form-control black-border large-input -flex justify-content-between">
                                        <span>{nameFile}</span><img src={ImportIcon} />
                                    </label>
                                    <input id="file-upload" type="file" onChange={e => getFileBase64(e.target.files[0])} maxLength="50" className="form-control black-border large-input" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />

                                </div>
                            </div>
                            <div className="w-50">
                                <div className="form-group with-message px-3">
                                    <label className="input-label form-label">
                                        Nota
                                    </label>
                                    <p>Se cargará la primera hoja del archivo excel</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="container d-flex justify-content-end mt-5">
                        <button className="btn btn-white " onClick={() => { handleClose() }}>Cancelar</button>

                        <button className="btn btn-primary ml-3" onClick={() => { handlerAccept(stringFile, nameFile) }}>Importar</button>
                    </div>
                </Modal.Body>
            </Modal>
        </section>
    )
}
export default ModalImportFile