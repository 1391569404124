import Client from '../primusClient/client'
import React from 'react'
class Ws extends React.Component {

    constructor() {
        super()
        this.client = new Client(`${process.env.REACT_APP_SERVER_URL}/api`);
        this.conStatus = false;
        this.state = {
            connected: false
        }
    }

    componentDidMount() {
        this.connect();
    }

    verifyConnection = async () => {
        await this.client.connect(async () => { })
        this.conStatus = true

    }

    callAction = async (action, filters = false) => {
        return new Promise((resolve, reject) => {
            let data
            this.client.action(
                action,
                filters,
                (response) => {
                    if (response.data) {
                        if (response.data !== "no data") {
                            data = (typeof response.data === "string") ? JSON.parse(response.data) : response.data;

                        } else {
                            data = "no data"
                        }
                        resolve(data);
                    }

                    if (response.error) {
                        reject(response.error);
                    }

                }
            );
        })
    }


    getCurrenciesHistory = async (fields) => {

        if (!this.conStatus) {
            await this.verifyConnection();
        }
        const catalogObj = {
        }

        catalogObj.currencies = await this.callAction('getCurrenciesHist', fields);
        return catalogObj
    }

    getLabels = async () => {
        if (!this.conStatus) {
            await this.verifyConnection();
        }

        let labels
        labels = await this.callAction('getLabels')

        return labels

    }

    getExchangetypeCatalog = async () => {
        if (!this.conStatus) {
            await this.verifyConnection();
        }

        const catalogObj = {
        }


        return catalogObj
    }

    getSchemaTypes = async () => {
        if (!this.conStatus) {
            await this.verifyConnection();
        }
        try {
            let data = await this.callAction('getSchemaTypes');
            return data
        } catch (error) {
            throw error;
        }
    }

    getSchemaTable = async (filters) => {
        if (!this.conStatus) {
            await this.verifyConnection();
        }
        try {
            let data = await this.callAction('getSchemas', filters);
            return data
        } catch (error) {
            throw error;
        }
    }

    updateSchema = async (updData) => {
        if (!this.conStatus) {
            await this.verifyConnection();
        }
        try {
            let data = await this.callAction('updateSchemas', updData);
            return data;
        } catch (error) {
            throw error;
        }
    }

    addSchema = async (schema) => {
        if (!this.conStatus) {
            await this.verifyConnection();
        }
        try {
            let data = await this.callAction('saveSchemas', schema);
            return data;
        } catch (error) {
            throw error;
        }
    }

    saveGeneralInfoData = async (fields, code, user) => {
        if (!this.conStatus) {
            await this.verifyConnection();
        }

        fields.towers = JSON.stringify(fields.towers)
        fields.complex = JSON.stringify(fields.complex)
        fields.masterPlans = JSON.stringify(fields.masterPlans)
        fields.code = code
        fields.created_by = 1
        const data = {}



        data.phaseInfo = await this.callAction('savePhase', fields)


        return data.phaseInfo;
    }

    saveLocationData = async (fields, code, user) => {
        if (!this.conStatus) {
            await this.verifyConnection();
        }
        fields.phase_id = code
        fields.created_by = 1
        const data = {}
        data.locations = await this.callAction('saveLocations', fields)


        return data.locations;
    }

    savePhaseObservation = async (fields, id, user) => {
        if (!this.conStatus) {
            await this.verifyConnection();
        }
        fields.phase_id = id
        fields.created_by = 1
        const data = {}
        data.observacions = await this.callAction('savePhaseObservation', fields)
        return data.phaseInfo;
    }
    savePhaseAdditionalSales = async (fields, id, user) => {
        if (!this.conStatus) {
            await this.verifyConnection();
        }
        fields.phase_id = id
        fields.created_by = user
        const data = {}
        data.observacions = await this.callAction('savePhaseAdditionalSales', fields)
        return data.phaseInfo;
    }
    savePhaseExchangeRate = async (fields, id, user) => {
        if (!this.conStatus) {
            await this.verifyConnection();
        }
        fields.phase_id = id
        fields.created_by = user
        fields.is_preferencial_rate = fields.is_preferencial_rate ? 1 : 0
        const data = {}
        data.observacions = await this.callAction('savePhaseExchangeRate', fields)
        return data.phaseInfo;
    }

    saveRegulationZone = async (fields, id) => {
        if (!this.conStatus) {
            await this.verifyConnection();
        }

        fields.phase_id = id
        const data = {}
        data.regulationZones = await this.callAction('saveRegulationZone', fields)


        return data.phaseInfo;
    }

    saveImageOnAmazonBucket = async (files = [], id, user) => {

        if (!this.conStatus) {
            await this.verifyConnection();
        }

        const data = {}
        files.forEach(async element => {
            element.phase_id = id
            element.user_id = user
            await this.callAction('savePhaseImages', element)
        });
        //data.generalInfo = await this.callAction('savePhaseImage', image)

    }

    savePhaseSales = async (fields, code, user) => {
        if (!this.conStatus) {
            await this.verifyConnection();
        }
        await this.callAction('savePhaseAdditionalSales', fields)
    }

    getPhaseId = async (project, developer) => {
        if (!this.conStatus) {
            await this.verifyConnection();
        }
        return new Promise((resolve, reject) => {
            let data
            this.client.action(
                'getPhaseCode',
                { "project_id": project || 1, "developer_id": developer || 1 },

                (response) => {

                    resolve(response.data)
                }
            );
        })
    }

    getLocations = async (filters, type) => {
        if (!this.conStatus) {
            await this.verifyConnection();
        }

        let data = await this.callAction(type, filters);

        return data;
    }

}

export default Ws