import React, { useState, useEffect } from 'react';

/**
    * Componente <InputFormCol /> Estructura para colocar un Input como hijo.
    * @component
    * @param  props
    * @param {any} props.children - Elementos dentro del tag
    * @param {string}  props.label - Texto para la etiqueta Label 
    * @param {string} props.value - Valor del campo
    * @param {bool} props.isSubmited - Boleano para indicar si es requerido o no
    * @param {string} props.col - Clase de bootstrap para indicar la columna por default "col-3"
    * @param {bool} props.trim - Boleano para aplicar trim
    * @param {bool} props.withMessage - Boleano para indicar si muestra mensaje de error en caso de ser requerido.
*/
const InputFormCol = ({ children, label, value, isSubmited, col, trim, withMessage = true, extraLabelClass = null}) => {
    const [_value, setValue] = useState();

    useEffect(() => {
        if (value && trim)
            setValue(value.trim());
        else
            setValue(value);
    }, [value])

    return (
        <div className={(col) ? col : "col-3"}>
            <div className={`form-group px-0 ${(withMessage) ? "with-message" : ""} `}>
                <label className={`input-label form-label ${extraLabelClass}`}>
                    {label}
                </label>
                {children}
                {
                    (isSubmited && !_value) &&
                    <span className="error">*Campo obligatorio</span>
                }
            </div>
        </div>
    )
}
export default InputFormCol;
