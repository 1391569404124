import React, { useEffect, useState } from 'react'
import { activeamenity, formatStringDate, isEmpty, kFormatter } from '../../../../utils/utils'
import { Element } from 'react-scroll'
import AddToCatalogForm from "../../../molecules/Forms/AddToCatalog"
import Alert from '../../../atoms2/Alert';
import { AmenitiesAndServicesActions, CatalogsActions } from "../../../../actions";
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Image from 'react-bootstrap/Image'
import ModalBase from "../../../atoms2/ModalBase";
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'
import UploadImageComponent from '../../../atoms2/UploadImageComponent'
import ModalConfirmDelete from '../../../atoms/ModalConfirmDelete'
import NumberFormat from 'react-number-format';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearchLocation, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

//context
import { useGeneralInfoContext } from "../../../../contexts/GeneralInfoContext";
import CustomLoader from '../../../atoms2/CustomLoader';

const colorPalette = {
  "SOCIAL": "#122EB9",
  "ENTRETENIMIENTO": "#12A3B9",
  "RELAJACIÓN": "#12B960",
  "FITNESS": "#B98D12"
}
const TEST_PHASE_ID = 11

const AmenitiesTable = (props) => {
  const { addHere, handleAddHere, title, catalogs, amenityType, phaseID } = props
  let generalInfoContext = useGeneralInfoContext()
  const [inEditMode, setInEditMode] = useState({ status: false, rowKey: null })
  const [amenitiesList, setAmenitiesList] = useState([]);

  const amenitiesActions = new AmenitiesAndServicesActions()
  const catalogActions = new CatalogsActions();

  const [updateList, setUpdateList] = useState(false);
  const [updateAmeniesList, setUpdateAmenitiesList] = useState(false);

  const [errorMessage, setErrorMessage] = useState(false);

  const [amenityLocationID, setAmenityLocationId] = useState(0)
  const [status, setStatus] = useState(false);
  const [amenityId, setAmenityId] = useState(0);
  const [amenitieToDelete, setAmenitieToDelete] = useState(null);
  const [typeId, setTypeId] = useState();
  const [locationId, setLocationId] = useState();
  const [surface, setSurface] = useState(0);
  const [image, setImage] = useState("");
  const [imageCopy, setImageCopy] = useState("")
  const [isInside, setIsInside] = useState("")
  const [relevance, setRelevance] = useState(0);
  const [amenityStatus, setAmenityStatus] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const [updateAmenities, setUpdateAmenities] = useState(false)
  const [amenities, setAmenities] = useState([])
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const BUCKET_URL = process.env.REACT_APP_BUCKET_URL;

  const [validatedInputs, setValidatedInputs] = useState({
    location: true,
    type: true,
  })
  useEffect(() => {
    const getData = async () => {
      setShowLoader(true);

      try {
        let data = await amenitiesActions.getAmenityLocations("getPhaseAmenityLocations", {
          phaseID: phaseID,
          amenityTypeID: amenityType
        });
        await setInEditMode(false);
        await cleanForm();
        await setAmenitiesList(data);
        await setErrorMessage(false);

      } catch (error) {
        await setErrorMessage(false);
        await setErrorMessage(error);
      }
      setShowLoader(false)
    }
    getData();

  }, [updateList])
  useEffect(() => {
    const getData = async () => {
      try {
        let data = await catalogActions.getCatalog("getAmenities");
        setAmenities(data)
        setImage("")
      } catch (error) {
        await setErrorMessage(false);
        await setErrorMessage(error);
      }
    }
    getData();

  }, [updateAmenities])

  useEffect(() => {
    if (addHere != null) {
      addLine();
    }
  }, [addHere])


  const handleAddAmenity = async (amenity) => {
    try {
      if (!isEmpty(amenity)) {
        let data = {
          amenityTypeID: amenityType,
          description: amenity,
          userID: 1
        }
        await amenitiesActions.saveAmenity(data);
        await setShowModal(false);
        await setUpdateAmenities(!updateAmenities)
        return true;
      } else {
        throw "*Campo obligatorio";
      }
    } catch (error) {
      throw error;
    }
  }

  const saveAmenity = async (imageUpd) => {
    let imageWasUpdated = image ? 1 : 0
    let validInputs = {}
    if (amenityId === 0) {
      validInputs.type = false
    }
    if (locationId === 0) {
      validInputs.location = false

    }
    if (amenityId && locationId) {
      let phaseId = parseInt(phaseID);
      let amenity = {
        phaseID: parseInt(phaseID), //ID de la fase
        amenityTypeID: parseInt(amenityType),// ID de el tipo de tabla de menidad, este viene del componente padre al ser pintada la tabla
        amenityID: parseInt(amenityId),// ID de la amenidad seleccionada en la columna "tipo"
        amenityLocationTypeID: parseInt(locationId),//ID del nivel seleccionado, este se obtiene de la columna "Ubicación"
        surface: parseInt(surface),
        isImageUpdate: imageWasUpdated,
        imageName: image ? phaseId + "_Amenidad" : "",
        isInside: isInside == "1" ? true : false,
        relevance: relevance ? 1 : 0,
        status: status,
        userID: 1,
        imageData: image,
        phaseAmenityLocationID: amenityLocationID,
        status: amenityStatus
      }
      setShowLoader(true)

      await amenitiesActions.saveAmenityLocation("savePhaseAmenityLocations", amenity)
      validInputs = {
        location: true,
        type: true,
      };
      setUpdateList(!updateList)
    }
    await setValidatedInputs(validInputs)
    handleAddHere(amenityType);
  }

  const deleteAmenity = async () => {
    let params = { phaseID: amenitieToDelete.phaseID, amenityTypeID: amenitieToDelete.amenityTypeID, phaseAmenityLocationID: amenitieToDelete.phaseAmenityLocationID }

    await amenitiesActions.saveAmenityLocation("deletePhaseAmenityLocations", params);
    setUpdateList(!updateList);
    setShowDeleteModal(false);
  }


  const onEdit = async (data) => {
    await setInEditMode({ status: true, rowKey: data.phaseAmenityLocationID });
    await setRelevance(data.relevance);
    await setAmenityId(data.amenityID);
    await setIsInside(data.isInside);
    await setTypeId(data.amenityTypeID);
    await setLocationId(data.amenityLocationTypeID);
    await setSurface(data.surface);
    await setAmenityLocationId(data.phaseAmenityLocationID)
    await setAmenityStatus(data.status)
    await setRelevance(data.relevance)
  }


  const cleanForm = async () => {

    await setRelevance(0);
    await setAmenityId(0);
    await setIsInside(0);
    await setTypeId(0);
    await setLocationId(0);
    await setSurface(0);
    await setAmenityStatus(0);
    await setAmenityLocationId(0)
    await setAmenityStatus(0)
    await setImage("")
  }


  const addLine = async () => {
    /*if(inEditMode){
      setUpdateList(!updateList)
      return;
    }*/
    let newArr = [];
    if (Array.isArray(amenitiesList)) {
      newArr = [...amenitiesList]; // copying the old data array
    }
    if (newArr.findIndex(arr => arr.phaseAmenityLocationID === "") < 0) {
      await setRelevance(0);
      if (addHere)
        await setAmenityId(addHere);
      else
        await setAmenityId(0);
      await setTypeId(0);
      await setLocationId(0);
      await setSurface(0);
      newArr.push({
        phaseID: generalInfoContext.phaseIdCreated,
        amenityTypeID: amenityType,
        phaseAmenityLocationID: "",
        amenityID: 0,
        amenityLocationTypeID: 0,
        surface: 0,
        isImageUpdate: 0,
        imageName: "",
        isInside: 0,
        relevance: 0,
        status: 0,
        userID: 1,
        imageData: ""
      })
      await setInEditMode({ status: true, rowKey: "" });
      await setAmenitiesList(newArr);

    }
  }


  const handleChange = (e) => {
    const { value, name } = e.taget

  }


  const styles = {
    section: {
      width: "100%",
      border: "1px solid " + colorPalette[title],
      margin: "10px 0"
    }
  }



  return (
    <section>
      <Element name={"goto" + amenityType}></Element>
      <ModalConfirmDelete handlerAcceptDelete={deleteAmenity} showModal={showDeleteModal} setShow={setShowDeleteModal} title={"logout"} />
      <ModalBase showModal={showModal} setShow={setShowModal} title={""} size={"addCatalog"} >
        <AddToCatalogForm catalogName={"amenidad"} handleSubmit={handleAddAmenity} />
      </ModalBase>
      {showLoader ?
        <CustomLoader />
        :
        <div className="custom-card mb-4" style={{ boxShadow: "2px 2px 5px " + colorPalette[title] }}>
          <div className="custom-card-header py-3">
            <h4 className="px-3">{title}</h4>
          </div>
          <div className="custom-card-body px-3">
            <Row>
              <Col md={12}>
                <Table size={"lg"} striped hover responsive>
                  <thead className={"th-font"}>
                    <tr>
                      <th width="45"></th>
                      <th className="text-center">Eliminar</th>
                      <th>#</th>
                      <th className="text-center">Tipo
                        {/*<span className="btn-amenities-type btn-white btn btn-sm ml-2" variant="white" onClick={() => { setShowModal(true) }}>+</span>*/}
                      </th>
                      <th className="text-center">{inEditMode.status ? '*' : null} Ubicación</th>
                      <th className="text-center" style={{ minWidth: "110px" }} >M<sup>2</sup></th>
                      <th className="text-center">Imagen</th>
                      <th className="text-center">Interior / Exterior</th>
                      <th className="text-center">Relevante</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      (amenitiesList && amenitiesList.length > 0) && amenitiesList.map((amenity, index) => (
                        <tr key={`tr-${index}`}>
                          <td className={"text-center"}>
                            {
                              inEditMode.status && inEditMode.rowKey === amenity.phaseAmenityLocationID ? (
                                <React.Fragment>
                                  <Image
                                    src="./assets/icons/icon-save.svg"
                                    alt={`img-save-${amenity.amenityID}`}
                                    className={"pointer icon-image"}
                                    onClick={async () => {
                                      saveAmenity()
                                    }}
                                  />
                                </React.Fragment>
                              ) : (
                                <Image
                                  className={"pointer icon-edit"}
                                  src="./assets/icons/icon-edit.svg"
                                  alt={`img-edit-${amenity.phaseAmenityLocationID}`}
                                  disabled={inEditMode.status}
                                  onClick={() => {
                                    if (!inEditMode.status) {
                                      onEdit({
                                        phaseID: amenity.phaseID,
                                        amenityTypeID: amenity.amenityTypeID,
                                        phaseAmenityLocationID: amenity.phaseAmenityLocationID,
                                        amenityLocationTypeID: amenity.amenityLocationTypeID,
                                        surface: amenity.surface,
                                        isImageUpdate: amenity.isImageUpdate,
                                        imageName: amenity.imageName,
                                        isInside: amenity.isInside,
                                        relevance: amenity.relevance,
                                        status: amenity.status,
                                        userID: amenity.userID,
                                        imageData: amenity.imageData,
                                        amenityID: amenity.amenityID
                                      })
                                    }
                                  }}
                                />
                              )
                            }
                          </td>
                          <td className={"text-center"}>
                            {
                              !(inEditMode.status && inEditMode.rowKey === amenity.phaseAmenityLocationID) &&
                              <FontAwesomeIcon className="cursor-pointer" onClick={() => { setAmenitieToDelete(amenity); setShowDeleteModal(true) }} icon={faTrashAlt} />
                            }
                          </td>
                          <td className={"text-center"}>
                            {inEditMode.status && inEditMode.rowKey === amenity.phaseAmenityLocationID ? (
                              amenity.phaseAmenityLocationID
                            ) : (amenity.phaseAmenityLocationID)}

                          </td>
                          <td className={(inEditMode.status) ? "text-left" : "text-center"}>
                            {inEditMode.status && inEditMode.rowKey === amenity.phaseAmenityLocationID ? (
                              <Form.Group className={"my-0"} >
                                <Form.Control min="0" size={"large"} as="select" defaultValue={amenityId || amenity.amenityID}
                                  onChange={async (e) => await setAmenityId(e.target.value)}
                                  className={"black-border"} style={inEditMode.status ? { width: "150px" } : { width: "100%" }} >
                                  <option selected hidden>Seleccionar</option>

                                  {amenities ? amenities.map(({ amenity_type_id, description, id }) => {
                                    if (amenity_type_id === amenityType) {
                                      return (<option value={id}>{description}</option>)
                                    }
                                  }) : <option>Sin datos...</option>}

                                </Form.Control>
                              </Form.Group >

                            ) : (amenity.amenity.description)}
                          </td>
                          <td className={"text-center"}>
                            {inEditMode.status && inEditMode.rowKey === amenity.phaseAmenityLocationID ? (
                              <Form.Group className={"my-0"} >
                                <Form.Control min="0" size={"large"} as="select" defaultValue={locationId || amenity.amenityLocationTypeID}
                                  onChange={async (e) => await setLocationId(e.target.value)}
                                  className={"black-border"} style={inEditMode.status ? { width: "150px" } : { width: "100%" }} >
                                  <option selected hidden>Seleccionar</option>
                                  {catalogs.amenityLocationTypes ? catalogs.amenityLocationTypes.map(({ description = "", id }) => {

                                    return (<option value={id}>{description}</option>)

                                  }) : <option>Sin datos...</option>}
                                </Form.Control>
                                {(!validatedInputs.location && !locationId) ? (
                                  <Form.Control.Feedback style={{ color: "red" }} type="">
                                    *Campo obligatorio
                                  </Form.Control.Feedback>) : null}
                              </Form.Group>
                            ) : (amenity.locationType.description)
                            }
                          </td>
                          <td className={"text-center"}>
                            {inEditMode.status && inEditMode.rowKey === amenity.phaseAmenityLocationID ? (

                              <InputGroup>
                                <NumberFormat
                                  decimalScale={2}
                                  allowNegative={false}
                                  value={surface}
                                  maxLength={9}
                                  thousandSeparator={true}
                                  required={true}
                                  onValueChange={async (values) => {
                                    const { floatValue, formattedValue, value } = values;
                                    setSurface(floatValue)
                                  }}
                                  customInput={Form.Control}
                                />


                              </InputGroup>
                            ) : (amenity.surface)
                            }
                          </td><td className={"text-center"}>

                            {inEditMode.status && inEditMode.rowKey === amenity.phaseAmenityLocationID ? (
                              <UploadImageComponent size={"sm"} propertyName={'amenity'} zone={"amenities"} setImage={setImage} />
                            ) : (
                              amenity.uri ?
                                <Image alt={`img-${amenity.amenityID}`} src={BUCKET_URL + amenity.uri} className={"icon-image"} /> : <Image alt={`img-${amenity.amenityID}`} src={require("../../../../assets/icons/image-icon.svg")} className={"icon-image"} />
                            )
                            }
                          </td>
                          <td className={"text-center"}>
                            {inEditMode.status && inEditMode.rowKey === amenity.phaseAmenityLocationID ? (
                              <Form.Control onChange={async (e) => { await setIsInside(e.target.value) }} as="select" className="black-border large-input" defaultValue={amenity.isInside ? 1 : 2} style={inEditMode.status ? { width: "150px" } : { width: "100%" }} >
                                <option disabled hidden value={""}></option>
                                <option value={1}>Interior</option>
                                <option value={2}>Exterior</option>
                              </Form.Control>
                            ) : (amenity.isInside ? "Interior" : "Exterior")}
                          </td>

                          <td className={"text-center"}>
                            {inEditMode.status && inEditMode.rowKey === amenity.phaseAmenityLocationID ? (
                              <Form.Check
                                type={"checkbox"}
                                className={"pt-2"}
                                checked={relevance}
                                onChange={() => {
                                  setRelevance(!relevance)
                                }}
                              />
                            ) : (<Form.Check
                              type={"checkbox"}
                              checked={amenity.relevance}
                              name="priority"
                              disabled
                            />)
                            }
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </Col>
              <Col md={12}>
                {
                  (errorMessage) &&
                  <Alert className="auth__alert-error" theme={"error"} type="error" size={"large"}>
                    {errorMessage}
                  </Alert>
                }
              </Col>
            </Row>
          </div>
        </div>}
    </section >
  )

}




export default AmenitiesTable