import React, {useState, useEffect} from 'react'

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import './styles.css'
import { Container } from 'react-bootstrap'

const ModalBase = (props) => {
    const { showModal, setShow, title, size, children } = props;

    const handleClose = () => {
        setShow(false)
    }

    return (
        <section>
            <Modal size={ size } show={showModal} onHide={handleClose} backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Container>
                        <Modal.Title style={{fontWeight:"bolder"}} >{title}</Modal.Title>
                    </Container>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        { children }
                    </Container>
                </Modal.Body>
            </Modal>
        </section>
    )
}


export default ModalBase