import React, { useEffect, useState } from 'react';
import _ from 'lodash';
/**
    * Renders a <InputCheckboxList /> List of checkboxes
    * @component
    * @param  props
    * @param {array} props.catalog - Array of values.
    * @param {array}  props.currentData - Current value.
    * @param {string} props.paramName - Input value.
    * @param {string} props.idProperty - Value property. 
    * @param {string} props.displayName - Label property.
    * @param {function} props.handleData - Set data on change.
    * @param {string} props.cssClass - Css class.
*/
const InputCheckboxList = ({ catalog, currentData, paramName, idProperty, displayName, handleData, cssClass }) => {
    const [data, setData] = useState(null);

    useEffect(() => {
        if (data) {
            handleData(paramName, data);
        }
    }, [data])

    /**
        * Set values on check
        * @param {number} id - Catalog ID
    */
    const setCheck = async (id) => {
        let newData = [];
        if (currentData && currentData.length)
            newData = [...currentData];

        let index = _.findIndex(newData, function (o) { return o[idProperty] == id; });

        if (index != -1)
            newData.splice(index, 1);
        else
            newData.push({ [idProperty]: id });

        setData(newData);
    }

    return (
        <div className={cssClass}>
            {
                (catalog) ?
                    catalog.map((element) => {

                        return (
                            <p key={element[idProperty]}>

                                <input onChange={() => { setCheck(element[idProperty]) }}
                                    checked={
                                        (currentData && currentData.filter((row) => { return element[idProperty] == row[idProperty] }).length)
                                    }
                                    type="checkbox" /> {element[displayName]}
                            </p>
                        )
                    })
                    : null
            }
        </div>
    )
}
export default InputCheckboxList;