import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
//ACTION && CONTEXT
import { RestActions } from "../../actions";
import { useMainContext } from "../../contexts/MainContext";
//COMPONENTS
import MenuPhases from '../../components/molecules/MenuPhases';
import ModalDeleteUnits from '../../components/atoms/ModalDeleteUnits'
//ASSETS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import iconEdit from '../../assets/icons/icon-edit.svg';
import exclamationIcon from '../../assets/icons/icon-exclamation-mark.svg';
import ModalConfirmGeneral from '../../components/atoms/ModalConfirmGeneral';
import _ from 'lodash';

const Units = ({ preloadReady }) => {
    const rest = new RestActions();
    let mainContext = useMainContext();
    //let { phaseID } = useParams();
    const location = useLocation();
    if (window.sessionStorage.getItem("phaseID") == "" || window.sessionStorage.getItem("phaseID") == undefined)
        window.sessionStorage.setItem("phaseID", location.state.phaseID);
    const phaseID = window.sessionStorage.getItem("phaseID");

    const [phaseData, setPhaseData] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [levels, setLevels] = useState([]);
    const [units, setUnits] = useState();
    const [isSubmited, setIsSubmited] = useState(false);
    const [showDeleteModalDelete, setShowDeleteModalDelete] = useState(false);
    useEffect(() => {
        if (preloadReady) {
            initPhase();
            getUnits();
        }
    }, [preloadReady]);

    const initPhase = async () => {
        mainContext.setIsLoading(true);
        mainContext.setIsDirty(false);
        try {
            let response = await rest.post("getPhases", { phaseID: parseInt(phaseID) });
            setPhaseData(response[0]);

        }
        catch (error) {/*TODO SOMETHING*/ }
    }

    const getUnits = async () => {
        try {
            let response = await rest.post("getUnits", { phaseID: parseInt(phaseID) });
            setLevels(response);
            mainContext.setIsLoading(false);
        }
        catch (error) {/*TODO SOMETHING*/ }
    }

    const onFormDataChange = (indexLevel, indexUnit, e) => {
        mainContext.setIsDirty(true);
        let _levels = [...levels];
        _levels[indexLevel].units[indexUnit].name = e.value;
        setLevels(_levels);
    }

    const getErrorClass = (value) => {
        if (isSubmited && (value.trim() == ""))
            return "error-border";
        else
            return "";
    }

    const validateForm = () => {
        let unitsArr = [];
        _.forEach(levels, (row) => {
            unitsArr = _.concat(unitsArr, row.units);
        });

        let isValid = true;
        _.forEach(unitsArr, (row) => {
            if (row.name.trim() == "")
                isValid = false;
        });

        if (!isValid)
            mainContext.setMessageAlert("Es necesario llenar todos los campos");

        return isValid;
    }

    const sendForm = async (end = false) => {
        setIsSubmited(true);
        if (!validateForm())
            return false;

        let unitsArr = [];
        _.forEach(levels, (row) => {
            unitsArr = _.concat(unitsArr, row.units);
        });

        let params = { units: [] };
        _.forEach(unitsArr, (row) => {
            params.units.push({ unitID: row.unitID, name: row.name });
        });
        mainContext.setIsLoading(true);
        try {
            await rest.post("updateUnits", params);
            mainContext.setMessageAlert("Las unidades se guardaron correctamente");
            mainContext.setIsLoading(false);
            mainContext.setIsDirty(false);
            if (end)
                window.location = "#/home";
        }
        catch (error) {
            mainContext.setMessageAlert(error.toString());
            mainContext.setIsLoading(false);
        }
    }

    const deleteUnits = async () => {

        await mainContext.setMessageAlert("Se eliminó el listado de unidades correctamente");
        let response = await rest.post("deleteUnits", { phaseID: parseInt(phaseID) });
        //setLevels(response);
        mainContext.setIsLoading(false);
        window.location.href = `/#/phase/stories`
    }

    return (
        (preloadReady) &&
        <>
            <MenuPhases phaseID={phaseID} phaseData={phaseData} linkActive={"unidades"} />
            <ModalDeleteUnits handlerAcceptDelete={deleteUnits} showModal={showDeleteModalDelete} setShow={setShowDeleteModalDelete} title={"Aviso"} />
            <div className="custom-card mt-4">
                <div className="custom-card-header">
                    <h4 className="py-3 px-3">TABLA DE UNIDAES Y ÁREAS</h4>
                </div>
                <div className="custom-card-body px-3">
                    <div className="row">
                        <div className="col">
                            {
                                <table className="w-100 table-no-border table-striped">
                                    <thead>
                                        <tr>
                                            <th className="">Código</th>
                                            <th className="text-center">Área</th>
                                            <th className="text-center">Modelo / Otros</th>
                                            <th className="text-center">M<sup>2</sup> Totales</th>
                                            <th className="text-center">Tipo</th>
                                            <th className="">Nombre</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {levels.length > 0 && levels.map((level, indexLevel) =>
                                        (
                                            <>
                                                <tr key={`row-${indexLevel}`} className="table-row-border-top">
                                                    <td colSpan="6"><b>{level.levelName}</b></td>
                                                </tr>
                                                {level.units.map((unit, indexUnit) =>
                                                (
                                                    <tr key={`row-${unit.unitID}`}>
                                                        <td>{unit.code} </td>
                                                        <td className="text-center">
                                                            {unit.areaName}
                                                        </td>
                                                        <td className="text-center">
                                                            {unit.models.modelNameOthers}
                                                        </td>
                                                        <td className="text-center">
                                                            {unit.models.totalM2}
                                                        </td>
                                                        <td className="text-center">
                                                            {unit.models.modelTypes?.description}
                                                        </td>
                                                        <td className="text-center">
                                                            <input value={unit.name} onChange={(e) => { onFormDataChange(indexLevel, indexUnit, e.target) }} className={`form-control ${getErrorClass(unit.name)}`} />
                                                        </td>
                                                    </tr>
                                                ))
                                                }
                                            </>
                                        ))
                                        }
                                    </tbody>
                                </table>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-7 offset-5 d-flex justify-content-end">
                    {!phaseData.hasGatheringInformation ?
                        <button onClick={() => { setShowDeleteModalDelete(true) }} className="btn btn-white w-50">Eliminar unidades</button> : <>
                            <Button type="button" className="btn btn-white w-50 disabled" label="Eliminar unidades" tooltip="Existen levantamientos, no es posible eliminar las unidades." /></>
                    }

                    <button onClick={() => { sendForm(false) }} disabled={!mainContext.isDirty} className="btn btn-white w-50 ml-5 mr-3">Guardar</button>
                    <button onClick={() => { sendForm(true) }} className="btn btn-primary w-50">Guardar y terminar</button>
                </div>
            </div>
        </>
    )
}
export default Units;