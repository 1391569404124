const statesMexico = {
	"aguascalientes": "Aguascalientes",
	"baja california": "Baja California",
	"baja california sur": "Baja California Sur",
	"campeche": "Campeche",
	"chiapas": "Chiapas",
	"chihuahua": "Chihuahua",
	"ciudad de mexico": "ciudad de México",
	"coahuila de zaragoza": "Coahuila de Zaragoza",
	"coahuila": "Coahuila de Zaragoza",
	"colima": "Colima",
	"durango": "Durango",
	"guanajuato": "Guanajuato",
	"guerrero": "Guerrero",
	"hidalgo": "Hidalgo",
	"jalisco": "Jalisco",
	"estado de mexico": "Estado de México",
	"cdmx": "Ciudad de México",
	"ciudad de mexico": "Ciudad de México",
	"df": "Ciudad de México",
	"michoacan de ocampo": "Michoacán",
	"michoacan": "Michoacán",
	"morelos": "Morelos",
	"nayarit": "Nayarit",
	"nuevo leon": "Nuevo León",
	"oaxaca": "Oaxaca",
	"puebla": "Puebla",
	"queretaro": "Querétaro",
	"quintana roo": "Quintana Roo",
	"san luis potosi": "San Luis Potosí",
	"sinaloa": "Sinaloa",
	"sonora": "Sonora",
	"tabasco": "Tabasco",
	"tamaulipas": "Tamaulipas",
	"tlaxcala": "Tlaxcala",
	"veracruz de ignacio de la llave": "Veracruz",
	"veracruz": "Veracruz",
	"yucatan": "Yucatán",
	"zacatecas": "Zacatecas",

}

export {
	statesMexico
}