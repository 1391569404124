
const axios = require('axios');
class RestActions 
{
    async getURL(url) 
    {
      try {
        const response = await axios.get(url);
        return response;
      } 
      catch (error) 
      {
        throw error;
      }
    }

    async get(action) 
    {
      let url = window.location.protocol + "//" + window.location.host;
      try {
        const response = await axios.get(`${url}/api/${action}`);
        return response.data.data;
      } 
      catch (error) 
      {
        throw error;
      }
    }

    async post(action, params) 
    {
      let url = window.location.protocol + "//" + window.location.host;

      try {
        const response = await axios.post(`${url}/api/${action}`, params);
        return response.data.data;
      } 
      catch (error) 
      {
        //throw error;
        throw error.response.data.error;
      }
    }

  async logout() 
  {
    try {
      const response = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_SERVER_URL}/api/logout`,
        data: this.data
      })
      return response;
    } catch (error) {
      throw error;
    }
  }
}

export default RestActions
