import React, { useEffect, useState } from 'react'
import { Switch, Route, useRouteMatch } from "react-router-dom";
//ACTION && CONTEXT
import { RestActions } from "../../actions";
import { useMainContext } from "../../contexts/MainContext";
import { useCatalogsContext } from "../../contexts/CatalogsContext";
//COMPONENTS
import Home from '../Home';
import General from '../General';
import Especificaciones from '../Especificaciones'
import Models from '../Models';
import Stories from '../Stories';
import Units from '../Units';
import SalesStatus from '../SalesStatus';
import Sales from '../Sales';
import Control from '../Control';
import LiftingReview from '../LiftingReview';
import ModalConfirmNavigation from '../../components/atoms/ModalConfirmNavigation';
import Loading from '../../components/atoms/Loading';
import MessageAlert from '../../components/atoms/MessageAlert';
//ASSETS
import logo from '../../assets/icons/4SRealEstate_SingleLine.png';
import './styles.css';
//TODO COMPONENS TO CLEAN
import AmenititiesAndServices from '../../components/organisms/AmenitiesAndServices';

//TODO COMPONENS TO CLEAN
const PagesManager = () => {
    const rest = new RestActions();
    let mainContext = useMainContext();
    let catalogs = useCatalogsContext();

    const [preloadReady, setPreloadReady] = useState(false);

    useEffect(() => {
        getSession();
    }, [])

    useEffect(() => {
        if (mainContext.session || mainContext.updateCatalogs) {

            getCatalogs();
        }

    }, [mainContext.session, mainContext.updateCatalogs])

    const getSession = async () => {
        try {
            let response = await rest.post("getSession");
            mainContext.setSession(response);
        }
        catch (error) {
            if (window.location.hostname == "localhost")
                mainContext.setSession({ userID: 1 });
            else
                window.location.href = "/";
        }
    }

    const getCatalogs = async () => {
        if (catalogs.workProgress && mainContext.updateCatalogs == false) {
            setPreloadReady(true);
            return;
        }
        try {
            let countries = await rest.get("getComplexFilters");
            catalogs.setCountries(countries[0].countries);
            catalogs.setDevelopers(await rest.get("getDevelopers"));

            //catalogs.setTowers(JSON.parse(await rest.get("getTowers")));
            //catalogs.setComplexUses(await rest.get("getComplexUses"));
            catalogs.setTradeStatus(JSON.parse(await rest.get("getTradeStatus")));
            //catalogs.setClassifications(JSON.parse(await rest.get("getClassifications")));
            catalogs.setFileTypes(JSON.parse(await rest.get("getFileTypes")));
            let _catalogs = await rest.post("getCatalogs", { phaseID: window.sessionStorage.getItem("phaseID") });
            //catalogs.setMasterPlanUses(_catalogs.masterPlanUses);
            catalogs.setClassifications(_catalogs.classifications);
            catalogs.setMarketingSchemes(_catalogs.marketingSchemes);
            catalogs.setMasterPlan(_catalogs.masterPlans);
            catalogs.setAreaLocations(_catalogs.areaLocations);
            catalogs.setCupboardTypes(_catalogs.cupboardTypes);
            catalogs.setCurrencies(_catalogs.currencies);
            catalogs.setFloorTypeUses(_catalogs.floorTypeUses);
            catalogs.setFloorTypes(_catalogs.floorTypes);
            catalogs.setHouseDistributions(_catalogs.houseDistributions);
            catalogs.setLandDistributions(_catalogs.landDistributions);
            catalogs.setKitchenDistributions(_catalogs.kitchenDistributions);
            catalogs.setLaundryRooms(_catalogs.laundryRooms);
            catalogs.setModelServices(_catalogs.modelServices);
            catalogs.setModelSpecials(_catalogs.modelSpecials);
            catalogs.setCommercialBusiness(_catalogs.commercialBusiness);
            catalogs.setModelTypes(_catalogs.modelTypes);
            catalogs.setNaturalVentilationSpaces(_catalogs.naturalVentilationSpaces);
            catalogs.setDoubleHeightSpaces(_catalogs.doubleHeightSpaces);
            catalogs.setNumberViews(_catalogs.numberViews);
            catalogs.setServices4S(_catalogs.services4S);
            catalogs.setWalkInRooms(_catalogs.walkInRooms);
            catalogs.setSaleStatus(_catalogs.saleStatus);
            catalogs.setWallFinish(_catalogs.wallFinish);
            catalogs.setCeiling(_catalogs.ceiling);
            catalogs.setLighting(_catalogs.lighting);
            catalogs.setSystemAC(_catalogs.systemAC);
            catalogs.setDeckTileTypes(_catalogs.deckTileTypes);
            catalogs.setDeckTileOrigins(_catalogs.deckTileOrigins);
            catalogs.setDeckTileMeasures(_catalogs.deckTileMeasures);

            catalogs.setElevatorTypes(_catalogs.elevatorTypes);
            catalogs.setBuildingTypes(_catalogs.buildingTypes);
            catalogs.setFacadeTypes(_catalogs.facadeTypes);
            catalogs.setInteriorWallTypes(_catalogs.interiorWallTypes);
            catalogs.setDividenWallTypes(_catalogs.dividenWallTypes);
            catalogs.setWindowTypes(_catalogs.windowTypes);

            catalogs.setFinishedTypes(_catalogs.finishedTypes);
            catalogs.setFinishedFeatures(_catalogs.finishedFeatures);
            catalogs.setFinishedTypesFeatures(_catalogs.finishedTypesFeatures);
            catalogs.setFinishedDeliveryTypes(_catalogs.finishedDeliveryTypes);
            catalogs.setRentalStatus(_catalogs.rentalStatus);
            catalogs.setSubcategories(_catalogs.subCategories);
            catalogs.setRoles(_catalogs.roles)
            catalogs.setGeneralCats(_catalogs.generalCats)
            catalogs.setOffices(_catalogs.offices)
            //catalogs.setUsoTorre(_catalogs.usoTorre)
            catalogs.setWorkProgress(JSON.parse(await rest.get("getWorkProgress")));
            if (mainContext.updateCatalogs) {
                await mainContext.setUpdateCatalogs(false)
                mainContext.setIsLoading(false);
            }

            setPreloadReady(true);
        }
        catch (error) { console.log(error);/*TODO SOMETHING*/ }
    }

    const getHomeTemplate = () => {
        return <section className="pb-5">
            <Home preloadReady={preloadReady} />
        </section>
    }

    const getPhaseTemplate = (children, classAddProps) => {
        return <section className="main-section pb-5">
            <div className="container">
                <header className="row py-4">
                    <div className="col-12 col-md-6 px-0 text-center text-md-left">
                        <img className="home-logo" src={logo} alt="" />
                    </div>
                </header>
            </div>
            <div className="container bg-white px-4 pb-5">
            {/* className={`container bg-white px-4 pb-5 ${classAddProps ? classAddProps : 'contentLiftingReview'}`} */}
                {children}
            </div>
        </section>
    }

    return (
        <>
            <Switch>
                <Route exact path="/home">
                    {getHomeTemplate()}
                </Route>
                <Route exact path="/create">
                    {getPhaseTemplate(<General edition={false} preloadReady={preloadReady} />)}
                </Route>
                <Route exact path="/phase/general">
                    {getPhaseTemplate(<General edition={true} preloadReady={preloadReady} />)}
                </Route>
                <Route exact path="/phase/amenidades">
                    {getPhaseTemplate(<AmenititiesAndServices preloadReady={preloadReady} />)}
                </Route>
                <Route exact path="/phase/especificaciones">
                    {getPhaseTemplate(<Especificaciones preloadReady={preloadReady} />)}
                </Route>
                <Route exact path="/phase/models">
                    {getPhaseTemplate(<Models preloadReady={preloadReady} />)}
                </Route>
                <Route exact path="/phase/stories">
                    {getPhaseTemplate(<Stories preloadReady={preloadReady} />)}
                </Route>
                <Route exact path="/phase/units">
                    {getPhaseTemplate(<Units preloadReady={preloadReady} />)}
                </Route>
                <Route exact path="/phase/salesStatus">
                    {getPhaseTemplate(<SalesStatus preloadReady={preloadReady} />)}
                </Route>
                <Route exact path="/phase/conditions">
                    {getPhaseTemplate(<Sales preloadReady={preloadReady} />)}
                </Route>
                <Route exact path="/phase/control">
                    {getPhaseTemplate(<Control preloadReady={preloadReady} />)}
                </Route>
                <Route exact path="/liftingReview">
                    {getPhaseTemplate(<LiftingReview preloadReady={preloadReady} />)}
                </Route>
            </Switch>
            <Loading />
            <MessageAlert />
            <ModalConfirmNavigation />
        </>
    )
}
export default PagesManager;