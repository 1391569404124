import React from 'react';
//ACTIONS AND CONTEXT
import { useMainContext } from "../../../contexts/MainContext";
/**
    * Componente <Loading /> Renderiza un icono de carga
    * Utiliza la variable "isLoading" del contexto "MainContext" para mostrar o no el icono
*/
const Loading = () => 
{
    let mainContext = useMainContext();
    if(mainContext.isLoading)
        return (
            <div className="loading-container">
                <i className="pi pi-spin pi-spinner" style={{'fontSize': '4em'}}></i>
            </div>
        )
    else 
        return(null)
}
export default Loading;
