import React from 'react';
import Modal from 'react-bootstrap/Modal';
import deleteImg from '../../../assets/icons/delete-files.svg';
import exclamationIcon from '../../../assets/icons/icon-exclamation-mark.svg';
import './styles.css'

/**
    * Componente <ModalDeleteUnits /> Abre un modal de confirmación
    * al intentar borrar un registro.
    * @component
    * @param  props
    * @param {bool} props.showModal - Muestra/oculta el modal.
    * @param {function} props.setShow - Maneja el cerrado del modal.
    * @param {function} props.setCancelAction - Maneja la cancelación del borrado.
    * @param {function}  props.handlerAcceptDelete - Maneja la confirmacion de borrado.
*/
const ModalDeleteUnits = (props) => {
    const { showModal, setShow, setCancelAction, handlerAcceptDelete } = props

    /**
        * Cierra el modal.
    */
    const handleClose = () => {

        setShow(false);
    }

    /**
        * Comfirmacion de borrado.
    */
    const handleAccept = () => {
        handlerAcceptDelete();
    }

    return (
        //TODO: Fix styles
        <section>
            <Modal size={"logout"} show={showModal} onHide={handleAccept} id="close-session-modal" backdrop="static" aria-labelledby="contained-modal-title-vcenter" centered >
                <Modal.Body className={"text-center"}>
                    <div className="row my-5 py-2">
                        <div className="col col-md-4 offset-md-4">
                            {/*<div className="border-image text-center col-md-8 offset-md-2">
                                
                            </div>*/}
                            <img src={exclamationIcon} className={"my-3"} width="100" />
                        </div>
                    </div>
                    <div className="row text-center mb-5">
                        <div className="col">
                            <p className="title-modal font-weight-bold">¿Estas seguro que quieres eliminar todas las unidades?</p>
                            <p className="content-modal px-5">Al aceptar no se podra recuperar las unidades.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 offset-md-2">
                            <button className="btn btn-outline-link" onClick={() => { handleClose() }} >Cancelar</button>
                        </div>
                        <div className="col-md-4 offset-md-1">
                            <button className="btn btn-primary btn-block" onClick={() => { handleAccept() }}>Aceptar</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </section>
    )
}
export default ModalDeleteUnits