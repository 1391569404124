import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import deleteImg from '../../../assets/icons/delete-files.svg';
import exclamationIcon from '../../../assets/icons/icon-exclamation-mark.svg';
import './styles.css'
import ImportIcon from '../../../assets/icons/icon-import.svg'
import Dropdown from "../Dropdown";
import { useMainContext } from "../../../contexts/MainContext";
import { useCatalogsContext } from "../../../contexts/CatalogsContext";

const ModalCreateUser = (props) => {
    let mainContext = useMainContext();
    let catalogs = useCatalogsContext();
    const { showModal, setShow, setCancelAction, handlerAccept, cancel, success, edit, dataUser, nameCatalog, hasStatus } = props
    const [nameFile, setNameFile] = useState("Seleccionar archivo")
    const [stringFile, setStringFile] = useState("")
    const [rol, setRol] = useState("")
    const [formData, setFormData] = useState({})
    const [isSubmitted, setIsSubmitted] = useState(false);
    /**
        * Cierra el modal.
    */
    const handleClose = () => {
        setNameFile("Seleccionar archivo")
        setShow(false);
    }

    const sendData = async () => {
        setIsSubmitted(true)

        if (valitation()) {
            await handlerAccept(formData)
            await setFormData({})
        }

    }
    const valitation = () => {
        if (!formData
            || formData.name === undefined
            || formData.developerID === undefined
            || formData?.name.trim() === ""
            || formData.developerID === ""
            || formData.name === null
            || formData.developerID === null
            || formData.status === undefined
            || formData.status === ""
            || formData.status === null)
            return false
        else
            return true
    }
    /**
        * Comfirmacion de borrado.
    */
    useEffect(() => {
        setIsSubmitted(false)
        setFormData({})
        setNameFile("Seleccionar archivo")
        if (edit) {
            setFormData({
                developerID: initCombo(0, "developerID", dataUser?.masterPlanDevelopers?.developerID),
                status: initCombo("status", "id", dataUser.enabled ? 1 : 0),
                masterPlanID: dataUser.masterPlanID,
                name: dataUser.name
            })

        }

    }, [showModal, dataUser])
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const getFileBase64 = async (event) => {
        setNameFile(event.name)
        const result = await toBase64(event);
        setStringFile(result)
    }

    const onFormDataChange = async (name, e) => {
        setIsSubmitted(false)
        if (name == "status")
            setFormData({ ...formData, [name]: e });
        else
            setFormData({ ...formData, [name]: e.value });

    }
    const initCombo = (catalog, filterBy1, filterBy2) => {
        let status = [{ "id": 1, "status": "Activo" }, { "id": 0, "status": "Inactivo" }];
        if (catalog == "status") {
            let dato = status.filter((element) => { return element[filterBy1] == filterBy2 })[0];
            return dato;
        } else if (catalogs.developers) {

            let dato = catalogs.developers.filter((element) => { return element[filterBy1] == filterBy2 })[0];
            return dato;
        }
        else
            return null;
    }

    return (
        //TODO: Fix styles
        <section>
            <Modal size={"one-input-form"} show={showModal} onHide={() => { handleClose() }} backdrop="static" centered >
                <Modal.Header className="no-border p-1" closeButton>
                    <Modal.Title className="pl-3"></Modal.Title>
                </Modal.Header>
                <Modal.Body className={"text-center"} >
                    <div className="custom-card">
                        <div className="custom-card-header px-3 d-flex">
                            <h4 className="py-3">{edit ? "EDITAR" : "AGREGAR"} MASTER PLAN</h4>
                            <div className=" py-2 d-flex justify-content-end">
                                {/*<button onClick={() => { setShowModal(true) }} className="btn btn-primary"><span className="d-inline-block pr-4">Nueva importación</span> +</button>*/}.
                            </div>
                        </div>
                        <div className="custom-card-body px-3">
                            <div className="row">
                                <div className="form-group with-message col-6">
                                    <label className="input-label form-label">
                                        * Nombre
                                    </label>
                                    <div className="">
                                        <input type="text" value={formData.name} onChange={(e) => { onFormDataChange("name", e.target) }} maxLength="300" className="form-control black-border large-input" />
                                        {
                                            (isSubmitted && !formData.name) &&
                                            <p className="error">*Campo Nombre es obligatorio.</p>
                                        }
                                        {
                                            (isSubmitted && formData?.name && formData.name.trim() == "") &&
                                            <p className="error">*No se permiten solo espacios.</p>

                                        }
                                    </div>
                                </div>
                                <div className="col-6 form-group with-message">
                                    <label className="input-label form-label">
                                        * Desarrollador
                                    </label>
                                    <div className="">
                                        {/*<input type="text" value={formData.fullName} onChange={(e) => { onFormDataChange("fullName", e.target) }} maxLength="300" className="form-control black-border large-input" />*/}
                                        <Dropdown value={formData.developerID} options={catalogs.developers} onChange={(e) => { onFormDataChange("developerID", e) }} optionValue="developerID" optionLabel="developerName" className="w-100" />
                                        {
                                            (isSubmitted && !formData.developerID) &&
                                            <p className="error">*Campo Desarrollador es obligatorio.</p>
                                        }
                                    </div>
                                </div>
                                <div className="col-6 form-group with-message">
                                    <label className="input-label form-label">
                                        * Estatus
                                    </label>
                                    <div className="">
                                        {
                                            (isSubmitted && !formData.status) &&
                                            <p className="error">*Campo Estatus es obligatorio.</p>
                                        }
                                        {/*<input type="file" onChange={e => getFileBase64(e.target.files[0])} maxLength="50" className="form-control black-border large-input" />*/}
                                        <Dropdown value={formData.status} options={[{ "id": 1, "status": "Activo" }, { "id": 0, "status": "Inactivo" }]} onChange={(e) => { onFormDataChange("status", e.value) }} optionValue="id" optionLabel="status" className="w-100" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div
                        className="container d-flex justify-content-end mt-5">
                        <button className="btn btn-white " onClick={() => { handleClose() }}>Cancelar</button>

                        <button className="btn btn-primary ml-3" onClick={sendData}>{edit ? "Actualizar" : "Agregar"}</button>
                    </div>
                </Modal.Body>
            </Modal>
        </section>
    )
}
export default ModalCreateUser