import React, { useEffect, useState } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { Link } from "react-router-dom";
import { faHeart as faHeartSolid, faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
//ACTIONS AND CONTEXT
import { RestActions } from "../../../actions";
import { useMainContext } from "../../../contexts/MainContext";
//ASSETS
import ventas from '../../../assets/icons/icon-ventas.svg';

/**
    * Componente SearchEngineList
    * @component
    * @param  props
    * @param {array} props.data - Arreglo del Listado de fases.
    * @param {function} props.handleRefresh - Maneja el cambio en el listado de fases
    * 
*/
const SearchEngineList = ({ data, handleRefresh }) => {
    const rest = new RestActions();
    let mainContext = useMainContext();
    const [dataFormatted, setDataFormatted] = useState([]);
    const [nextPage, setNextPage] = useState(null);
    const [prevPage, setPrevPage] = useState(null);
    const [currentPage, setCurrentPage] = useState(null);
    const [pages, setPages] = useState([1]);

    useEffect(() => {
        if (data && data.data != undefined) {
            proccessData();
            createPaginator();
        }
    }, [data])

    /**
        * Formatea el listado de datos para ser desplegado en la tabla.
        * @param {object} props.event - valor del campo pais en el formulario.
        * 
    */
    const proccessData = async () => {
        let newData = [];
        data.data.forEach(element => {
            newData.push({
                phaseID: element.phaseID,
                complexName: (element.developerComplex) ? element.developerComplex.complex.complexName : null,
                isFavorite: element.isFavorite,
                municipality: (element.locations && element.locations.municipalities) ? element.locations.municipalities.municipalityName : null,
                state: (element.locations && element.locations.municipalities) ? element.locations.municipalities.states.stateName : null,
                country: (element.locations && element.locations.municipalities) ? element.locations.municipalities.states.countries.countryName : null,
                zone: (element.locations && element.locations.zones) ? element.locations.zones.zoneName : null,
                developer: (element.developerComplex) ? element.developerComplex.developers.developerName : null,
                towerName: element.phaseName
            });
        });
        setDataFormatted(newData);
    }

    /**
        * Crea el paginador segun los datos proporcionados en la variable data.
    */
    const createPaginator = async () => {
        let totalPages;
        if ((data.totalRows / data.pageLimit) % 1 == 0)
            totalPages = data.totalRows / data.pageLimit;
        else
            totalPages = Math.floor(data.totalRows / data.pageLimit) + 1;
        let pagesArray = [];
        let initPage = 1;
        let endPage = 1;
        let pagesShowed = (totalPages > 5) ? 5 : totalPages;
        if (data.currentPage < pagesShowed - 1) {
            initPage = 1;
            endPage = pagesShowed;
        }
        else if (data.currentPage > totalPages - pagesShowed) {
            if (totalPages > 5)
                initPage = totalPages - 5;
            endPage = totalPages;
        } else {
            initPage = data.currentPage - 2;
            endPage = data.currentPage + 2;
        }
        for (let i = initPage; i <= endPage; i++) {
            if (data.currentPage == i)
                pagesArray.push(<button onClick={() => { onChangePage(i) }} className="btn current-page" key={i.toString()}>Página {i}</button>);
            else
                pagesArray.push(<button onClick={() => { onChangePage(i) }} className="btn" key={i.toString()}>{i}</button>);
        }
        setPages(pagesArray);
        setNextPage(data.nextPage);
        setPrevPage(data.previousPage);
        setCurrentPage(data.currentPage);
    }

    /**
        * Selecciona una fase como favorita una fase.
        * @param {number} props.phaseID - ID de la fase.
    */
    const checkFavorite = async (phaseID) => {
        mainContext.setIsLoading(true);
        try {
            await rest.post("checkFavoritePhase", { phaseID: phaseID, userID: mainContext.session.userID });
            handleRefresh(1);
        }
        catch (error) {/*TODO SOMETHING*/ }
    }

    /**
        * selecciona una pagina de la tabla.
        * @param {number} props.page - ID de la fase.
    */
    const onChangePage = async (page) => {
        handleRefresh(page);
    }

    /**
        * Retorna el selector HTML <a> con la informacion de la fase seleccionada
        * Crea el vinculo hacia la ruta de informacion general.
        * @param {object} props.rowData - Datos de la fase.
    */
    const linkTableTemplate = (rowData) => {
        //return <a href={"/#/phase/"+rowData.phaseID+"/general"}>{rowData.towerName}</a>
        return <a className="mr-2 is-cursor" onClick={() => {
            window.sessionStorage.setItem("phaseID", rowData.phaseID);
            window.location.href = "/#/phase/general"
        }}>{rowData.towerName}</a>

    }

    /**
        * Retorna el selector HTML <a> con la informacion de la fase seleccionada.
        * Crea el vinculo hacia la ruta de ventas.
        * @param {object} props.rowData - Datos de la fase.
    */
    const linkVentasTableTemplate = (rowData) => {

        return <a onClick={() => {
            window.sessionStorage.setItem("phaseID", rowData.phaseID);
            window.location.href = "/#/phase/salesStatus"
        }}><img className="mr-2 is-cursor" src={ventas} alt="" /></a>
    }

    /**
        * Retorna el selector HTML <span> con la informacion de la fase seleccionada.
        * Crea el boton para agregar a favoritos una fase.
        * @param {object} props.rowData - Datos de la fase.
    */
    const favoriteTableTemplate = (rowData) => {
        if (rowData.isFavorite)
            return <span className="btn-icon mt-3 color-red"><FontAwesomeIcon onClick={() => { checkFavorite(rowData.phaseID); }} icon={faHeartSolid} /></span>
        else
            return <span className="btn-icon mt-3"><FontAwesomeIcon onClick={() => { checkFavorite(rowData.phaseID); }} icon={faHeart} /></span>
    }

    return (
        <div className="datatable-responsive-demo mt-4">
            <DataTable value={dataFormatted} emptyMessage="No se encontraron datos" className="p-datatable-striped" rows={10}>
                <Column className="text-left w-25 ellipsis" field="towerName" header="Torre / Fase / Proyecto" body={linkTableTemplate} />
                <Column className="text-center" field="country" header="País" />
                <Column className="text-center w-220" field="municipality" header="Delegación / Municipio" />
                <Column className="text-center" field="zone" header="Zonas" />
                <Column className="text-center ellipsis" field="complexName" header="Complejo" />
                <Column className="text-center" field="developer" header="Desarrollador" />
                <Column className="text-center" field="developer" header="Ventas" body={linkVentasTableTemplate} />
                <Column className="text-center" field="proyecto" header="Favoritos" body={favoriteTableTemplate} />
            </DataTable>
            <div className="mt-4 text-right">
                <button className="btn" onClick={() => { onChangePage(prevPage) }} disabled={(!prevPage)}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                </button>
                {pages}
                <button className="btn" onClick={() => { onChangePage(nextPage) }} disabled={(!nextPage)}>
                    <FontAwesomeIcon icon={faChevronRight} />
                </button>
            </div>
        </div>
    )
}
export default SearchEngineList