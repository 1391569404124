import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { animateScroll as scroll } from 'react-scroll';
import _, { forEach } from 'lodash';
//ACTION && CONTEXT
import { RestActions } from "../../actions";
import { useMainContext } from "../../contexts/MainContext";
//COMPONENTS
import MenuLifting from '../../components/molecules/MenuLifting';
import TableLiftingReview from './TableLiftingReview';
import SearchLiftingReviewFilters from './SearchLiftingReviewFilters';
// 	Styles
import './styles.css';

const LiftingReview = ({ preloadReady }) => {
	let phaseID = false;
	const rest = new RestActions();
	let mainContext = useMainContext();
	const [dataTable, setDataTable] = useState([]);
	const [dataHeader, setDataHeader] = useState([]);
	const [dataFilters, setDataFilters] = useState([]);
	const [checkChecked, setCheckChecked] = useState([]);
	const [countCheckProyects, setCountCheckProyects] = useState(0); // Cantidad de proyectos seleccionados para cambio de estado.
	const [updatePopUps, setUpdatePopUps] = useState(false); //Actualizar los estados de los popUps?
	const [errorMessageApproved, setErrorMessageApproved] = useState({ status: false, elements: [] }); // estado PopUp de error al aprobar
	const [errorMessagePublished, setErrorMessagePublished] = useState({ status: false, elements: [] }); // estado PopUp de error al publicar

	//Boton de creación de excel
	const [dataButtonExcel, setDataButtonExcel] = useState({
		disabled: false,
		content: 'icon'
	});

	const [formData, setFormData] = useState({
		gatheringAT: '', giro: '', cities: '',
		offices: [],
		esquema: [],
		zonas: [],
		classification: [],
		developer: [],
		projectStatus: [],
		surveyStatus: [],
	});
	useEffect(() => {
		if (preloadReady) {
			initFilters()
		}
	}, [preloadReady])


	const initFilters = async () => {
		mainContext.setIsLoading(true);
		try {
			let idUser = mainContext.session.userID;
			let responseFilters = await rest.post("getFilters", { idUser: idUser });

			setDataFilters(responseFilters);
			mainContext.setIsLoading(false);

		}
		catch (error) {/*TODO SOMETHING*/ }
	}

	const initTable = async (isCreateExcel = false) => {

		console.log('Create excel: ', isCreateExcel);

		if (formData.giro == null || formData.giro == '' || formData.giro == undefined) {
			mainContext.setMessageAlert("La opción de giro es requerida");
			return;
		}

		if (formData.cities == null || formData.cities == '' || formData.cities == undefined) {
			mainContext.setMessageAlert("La opción de ciudad es requerida");
			return;
		}

		if (formData.gatheringAT == null || formData.gatheringAT == '' || formData.gatheringAT == undefined) {
			mainContext.setMessageAlert("La opción de fecha de levantamiento es requerida");
			return;
		}

		if (isCreateExcel) {
			setDataButtonExcel({
				content: 'icon',
				disabled: true
				// content: 'Cargando...'
			})
		} else {
			mainContext.setIsLoading(true);
		}

		try {

			let officesArray = formData.offices ? formData.offices.map((element) => element.officesid) : [];
			let esquemaArray = formData.esquema ? formData.esquema.map((element) => element.esquemaid) : [];
			let zonasArray = formData.zonas ? formData.zonas.map((element) => element.zonesid) : [];
			let classificationArray = formData.classification ? formData.classification.map((element) => element.classificationsid) : [];
			let developerArray = formData.developer ? formData.developer.map((element) => element.developersid) : [];
			let projectStatusArray = formData.projectStatus ? formData.projectStatus.map((element) => element.projectstatusid) : [];
			let surveyStatusArray = formData.surveyStatus ? formData.surveyStatus.map((element) => element.surveyStatusID) : [];

			let params = {
				giro: formData.giro.giroid,
				cities: formData.cities.citiesid,
				gatheringAT: formData.gatheringAT.gathering_at,

				offices: officesArray,
				esquema: esquemaArray,
				zonas: zonasArray,
				classification: classificationArray,
				developer: developerArray,
				projectStatus: projectStatusArray,
				surveyStatus: surveyStatusArray
			}

			if (!isCreateExcel) {
				let response = await rest.post("getUprisingAuditorium", params);

				setDataHeader(response.header);
				setDataTable(response.table);
				mainContext.setIsLoading(false);
			} else {

				console.log('datasend: ', params);

				// let createExcel = await rest.post("getExcelLiftingReview", params);
				rest.post("getExcelLiftingReview", params);

				// setDataButtonExcel({
				// 	disabled: false,
				// 	content: 'icon'
				// });
				mainContext.setMessageAlert("Fue solicitado el envío de toda la información, en un tiempo promedio de 30 min llegará a tu correo electrónico.")
				return;
			}

		}
		catch (error) {/*TODO SOMETHING*/ }
	}


	const sendChangeState = async typeChange => {
		if (checkChecked == null || checkChecked == '' || checkChecked == undefined) {
			mainContext.setMessageAlert("tienes que marcar al menos un levantamiento");
			return;
		}
		//Cuantos en estado cerrado
		let countClose = 0;
		//Cuantos en estado aprobado
		let countApproved = 0;

		let grupID = [], objectElementClose = [], objectElementApproved = [];

		checkChecked.forEach(element => {

			grupID.push(element.id);

			if (element.status == 2) {
				countClose++;
				objectElementClose.push({ nameProject: element.nameProject, status: 'Cerrado' });

			} else if (element.status == 3) {
				countApproved++;

				objectElementApproved.push({ nameProject: element.nameProject, status: 'Aprobado' });
			}
		});

		//Si existen aprobados u otro estado diferente a cerrado, no se pueden aprobar, ya que se require que esten en paso de cerrado
		if (typeChange == 'approved' && countApproved != 0) {
			// mainContext.setMessageAlert("No todos los elementos seleccionados están en estado cerrado, verifica nuevamente los elementos seleccionados.");
			return setErrorMessageApproved({ status: true, elements: objectElementApproved });
		}

		//Si existen cerrados no se pueden publicar, ya que se require que esten en paso de aprobado
		if (typeChange == 'published' && countClose != 0) {
			// mainContext.setMessageAlert("No todos los elementos seleccionados están en estado aprobado, verifica nuevamente los elementos seleccionados.");

			return setErrorMessagePublished({ status: true, elements: objectElementClose });
		}



		mainContext.setIsLoading(true);
		try {
			// published
			// approved
			let params = { typeChange: typeChange, gathering_ID: grupID }
			let response = await rest.post("setPublishOrApproveGathering", params);

			setUpdatePopUps(true); // Solicitar cerrar todos los popups abiertos.

			if (response.status == 'Ok') {
				mainContext.setMessageAlert("Actualización correcta");
				setCheckChecked([]);
				return initTable();
			} else {
				mainContext.setMessageAlert("Hubo un error, por favor contactate con servicio técnico");
				mainContext.setIsLoading(false);
				return;
			}
		}
		catch (error) {/*TODO SOMETHING*/
			mainContext.setMessageAlert("Hubo un error, por favor contactate con servicio técnico");
			return mainContext.setIsLoading(false);
		}
	}


	const [dataDatesGathering, setDataDatesGathering] = useState({
		disabled: true,
		placeholder: 'Giro y Ciudad requeridos',
		surveyDate: []
	});

	const changeDataSurveyDate = async () => {
		setDataDatesGathering({ ...dataDatesGathering, disabled: true, placeholder: 'Extrayendo info...' });

		const idCity = formData.cities.citiesid;
		const idGiro = formData.giro.giroid;

		let response = await rest.post("getSurveyDate", { idCity, idGiro });

		setDataDatesGathering({ disabled: false, placeholder: 'Seleccionar', surveyDate: response.surveyDate });
	};

	useEffect(() => {
		setFormData({ ...formData, gatheringAT: '' });
		if (formData.cities && formData.giro) {
			changeDataSurveyDate();
		} else if (dataDatesGathering.disabled === false) {
			setDataDatesGathering({ ...dataDatesGathering, disabled: true, placeholder: 'Giro y Ciudad requeridos' });
		}
	}, [formData.giro, formData.cities]);

	return (
		(preloadReady) &&
		<>
			<MenuLifting
				rolID={mainContext.session.roleID}
				sendChangeState={(typeChange) => sendChangeState(typeChange)}
				countCheckProyects={countCheckProyects}
				selectDate={formData.gatheringAT ? formData.gatheringAT.dateFormat : ''}
				updatePopUps={updatePopUps}
				setUpdatePopUps={setUpdatePopUps}
				errorMessageApproved={errorMessageApproved}
				setErrorMessageApproved={setErrorMessageApproved}
				errorMessagePublished={errorMessagePublished}
				setErrorMessagePublished={setErrorMessagePublished}
			/>
			<div style={{ width: '100vw' }}>
				<SearchLiftingReviewFilters dataDatesGathering={dataDatesGathering} loadDataInit={() => initTable()} formData={formData} setFormData={setFormData} dataFilters={dataFilters} />
			</div>
			<div className="row px-md-5 mb-5">
				<TableLiftingReview dataButtonExcel={dataButtonExcel} createExcel={() => initTable(true)} setCountCheckProyects={setCountCheckProyects} dataTable={dataTable} checkChecked={checkChecked} setCheckChecked={setCheckChecked} dataHeader={dataHeader} />
			</div>

		</>
	)
}
export default LiftingReview;