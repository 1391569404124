const axios = require('axios');

class CatalogsActions {
  constructor() {
  }
  async getCatalog(action, data) 
  {
    let url = window.location.protocol + "//" + window.location.host;  
    try {
      const response = await axios({
        method: 'post',
        url: `${url}/api/${action}`,
        data: data
      })
        .then(response => {
          if (typeof response.data.data === "string") {
            return JSON.parse(response.data.data)
          } else {
            if (response.data.data.regNames) {
              return response.data.data.regNames
            } else {
              return response.data.data
            }
          }

        })
      return response;
    }
    catch (error) {
      throw error
    }
  }
  async addToCatalog(action, data) {
    try {
      const response = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_SERVER_URL}/api/${action}`,
        data: data
      })
        .then(response => {
          if (typeof response.data.data === "string") {
            if (response.data.data === "ok") {
              return response.data.data

            } else {
              return JSON.parse(response.data.data)
            }
          } else {
            return response.data.data
          }

        })

      //return response;
    }
    catch (error) {
      throw error
    }
  }
}
export default CatalogsActions