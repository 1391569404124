import React, { Fragment, useEffect, useState } from 'react';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearchLocation, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
//ACTION && CONTEXT
import { RestActions } from "../../../actions";
import { useMainContext } from "../../../contexts/MainContext";
import { useCatalogsContext } from "../../../contexts/CatalogsContext";
import Dropdown from "../../../components/atoms/Dropdown";
//COMPONENTS
import InputNumeric from "../../../components/atoms/InputNumeric";
import ButtonModalOneInputForm from "../../../components/atoms/ButtonModalOneInputForm";
import InputCheckboxList from '../../../components/atoms2/InputCheckboxList';
import Alert from '../../../components/atoms2/Alert';
import ModalBase from "../../../components/atoms2/ModalBase";
import AddToCatalogForm from "../../../components/molecules/Forms/AddToCatalog";
import UploadImageComponent from '../../../components/atoms2/UploadImageComponent';

import { format, parse } from 'date-fns';

const GeneralInfoForm = ({ edit, handlerFormData, handlerImageData, isSubmited, initData }) => {


    // const initComplexInformations = (initData) => {
    if (initData && initData.complexInformations && initData.complexInformations.length > 0) {
        initData.firstPhaseStartDate = initData.complexInformations[0].firstPhaseStartDate ? initData.complexInformations[0].firstPhaseStartDate : null;
        initData.projectedPhasesComplex = initData.complexInformations[0].projectedPhasesComplex ? initData.complexInformations[0].projectedPhasesComplex : null;
        initData.totalProjectedUnitsComplex = initData.complexInformations[0].totalProjectedUnitsComplex ? initData.complexInformations[0].totalProjectedUnitsComplex : null;
    }
    // }

    let complexInformations = [];
    if (initData && initData.complexInformations && initData.complexInformations.length > 0) {
        initData.complexInformations.map((element) => {
            complexInformations.push(element);
        });
    }

    const rest = new RestActions();
    let mainContext = useMainContext();
    let catalogs = useCatalogsContext();

    const [formData, setFormData] = useState({ phaseName: "", developerID: null, projectID: null });
    const [images, setImages] = useState([]);
    const [initThumbs, setInitThumbs] = useState([]);
    const [thumbs, setThumbs] = useState({});
    const [complexCat, setComplexCat] = useState([]);
    const [timeInMarket, setTimeInMarket] = useState(0);
    const [startDate, setStartDate] = useState(false)
    const [deliveryDate, setDeliveryDate] = useState(false)
    const [firstPhaseStartDate, setFirstPhaseStartDate] = useState(false);
    const [salesEndDate, setSalesEndDate] = useState(false)
    const [showCatalogModal, setShowCatalogModal] = useState(false)
    const [addToCatalogName, setAddToCatalogName] = useState(false)
    const [subacatalogs, setSubcatalogs] = useState({})
    const [disbaleCheckboxesMasterPlan, setDisbaleCheckboxesMasterPlan] = useState(true)
    const [disbaledClassification, setDisbaledClassification] = useState({})
    const [disbaleCheckboxesComplejo, setDisbaleCheckboxesComplejo] = useState(true)
    const [disabledPiker, setDisabledPiker] = useState(true);


    useEffect(() => {
        if (edit && initData.phaseID) {

            initForm();
        } else if (!edit) {
            mainContext.setIsLoading(false);
            let disbaledClassificationData = {}
            catalogs.commercialBusiness.map((itemCB) => {

                disbaledClassificationData = { ...disbaledClassificationData, [itemCB.commercialBusinessID]: true }
            })

            setDisbaledClassification(disbaledClassificationData)
            let subcatalogsData = {}
            //genera los subcatalogos para los selectores de clasification
            catalogs.commercialBusiness.map((item) => {
                let subCatalogItems = [];
                catalogs.classifications.map((el) => {
                    if (item.commercialBusinessID == el.commercialBusinessID) {
                        subCatalogItems.push(el)
                    }

                })
                subcatalogsData = {
                    ...subcatalogsData, [item.commercialBusinessID]: subCatalogItems
                }


            })
            setSubcatalogs(subcatalogsData);
        }
    }, [initData])

    useEffect(() => {
        handlerFormData(formData);
    }, [formData])

    useEffect(() => {
        handlerFormData(images);
    }, [images])

    const initForm = async () => {

        let complexes = await rest.post("getComplex", { "developerID": initData.developerComplex.developers.developerID });
        await setComplexCat(complexes);
        let complexID = complexes.filter((element) => { return element.complexID == initData.developerComplex.complex.complexID })[0];
        mainContext.setIsLoading(true);
        if (initData.startDate) {
            let _startDate = initData.startDate.split("/")
            let newDate = new Date(+_startDate[2], (_startDate[1] - 1), +_startDate[0])
            setStartDate(newDate);
            initData.startDate = newDate.toString(2);
        }

        if (initData.deliveryDate) {

            let _deliveryDate = initData.deliveryDate.split("/");
            let newDate = new Date(+_deliveryDate[2], (_deliveryDate[1] - 1), +_deliveryDate[0])
            setDeliveryDate(newDate);
            initData.deliveryDate = newDate.toString(2);
        }

        if (initData.firstPhaseStartDate) {
            // // Parsea la fecha original en el formato 'yyyy-MM-dd'
            // const fechaOriginal = parse(initData.firstPhaseStartDate, 'yyyy-MM-dd', new Date());

            // // Formatea la fecha en el formato 'dd/MM/yyyy'
            // const fechaFormateada = format(fechaOriginal, 'dd/MM/yyyy');

            // let _firstPhaseStartDate = fechaFormateada.split("/");
            // let newDate = new Date(+_firstPhaseStartDate[2], (_firstPhaseStartDate[1] - 1), +_firstPhaseStartDate[0])
            // setFirstPhaseStartDate(newDate);
            // initData.firstPhaseStartDate = newDate.toString(2);

            // let _firstPhaseStartDateMin = initData.firstPhaseStartDate.split("-");
            let _firstPhaseStartDateSla = initData.firstPhaseStartDate.split("/");
            // if (_firstPhaseStartDateMin[0] > 100) {
            //     let newDate = new Date(+_firstPhaseStartDateMin[0], (_firstPhaseStartDateMin[1] - 1), +_firstPhaseStartDateMin[2])
            //     setFirstPhaseStartDate(newDate);
            //     initData.firstPhaseStartDate = newDate.toString(2);
            // } else {
            let newDate = new Date(+_firstPhaseStartDateSla[2], (_firstPhaseStartDateSla[1] - 1), +_firstPhaseStartDateSla[0])
            setFirstPhaseStartDate(newDate);
            initData.firstPhaseStartDate = newDate.toString(2);
            // }
        }

        if (initData.salesEndDate) {

            let _salesEndDate = initData.salesEndDate.split("/");
            let newDate = new Date(+_salesEndDate[2], (_salesEndDate[1] - 1), +_salesEndDate[0])
            setSalesEndDate(newDate);
            initData.salesEndDate = newDate.toString(2);
        }

        let initTower = [], initMasterPlan = [], initComplex = [];
        initData.commercialBusinessUses.forEach(element => {

            if (element.useTypeID == 1) {
                initMasterPlan.push(element)
            } else if (element.useTypeID == 2) {
                initComplex.push(element)
            } else if (element.useTypeID == 3) {

                initTower.push(element)
            }

        });
        let subcatalogsData = {}
        //genera los subcatalogos para los selectores de clasification
        catalogs.commercialBusiness.map((item) => {
            let subCatalogItems = [];
            catalogs.classifications.map((el) => {
                if (item.commercialBusinessID == el.commercialBusinessID) {
                    subCatalogItems.push(el)
                }

            })
            subcatalogsData = {
                ...subcatalogsData, [item.commercialBusinessID]: subCatalogItems
            }


        })
        setSubcatalogs(subcatalogsData);

        let initclassificationsData = {}
        let disbaledClassificationData = {}
        catalogs.commercialBusiness.map((itemCB) => {
            let dataitem;
            let isValid = false;
            initTower.map((elTower) => {
                if (elTower.commercialBusinessID == itemCB.commercialBusinessID) {
                    dataitem = catalogs.classifications.filter((elementClassification) => { return elementClassification.classificationID == elTower.classificationID && elementClassification.commercialBusinessID == itemCB.commercialBusinessID })[0];
                    isValid = true;
                }
            })
            if (isValid)
                disbaledClassificationData = { ...disbaledClassificationData, [itemCB.commercialBusinessID]: false }
            else
                disbaledClassificationData = { ...disbaledClassificationData, [itemCB.commercialBusinessID]: true }

            initclassificationsData = { ...initclassificationsData, [itemCB.commercialBusinessID]: dataitem }
        })

        setDisbaledClassification(disbaledClassificationData)



        changeDisabledPiker('', initData.tradeStatusID);

        let formattedData = {
            developerID: initCombo("developers", "developerID", initData.developerComplex.developers.developerID),
            complexID: complexID,
            phaseName: initData.phaseName,
            towers: initMultiCombo(initTower, "commercialBusinessID", "commercialBusinessID"),
            complexUses: initMultiCombo(initComplex, "commercialBusinessID", "commercialBusinessID"),
            masterPlanUses: initMultiCombo(initMasterPlan, "commercialBusinessID", "commercialBusinessID"),
            masterPlanID: initCombo("masterPlan", "masterPlanID", initData.masterPlanID),
            marketingSchemeID: initCombo("marketingSchemes", "marketingSchemeID", initData.marketingSchemeID),
            tradeStatusID: initCombo("tradeStatus", "id", initData.tradeStatusID),
            services4S: initMultiCombo(initData.services4S, "services4SID", "services4SID"),
            tradeStatusID: initCombo("tradeStatus", "id", initData.tradeStatusID),
            workProgressID: initCombo("workProgress", "id", initData.workProgressID),
            levels: initData.levels,
            unitsPerLevel: initData.unitsPerLevel.toString(),
            projectedPhasesComplex: initData.projectedPhasesComplex ? initData.projectedPhasesComplex.toString() : null,
            totalProjectedUnitsComplex: initData.totalProjectedUnitsComplex ? initData.totalProjectedUnitsComplex.toString() : null,
            analysis: initData.analysis,
            commercialization4S: initData.commercialization4S,
            phaseCode: initData.phaseCode,
            startDate: (initData.startDate) ? formatDate(new Date(initData.startDate)) : null,
            deliveryDate: (initData.deliveryDate) ? formatDate(new Date(initData.deliveryDate)) : null,
            firstPhaseStartDate: (initData.firstPhaseStartDate) ? formatDate(new Date(initData.firstPhaseStartDate)) : null,
            salesEndDate: (initData.salesEndDate) ? formatDate(new Date(initData.salesEndDate)) : null,
            phaseImages: initData.phaseImages,
            classificationsData: initclassificationsData
        }

        if (initData.developerComplex.complex.complexID == 0 || initData.developerComplex.complex.complexID == "")
            setDisbaleCheckboxesComplejo(true)
        else
            setDisbaleCheckboxesComplejo(false)

        if (initData.masterPlanID == 0 || initData.masterPlanID == "")
            setDisbaleCheckboxesMasterPlan(true)
        else
            setDisbaleCheckboxesMasterPlan(false)


        setFormData(formattedData);
        setTimeInMarket(initData.timeMarket);
        let _thumbs = {}
        initData.phaseImages.forEach(element => {
            if (element.uri.indexOf("logo") != -1)
                _thumbs.logo = element.uri;
            else if (element.uri.indexOf("optional") != -1)
                _thumbs.optional = element.uri;
            else if (element.uri.indexOf("front") != -1)
                _thumbs.front = element.uri;
        });
        setThumbs(_thumbs);
        setInitThumbs(initData.phaseImages);
        mainContext.setIsLoading(false);
    }

    const initCombo = (catalog, filterBy1, filterBy2) => {
        if (catalogs[catalog])
            return catalogs[catalog].filter((element) => { return element[filterBy1] == filterBy2 })[0];
        else
            return null;
    }

    const initMultiCombo = (_data, paramName, idName) => {
        let formattedData = [];
        _data.forEach(element => {
            formattedData.push({ [idName]: element[paramName] });
        });
        return formattedData;
    }

    const changeDisabledPiker = (description, id) => {
        return (description === 'Vendido' || id === 6) ? setDisabledPiker(false) : ((disabledPiker == false) && setDisabledPiker(true));
    }

    const onFormDataChange = async (name, e) => {
        mainContext.setIsDirty(true);
        if (name == "complexID" && e.value != null && e.value.complexID != 0) {
            await setFormData({ ...formData, phaseName: e.value.complexName, [name]: e.value });
            setDisbaleCheckboxesComplejo(false)
        } else if (name == "complexID" && e.value != null && e.value.complexID == 0) {
            setDisbaleCheckboxesComplejo(true)
            await setFormData({ ...formData, [name]: e.value, complexUses: [] });
        } else if (name == "complexID" && e.value == null) {
            setDisbaleCheckboxesComplejo(true)
            await setFormData({ ...formData, [name]: e.value, complexUses: [] });
        } else if (name == "tradeStatusID") {
            changeDisabledPiker('', e.value.id);
            setFormData({ ...formData, [name]: e.value });
        } else {
            setFormData({ ...formData, [name]: e.value });
        }
    }
    const onFormDataChangeClasifications = async (name, e, id) => {

        let newData = { ...formData.classificationsData };
        mainContext.setIsDirty(true);
        newData[id] = e.value
        setFormData({ ...formData, classificationsData: newData });
    }



    const handleCheckboxList = async (paramName, data) => {

        if (paramName == "towers") {
            let newData = { ...disbaledClassification }
            let newDataClassification = { ...formData.classificationsData }
            Object.keys(disbaledClassification).forEach((key) => {
                const disabledDrow = data.filter((element) => { return element.commercialBusinessID == key })[0];
                if (disabledDrow) {
                    newData[key] = false

                } else { newData[key] = true; newDataClassification[key] = null; }

            })
            setDisbaledClassification(newData)
            mainContext.setIsDirty(true);
            setFormData({ ...formData, [paramName]: data, classificationsData: newDataClassification });
        } else {
            mainContext.setIsDirty(true);
            setFormData({ ...formData, [paramName]: data });
        }
    }

    const getImageHandler = (image) => {
        mainContext.setIsDirty(true);
        setImages(image);
        handlerImageData(image);
    }

    const onChangeDeveloper = async (value) => {
        mainContext.setIsDirty(true);
        if (value != null) {
            await setFormData({ ...formData, developerID: value, complexID: null });
            const complexes = await rest.post("getComplex", { "developerID": value.developerID });
            setComplexCat(complexes);
        } else {
            await setComplexCat([]);
            await setFormData({ ...formData, ["developerID"]: null, ["complexID"]: null });
        }
    }
    const onChangeMasterPlan = async (value, e) => {

        mainContext.setIsDirty(true);
        if (value != null) {

            if (e.value != null && e.value?.masterPlanID == 0) {
                setDisbaleCheckboxesMasterPlan(true)
                await setFormData({ ...formData, masterPlanID: e.value, masterPlanUses: [] });
            } else if (e.value == null) {
                setDisbaleCheckboxesMasterPlan(true)
                await setFormData({ ...formData, masterPlanID: e.value, masterPlanUses: [] });
            } else {
                setDisbaleCheckboxesMasterPlan(false)
                await setFormData({ ...formData, masterPlanID: e.value });
            }

        } else {
            setDisbaleCheckboxesMasterPlan(true)
            await setFormData({ ...formData, masterPlanID: e.value, masterPlanUses: [] });
        }
    }

    const calculateTimeInMarket = (startDate) => {
        let months;
        let today = new Date()
        months = (today.getFullYear() - startDate.getFullYear()) * 12;
        months -= startDate.getMonth();
        months += today.getMonth();
        if (months < 1)
            months = 0;
        setTimeInMarket(months)
    }

    const formatDate = (date) => {
        let year = date.getFullYear()
        let month = `${date.getMonth() + 1}`
        month = month.length === 1 ? `0${month}` : month
        let formatedDate = `01/${month}/${year}`
        return formatedDate;
    }

    const onDeveloperAdded = async () => {
        catalogs.setDevelopers(await rest.get("getDevelopers"));
    }

    const onComplexAdded = async () => {
        onChangeDeveloper(formData.developerID);
    }

    const deleteImage = async (thumb) => {
        let _images = [...initThumbs];
        let idx = _.findIndex(_images, (element) => { return element.uri.indexOf(thumb) != -1 });
        let img = _images[idx];
        await rest.post("deletePhaseImages", { phaseImageID: img.phaseImageID, phaseID: parseInt(initData.phaseID) });
        _images.splice(idx, 1);
        let _thumbs = {}
        _images.forEach(element => {
            if (element.uri.indexOf("logo") != -1)
                _thumbs.logo = element.uri;
            else if (element.uri.indexOf("optional") != -1)
                _thumbs.optional = element.uri;
            else if (element.uri.indexOf("front") != -1)
                _thumbs.front = element.uri;
        });
        setThumbs(_thumbs);
        setInitThumbs(_images);
    }
    const handleDateChangeRaw = (e) => {
        e.preventDefault();
    }
    const handleAddToCatalog = async (item) => {
        const data = {}
        let action;
        switch (addToCatalogName) {
            case "desarrollador":
                action = "saveDeveloper"
                data.developerName = item
                data.userID = 1
                break
            case "proyecto":
                action = "saveComplex"
                data.complexName = item;
                data.developerID = formData.developerID.developerID
                data.userID = 1
                break
            default:
                break;
        }

        try {
            if (item != "" && item != null) {
                await rest.post(action, data);
                await setShowCatalogModal(false);
                if (addToCatalogName == "desarrollador") {
                    let developers = await rest.get("getDevelopers");
                    catalogs.setDevelopers(developers);
                }
                else {

                    let complexes = await rest.post("getComplex", { "developerID": formData.developerID.developerID });
                    await setComplexCat(complexes)
                }
            } else {
                throw "*Campo obligatorio";
            }
        } catch (error) {
            //throw error;
        }
    }
    /**
 * ************
 * Date Picker 
 * ************
 * */
    // const [timeInMarket, setTimeInMarket] = useState(0);
    // const [startDate, setStartDate] = useState(false);
    // const [firstPhaseStartDate, setFirstPhaseStartDate] = useState(false);

    // const formatDate = (date) => {
    //     let year = date.getFullYear()
    //     let month = `${date.getMonth() + 1}`
    //     month = month.length === 1 ? `0${month}` : month
    //     let formatedDate = `01/${month}/${year}`
    //     return formatedDate;
    // }
    // const handleDateChangeRaw = (e) => {
    //     e.preventDefault();
    // }
    // const calculateTimeInMarket = (startDate) => {
    //     let months;
    //     let today = new Date()
    //     months = (today.getFullYear() - startDate.getFullYear()) * 12;
    //     months -= startDate.getMonth();
    //     months += today.getMonth();
    //     if (months < 1)
    //         months = 0;
    //     setTimeInMarket(months)
    // }
    /**
     * ************
     * End Date Picker 
     * ************
     * */
    return (
        <>
            <form autoComplete="off" className="custom-card">
                <div className="custom-card-header py-3">
                    <h4 className="px-3">INFORMACIÓN BÁSICA</h4>
                </div>
                <div className="custom-card-body px-3">
                    <ModalBase showModal={showCatalogModal} setShow={setShowCatalogModal} title={"Agregar " + addToCatalogName} size={"addCatalog"} >
                        <AddToCatalogForm catalogName={addToCatalogName} handleSubmit={handleAddToCatalog} />
                    </ModalBase>
                    <div className="row">
                        <div className="col">
                            <div className="form-group with-message">
                                <label className="input-label form-label">
                                    * Desarrollador / Comercializador
                                    {/*<ButtonModalOneInputForm buttonType="icon" title="Nuevo Desarrollador" property={"developerName"} action={"saveDeveloper"} handleOnSaved={onDeveloperAdded} />*/}
                                </label>
                                <Dropdown value={formData.developerID} options={catalogs.developers} onChange={(e) => { onChangeDeveloper(e.value); }} optionLabel="developerName" optionValue="developerID" />
                                {
                                    (isSubmited && !formData.developerID) &&
                                    <span className="error">*Campo obligatorio</span>
                                }
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group with-message">
                                <label className="input-label form-label">
                                    * Nombre del Master Plan
                                    {/*<ButtonModalOneInputForm buttonType="icon" title="Nuevo Complejo" property={"complexName"} action={"saveComplex"} actionParams={{ developerID: (formData.developerID) ? formData.developerID.developerID : null }} handleOnSaved={onComplexAdded} />*/}
                                </label>
                                <Dropdown value={formData.masterPlanID} options={catalogs.masterPlan} onChange={(e) => { onChangeMasterPlan('masterPlanID', e); }} optionLabel="name" optionValue="masterPlanID" />

                                {
                                    (isSubmited && !formData.masterPlanID) &&
                                    <span className="error">*Campo obligatorio</span>
                                }
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group with-message">
                                <label className="input-label form-label">
                                    * Nombre del complejo
                                    {/*<ButtonModalOneInputForm buttonType="icon" title="Nuevo Complejo" property={"complexName"} action={"saveComplex"} actionParams={{ developerID: (formData.developerID) ? formData.developerID.developerID : null }} handleOnSaved={onComplexAdded} />*/}
                                </label>
                                <Dropdown value={formData.complexID} options={complexCat} onChange={(e) => { onFormDataChange('complexID', e); }} optionLabel="complexName" optionValue="complexID" />
                                {
                                    (isSubmited && !formData.complexID) &&
                                    <span className="error">*Campo obligatorio</span>
                                }
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group with-message">
                                <label className="input-label form-label">
                                    * Nombre de torre / fase / proyecto
                                </label>
                                <input type="text" value={formData.phaseName} onChange={(e) => { onFormDataChange("phaseName", e.target) }} maxLength="300" className="form-control black-border large-input" />
                                {
                                    (isSubmited && (!formData.phaseName || formData.phaseName.trim() == "")) &&
                                    <span className="error">*Campo obligatorio</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="form-group with-message">
                                <label className="input-label form-label">
                                    * Estatus comercial

                                </label>
                                <Dropdown value={formData.tradeStatusID} options={catalogs.tradeStatus} onChange={(e) => { onFormDataChange('tradeStatusID', e); }} optionLabel="description" optionValue="id" />
                                {
                                    (isSubmited && !formData.tradeStatusID) &&
                                    <Alert className="input__alert-error mt-2" theme={"error"} type="error" size={"large"}>
                                        *Campo obligatorio.
                                    </Alert>
                                }
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group with-message">
                                <label className="input-label form-label">Avance de obra</label>
                                <Dropdown value={formData.workProgressID} options={catalogs.workProgress} onChange={(e) => { onFormDataChange('workProgressID', e); }} optionLabel="description" optionValue="id" />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group with-message">
                                <label className="input-label form-label" title="El número de niveles debe  incluir estacionamientos y subterráneos"> Niveles / Manzanas de proyecto</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">#</span>
                                    </div>
                                    <InputNumeric hasTootip={true} tooltipText="El número de niveles debe incluir estacionamientos y subterráneos" defaultValue={formData.levels} paramName="levels" maxlength={2} handleValue={onFormDataChange} />
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group with-message">
                                <label className="input-label form-label">Unidades por piso</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">#</span>
                                    </div>
                                    <InputNumeric defaultValue={formData.unitsPerLevel} paramName="unitsPerLevel" maxlength={2} handleValue={onFormDataChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="form-group with-message">
                                <label className="input-label form-label">*Fecha de inicio comercialización</label>
                                <DatePicker
                                    selected={startDate}
                                    onChangeRaw={handleDateChangeRaw}
                                    onChange={date => {
                                        calculateTimeInMarket(date)
                                        setStartDate(date)
                                        setFormData({ ...formData, startDate: formatDate(new Date(date)) })
                                        mainContext.setIsDirty(true);
                                    }}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                    className={"small-input black-border form-control"}
                                    placeholderText="Seleccionar"
                                    required={true}
                                    id={"startDate"}
                                />
                                {
                                    (isSubmited && !formData.startDate && (formData.tradeStatusID && formData.tradeStatusID.id != 1)) &&
                                    <Alert className="input__alert-error mt-2" theme={"error"} type="error" size={"large"}>
                                        *Campo obligatorio.
                                    </Alert>
                                }
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group with-message">
                                <label className="input-label form-label">Fecha de entrega/apertura</label>
                                <DatePicker
                                    minDate={startDate}
                                    selected={deliveryDate}
                                    onChange={date => {
                                        setDeliveryDate(date)
                                        if (date == null)
                                            setFormData({ ...formData, deliveryDate: null })
                                        else
                                            setFormData({ ...formData, deliveryDate: formatDate(new Date(date)) })
                                        mainContext.setIsDirty(true);
                                    }}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                    className={"small-input black-border form-control"}
                                    placeholderText="Seleccionar"
                                />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group with-message">
                                <label className="input-label form-label">Fecha de termino de ventas</label>
                                <DatePicker
                                    disabled={disabledPiker}
                                    minDate={startDate}
                                    selected={salesEndDate}
                                    onChange={date => {
                                        setSalesEndDate(date)
                                        if (date == null)
                                            setFormData({ ...formData, salesEndDate: null })
                                        else
                                            setFormData({ ...formData, salesEndDate: formatDate(new Date(date)) })
                                        mainContext.setIsDirty(true);
                                    }}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                    className={"small-input black-border form-control"}
                                    placeholderText="Seleccionar"
                                />
                            </div>
                        </div>

                        {/* <div className="col">
                        <div className="form-group with-message">
                            <label className="input-label form-label">Tiempo en el mercado</label>
                            <input type="text" value={timeInMarket + " Meses"} readOnly className="black-border small-input form-control" />
                        </div>
                    </div> */}
                        <div className="col-3">
                            <div className="form-group">
                                <label className="input-label form-label">Esquema de comercializacion</label>
                                <Dropdown value={formData.marketingSchemeID} options={catalogs.marketingSchemes} onChange={(e) => { onFormDataChange('marketingSchemeID', e); }} optionLabel="description" optionValue="marketingSchemeID" />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-3">
                            <div className="form-group with-message">
                                <label className="input-label form-label">Tiempo en el mercado</label>
                                <input type="text" value={timeInMarket + " Meses"} readOnly className="black-border small-input form-control" />
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col">
                            <div className="form-group with-message">
                                <label className="input-label form-label mb-4">Servicios 4S</label>
                                <InputCheckboxList handleData={handleCheckboxList} catalog={catalogs.services4S} currentData={formData.services4S} idProperty="services4SID" paramName="services4S" displayName="description" idx="services4SID" />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group with-message">
                                <label className="input-label form-label mb-4">Usos Master Plan</label>
                                <InputCheckboxList handleData={handleCheckboxList} catalog={catalogs.commercialBusiness} currentData={formData.masterPlanUses} idProperty="commercialBusinessID" paramName="masterPlanUses" displayName="description" idx="commercialBusinessID" disableCheckBoxes={disbaleCheckboxesMasterPlan} />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group with-message">
                                <label className="input-label form-label mb-4">Usos complejo</label>
                                <InputCheckboxList
                                    handleData={handleCheckboxList}
                                    catalog={catalogs.commercialBusiness}
                                    currentData={formData.complexUses}
                                    idProperty="commercialBusinessID"
                                    paramName="complexUses" displayName="description"
                                    idx="commercialBusinessID"
                                    disableCheckBoxes={disbaleCheckboxesComplejo} />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group with-message">
                                <label className="input-label form-label mb-4">Usos torre</label>
                                <InputCheckboxList
                                    handleData={handleCheckboxList}
                                    catalog={catalogs.commercialBusiness}
                                    currentData={formData.towers}
                                    idProperty="commercialBusinessID"
                                    paramName="towers"
                                    displayName="description"
                                    idx="commercialBusinessID" />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group with-message">
                                <label className="input-label form-label mb-4">Clasificación</label>
                                {catalogs.commercialBusiness.map((id) => {
                                    return (
                                        <div className="pb-2 mb-1">
                                            <Dropdown
                                                classNamePrefix="selectBiss"
                                                fontSize="10px"
                                                value={formData?.classificationsData?.[`${id.commercialBusinessID}`]}
                                                height="25px"
                                                options={subacatalogs[id.commercialBusinessID]}
                                                onChange={(e) => { onFormDataChangeClasifications('classificationID', e, id.commercialBusinessID); }}
                                                optionLabel="description"
                                                optionValue="classificationID"
                                                disabled={disbaledClassification[id.commercialBusinessID]} />
                                        </div>)
                                })}

                            </div>
                        </div>
                    </div>
                    <div className="images-dropzone">
                        <label className="form-label">
                            Agregar imágenes
                        </label>
                        <div className="row">
                            <div className="col text-center images-dropzone-img">
                                <UploadImageComponent images={images} image={thumbs.logo} size={"lg"} propertyName={'logo'} zone={"generalInfo"} getImageHandler={getImageHandler} />
                                <label className="image-label form-label dropzone-label">Logo</label>
                                {
                                    (thumbs.logo) &&
                                    <FontAwesomeIcon className="img-trash-icon cursor-pointer" onClick={() => { deleteImage("logo") }} icon={faTrashAlt} />
                                }
                            </div>
                            <div className="col text-center images-dropzone-img">
                                <UploadImageComponent images={images} image={thumbs.front} size={"lg"} propertyName={'front'} zone={"generalInfo"} getImageHandler={getImageHandler} />
                                <label className="image-label form-label dropzone-label">Fachada</label>
                                {
                                    (thumbs.front) &&
                                    <FontAwesomeIcon className="img-trash-icon cursor-pointer" onClick={() => { deleteImage("front") }} icon={faTrashAlt} />
                                }
                            </div>
                            <div className="col text-center images-dropzone-img">
                                <UploadImageComponent images={images} image={thumbs.optional} size={"lg"} propertyName={'optional'} zone={"generalInfo"} getImageHandler={getImageHandler} />
                                <label className="image-label form-label dropzone-label">Opcional</label>
                                {
                                    (thumbs.optional) &&
                                    <FontAwesomeIcon className="img-trash-icon cursor-pointer" onClick={() => { deleteImage("optional") }} icon={faTrashAlt} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <section className="mt-4 custom-card">
                <div className="custom-card-header py-3">
                    <h4 className="px-3">  INFORMACIÓN DEL COMPLEJO</h4>
                </div>
                <div className="custom-card-body px-3">
                    <div className="row">
                        <div className="col">
                            <div className="form-group with-message">
                                <label className="input-label form-label">Fecha de inicio de primera fase</label>
                                {/* <DatePicker
                                    selected={firstPhaseStartDate}
                                    // onChangeRaw={handleDateChangeRaw}
                                    onChange={date => {
                                        calculateTimeInMarket(date)
                                        setFirstPhaseStartDate(date)
                                        setFormData({ ...formData, firstPhaseStartDate: formatDate(new Date(date)) })
                                        mainContext.setIsDirty(true);
                                    }}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                    className={"small-input black-border form-control"}
                                    placeholderText="Seleccionar"
                                    required={true}
                                    id={"firstPhaseStartDate"}
                                /> */}
                                <DatePicker
                                    selected={firstPhaseStartDate}
                                    onChange={date => {
                                        setFirstPhaseStartDate(date)
                                        if (date == null)
                                            setFormData({ ...formData, firstPhaseStartDate: null })
                                        else
                                            setFormData({ ...formData, firstPhaseStartDate: formatDate(new Date(date)) })
                                        mainContext.setIsDirty(true);
                                    }}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                    className={"small-input black-border form-control"}
                                    placeholderText="Seleccionar"
                                />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group with-message">
                                <label className="input-label form-label">Torres / Fases proyectadas del complejo</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">#</span>
                                    </div>
                                    <InputNumeric maxlength={2} defaultValue={formData.projectedPhasesComplex} paramName="projectedPhasesComplex" handleValue={onFormDataChange} />
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group with-message">
                                <label className="input-label form-label">Unidades totales proyectadas del complejo</label>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">#</span>
                                    </div>
                                    <InputNumeric defaultValue={formData.totalProjectedUnitsComplex} maxlength={2} paramName="totalProjectedUnitsComplex" handleValue={onFormDataChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default GeneralInfoForm;