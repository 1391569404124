import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import { useMainContext } from "../../../contexts/MainContext";

/**
    * Componente <ModalConfirmNavigation /> Abre un modal de confirmación
    *  en caso de que existan datos sin guardar en un formulario.
    * @component
*/
const ModalConfirmNavigation = () => 
{
    let mainContext = useMainContext();
    const [show, setShow] = useState(false);

    useEffect(() => 
    {
        if(mainContext.goTo != null)
        {
            if(mainContext.isDirty)
                setShow(true);
            else
                onConfirm();
        }
    }, [mainContext.goTo]);

    /**
        * Confimacion.
    */
    const onConfirm = () => 
    {
        mainContext.setIsDirty(false);
        mainContext.setGoTo(null);
        window.location.href = mainContext.goTo;
    }

    /**
        * Cancelacion.
    */
    const onCancel = () => 
    {
        mainContext.setGoTo(null);
        setShow(false);
    }
    
    return (
            <Modal size={"logout"} show={show} onHide={setShow} backdrop="static" centered>
                <Modal.Body>
                    <div className="row my-5 py-2">
                        <div className="col col-md-4 offset-md-4">
                            <div className="border-image text-center col-md-8 offset-md-2">
                                <img src={'./assets/icons/icon-logout.svg'} className={"icon-logout my-3"} />
                            </div>
                        </div> 
                    </div>
                    <div className="row text-center mb-5">
                        <div className="col">
                            <p className="title-modal">¿Seguro que deseas salir de la pantalla?</p>
                            <p className="content-modal px-5">Al continuar, se perderá la información no guardada.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 offset-md-2">
                            <button className="btn btn-outline-link" onClick={() => { onCancel() }} >Cancelar</button>
                        </div>
                        <div className="col-md-4 offset-md-1">
                            <button className="btn btn-primary btn-block" onClick={() => { onConfirm() }}>Aceptar</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
    )
}
export default ModalConfirmNavigation;