import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'
import * as queryString from 'query-string';
//ACTIONS
import { RestActions } from "../../actions";
//COMPONENTS
import InputEmail from '../../components/atoms/InputEmail';
//ASSETS
import './styles.css';
import backLogo from '../../assets/icons/4SRealEstate_SingleLine.png';
import logoWhite from '../../assets/icons/real-state-white.svg';

/**
    * Pagina login
    * @component
*/
const LoginPage = ({ googleRedirect }) => 
{
    const rest = new RestActions();
    let { loginErrorMessage } = useParams();

    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isInRequest, setIsInRequest] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);

    useEffect(() => 
    {
        if(loginErrorMessage)
            setErrorMessage(<p>{loginErrorMessage}</p>);
    },
    [googleRedirect]);
    
    /**
        * Maneja la validación del email 
    */
    const validateEmail = (isValid, value)=>
    {
        setIsEmailValid(isValid);
        if(isValid)
        {
            setEmail(value);
        }
    }
    
    /**
        * Realiza la petición via POST para el inicio de sesión.
    */
    const handleSubmit = async (evt) => 
    {
        evt.preventDefault();
        setIsInRequest(true);
        try
        {
            let action = "authenticate";
            if(process.env.REACT_APP_NODE_ENV == "development")
                action = "devAuthenticate";
                
            let response = await rest.post(action, {username:email});
            if(response)
            {
                if(process.env.REACT_APP_NODE_ENV == "development")
                {
                   window.location.href = "/#/home";
                }else{
                    createGoogleUrl();
                }
                setIsInRequest(false);
            }
        }
        catch (error) 
        {
            await setErrorMessage("Usuario inválido.");
            setIsInRequest(false);
        }
    }

    /**
        * Genera URL para inicio de sesión con Google.
    */
    const createGoogleUrl = ()=>
    {
        const stringifiedParams = queryString.stringify(
        {
            client_id: process.env.REACT_APP_CLIENT_ID,
            redirect_uri: process.env.REACT_APP_REDIRECT_URI + "/api/authGoogle",
            scope: [
              'https://www.googleapis.com/auth/userinfo.email',
              'https://www.googleapis.com/auth/userinfo.profile',
            ].join(' '), // space seperated string
            response_type: 'code',
            access_type: 'offline',
            prompt: 'consent',
        });
        window.location = `https://accounts.google.com/o/oauth2/v2/auth?${stringifiedParams}`;
    }
    
    return (
        <div id="login-section">
            <div className="bg">
                <img className="black-logo" src={backLogo} />
            </div>
            <div className="color-block">
                <div className="form-section">
                    <div className="container">
                        <div className="brand-section row row-cols-lg-12 row-cols-md-12">
                            <div className="col-12 col-lg-6 col-md-6">
                                <h1 className="welcome-text">BIENVENIDO <br />AL PORTAL</h1>
                                <hr className="text-underline"/>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <img className="brand-icon" src={logoWhite} />
                            </div>
                        </div>
                    </div>
                    <form className="login-form" onSubmit={handleSubmit}>
                        <InputEmail validateEmailHandler={validateEmail} placeholder="Email" className="input-form"></InputEmail> 
                        {
                            (errorMessage) &&
                                <div className="login__alert-error">
                                    {errorMessage}
                                </div>
                        }
                        {
                            (isEmailValid && !isInRequest) ?
                                <button data-cy="submit_button" className="black_and_white-theme" type="submit">Entrar</button>
                            : 
                                <button data-cy="submit_button" className="black_and_white-theme disabled" disabled>Entrar</button>
                        }
                    </form>
                </div>
                <div className="version-number"> 
                    <p> v.==versionNumber==</p>
                </div>
            </div>
        </div>
    )
}
export default LoginPage;