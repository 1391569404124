import React, { useEffect, useState } from 'react';
import _ from 'lodash';

/**
    * Renders a <InputRadioList /> Lista de radiobuttons
    * @component
    * @param  props
    * @param {array} props.catalog - Array of values.
    * @param {array}  props.currentData - Current value.
    * @param {string} props.paramName - Input value.
    * @param {string} props.idProperty - Value property. 
    * @param {string} props.displayName - Label property.
    * @param {function} props.handleData - Set data on change.
    * @param {string} props.col - Bootstrap class for columns 
*/
const InputRadioList = ({catalog, currentData, paramName, index, idProperty, displayName, handleData, col}) => 
{
    const [data, setData] = useState([]);

    useEffect(() => 
    {
        if(data && data.length)
        {
            handleData(paramName, data);
        }
    }, [data])

    /**
        * Funcion para seleccionar algun valor del listado.
        * @param {number} id - id del valor seleccionado.
    */
    const select = async (id) => 
    {
        let newData = [];
        if(currentData)
            newData = [...currentData];

        _.forEach(catalog, function(cat) 
        {
            let index = _.findIndex(newData, function(o) { return o[idProperty] == cat[idProperty]; });
            if(index != -1)
            {
                newData.splice(index, 1);
            }
        });
        newData.push({[idProperty]:id});
        setData(newData);
    }

    return (
        <div className={col}>
            <div className="py-4 form-radio-reparator h-100">
            {
                (catalog) &&
                    catalog.map((element) => 
                    {
                        return (
                            <p key={element[idProperty]} title={element.tooltip}>
                                <input name={paramName + index} onChange={()=>{select(element[idProperty])}} 
                                    checked={
                                        (currentData && currentData.filter((row)=>{return element[idProperty] == row[idProperty]}).length)
                                    } type="radio"/> {element[displayName]}
                            </p>
                        )
                    })
            }
            </div>
        </div>
    )
}
export default InputRadioList;