const axios = require('axios');
const SERVER_URL = `${process.env.REACT_APP_SERVER_URL}/api`;

class CommercialConditions {
  constructor ( ) { }

  async getSeasonTypes ( ) {
    try {
      const response = await axios({
        method: 'get',
        url: SERVER_URL + "/getSeasons",
      })
        .then(response => {
          return JSON.parse(response.data.data);
        })
        .catch(error => {
          throw error.response.data.error;
        })
      return response;

    } catch (error) {
      throw error;
    }
  }

  async getDataTypes ( ) {
    try {
      const response = await axios({
        method: 'get',
        url: SERVER_URL + "/getDataTypes",
      })
        .then(response => {
          return JSON.parse(response.data.data);
        })
        .catch(error => {
          throw error.response;
        })
      return response;

    } catch (error) {
      throw error;
    }
  }

  async getPromotions ( data ) {
    try {
      const response = await axios({
        method: 'post',
        url: SERVER_URL + "/getPhasePromotions",
        data: data
      })
        .then(({data}) => {
          return data.data;
        })
        .catch(error => {
          throw error.response.data.error;
        })
      return response;

    } catch (error) {
      throw error;
    }
  }
  async savePromotion ( data ) {
    try {
      const response = await axios({
        method: 'post',
        url: SERVER_URL + "/savePhasePromotion",
        data: data
      })
      return response;

    } catch (error) {
      throw error;
    }
  }

  async updPromotion ( data ) {
    try {
      const response = await axios({
        method: 'post',
        url: SERVER_URL + "/updatePhasePromotion",
        data: data
      })
      return response;

    } catch (error) {
      throw error;
    }
  }


}
export default CommercialConditions