import React from 'react'
import Loader from 'react-loader-spinner'

const CustomLoader = () => {
  return (<Loader
    type="TailSpin"
    color="#000000"
    height={100}
    width={100}
    className="text-center"
    //timeout={3000} //3 secs

  />)
}
export default CustomLoader