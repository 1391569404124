import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';

/**
    * Componente <ModalConfirmGeneral /> Abre un modal de confirmación customizable
    * @component
    * @param  props
    * @param {bool} props.show - Muestra/oculta el modal.
    * @param {function} props.handleCancel - Maneja la cancelación.
    * @param {function} props.handleConfirm - Maneja la confirmacion.
    * @param {string}  props.icon - Icono que se mostrara en el modal.
    * @param {object}  props.children - Contenido del modal.
*/
const ModalConfirmGeneral = ({ show, handleCancel, handleConfirm, icon, children }) => {
    /**
        * Confimacion.
    */
    const onConfirm = () => {
        handleConfirm();
    }

    /**
        * Cancelacion.
    */
    const onCancel = () => {
        handleCancel(false)
    }

    return (
        <Modal size={"logout"} show={show} backdrop="static" centered>
            <Modal.Body>
                <div className="row my-5 py-2">
                    <div className="col col-md-4 offset-md-4 text-center">
                        <img src={icon} className={"icon-logout my-3"} />
                    </div>
                </div>
                <div className="row text-center mb-5">
                    {children}
                </div>
                <div className="row justify-content-end">
                    <div className="mx-2">
                        <button className="btn btn-outline-link" onClick={() => { onCancel() }} data-cy="btnCancelConfirm">Cancelar</button>
                    </div>
                    <div className="mx-2">
                        <button className="btn btn-primary btn-block" onClick={() => { onConfirm() }} data-cy="btnConfirm">Aceptar</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
export default ModalConfirmGeneral;