import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
//import { Dropdown } from 'primereact/dropdown';
//ACTION && CONTEXT
import { RestActions } from "../../actions";
import { useMainContext } from "../../contexts/MainContext";
import { useCatalogsContext } from "../../contexts/CatalogsContext";
//COMPONENTS
import MenuPhases from '../../components/molecules/MenuPhases';
import StoriesForm from './StoriesForm';
import Dropdown from '../../components/atoms/Dropdown';
//ASSETS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import iconEdit from '../../assets/icons/icon-edit.svg';
import exclamationIcon from '../../assets/icons/icon-exclamation-mark.svg';
import './styles.css';
import ModalConfirmGeneral from '../../components/atoms/ModalConfirmGeneral';
import _ from 'lodash';

const Stories = ({ preloadReady }) => {
    const rest = new RestActions();
    let mainContext = useMainContext();
    let catalogs = useCatalogsContext();
    //let { phaseID } = useParams();
    const location = useLocation();
    if (window.sessionStorage.getItem("phaseID") == "" || window.sessionStorage.getItem("phaseID") == undefined)
        window.sessionStorage.setItem("phaseID", location.state.phaseID);
    const phaseID = window.sessionStorage.getItem("phaseID");

    const [phaseData, setPhaseData] = useState({});
    const [modelsData, setModelsData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [storiesList, setStoriesList] = useState([]);
    const [floorTypeID, setFloorTypeID] = useState();
    const [levelList, setLevelList] = useState([]);
    const [isSubmited, setIsSubmited] = useState(false);
    const [storiesIds, setStoriesIds] = useState({});
    const [showConfirmCloseStories, setShowConfirmCloseStories] = useState(false);
    const [showConfirmrecalculate, setShowConfirmRecalculate] = useState(false);

    useEffect(() => {
        if (floorTypeID)
            setShowModal(true);
    }, [floorTypeID]);

    useEffect(() => {
        if (preloadReady) {
            initPhase();
            getModels();
        }
    }, [preloadReady]);

    useEffect(() => {
        if (phaseData.phaseID)
            getStories();
    }, [phaseData]);

    useEffect(() => {
        if (storiesList.length)
            getLevels();
    }, [storiesList]);

    const initPhase = async () => {
        mainContext.setIsLoading(true);
        mainContext.setIsDirty(false);
        try {
            let response = await rest.post("getPhases", { phaseID: parseInt(phaseID) });
            setPhaseData(response[0]);

        }
        catch (error) {/*TODO SOMETHING*/ }
    }

    const getStories = async () => {
        try {
            let response = await rest.post("getFloorTypes", { phaseID: parseInt(phaseID) });
            setStoriesList(response);
            let storiesTypes = _.groupBy(response, 'isUnderground');
            setStoriesIds({
                underground: _.map(storiesTypes.true, 'floorTypeID'),
                ground: _.map(storiesTypes.false, 'floorTypeID')
            });
        }
        catch (error) {/*TODO SOMETHING*/ }
    }

    const getLevels = async () => {
        try {
            let response = await rest.post("getLevels", { phaseID: parseInt(phaseID) });
            if (response.length) {
                recalculateStories(response);
            }
            else
                calculateStories();
        }
        catch (error) {/*TODO SOMETHING*/ }
    }

    const getModels = async () => {
        try {
            let response = await rest.post("getModels", { phaseID: parseInt(phaseID), toFloorTypes: true });
            catalogs.setModels(response);
            mainContext.setIsLoading(false);
        }
        catch (error) {/*TODO SOMETHING*/ }
    }

    const checkTemplate = (rowData, field) => {
        if (rowData[field.field])
            return <FontAwesomeIcon icon={faCheck} />
        else
            return <span className="slash"></span>
    }

    const usosTemplate = (rowData) => {
        let text = "";
        _.forEach(rowData.floorTypeUses, (row, key) => {
            text += row.description + ", ";
        })
        let lastChar = text.charAt(text.length - 2)
        if (lastChar = ",") { text = text.slice(0, text.length - 2) }
        return text;
    }

    const statusTemplate = (rowData) => {
        if (rowData.status)
            return "Activo";
        else
            return "Inactivo";
    }

    const linkEditStorieTemplate = (rowData) => {
        return <span className="btn-icon"><img onClick={() => { setFloorTypeID(rowData.floorTypeID) }} src={iconEdit} /></span>
    }

    const getErrorClass = (value) => {
        if (isSubmited && (value == "" || !value))
            return "error-border";
        else
            return "";
    }

    const onNameChange = async (paramName, index, e) => {
        mainContext.setIsDirty(true);
        let levels = [...levelList];
        levels[index][paramName] = e.value;
        setLevelList(levels);
    }

    const onDataChange = async (index, e) => {
        mainContext.setIsDirty(true);
        let levels = [...levelList];
        let prevValue = levels[index].floorTypeID;
        levels[index].floorTypeID = e.value;

        let wrongStory = false;
        if (_.includes(storiesIds.underground, e.value.floorTypeID)) {
            for (let i = index; i >= 0; i--)
                if (levels[i].floorTypeID != null && !_.includes(storiesIds.underground, levels[i].floorTypeID.floorTypeID))
                    wrongStory = true;
        }
        else {
            for (let i = index; i < levels.length; i++)
                if (levels[i].floorTypeID != null && !_.includes(storiesIds.ground, levels[i].floorTypeID.floorTypeID))
                    wrongStory = true;
        }

        if (wrongStory) {
            mainContext.setMessageAlert("No se puede asignar un tipo de planta subterráneo después de un tipo de planta NO subterráneo");
            levels[index].floorTypeID = prevValue;
            return;
        }

        let idx = _.findLastIndex(levels, (row) => { if (row.floorTypeID) return _.includes(storiesIds.underground, row.floorTypeID.floorTypeID) });
        if (idx != -1)
            for (let i = 0; i <= idx; i++) {
                levels[i].floorOrder = "S" + (idx - i + 1);
                if (levels[i].name == "")
                    levels[i].name = "Subterráneo " + (idx - i + 1);
            }

        idx = _.findIndex(levels, (row) => { if (row.floorTypeID) return _.includes(storiesIds.ground, row.floorTypeID.floorTypeID) });
        if (idx != -1)
            for (let i = idx; i < levels.length; i++) {
                levels[i].floorOrder = i - idx + 1;
                if (levels[i].name == "")
                    levels[i].name = "Piso " + (i - idx + 1);
            }

        setLevelList(levels);
    }

    const recalculateStories = (data) => {
        let _levels = [];
        if (phaseData.levels == data.length) {
            _levels = data;
        }
        else if (phaseData.levels > data.length) {
            _levels = [...data];
            for (let i = data.length; i < phaseData.levels; i++) {
                _levels.push({ levelNumber: i + 1, floorTypeID: null, floorOrder: "-", name: "" });
            }
        }
        else {
            for (let i = 0; i < phaseData.levels; i++) {
                _levels.push(data[i]);
            }
        }

        _.forEach(_levels, (row, index) => {
            if (row.floorTypeID) {
                let _index = _.findIndex(storiesList, (st) => { return st.floorTypeID == row.floorTypeID });
                _levels[index].floorTypeID = storiesList[_index];
            } else {
                _levels[index].floorTypeID = null;
            }
        })
        setLevelList(_levels);
    }

    const calculateStories = () => {
        setShowConfirmRecalculate(false);
        let levels = [];
        for (let i = 0; i < phaseData.levels; i++) {
            levels.push({ levelNumber: i + 1, floorTypeID: null, floorOrder: "-", name: "" });
        }
        setLevelList(levels);
    }

    const validateForm = async () => {
        setIsSubmited(true);
        let requiredData = ["name", "floorTypeID"];
        let isValid = true;
        _.forEach(requiredData, (row) => {
            _.forEach(levelList, (level) => {
                if (level[row] == undefined || level[row] == null || level[row] == "")
                    isValid = false;
            });
        });

        if (!isValid)
            mainContext.setMessageAlert("Es necesario llenar todos los campos obligatorios (*)");
        else {
            await sendForm(false);
            let responseUnits = await rest.post("getUnitsModels", { phaseID: parseInt(phaseID) });
            setModelsData(responseUnits);
        }
        return isValid;
    }

    const sendForm = async (showMesages = true) => {
        let _levels = [];
        _.forEach(levelList, (row, index) => {
            _levels[index] = Object.assign({}, row);
            if (row.floorTypeID)
                _levels[index].floorTypeID = row.floorTypeID.floorTypeID;
        });

        let params = {
            userID: mainContext.session.userID,
            phaseID: parseInt(phaseID),
            levels: _levels
        }
        mainContext.setIsLoading(true);
        try {
            await rest.post("saveLevels", params);
            if (showMesages)
                mainContext.setMessageAlert("El registro se guardó correctamente");
            mainContext.setIsLoading(false);
            mainContext.setIsDirty(false);
        }
        catch (error) {
            mainContext.setMessageAlert(error.toString());
            mainContext.setIsLoading(false);
        }
    }

    const closeStories = async () => {
        if (!await validateForm())
            return false;

        let params = {
            userID: mainContext.session.userID,
            phaseID: parseInt(phaseID)
        }

        mainContext.setIsLoading(true);
        try {
            await rest.post("createUnits", params);
            mainContext.setMessageAlert("Se crearon las unidades exitosamente");
            mainContext.setIsLoading(false);
            window.location = "#/phase/units";
        }
        catch (error) {
            mainContext.setMessageAlert(error.toString());
            mainContext.setIsLoading(false);
        }
    }

    return (
        (preloadReady) &&
        <>
            <MenuPhases phaseID={phaseID} phaseData={phaseData} linkActive={"pisos"} />
            <StoriesForm floorTypeID={floorTypeID} phaseID={phaseID} showModal={showModal} handleShowModal={setShowModal} handleFloorTypeID={setFloorTypeID} handleRefreshList={getStories} storiesList={storiesList} levelList={levelList} />
            <ModalConfirmGeneral handleConfirm={() => { closeStories() }} handleCancel={setShowConfirmCloseStories} show={showConfirmCloseStories} icon={exclamationIcon} >
                <div className="col">
                    <p className="title-modal"><b>¿Seguro que desea generar la tabla?</b></p>
                    <p>una vez creada la tabla de unidades ya no podrá ser editada</p>
                    <p>Total de unidades: {_.sumBy(modelsData, (o) => { return parseInt(o.totalUnits); })}</p>
                    <p>Unidades por modelo:</p>
                    {
                        modelsData.map((model) =>
                        (
                            <p>{model.modelName}: {model.totalUnits}</p>
                        ))
                    }
                </div>
            </ModalConfirmGeneral>
            <ModalConfirmGeneral handleConfirm={() => { calculateStories() }} handleCancel={setShowConfirmRecalculate} show={showConfirmrecalculate} icon={exclamationIcon}>
                <div className="col">
                    <p className="title-modal">Al re-calcular los pisos, se perdera cualquier informacion editada previamente (nombres de pisos)</p>
                </div>
            </ModalConfirmGeneral>
            <div className="custom-card">
                <div className="custom-card-header px-3 d-flex">
                    <h4 className="py-3 w-50">TIPOS DE PLANTA</h4>
                    <div className="w-50 py-2 d-flex justify-content-end">
                        <button onClick={() => { setShowModal(true) }} className="btn btn-primary"><span className="d-inline-block pr-3">Añadir tipo de planta</span> +</button>
                    </div>
                </div>
                <div className="custom-card-body px-3">
                    <div className="row">
                        <div className="col">
                            <DataTable value={storiesList} emptyMessage="No se encontraron datos" className="p-datatable-no-border p-datatable-striped" rows={10000}>
                                <Column className="px-1" field="name" header="Nombre" />
                                <Column className="text-center px-1" field="areas" header="# de Áreas" />
                                <Column className="px-1 ellipsis w-25" field="floorTypeUses" body={usosTemplate} header="Descripción" />
                                <Column className="text-center px-1" field="isUnderground" body={checkTemplate} header="Subterráneo" />
                                <Column className="text-center px-1" field="isTypePlant" body={checkTemplate} header="Planta tipo" />
                                <Column className="text-center px-1" field="status" body={statusTemplate} header="Estatus" />
                                <Column className="px-1 w-50px" field="" header="" body={linkEditStorieTemplate} />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>

            <div className="custom-card mt-4">
                <div className="custom-card-header">
                    <h4 className="py-3 px-3">ASIGNACIÓN DE NIVELES</h4>
                </div>
                <div className="custom-card-body px-3">
                    <div className="row">
                        <div className="col">
                            {(phaseData.levels > 0 && storiesList.length) ?
                                <table className="w-100 table-no-border table-striped">
                                    <thead>
                                        <tr>
                                            <th className="text-center">Nivel</th>
                                            <th className="w-25">Tipo de planta</th>
                                            <th className="text-center w-25">Pisos</th>
                                            <th className="w-25">Nombre</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            levelList.map((level, index) =>
                                            (
                                                <tr key={`row-${index}`}>
                                                    <td className="text-center">
                                                        {level.levelNumber}
                                                    </td>
                                                    <td>
                                                        <Dropdown
                                                            value={levelList[index].floorTypeID}
                                                            options={storiesList}
                                                            onChange={(e) => { onDataChange(index, e); }}
                                                            optionValue="floorTypeID"
                                                            optionLabel="name"
                                                            placeholder="Seleccionar tipo de planta"
                                                            isClearable={false}
                                                            customClass={`input-table-td ${getErrorClass(levelList[index].floorTypeID)}`}
                                                            hideItemBy="status" />
                                                    </td>
                                                    <td className="text-center">
                                                        {level.floorOrder}
                                                    </td>
                                                    <td>
                                                        <input value={levelList[index].name} onChange={(e) => { onNameChange('name', index, e.target); }} type="text" className={`form-control w-100 ${getErrorClass(levelList[index].name)}`} />
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                                : (!storiesList.length) ?
                                    <p><small>No hay tipos de planta creados, favor de crear tipos de planta</small></p>
                                    :
                                    <p><small>No hay niveles asignados al proyecto, favor de ir a la sección General y llenar el campo 'Niveles de proyecto'</small></p>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-4 offset-8 d-flex justify-content-end">
                    <button onClick={sendForm} disabled={!mainContext.isDirty} className="btn btn-primary w-50 mr-3">Guardar</button>
                    <button onClick={async () => { if (await validateForm()) setShowConfirmCloseStories(true) }} disabled={mainContext.isDirty || phaseData.hasUnits || phaseData.levels == 0 || storiesList.length == 0} className="btn btn-primary w-50">Cerrar pisos</button>
                </div>
            </div>
        </>
    )
}
export default Stories;