import React, { useEffect, useState } from "react"

import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"

//Context
import { useAmenitiesAndServicesContext } from "../../../../contexts/AmenitiesAndServicesContext"
import { useMainContext } from "../../../../contexts/MainContext"
//Actions
import { CatalogsActions } from "../../../../actions"

const ServicesSection = (props) => {
  let { update, phaseID } = props;

  const catalogActions = new CatalogsActions()
  let servicesContext = useAmenitiesAndServicesContext();
  let mainContext = useMainContext();

  const [servicesCatalog, setServiceCatalogs] = useState()
  const [selectedItems, setSelectedItems] = useState({})

  useEffect(() => {
    const getCatalog = async () => {
      let catalog, itemsObject = {};
      let itemsContext = servicesContext.services;
      catalog = await catalogActions.getCatalog("getServices")
      await catalog.map( item => {
        if(item){
          itemsObject["item-"+item.serviceID] = itemsContext.includes(item.serviceID);
        }
      })
      await setServiceCatalogs(catalog)
      setTimeout(()=>{
        mainContext.setIsLoading(false);
      },1000)
    }
    getCatalog()
  }, [update])

  
  useEffect(() => {
    const setCatalog = async () => 
    {
      let itemsObject = {};
      await servicesCatalog.map( item => {
        if(item){
          itemsObject["item-"+item.serviceID] = servicesContext.services.includes(item.serviceID);
        }
      })
      await setSelectedItems(itemsObject);
    }
    if(servicesCatalog)
      setCatalog();
  }, [servicesContext.services])

  const addSelected = async (checked, serviceID) => 
  {
    mainContext.setIsDirty(true);
    let arrayServices = [...servicesContext.services];
    let itemsObject = {...selectedItems};
    if(checked){
      arrayServices.push(serviceID);
      itemsObject[`item-${serviceID}`] = true;
    }else{
      let index = arrayServices.indexOf(serviceID);
      arrayServices.splice(index, 1);
      itemsObject[`item-${serviceID}`] = false;
    }
    await setSelectedItems(itemsObject);
    await servicesContext.setServices(arrayServices);
  }

  return (
    <section>
      <Row>
        {servicesCatalog ? servicesCatalog.map((item) => {
          return (
            <Col lg={3} md={3} className="label-elipsis">
              <Form.Check checked={selectedItems["item-" + item.serviceID]} key={item.serviceID} id={"item-" + item.serviceID} onClick={(e) => {  addSelected(e.target.checked, item.serviceID) }} type="checkbox" label={item.description} style={{marginTop:"10px"}} />
            </Col>
          )

        }) : null}
      </Row>
    </section>
  )
}
export default ServicesSection