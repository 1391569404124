import React, { useState, useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';

const LoginSuccessPage = (props) => 
{
    const { googleRedirect } = props;
    let { googleCode } = useParams();
    const cookies = new Cookies();
    const [isLoged, setIsLoged] = useState(false);

    useEffect(() => 
    {
        if(googleCode){
            cookies.set('token', googleCode, { path: '/' });
            setIsLoged(true);
        }
    },
    [googleRedirect]);
      
    return (
        (isLoged) &&
            <Redirect to='/home' />
    )
}
export default LoginSuccessPage;