import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import deleteImg from '../../../assets/icons/delete-files.svg';
import exclamationIcon from '../../../assets/icons/icon-exclamation-mark.svg';
import './styles.css'
import ImportIcon from '../../../assets/icons/icon-import.svg'
import { useMainContext } from "../../../contexts/MainContext";
import { isNull } from 'lodash';

const ModalImportFile = (props) => {
    let mainContext = useMainContext();
    const { showModal, setShow, setCancelAction, handlerAccept, cancel, success, size, } = props
    const [name, setName] = useState(isNull)
    /**
        * Cierra el modal.
    */
    const handleClose = () => {
        setName(null)
        setShow(false);
    }

    /**
        * Comfirmacion de borrado.
    */
    useEffect(() => {
        setName(null)
    }, [showModal])

    const sendData = () => {
        if (name)
            handlerAccept(name)
        else
            mainContext.setMessageAlert("Ingresa un nombre valido");
    }

    const onFormDataChange = async (e) => {
        setName(e.value);

    }
    return (
        //TODO: Fix styles
        <section>
            <Modal size={"logout"} show={showModal} onHide={() => { handleClose() }} backdrop="static" centered >
                <Modal.Header className="no-border p-1" closeButton>
                    <Modal.Title className="pl-3"></Modal.Title>
                </Modal.Header>
                <Modal.Body className={"text-center"} >
                    <div className="custom-card">
                        <div className="custom-card-header px-3 d-flex">
                            <h4 className="py-3">Borrar levantamientos</h4>
                            <div className=" py-2 d-flex justify-content-end">
                                {/*<button onClick={() => { setShowModal(true) }} className="btn btn-primary"><span className="d-inline-block pr-4">Nueva importación</span> +</button>*/}.
                            </div>
                        </div>
                        <div className="custom-card-body px-3 d-flex">
                            <div className="form-group with-message w-100 ">
                                <label className="input-label form-label">
                                    Nombre fase
                                </label>
                                <div className="">

                                    <input type="tetx" className="form-control black-border large-input" onChange={(e) => { onFormDataChange(e.target) }} />

                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="container d-flex justify-content-end mt-5">
                        <button className="btn btn-white " onClick={() => { handleClose() }}>Cancelar</button>

                        <button className="btn btn-primary ml-3" onClick={sendData}>Borrar</button>
                    </div>
                </Modal.Body>
            </Modal>
        </section>
    )
}
export default ModalImportFile