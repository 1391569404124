import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { animateScroll as scroll } from 'react-scroll';
import _ from 'lodash';
//ACTION && CONTEXT
import { RestActions } from "../../actions";
import { useMainContext } from "../../contexts/MainContext";
import Delivery from "./Delivery"
import InteriorFeatures from './InteriorFeatures';
import PresentationProyect from './PresentationProyect';
import AdditionalFeatures from './AdditionalFeatures';
import MenuPhases from '../../components/molecules/MenuPhases';

const Especificaciones = ({ preloadReady, edition }) => {

	// --------------------------------------
	// Component State and Contexts
	// --------------------------------------
	const rest = new RestActions();
	const mainContext = useMainContext();
	//let { phaseID } = useParams();
	const location = useLocation();
	if (window.sessionStorage.getItem("phaseID") == "" || window.sessionStorage.getItem("phaseID") == undefined)
		window.sessionStorage.setItem("phaseID", location.state.phaseID);
	const phaseID = window.sessionStorage.getItem("phaseID");

	const [isSubmited, setIsSubmited] = useState(false);
	const [formData, setFormData] = useState({});
	const [images, setImages] = useState([]);
	const [phaseData, setPhaseData] = useState({});

	useEffect(() => {
		if (preloadReady)
			initPhase();
		getTechSpecifications();
	}, [preloadReady]);



	// --------------------------------------
	// Set Phase Value
	// --------------------------------------
	const initPhase = async () => {
		mainContext.setIsLoading(true);
		mainContext.setIsDirty(false);
		try {
			if (phaseID) {
				let response = await rest.post("getPhases", { phaseID: phaseID });
				setPhaseData(response[0]);
			}
			else {
				let response = await rest.post("getPhaseCode", { "project_id": 0, "developer_id": 0 });
				setPhaseData({ phaseCode: response, projects: null, phaseID: null });
			}
			mainContext.setIsLoading(false);
		}
		catch (error) {/*TODO SOMETHING*/ }
	}

	const updateFormData = async (_formData) => {
		let newData = await Object.assign({}, formData, _formData);
		await setFormData(newData);
	}


	// --------------------------------------
	// Save Data
	// --------------------------------------

	const sendForm = async (goNext = false) => {

		//if (!validatePresentationToggles(formData)) {
		//	mainContext.setMessageAlert("Debes seleccionar al menos una de las opciones de la presentación del proyecto");
		//	return
		//}

		mainContext.setIsLoading(true);
		// let params = { ...formData };

		let params = {};


		const { interiorFeatures, buildingFeatures, delivery } = formData;
		params.action = "saveTechSpecs"
		params.phaseID = phaseData.phaseID;
		params.techSpecsID = null
		params.observations = formData.observations;
		params.isDeveloperOffice = formData.isDeveloperOffice;
		params.isProjectOffice = formData.isProjectOffice;
		params.isShowroom = formData.isShowroom;
		params.isAdjustedDepartment = formData.isAdjustedDepartment;

		if (delivery) {
			params.finishedFeatures = delivery.finishedFeatures
			params.finishedTypeID = delivery.finishedTypeID ? delivery.finishedTypeID.finishedTypeID : null
			params.finishedDeliveryTypeID = delivery.finishedDeliveryType ? delivery.finishedDeliveryType.finishedDeliveryTypeID : null
		} else {
			params.finishedFeatures = formData.finishedFeatures ? formData.finishedFeatures : []
		}
		if (interiorFeatures) {
			params.interiorFeatures = {
				wallFinishID: interiorFeatures.wallFinishID ? interiorFeatures.wallFinishID.wallFinishID : null,
				ceilingID: interiorFeatures.ceilingID ? interiorFeatures.ceilingID.ceilingID : null,
				lightingID: interiorFeatures.lightingID ? interiorFeatures.lightingID.lightingID : null,
				systemACID: interiorFeatures.systemACID ? interiorFeatures.systemACID.systemACID : null,
				// deckTileTypeID: interiorFeatures.deckTileTypeID ? interiorFeatures.deckTileTypeID.deckTileTypeID : null,
				interiorDeckTileTypes: interiorFeatures.interiorDeckTileTypes ? interiorFeatures.interiorDeckTileTypes : null,
				deckTileOriginID: interiorFeatures.deckTileOriginID ? interiorFeatures.deckTileOriginID.deckTileOriginID : null,
				deckTileMeasureID: interiorFeatures.deckTileMeasureID ? interiorFeatures.deckTileMeasureID.deckTileMeasureID : null,
				height: interiorFeatures.height ? interiorFeatures.height : 0,
				hasAcousticInsulator: interiorFeatures.hasAcousticInsulator ? interiorFeatures.hasAcousticInsulator : false,
			};
		}
		else
			params.interiorFeatures = {
				wallFinishID: null,
				ceilingID: null,
				lightingID: null,
				systemACID: null,
				deckTileTypeID: null,
				deckTileOriginID: null,
				deckTileMeasureID: null,
				height: 0,
				hasAcousticInsulator: false,
			};


		if (buildingFeatures) {
			buildingFeatures.totalElevators = buildingFeatures?.totalElevators != null ? buildingFeatures.totalElevators.toString() : buildingFeatures.totalElevators
			buildingFeatures.totalParkingLots = (buildingFeatures?.totalParkingLots != null) ? buildingFeatures.totalParkingLots.toString() : buildingFeatures.totalParkingLots
			buildingFeatures.totalVisitorParkingLots = buildingFeatures?.totalVisitorParkingLots != null ? buildingFeatures.totalVisitorParkingLots.toString() : buildingFeatures.totalVisitorParkingLots
			params.buildingFeatures = {
				totalElevators: buildingFeatures.totalElevators ? parseFloat(buildingFeatures.totalElevators.replace(',', '')) : null,
				buldingElevatorTypes: buildingFeatures.buldingElevatorTypes ? buildingFeatures.buldingElevatorTypes : null,
				buildingTypeID: buildingFeatures.buildingTypeID ? buildingFeatures.buildingTypeID.buildingTypeID : null,
				facadeTypeID: buildingFeatures.facadeTypeID ? buildingFeatures.facadeTypeID.facadeTypeID : null,
				interiorWallTypeID: buildingFeatures.interiorWallTypeID ? buildingFeatures.interiorWallTypeID.interiorWallTypeID : null,
				dividenWallTypeID: buildingFeatures.dividenWallTypeID ? buildingFeatures.dividenWallTypeID.dividenWallTypeID : null,
				windowTypeID: buildingFeatures.windowTypeID ? buildingFeatures.windowTypeID.windowTypeID : null,
				totalParkingLots: buildingFeatures.totalParkingLots != null ? parseFloat(buildingFeatures.totalParkingLots.replace(',', '')) : null,
				totalVisitorParkingLots: buildingFeatures.totalVisitorParkingLots ? parseFloat(buildingFeatures.totalVisitorParkingLots.replace(',', '')) : null,
				hasFireProtectionSystem: buildingFeatures.hasFireProtectionSystem != null ? buildingFeatures.hasFireProtectionSystem : false
			};
		}
		else
			params.buildingFeatures = {
				totalElevators: null,
				elevatorTypeID: null,
				buildingTypeID: null,
				facadeTypeID: null,
				interiorWallTypeID: null,
				dividenWallTypeID: null,
				windowTypeID: null,
				totalParkingLots: null,
				totalVisitorParkingLots: null,
				hasFireProtectionSystem: false
			};






		try {

			let response = await rest.post("saveTechSpecs", params);
			console.log("params ", formData);

			let newData = await Object.assign({}, formData, params);

			await setFormData(newData);
			let responsePhase = await rest.post("getPhases", { phaseID: phaseData.phaseID });
			await setPhaseData(responsePhase[0]);
			await mainContext.setIsLoading(false);
			await mainContext.setIsDirty(false);
			if (goNext)
				requestNavigation();
		}
		catch (error) {

			console.log("error JONNY");
			console.log(error);
			/*TODO SOMETHING*/
			mainContext.setMessageAlert("Debes agregar al menos una característica");
			mainContext.setIsLoading(false);
		}
	}


	// --------------------------------------
	// Validate that at least 1 of the 
	// PresentationProyect toggles is ON
	// --------------------------------------
	const validatePresentationToggles = (formData) => {
		const { isDeveloperOffice, isProjectOffice, isShowroom, isAdjustedDepartment } = formData;

		if (!isDeveloperOffice && !isProjectOffice && !isShowroom && !isAdjustedDepartment)
			return false;

		else return true;

	}



	const requestNavigation = () => {
		scroll.scrollTo(0);
		let goTo = "/#phase/models";
		mainContext.setGoTo(goTo);
	}
	const getTechSpecifications = async () => {
		try {
			let response = await rest.post("getTechSpecs", { phaseID: parseInt(phaseID) });
			setFormData(response)
			mainContext.setIsLoading(false);
		}
		catch (error) {/*TODO SOMETHING*/ }
	}

	const saveTechSpecifications = async () => { }

	return (
		(preloadReady) &&
		<>
			<MenuPhases
				phaseID={phaseID}
				initData={phaseData}
				phaseData={phaseData}
				handlerFormData={updateFormData}
				linkActive={"especificaciones"}
				hasUnits={phaseData.hasUnits}
			/>

			<Delivery
				phaseID={phaseID}
				initData={phaseData}
				phaseData={formData}
				handlerFormData={updateFormData}
				edit={edition}
			/>
			<InteriorFeatures
				phaseID={phaseID}
				initData={phaseData}
				phaseData={formData}
				handlerFormData={updateFormData}
				edit={edition}
			/>
			<AdditionalFeatures
				phaseID={phaseID}
				initData={phaseData}
				phaseData={formData}
				handlerFormData={updateFormData}
				edit={edition}
			/>
			<PresentationProyect
				phaseID={phaseID}
				initData={phaseData}
				phaseData={formData}
				handlerFormData={updateFormData}
				edit={edition}
			/>
			<div className="row d-flex justify-content-end mt-5">
				<div className="col-10 d-flex justify-content-end">
					{
						(mainContext.isDirty) ?
							<button onClick={() => { sendForm() }} className="btn btn-white w-25 mr-4">Guardar</button>
							:
							<button className="btn btn-white w-25 mr-4 disabled">Guardar</button>
					}
					{
						<button onClick={() => { sendForm(true) }} className="btn btn-primary w-25">Continuar</button>

					}
				</div>
			</div>
		</>

	)
}

export default Especificaciones
