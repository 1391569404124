import React, { useState } from 'react';

/**
    * Componente <InputEmail /> renderiza un input de tipo emai con validaciones
    * Utilizado para el login
    * @component
    * @param  props
    * @param {function} props.validateEmailHandler - Funcion para validar email
    * @param {string}  props.className - Nombre de la clase de CSS
    * @param {string} props.placeholder - texto para placeholder
*/
const InputEmail = (props) => 
{
    const { validateEmailHandler, className, placeholder } = props;
    const [inputValue, setInputValue] = useState('');

    /**
        * funcion para validar email
        * @param {object} event - Input event with the value property
    */
    const validateEmail = (e) => 
    {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        setInputValue(e.target.value);
        validateEmailHandler(re.test(String(e.target.value).toLowerCase()), e.target.value);
    }

    return (
        <input value={inputValue} type="email" onChange={validateEmail} placeholder={placeholder} className={className} data-cy="user_input" />
    )
}
export default InputEmail;
