import React, { useEffect, useState } from 'react'
import { Switch, Route, useRouteMatch } from "react-router-dom";
//ACTION && CONTEXT
import { RestActions } from "../../actions";
import { useMainContext } from "../../contexts/MainContext";
import { useCatalogsContext } from "../../contexts/CatalogsContext";
//COMPONENTS
import Importer from './Importer/Index'
import Users from './Users/Index'
import GeneralCatalogs from "./GeneralCatalogs/Index"
import Offices from "./Offices"
import Cities from "./Cities"

import Loading from '../../components/atoms/Loading';
import MessageAlert from '../../components/atoms/MessageAlert';
//ASSETS
import logo from '../../assets/icons/4SRealEstate_SingleLine.png';
import './styles.css';


//TODO COMPONENS TO CLEAN
const PagesManager = () => {
	const rest = new RestActions();
	let mainContext = useMainContext();
	let catalogs = useCatalogsContext();
	const [preloadReady, setPreloadReady] = useState(false);

	useEffect(() => {
		getSession();
	}, [])

	useEffect(() => {
		if (mainContext.session) {
			getCatalogs();
		}
	}, [mainContext.session])

	useEffect(() => {
		if (mainContext.updateCatalogs)
			updateOfficeCalatog();


	}, [mainContext.updateCatalogs])

	const getSession = async () => {
		try {
			let response = await rest.post("getSession");
			mainContext.setSession(response);
		}
		catch (error) {
			if (window.location.hostname == "localhost")
				mainContext.setSession({ userID: 1 });
			else
				window.location.href = "/";
		}
	}
	const updateOfficeCalatog = async () => {

		try {

			let countries = await rest.get("getComplexFilters");
			catalogs.setCountries(countries[0].countries);
			catalogs.setDevelopers(await rest.get("getDevelopers"));

			//catalogs.setTowers(JSON.parse(await rest.get("getTowers")));
			//catalogs.setComplexUses(await rest.get("getComplexUses"));
			catalogs.setTradeStatus(JSON.parse(await rest.get("getTradeStatus")));
			//catalogs.setClassifications(JSON.parse(await rest.get("getClassifications")));
			catalogs.setFileTypes(JSON.parse(await rest.get("getFileTypes")));
			catalogs.setAmenityTypes(JSON.parse(await rest.get("getAmenityTypes")));
			let _catalogs = await rest.get("getCatalogs");
			console.log("_catalogs ", _catalogs);
			//catalogs.setMasterPlanUses(_catalogs.masterPlanUses);
			catalogs.setClassifications(_catalogs.classifications);
			catalogs.setMarketingSchemes(_catalogs.marketingSchemes);
			catalogs.setMasterPlan(_catalogs.masterPlans);
			catalogs.setAreaLocations(_catalogs.areaLocations);
			catalogs.setCupboardTypes(_catalogs.cupboardTypes);
			catalogs.setCurrencies(_catalogs.currencies);
			catalogs.setFloorTypeUses(_catalogs.floorTypeUses);
			catalogs.setFloorTypes(_catalogs.floorTypes);
			catalogs.setHouseDistributions(_catalogs.houseDistributions);
			catalogs.setLandDistributions(_catalogs.landDistributions);
			catalogs.setKitchenDistributions(_catalogs.kitchenDistributions);
			catalogs.setLaundryRooms(_catalogs.laundryRooms);
			catalogs.setModelServices(_catalogs.modelServices);
			catalogs.setModelSpecials(_catalogs.modelSpecials);
			catalogs.setCommercialBusiness(_catalogs.commercialBusiness);
			catalogs.setModelTypes(_catalogs.modelTypes);
			catalogs.setNaturalVentilationSpaces(_catalogs.naturalVentilationSpaces);
			catalogs.setDoubleHeightSpaces(_catalogs.doubleHeightSpaces);
			catalogs.setNumberViews(_catalogs.numberViews);
			catalogs.setServices4S(_catalogs.services4S);
			catalogs.setWalkInRooms(_catalogs.walkInRooms);
			catalogs.setSaleStatus(_catalogs.saleStatus);
			catalogs.setWallFinish(_catalogs.wallFinish);
			catalogs.setCeiling(_catalogs.ceiling);
			catalogs.setLighting(_catalogs.lighting);
			catalogs.setSystemAC(_catalogs.systemAC);
			catalogs.setDeckTileTypes(_catalogs.deckTileTypes);
			catalogs.setDeckTileOrigins(_catalogs.deckTileOrigins);
			catalogs.setDeckTileMeasures(_catalogs.deckTileMeasures);

			catalogs.setElevatorTypes(_catalogs.elevatorTypes);
			catalogs.setBuildingTypes(_catalogs.buildingTypes);
			catalogs.setFacadeTypes(_catalogs.facadeTypes);
			catalogs.setInteriorWallTypes(_catalogs.interiorWallTypes);
			catalogs.setDividenWallTypes(_catalogs.dividenWallTypes);
			catalogs.setWindowTypes(_catalogs.windowTypes);

			catalogs.setFinishedTypes(_catalogs.finishedTypes);
			catalogs.setFinishedFeatures(_catalogs.finishedFeatures);
			catalogs.setFinishedTypesFeatures(_catalogs.finishedTypesFeatures);
			catalogs.setFinishedDeliveryTypes(_catalogs.finishedDeliveryTypes);
			catalogs.setRentalStatus(_catalogs.rentalStatus);
			catalogs.setSubcategories(_catalogs.subCategories);
			catalogs.setRoles(_catalogs.roles)
			catalogs.setGeneralCats(_catalogs.generalCats)
			catalogs.setOffices(_catalogs.offices)
			await mainContext.setUpdateCatalogs(false)
			mainContext.setIsLoading(false);
		}
		catch (error) { console.log(error);/*TODO SOMETHING*/ }
	}
	const getCatalogs = async () => {
		if (catalogs.workProgress) {
			setPreloadReady(true);
			return;
		}
		try {
			let countries = await rest.get("getComplexFilters");
			catalogs.setCountries(countries[0].countries);
			catalogs.setDevelopers(await rest.get("getDevelopers"));

			//catalogs.setTowers(JSON.parse(await rest.get("getTowers")));
			//catalogs.setComplexUses(await rest.get("getComplexUses"));
			catalogs.setTradeStatus(JSON.parse(await rest.get("getTradeStatus")));
			//catalogs.setClassifications(JSON.parse(await rest.get("getClassifications")));
			catalogs.setFileTypes(JSON.parse(await rest.get("getFileTypes")));
			catalogs.setAmenityTypes(JSON.parse(await rest.get("getAmenityTypes")));
			let _catalogs = await rest.get("getCatalogs");
			console.log("_catalogs ", _catalogs);
			//catalogs.setMasterPlanUses(_catalogs.masterPlanUses);
			catalogs.setClassifications(_catalogs.classifications);
			catalogs.setMarketingSchemes(_catalogs.marketingSchemes);
			catalogs.setMasterPlan(_catalogs.masterPlans);
			catalogs.setAreaLocations(_catalogs.areaLocations);
			catalogs.setCupboardTypes(_catalogs.cupboardTypes);
			catalogs.setCurrencies(_catalogs.currencies);
			catalogs.setFloorTypeUses(_catalogs.floorTypeUses);
			catalogs.setFloorTypes(_catalogs.floorTypes);
			catalogs.setHouseDistributions(_catalogs.houseDistributions);
			catalogs.setLandDistributions(_catalogs.landDistributions);
			catalogs.setKitchenDistributions(_catalogs.kitchenDistributions);
			catalogs.setLaundryRooms(_catalogs.laundryRooms);
			catalogs.setModelServices(_catalogs.modelServices);
			catalogs.setModelSpecials(_catalogs.modelSpecials);
			catalogs.setCommercialBusiness(_catalogs.commercialBusiness);
			catalogs.setModelTypes(_catalogs.modelTypes);
			catalogs.setNaturalVentilationSpaces(_catalogs.naturalVentilationSpaces);
			catalogs.setDoubleHeightSpaces(_catalogs.doubleHeightSpaces);
			catalogs.setNumberViews(_catalogs.numberViews);
			catalogs.setServices4S(_catalogs.services4S);
			catalogs.setWalkInRooms(_catalogs.walkInRooms);
			catalogs.setSaleStatus(_catalogs.saleStatus);
			catalogs.setWallFinish(_catalogs.wallFinish);
			catalogs.setCeiling(_catalogs.ceiling);
			catalogs.setLighting(_catalogs.lighting);
			catalogs.setSystemAC(_catalogs.systemAC);
			catalogs.setDeckTileTypes(_catalogs.deckTileTypes);
			catalogs.setDeckTileOrigins(_catalogs.deckTileOrigins);
			catalogs.setDeckTileMeasures(_catalogs.deckTileMeasures);

			catalogs.setElevatorTypes(_catalogs.elevatorTypes);
			catalogs.setBuildingTypes(_catalogs.buildingTypes);
			catalogs.setFacadeTypes(_catalogs.facadeTypes);
			catalogs.setInteriorWallTypes(_catalogs.interiorWallTypes);
			catalogs.setDividenWallTypes(_catalogs.dividenWallTypes);
			catalogs.setWindowTypes(_catalogs.windowTypes);

			catalogs.setFinishedTypes(_catalogs.finishedTypes);
			catalogs.setFinishedFeatures(_catalogs.finishedFeatures);
			catalogs.setFinishedTypesFeatures(_catalogs.finishedTypesFeatures);
			catalogs.setFinishedDeliveryTypes(_catalogs.finishedDeliveryTypes);
			catalogs.setRentalStatus(_catalogs.rentalStatus);
			catalogs.setSubcategories(_catalogs.subCategories);
			catalogs.setRoles(_catalogs.roles)
			catalogs.setGeneralCats(_catalogs.generalCats)
			catalogs.setOffices(_catalogs.offices)

			catalogs.setWorkProgress(JSON.parse(await rest.get("getWorkProgress")));
			setPreloadReady(true);
		}
		catch (error) { console.log(error);/*TODO SOMETHING*/ }
	}





	const getPhaseTemplate = (children) => {
		return <section className="main-section pb-5">
			<div className="container">
				<header className="row py-4">
					<div className="col-12 col-md-6 px-0 text-center text-md-left">
						<img className="home-logo" src={logo} alt="" />
					</div>
				</header>
			</div>
			<div className="container bg-white px-4 pb-5">
				{children}
			</div>
		</section>
	}

	return (
		<>
			<Switch>

				<Route exact path="/admin/importer">
					{getPhaseTemplate(<Importer preloadReady={preloadReady} />)}
				</Route>
				<Route exact path="/admin/users">
					{getPhaseTemplate(<Users preloadReady={preloadReady} />)}
				</Route>
				<Route exact path="/admin/general-catalogs">
					{getPhaseTemplate(<GeneralCatalogs preloadReady={preloadReady} />)}
				</Route>
				<Route exact path="/admin/offices">
					{getPhaseTemplate(<Offices preloadReady={preloadReady} />)}
				</Route>
				<Route exact path="/admin/cities">
					{getPhaseTemplate(<Cities preloadReady={preloadReady} />)}
				</Route>
			</Switch>
			<Loading />
			<MessageAlert />
		</>
	)
}
export default PagesManager;