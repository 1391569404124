import React, { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'

import { useMainContext } from "../../../contexts/MainContext";
import { useCatalogsContext } from "../../../contexts/CatalogsContext";





const PresentationProyect = ({ edit, handlerFormData, phaseData, handlerImageData, isSubmited, initData }) => {

	// --------------------------------------
	// Component State and Contexts
	// --------------------------------------
	const mainContext = useMainContext();
	const [formData, setFormData] = useState(null);


	// --------------------------------------
	// Component Load, set Values when the
	// init data is available
	// --------------------------------------
	useEffect(() => {

		if (phaseData.phaseID) {
			initForm();
		} else {
			const data = {
				isAdjustedDepartment: false,
				isDeveloperOffice: false,
				isProjectOffice: false,
				isShowroom: false,
				observations: ''
			}
			setFormData({ ...formData, ...data });
		}
		mainContext.setIsLoading(false);
	}, [initData])


	// --------------------------------------
	// Update the Form Data
	// --------------------------------------
	useEffect(() => {
		handlerFormData(formData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formData])


	// TODO : add validation rule



	// --------------------------------------
	// Set Initial Values
	// --------------------------------------
	const initForm = async () => {

		// ? Get values
		const {
			isAdjustedDepartment,
			isDeveloperOffice,
			isProjectOffice,
			isShowroom,
			observations
		} = phaseData;

		const formattedData = {
			isAdjustedDepartment: isAdjustedDepartment,
			isDeveloperOffice: isDeveloperOffice,
			isProjectOffice: isProjectOffice,
			isShowroom: isShowroom,
			observations: observations
		}

		setFormData({
			...formData,
			isAdjustedDepartment: formattedData.isAdjustedDepartment,
			isDeveloperOffice: formattedData.isDeveloperOffice,
			isProjectOffice: formattedData.isProjectOffice,
			isShowroom: formattedData.isShowroom,
			observations: formattedData.observations
		});


	}


	// --------------------------------------
	// Set Data Values on Input Event
	// --------------------------------------
	const onFormDataChange = async (name, event) => {
		const proyectPresentatiosSlide = ["isDeveloperOffice", "isProjectOffice", "isShowroom", "isAdjustedDepartment"]
		let slide = proyectPresentatiosSlide.findIndex(el => el == name)
		const newData = { ...formData };

		if (name === 'observations') {
			newData[name] = event.target.value;
		} else if (slide != -1) {
			proyectPresentatiosSlide.forEach((el) => {
				if (el == name)
					newData[el] = !newData[el];
				else
					newData[el] = false;
			})
		} else {
			newData[name] = event.value;
		}

		mainContext.setIsDirty(true);
		await setFormData({ ...formData, ...newData });
	}


	if (!formData && formData === null)
		return null;


	return (
		<>
			<div className="my-4">
				<form autoComplete="off" className="custom-card">
					<div className="custom-card-header  d-flex justify-content-between align-items-center">
						<h4 className="px-3">PRESENTACIÓN DEL PROYECTO</h4>
					</div>
					<div className="custom-card-body px-3">

						<div className="row">
							<div className="col-3">
								<div className="form-group with-message">
									<label className="input-label form-label mb-4">Oficinas del desarrollador</label>
									<Form.Check
										disabled={false}
										type="switch"
										id="isDeveloperOffice-switch"
										label=""
										checked={formData.isDeveloperOffice}
										onChange={(e) => {
											onFormDataChange("isDeveloperOffice", {
												value: !formData.isDeveloperOffice
											})
										}}
									/>
								</div>
							</div>

							<div className="col-3">
								<div className="form-group with-message">
									<label className="input-label form-label mb-4">Oficinas del proyecto</label>
									<Form.Check
										disabled={false}
										type="switch"
										id="isProjectOffice-switch"
										label=""
										checked={formData.isProjectOffice}
										onChange={(e) => {
											onFormDataChange("isProjectOffice", {
												value: !formData.isProjectOffice
											})
										}}
									/>
								</div>
							</div>
							<div className="col-3">
								<div className="form-group with-message">
									<label className="input-label form-label mb-4">Showroom</label>
									<Form.Check
										disabled={false}
										type="switch"
										id="isShowroom-switch"
										label=""
										checked={formData.isShowroom}
										onChange={(e) => {
											onFormDataChange("isShowroom", {
												value: !formData.isShowroom
											})
										}}
									/>
								</div>
							</div>
							<div className="col-3">
								<div className="form-group with-message">
									<label className="input-label form-label mb-4">Departamento adecuado</label>
									<Form.Check
										disabled={false}
										type="switch"
										id="isAdjustedDepartment-switch"
										label=""
										checked={formData.isAdjustedDepartment}
										onChange={(e) => {
											onFormDataChange("isAdjustedDepartment", {
												value: !formData.isAdjustedDepartment
											})
										}}
									/>
								</div>
							</div>
						</div>


					</div>
				</form>
			</div >
			<div className="my-4">
				<form autoComplete="off" className="custom-card">
					<div className="custom-card-header  d-flex justify-content-between align-items-center">
						<h4 className="px-3">Notas / Observaciones</h4>
					</div>
					<div className="custom-card-body px-3">

						<div className="row">
							<div className="col-12">
								<div className="form-group with-message">

									<textarea
										value={formData.observations}
										maxLength="300"
										className="black-border form-control rounded-0"
										onChange={(e) => { onFormDataChange('observations', e); }}
									/>
									<span>{(formData.observations) ? formData.observations.length : "0"}/300</span>
								</div>
							</div>


						</div>


					</div>
				</form>
			</div >
		</>
	)
}

export default PresentationProyect
